<div class="sheet-group-select">
    <div>
        <h5>Cutting Group</h5>
        <p>Select cutting group</p>
        <p-dropdown class="p-mr-3" [options]="cuttingGroups" [(ngModel)]="selectedGroup"
            (ngModelChange)="changeGroup($event)">
            <ng-template let-item pTemplate="item">
                {{trimValue(item.thickness)}}
            </ng-template>
            <ng-template let-item pTemplate="selectedItem">
                {{trimValue(item.thickness)}}
            </ng-template>
        </p-dropdown>
    </div>
    <div class="current-sheet-select">
        <h5>Current Sheet</h5>
        <p>Select sheet to view and cut</p>
        <p-dropdown class="p-mr-3" [options]="sheets" [(ngModel)]="selectedSheet" (ngModelChange)="changePage($event)"
            [disabled]="sheetsLoading">
            <ng-template let-item pTemplate="item">
                {{sheets.indexOf(item)+1}}
            </ng-template>
            <ng-template let-item pTemplate="selectedItem">
                {{sheets.indexOf(item)+1}}
            </ng-template>
        </p-dropdown>
    </div>
</div>
