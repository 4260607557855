import {Component, Input, OnInit} from '@angular/core';
import {
    DefaultService,
    MachineInstance,
    VersionedMachine
} from '../../../../../generated-src';

@Component({
    selector: 'fab-machine-info',
    templateUrl: './machine-info.component.html',
    styleUrls: ['./machine-info.component.scss']
})
export class MachineInfoComponent implements OnInit {
    @Input() machine: MachineInstance;
    public userMachine: VersionedMachine;
    public loading: boolean;

    constructor(private api: DefaultService) {}

    ngOnInit(): void {
        this.loading = true;
        this.fetchMachineData(
            this.machine.machine.id,
            this.machine.machine.version
        );
    }

    public fetchMachineData(id: string, version: string) {
        this.api.getMachine(id, version).subscribe((machine) => {
            this.userMachine = machine;
            // console.log(this.userMachine);
            this.loading = false;
        });
    }
}
