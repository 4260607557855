import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VersionedBit} from 'generated-src';
import {DefaultValuesService} from '../../services/default-values.service';

@Component({
    selector: 'fab-material-settings',
    templateUrl: './material-settings.component.html',
    styleUrls: ['./material-settings.component.scss']
})
export class MaterialSettingsComponent implements OnInit {
    @Input() bit: VersionedBit;
    @Input() isEditable: boolean;
    @Input() isAdminDashboard: boolean;
    @Output() bitChange = new EventEmitter<VersionedBit>();

    constructor(private defaultValuesService: DefaultValuesService) {}

    ngOnInit(): void {}

    onChange(): void {
        this.bitChange.emit(Object.assign({}, this.bit));
    }

    onAddMaterial(): void {
        this.bit.document.materialSettings.push(this.defaultValuesService.getDefaultBitMaterial());
    }

    onRemoveMaterial(index: number): void {
        this.bit.document.materialSettings.splice(index, 1);
    }
}
