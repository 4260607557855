<p-accordionTab [selected]="true">
    <ng-template pTemplate="header" class="p-grid p-ai-end p-nogutter">
        <div class="p-col-3">
            <!-- Sheet thickness: -->
            {{ group.thickness | unit }}
        </div>
        <div class="p-col p-ml-2 p-formgroup-inline">
            <div class="selector">
                <fab-dropdown-with-loading-spinner
                    [values]="machines"
                    [(selectedValue)]="selectedMachine"
                    (selectedValueChange)="onMachineSelect($event)"
                    optionField="name"
                    [valuePlaceholder]="machineError ? 'Machine not found' : 'Select Machine'"
                    [isLoading]="machinesLoading"
                    [isSpinnerEnabled]="machinesLoading"
                    fabStopPropagation
                ></fab-dropdown-with-loading-spinner>
            </div>
            <div class="selector">
                <fab-dropdown-with-loading-spinner
                    [values]="materials"
                    [(selectedValue)]="selectedMaterial"
                    (selectedValueChange)="onMaterialSelect($event)"
                    valuePlaceholder="Select Material"
                    [isLoading]="materialsLoading"
                    [isSpinnerEnabled]="materialsLoading"
                    fabStopPropagation
                ></fab-dropdown-with-loading-spinner>
            </div>
        </div>
        <div *ngIf="group.machine && group.sheetSize" class="p-col">
            <fab-sheet-size-editor
                [(isEdited)]="isSheetSizeEdited"
                [sheetSize]="group.sheetSize"
                (sheetSizeChange)="onSheetSizeChange($event)"
                (onUpdateGroup)="onUpdateGroup()"
            ></fab-sheet-size-editor>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <ng-container *ngFor="let instance of group.instances; let n = index; let last = last">
            <div class="p-d-flex p-ai-center p-jc-between p-py-3">
                <div class="p-d-flex p-jc-center">
                    <img
                        [src]="
                            'api/projects/' +
                            project.id +
                            '/v/' +
                            project.version +
                            '/parts/' +
                            instance.partId +
                            '/thumbnail.png?token=' +
                            authToken
                        "
                        width="45%"
                        height="auto"
                        onError="this.onerror=null;this.src='../../assets/default/fabber_part_default.png';"
                        alt="{{ instance.name }}"
                    />
                </div>
                <div class="p-mx-4 p-my-2 p-d-flex">
                    <div class="p-ac-center">
                        <h4>{{ instance.name }}</h4>
                        <p *ngIf="instance.slice" class="text-muted">
                            {{ instance.slice.minimumSheetSize.length | unit }}
                            x
                            {{ instance.slice.minimumSheetSize.width | unit }}
                            x
                            {{ instance.slice.thickness | unit }}
                        </p>
                    </div>
                </div>
                <div class="p-mx-4 p-my-2 p-d-flex p-jc-center p-ai-center">
                    <label for="{{ instance.partId }}-copies" class="p-mr-2">Copies: </label>
                    <p-inputNumber
                        inputId="{{ instance.partId }}-copies"
                        class="n-off"
                        id="{{ instance.partId }}-off"
                        name="{{ instance.partId }}-off"
                        [(ngModel)]="instance.off"
                        [showButtons]="true"
                        tooltipPosition="top"
                        pTooltip="Select number off"
                        (ngModelChange)="onUpdateGroup()"
                        [min]="0"
                        value="1"
                        [max]="100"
                        [disabled]="disabled"
                    >
                    </p-inputNumber>
                </div>
                <div class="p-mr-2 p-d-flex p-jc-center">
                    <button
                        pButton
                        pTooltip="Open part 3D view"
                        tooltipPosition="top"
                        *ngIf="!inApp"
                        class="p-mr-2"
                        icon="fal fa-cube"
                        [routerLink]="['/projects', project.id, 'part', instance.partId]"
                        [queryParams]="{version: project.version}"
                    ></button>
                    <button
                        pButton
                        pTooltip="Edit part"
                        tooltipPosition="top"
                        class="p-mr-2"
                        icon="pi pi-ellipsis-h"
                        (click)="onPartEdit(instance)"
                    ></button>
                    <button
                        pButton
                        pTooltip="Delete part"
                        tooltipPosition="top"
                        class="p-button-danger p-mr-2"
                        icon="pi pi-times"
                        (click)="onPartRemove(instance)"
                    ></button>
                </div>
            </div>
            <p-divider *ngIf="!last || group.instances?.length > 1"></p-divider>
        </ng-container>
    </ng-template>
</p-accordionTab>
