import {Component, Input, OnInit} from '@angular/core';
import {CuttingGroup, DefaultService, ToolChoice, VersionedProject} from '../../../../../generated-src';
import {SheetsService} from '../../services/sheets.service';
import {AlertService} from '@harmanpa/ng-patchwork';

@Component({
    selector: 'fab-tooling',
    templateUrl: './tooling.component.html',
    styleUrls: ['./tooling.component.scss']
})
export class ToolingComponent implements OnInit {
    @Input() project: VersionedProject;
    toolingOptions: any[];
    selectedTooling: string;
    features: any[];
    groupsCount = 0;
    currentGroup = 1;
    cuttingGroup: CuttingGroup;
    toolchoices: ToolChoice[] = [];

    constructor(private api: DefaultService, private sheetsService: SheetsService, private alertService: AlertService) {
        this.toolingOptions = [{name: 'by Part'}, {name: 'by Feature'}];
        this.features = [
            {name: 'Pockets'},
            {name: 'Drill'},
            {name: 'Holes'},
            {name: 'Cutout'}
        ];
    }

    ngOnInit(): void {
        console.log('TOOLS COMPONENT A');
        this.sheetsService.observeGroupChoice().subscribe((groupChoice) => {
            [this.currentGroup, this.groupsCount, this.cuttingGroup] = groupChoice;
            console.log('TOOLS COMPONENT B');
            this.api.getProjectGroupToolChoices(this.project.id, this.project.version, (this.currentGroup + 1).toFixed(0)).subscribe(
                {
                    next:
                        choices => {
                            this.toolchoices = choices;
                            console.log('TOOLS', this.toolchoices);
                        },
                    error: err => console.error(err)
                }
            );
        });
    }
}
