<h5 class="p-mt-3">Choose from {{ bits.length }} standard bit configurations.</h5>
<p-table
    #tasksTable
    [value]="bits"
    [rows]="10"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [autoLayout]="true"
    [resizableColumns]="true"
    [globalFilterFields]="['data.name']"
    [rowHover]="true"
>
    <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                    pInputText
                    type="text"
                    (input)="tasksTable.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search by name..."
                />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th>Product id</th>
            <th>Name</th>
            <th>Type</th>
            <th pSortableColumn="data.diameter">
                Diameter
                <p-sortIcon field="data.diameter"></p-sortIcon>
            </th>
            <th pSortableColumn="data.fluteLength">
                Flute length
                <p-sortIcon field="data.fluteLength"></p-sortIcon>
            </th>
            <th pSortableColumn="data.manufacturer">
                Manufacturer
                <p-sortIcon field="data.manufacturer"></p-sortIcon>
            </th>
            <th pSortableColumn="data.numberOfFlutes">
                No. flutes
                <p-sortIcon field="data.numberOfFlutes"></p-sortIcon>
            </th>
            <th pSortableColumn="data.overallLength">
                Overall length
                <p-sortIcon field="data.overallLength"></p-sortIcon>
            </th>
            <th pSortableColumn="data.shaftDiameter">
                Shaft diameter
                <p-sortIcon field="data.shaftDiameter"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-bit>
        <tr (click)="viewBit(bit)" style="cursor: pointer">
            <td>
                <div>
                    <button
                        pButton
                        pTooltip="Add {{ bit.data.name }} to my workshop"
                        tooltipPosition="top"
                        class="p-button-text p-mr-1"
                        icon="pi pi-plus"
                        (click)="addBit(bit, $event)"
                        fabStopPropagation
                    ></button>
                    <button
                        pButton
                        pTooltip="Configure {{ bit.data.name }}"
                        tooltipPosition="top"
                        class="p-button-secondary"
                        icon="pi pi-sliders-v"
                        *ngIf="!bit.permissions.write"
                        (click)="copy(bit, $event)"
                        fabStopPropagation
                    ></button>
                    <button
                        pButton
                        pTooltip="delete {{ bit.data.name }}"
                        tooltipPosition="top"
                        *ngIf="bit.permissions.write"
                        class="p-button-danger"
                        icon="pi pi-trash"
                        (click)="remove(bit)"
                        fabStopPropagation
                    ></button>
                </div>
            </td>
            <td>
                {{ bit.data.productId }}
            </td>
            <td>
                {{ bit.data.name }}
            </td>
            <td>
                {{ bit.data.type }}
            </td>
            <td>
                {{ bit.data.diameter | unit }}
            </td>
            <td>
                {{ bit.data.fluteLength | unit }}
            </td>
            <td>
                {{ bit.data.manufacturer }}
            </td>
            <td>
                {{ bit.data.numberOfFlutes }}
            </td>
            <td>
                {{ bit.data.overallLength | unit }}
            </td>

            <td>
                {{ bit.data.shaftDiameter | unit }}
            </td>
        </tr>
    </ng-template>
    <!-- <ng-template pTemplate="summary">
        <div class="p-d-flex p-ai-center p-jc-between">
            In total there are
            {{ bits ? bits.length : 0 }} bits.
        </div>
    </ng-template> -->
</p-table>
