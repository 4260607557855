import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from 'ng2-ui-auth';
import {Subject} from 'rxjs';
import {DefaultService, VersionedPartMap, VersionedProject} from '../../../../generated-src';
import {LoadingManagerService} from '@harmanpa/ng-cae';
import {debounceTime} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import * as THREE from 'three';

@Component({
    selector: 'fab-assembly',
    templateUrl: './assembly.component.html',
    styleUrls: ['./assembly.component.scss']
})
export class AssemblyComponent implements OnInit {
    project: VersionedProject;
    parts: VersionedPartMap;
    pi = Math.PI;
    halfpi = (-1 * this.pi) / 2;
    wheelAction = 4;
    initialLoadCompleted = false;
    initialLoadComplete = new Subject<boolean>();
    white = new THREE.Color(1, 1, 1).convertLinearToSRGB();
    black = new THREE.Color(0, 0, 0).convertLinearToSRGB();
    multipleViews = false;

    constructor(
        private api: DefaultService,
        private route: ActivatedRoute,
        public auth: AuthService,
        private loadingManager: LoadingManagerService,
        private title: Title
    ) {}

    ngOnInit() {
        this.project = this.route.snapshot.data['project'];
        this.title.setTitle(`View - ${this.project.document.name}`);
        this.api.getProjectParts(this.project.id, this.project.version).subscribe((parts) => {
            this.parts = parts;
            console.log(parts.document);
        });
        this.loadingManager.loading.pipe(debounceTime(200)).subscribe((loading) => {
            if (!loading) {
                this.loadComplete();
            }
        });
    }

    geometryUrl(part: string) {
        return (
            '/api/projects/' + this.project.id + '/v/' + this.project.version + '/parts/' + part + '/stl.stl?token=' + this.auth.getToken()
        );
    }

    loadComplete(): void {
        // // Trigger actions on initial load
        if (!this.initialLoadCompleted) {
            this.initialLoadComplete.next(true);
            this.initialLoadComplete.complete();
            this.initialLoadCompleted = true;
            console.log('load complete');
        }
    }
}
