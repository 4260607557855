<div class="card">
    <div class="p-p-5 p-d-flex p-ai-center">
        <h4 class="p-mr-2" style="margin-bottom: 0px">MY FABBER TOOLS</h4>
        <button
            pButton
            pTooltip="Refresh my workshop"
            tooltipPosition="top"
            class="p-button-text p-mr-2"
            icon="pi pi-refresh"
            (click)="reload(null)"
            [loading]="loading"
        ></button>
        <!-- <button
            pButton
            
            pTooltip="Go to Fabber Library"
            tooltipPosition="top"
            class="p-button-raised p-mr-2 p-ml-auto"
            label="Fabber Library"
            icon="pi pi-arrow-left"
            [routerLink]="['/machines']"
        ></button> -->
    </div>
    <!-- <div>
        <p-tabView styleClass="tabview-custom" *ngIf="!loading && machines && bits" [(activeIndex)]="activeTab">
            <p-tabPanel header="Machines">
                <fab-machines-panel [machines]="machines" [bits]="bits" [(workshop)]="workshop"></fab-machines-panel>
            </p-tabPanel>
            <p-tabPanel header="Bits">
                <fab-bits-panel [bits]="bits" [(workshop)]="workshop" (reloadChange)="reload($event)"></fab-bits-panel>
            </p-tabPanel>
            <p-tabPanel header="Post Processors">
                <fab-post-processors-panel [postpos]="postpos" [loading]="loading" (reloadChange)="reload($event)"></fab-post-processors-panel>
            </p-tabPanel>
        </p-tabView>
    </div> -->
    <div>
        <p-tabView *ngIf="!loading" [(activeIndex)]="activeTab">
            <p-tabPanel header="Workshop Machines">
                <fab-user-workshop-machines-panel
                    [machines]="machinesArray"
                    [workshop]="workshop"
                    [authToken]="authToken"
                    (reloadChange)="reload($event)"
                ></fab-user-workshop-machines-panel>
            </p-tabPanel>
            <p-tabPanel header="My Machines">
                <fab-machines-panel
                    [machines]="machines"
                    [bits]="bits"
                    [(workshop)]="workshop"
                    (reloadChange)="reload($event)"
                ></fab-machines-panel>
            </p-tabPanel>
            <p-tabPanel header="My Bits">
                <fab-bits-panel [bits]="bits" [(workshop)]="workshop" (reloadChange)="reload($event)"></fab-bits-panel>
            </p-tabPanel>
            <p-tabPanel header="My Post Processors">
                <fab-post-processors-panel
                    [postpos]="postpos"
                    [loading]="loading"
                    (reloadChange)="reload($event)"
                ></fab-post-processors-panel>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>

<!-- <div *ngIf="machinesArray.length == 0">
                    <h6>Please add at least one machine to start Manufacturing.</h6>
                </div>
                <p-dataView #userViewMachines [value]="machinesArray" [paginator]="false" rows="10" filterBy="value.name" layout="grid">
                    <ng-template pTemplate="header">
                        <div class="p-d-flex p-flex-column p-ai-center p-flex-md-row p-jc-md-between">
                            <div class="p-d-flex">
                                <span class="p-input-icon-left p-mb-2 p-mb-md-0 p-mr-2">
                                    <i class="pi pi-search"></i>
                                    <input
                                        type="search"
                                        pInputText
                                        placeholder="Search by name..."
                                        (input)="userViewMachines.filter($event.target.value)"
                                    />
                                </span>
                            </div>
                            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                        </div>
                    </ng-template>
                    <ng-template let-machine pTemplate="gridItem">
                        <div class="p-col-3 p-p-2" *ngIf="machine.value">
                            <p-panel class="public">
                                <p-header class="p-d-flex p-jc-between p-ai-center">
                                    <img
                                        [src]="
                                            'api/machines/' +
                                            machine.value.machine.id +
                                            '/v/' +
                                            machine.value.machine.version +
                                            '/thumbnail.png?token=' +
                                            authToken
                                        "
                                        width="40"
                                        height="40"
                                        onError="this.onerror=null;this.src='../assets/brands/machine.png';"
                                        alt="{{ machine.value.name }}"
                                    />
                                    <h5 class="p-pl-3 p-m-0 p-text-bold">
                                        {{ machine.value.name }}
                                    </h5>
                                </p-header>
                                <div class="p-d-flex p-ai-end p-jc-between">
                                    <div class="machine-card">
                                        <fab-machine-info [machine]="machine.value"></fab-machine-info>
                                    </div>
                                    <div class="p-d-flex">
                                        <button
                                            pButton
                                            
                                            type="button"
                                            icon="pi pi-pencil"
                                            class="p-ml-auto p-mr-1"
                                            tooltipPosition="top"
                                            pTooltip="Edit {{ machine.name }}"
                                            [routerLink]="['/workshop', workshop.id, 'machine', machine.key]"
                                        ></button>
                                        <button
                                            pButton
                                            
                                            type="button"
                                            icon="pi pi-clone"
                                            class="p-mr-1 p-button-outlined"
                                            tooltipPosition="top"
                                            pTooltip="Duplicate {{ machine.name }}"
                                            (click)="duplicate(machine.value)"
                                        ></button>
                                        <button
                                            pButton
                                            
                                            type="button"
                                            icon="pi pi-trash"
                                            class="p-button-danger"
                                            tooltipPosition="top"
                                            pTooltip="Delete {{ machine.name }}"
                                            (click)="remove(machine.key)"
                                        ></button>
                                    </div>
                                </div>
                            </p-panel>
                        </div>
                    </ng-template>
                    <ng-template let-machine pTemplate="listItem">
                        <div class="p-col-12 p-my-2">
                            <p-panel class="public">
                                <p-header class="p-d-flex p-jc-between p-ai-center">
                                    <img
                                        [src]="'api/machines/' + machine.id + '/v/' + machine.head + '/thumbnail.png?token=' + authToken"
                                        width="40"
                                        height="40"
                                        onError="this.onerror=null;this.src='../assets/brands/machine.png';"
                                        alt="{{ machine.name }}"
                                    />
                                    <p class="p-ml-3">
                                        {{ machine.value.name }}
                                    </p>
                                </p-header>
                                <div class="p-d-flex p-jc-between">
                                    <div class="p-ml-6 p-as-stretch">
                                        <fab-machine-info [machine]="machine.value"></fab-machine-info>
                                    </div>
                                    <div class="p-mr-2 p-mt-3 p-as-end p-d-flex">
                                        <button
                                            pButton
                                            
                                            type="button"
                                            icon="pi pi-pencil"
                                            class="p-ml-auto p-mr-1"
                                            tooltipPosition="top"
                                            pTooltip="Edit {{ machine.value.name }}"
                                            [routerLink]="['/workshop', workshop.id, 'machine', machine.key]"
                                        ></button>
                                        <button
                                            pButton
                                            
                                            type="button"
                                            icon="pi pi-clone"
                                            class="p-mr-1 p-button-outlined"
                                            tooltipPosition="top"
                                            pTooltip="Duplicate {{ machine.name }}"
                                            (click)="duplicate(machine.value)"
                                        ></button>
                                        <button
                                            pButton
                                            
                                            type="button"
                                            icon="pi pi-trash"
                                            class="p-button-danger"
                                            tooltipPosition="top"
                                            pTooltip="Delete {{ machine.value.name }}"
                                            (click)="remove(machine.key)"
                                        ></button>
                                    </div>
                                </div>
                            </p-panel>
                        </div>
                    </ng-template>
                </p-dataView> -->
<!-- </p-tabView>
    </div>
</div> -->
