<div class="p-ml-6">
    <h5 *ngIf="!inApp" class="p-text-bold">Project summary</h5>
    <div class="p-my-5" *ngIf="!inApp">
        <button
            pButton
            pTooltip="Open project 3D view"
            tooltipPosition="top"
            class="p-button-outlined p-button-rounded p-text-bold"
            label="View complete design"
            icon="fas fa-cube fa-2x"
            [routerLink]="['/projects', project.id, 'view']"
            [queryParams]="{version: project.version}"
        ></button>
    </div>
    <div class="p-my-5 p-field" *ngIf="!inApp">
        <h6><strong>Name:</strong> {{ project.document.name }}</h6>
    </div>
    <div class="p-my-5 p-field" *ngIf="!inApp">
        <p><strong>CAD tool:</strong> {{ project.document.cad.provider | titlecase }}</p>
    </div>
    <div class="p-my-5 p-field">
        <p *ngIf="!project.permissions.write"><strong>Shared:</strong> Private</p>
        <p *ngIf="project.permissions.write"><strong>Shared:</strong> Public</p>
    </div>

    <div class="p-my-5 p-field" *ngIf="cuttingList">
        <label class="p-text-bold p-mr-2" for="project-off">Copies: </label>
        <p-inputNumber
            class="n-off ml-1"
            id="project-off"
            [(ngModel)]="projectOff"
            [showButtons]="true"
            inputId="minmax-buttons"
            [min]="1"
            value="1"
            [max]="100"
            (ngModelChange)="updateOff()"
            [disabled]="disabled"
            tooltipPosition="right"
            pTooltip="Select number off"
        >
        </p-inputNumber>
    </div>
    <div class="p-my-5 p-field" *ngIf="cuttingList">
        <p>
            Total of {{ nInstances }} instances of <strong>{{ nParts }} parts </strong>to be manufactured
        </p>
    </div>
</div>
