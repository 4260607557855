import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {AlertService, CacheService} from '@harmanpa/ng-patchwork';
import {BitService} from '@services/bit.service';
import {WorkshopService} from '@services/workshop.service';
import {BitInstance, DefaultService, DocumentSummary, MachineInstance, VersionedMachine, VersionedWorkshop} from 'generated-src';
import {AuthService} from 'ng2-ui-auth';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PromptDialogComponent} from 'src/app/shared/components/prompt-dialog/prompt-dialog.component';
import {NamingService} from '../../services/naming.service';
import {AddBitsPopUpComponent} from '../add-bits-pop-up/add-bits-pop-up.component';

@Component({
    selector: 'fab-machines-panel',
    templateUrl: './machines-panel.component.html',
    styleUrls: ['./machines-panel.component.scss']
})
export class MachinesPanelComponent implements OnInit {
    @Input() machines: DocumentSummary[];
    @Input() bits: DocumentSummary[];
    @Input() workshop: VersionedWorkshop;
    @Output() workshopChange: EventEmitter<VersionedWorkshop> = new EventEmitter();
    @Output() reloadChange: EventEmitter<boolean> = new EventEmitter();
    authToken: string;
    ref: DynamicDialogRef;

    constructor(
        private alertService: AlertService,
        private dialogService: DialogService,
        private workshopService: WorkshopService,
        private bitService: BitService,
        private authService: AuthService,
        private api: DefaultService,
        private router: Router,
        private namingService: NamingService,
        private cacheService: CacheService,
        private title: Title
    ) {}

    ngOnInit(): void {
        this.authToken = this.authService.getToken();
    }

    setupMachine(machine: DocumentSummary, event: any): void {
        const {data} = machine;
        const bitsList = this.bitService.filterMatchingBitsByColletSizeRange(this.bits, data.minColletSize, data.maxColletSize);
        this.showPopUp(machine, bitsList);
        event.target.blur();
    }

    private showPopUp(machine: DocumentSummary, bitsList: DocumentSummary[]): void {
        this.ref = this.dialogService.open(AddBitsPopUpComponent, {
            header: `Add bits to ${machine.data.name}`,
            width: '40%',
            contentStyle: {height: 'auto', overflow: 'auto'},
            baseZIndex: 10000,
            data: {
                bits: bitsList,
                machine: machine
            }
        });

        this.ref.onClose.subscribe((chosenBits: BitInstance[]) => {
            if (chosenBits) {
                this.namingService.setMachine(machine);
                this.workshopChange.emit(this.namingService.addUserWorkshopMachine(this.workshop, chosenBits));
                // this.addMachine(machine, chosenBits);
            }
        });
    }

    // private addMachine(machine: DocumentSummary, bits: BitInstance[]): void {
    //     console.log(this.workshop, this.workshop.id, this.workshop.version);
    //     const machineInstance: MachineInstance = {
    //         name: machine.data['name'],
    //         machine: {
    //             type: machine.type,
    //             id: machine.id,
    //             version: machine.head
    //         },
    //         toolTable: {bits: bits}
    //     };
    //     this.workshopService.addMachine(this.workshop, machineInstance).subscribe({
    //         next: (w) => {
    //             this.workshop.version = w.version;
    //         },
    //         error: (err) => {
    //             this.alertService.error(err);
    //         },
    //         complete: () => {
    //             this.alertService.success(`Machine ${machineInstance.name} added to your Workshop`);
    //             this.workshopChange.emit(this.workshop);
    //         }
    //     });
    // }

    copy(machine: DocumentSummary, event: any): void {
        this.namingService.setMachine(machine);
        this.namingService.copyMachine();
        event.target.blur();
        // this.api.findMachines(true, false, true, false, false, false, false).subscribe((machines) => {
        //     this.ref = this.dialogService.open(PromptDialogComponent, {
        //         header: 'Configure machine',
        //         width: '50%',
        //         contentStyle: {overflow: 'visible'},
        //         data: {
        //             type: "machine",
        //             currentName: machine.data.name,
        //             existingNames: machines.map(element => element.data.name)
        //         }
        //     });

        //     this.ref.onClose.subscribe((machineName: string | undefined) => {
        //         if(machineName){
        //             const machineCopy = JSON.parse(JSON.stringify(machine));
        //             this.api.getMe().subscribe((user) => {
        //                 machineCopy.owner = user.email;
        //                 machineCopy.data.name = machineName;
        //                 this.api.addMachine(machineCopy.data, machine.id, machine.head).subscribe({
        //                     next: (m) => {
        //                         this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //                         this.router.navigate(['/machines', m.id], {
        //                             queryParams: {version: m.version},
        //                             state: {source: "Workshop"}
        //                         });
        //                     },
        //                     error: (err) => {
        //                         this.alertService.error(err);
        //                     },
        //                     complete: () => {
        //                         this.alertService.success('Machine ' + machineCopy.data.name + ' was added to your ToolBox');
        //                     }
        //                 });
        //             });
        //         }
        //     });
        // })

        // const machineCopy = JSON.parse(JSON.stringify(machine));
        // this.api.getMe().subscribe((user) => {
        //     machineCopy.owner = user.email;
        //     machineCopy.data.name = machine.data.name + ' - Copy';
        //     this.api.addMachine(machineCopy.data, machine.id, machine.head).subscribe({
        //         next: (m) => {
        //             this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //             this.router.navigate(['/machines', m.id], {
        //                 queryParams: {version: m.version},
        //                 state: {source: "Workshop"}
        //             });
        //         },
        //         error: (err) => {
        //             this.alertService.error(err);
        //         },
        //         complete: () => {
        //             this.alertService.success('Machine ' + machineCopy.data.name + ' was added to your ToolBox');
        //         }
        //     });
        // });
    }

    reload(): void {
        this.reloadChange.emit(true);
    }

    viewMachine(machine: DocumentSummary) {
        this.namingService.setMachine(machine);
        this.router.navigate([`/${this.title.getTitle().toLowerCase()}/machines`, machine.id], {
            queryParams: {version: machine.head},
            state: {source: this.title.getTitle()}
        });
    }

    remove(machine: DocumentSummary) {
        this.api.deleteMachine(machine.id).subscribe({
            next: () => {
                this.reload();
            },
            error: (err) => {
                this.alertService.error(err);
            },
            complete: () => {
                this.alertService.success('Machine was removed');
            }
        });
    }
}
