<div class="container" *ngIf="bit">
    <h3 class="p-my-5 p-mx-6">
        <span *ngIf="isEditable">Edit</span>
        <span *ngIf="!isEditable">View</span>
        Bit: {{ bit.document.name }}
    </h3>
</div>

<!-- <section class="p-p-4">
    <p-card styleClass="p-px-4" *ngIf="bit"> -->
<div class="container" *ngIf="bit">
    <fab-bit-params [(bit)]="bit" (bitChange)="onChange($event)" [isEditable]="isEditable"></fab-bit-params>
    <fab-material-settings
        [(bit)]="bit"
        (bitChange)="onChange($event)"
        [isEditable]="isEditable"
        [isAdminDashboard]="isAdminDashboard"
    ></fab-material-settings>

    <div class="p-my-5 p-d-flex p-jc-end">
        <button
            pButton
            pTooltip="Back to Library"
            tooltipPosition="top"
            *ngIf="isInLibrary && !isAdminDashboard"
            class="p-mr-2"
            label="Back"
            icon="pi pi-arrow-left"
            [routerLink]="['/library']"
            [state]="{activeTab: 1}"
        ></button>
        <button
            pButton
            pTooltip="Back to Workshop"
            tooltipPosition="top"
            *ngIf="!isInLibrary && !isAdminDashboard"
            class="p-mr-2"
            label="Back"
            icon="pi pi-arrow-left"
            [routerLink]="['/workshop', workshop.id]"
            [state]="{activeTab: 2}"
        ></button>
        <button
            pButton
            pTooltip="Configure {{ bit.document.name }}"
            tooltipPosition="top"
            *ngIf="!isEditable && isInLibrary && !isAdminDashboard"
            class="p-mr-2"
            label="Configure"
            (click)="copy($event)"
        ></button>
        <button
            pButton
            pTooltip="Add bit to Workshop Machine"
            tooltipPosition="top"
            *ngIf="!isAdminDashboard"
            class="p-mr-2"
            label="Add bit"
            (click)="addBit()"
        ></button>
    </div>
</div>
<!-- </p-card>
</section> -->
