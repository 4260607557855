/**
 * Fabber API
 * CAD to CNC in seconds
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ToolpathStrategy { 
    orderStrategy?: ToolpathStrategy.OrderStrategyEnum;
    infillStrategy?: ToolpathStrategy.InfillStrategyEnum;
    rasterAngle?: string;
    infillStepover?: number;
    internalCornerStrategy?: ToolpathStrategy.InternalCornerStrategyEnum;
    externalCornerStrategy?: ToolpathStrategy.ExternalCornerStrategyEnum;
    getvCarveInternalCornerStrategy?: ToolpathStrategy.GetvCarveInternalCornerStrategyEnum;
    getvCarveExternalCornerStrategy?: ToolpathStrategy.GetvCarveExternalCornerStrategyEnum;
    jogHeight?: string;
    xHome?: string;
    yHome?: string;
    punchThroughDepth?: string;
    superSkinThickness?: string;
    zeroStrategy?: ToolpathStrategy.ZeroStrategyEnum;
    edgeStrategy?: ToolpathStrategy.EdgeStrategyEnum;
    jogHeightStrategy?: ToolpathStrategy.JogHeightStrategyEnum;
    holdingStrategy?: ToolpathStrategy.HoldingStrategyEnum;
    drillStrategy?: ToolpathStrategy.DrillStrategyEnum;
}
export namespace ToolpathStrategy {
    export type OrderStrategyEnum = 'ByPart' | 'ByFeatureType' | 'ByTool';
    export const OrderStrategyEnum = {
        ByPart: 'ByPart' as OrderStrategyEnum,
        ByFeatureType: 'ByFeatureType' as OrderStrategyEnum,
        ByTool: 'ByTool' as OrderStrategyEnum
    };
    export type InfillStrategyEnum = 'None' | 'SpiralIn' | 'SpiralOut' | 'Raster' | 'Grid' | 'Hex';
    export const InfillStrategyEnum = {
        None: 'None' as InfillStrategyEnum,
        SpiralIn: 'SpiralIn' as InfillStrategyEnum,
        SpiralOut: 'SpiralOut' as InfillStrategyEnum,
        Raster: 'Raster' as InfillStrategyEnum,
        Grid: 'Grid' as InfillStrategyEnum,
        Hex: 'Hex' as InfillStrategyEnum
    };
    export type InternalCornerStrategyEnum = 'None' | 'TBone' | 'DogBone';
    export const InternalCornerStrategyEnum = {
        None: 'None' as InternalCornerStrategyEnum,
        TBone: 'TBone' as InternalCornerStrategyEnum,
        DogBone: 'DogBone' as InternalCornerStrategyEnum
    };
    export type ExternalCornerStrategyEnum = 'Round' | 'FlyPast';
    export const ExternalCornerStrategyEnum = {
        Round: 'Round' as ExternalCornerStrategyEnum,
        FlyPast: 'FlyPast' as ExternalCornerStrategyEnum
    };
    export type GetvCarveInternalCornerStrategyEnum = 'Sharp' | 'Round';
    export const GetvCarveInternalCornerStrategyEnum = {
        Sharp: 'Sharp' as GetvCarveInternalCornerStrategyEnum,
        Round: 'Round' as GetvCarveInternalCornerStrategyEnum
    };
    export type GetvCarveExternalCornerStrategyEnum = 'Round' | 'FlyPast';
    export const GetvCarveExternalCornerStrategyEnum = {
        Round: 'Round' as GetvCarveExternalCornerStrategyEnum,
        FlyPast: 'FlyPast' as GetvCarveExternalCornerStrategyEnum
    };
    export type ZeroStrategyEnum = 'Table' | 'TopOfMaterial';
    export const ZeroStrategyEnum = {
        Table: 'Table' as ZeroStrategyEnum,
        TopOfMaterial: 'TopOfMaterial' as ZeroStrategyEnum
    };
    export type EdgeStrategyEnum = 'None' | 'SuperEdge';
    export const EdgeStrategyEnum = {
        None: 'None' as EdgeStrategyEnum,
        SuperEdge: 'SuperEdge' as EdgeStrategyEnum
    };
    export type JogHeightStrategyEnum = 'SetHeight' | 'SafeZ';
    export const JogHeightStrategyEnum = {
        SetHeight: 'SetHeight' as JogHeightStrategyEnum,
        SafeZ: 'SafeZ' as JogHeightStrategyEnum
    };
    export type HoldingStrategyEnum = 'Vacuum' | 'SuperSkin' | 'Tabs';
    export const HoldingStrategyEnum = {
        Vacuum: 'Vacuum' as HoldingStrategyEnum,
        SuperSkin: 'SuperSkin' as HoldingStrategyEnum,
        Tabs: 'Tabs' as HoldingStrategyEnum
    };
    export type DrillStrategyEnum = 'None' | 'Peck';
    export const DrillStrategyEnum = {
        None: 'None' as DrillStrategyEnum,
        Peck: 'Peck' as DrillStrategyEnum
    };
}
