<div class="card container-fluid mt-2" *ngIf="postProcessor">
    <div class="p-d-flex p-jc-between">
        <h3 class="p-my-5 p-mx-6">
            <span *ngIf="isEditable">Edit</span>
            <span *ngIf="!isEditable">View</span>
            Postprocessor: {{ postProcessor.name }}
        </h3>
        <div class="p-p-5 p-d-flex">
            <button
                pButton
                pTooltip="Go to Fabber Library"
                tooltipPosition="top"
                *ngIf="isInLibrary && !isAdminDashboard"
                class="p-button-raised p-mr-2 p-ml-auto"
                label="Fabber Library"
                icon="pi pi-arrow-left"
                [routerLink]="['/library']"
                [state]="{activeTab: 2}"
            ></button>
            <button
                pButton
                pTooltip="Back to Fabber Workshop"
                tooltipPosition="top"
                *ngIf="!isInLibrary && !isAdminDashboard"
                class="p-button-raised p-mr-2 p-ml-auto"
                label="Fabber Workshop"
                icon="pi pi-arrow-left"
                [routerLink]="['/workshop', workshop.id]"
                [state]="{activeTab: 3}"
            ></button>
        </div>
    </div>
    <p-tabView>
        <p-tabPanel header="Details">
            <div class="p-grid p-fluid p-pt-3 p-px-6">
                <div class="p-col-4 p-px-4 p-field">
                    <pwk-text-input
                        label="Postprocessor Name"
                        [(text)]="postProcessor.name"
                        (textChange)="onChange()"
                        [debounce]="1000"
                        [disabled]="!isEditable"
                    ></pwk-text-input>
                </div>
                <div class="p-col-4 p-px-4">
                    <pwk-text-input
                        label="Content Type"
                        [(text)]="postProcessor.contentType"
                        (textChange)="onChange()"
                        [debounce]="1000"
                        [disabled]="!isEditable"
                    ></pwk-text-input>
                </div>
                <div class="p-col-4 p-px-4">
                    <pwk-text-input
                        label="File extension"
                        [(text)]="postProcessor.fileExtension"
                        (textChange)="onChange()"
                        [debounce]="1000"
                        [disabled]="!isEditable"
                    ></pwk-text-input>
                </div>
            </div>
            <div class="p-grid p-fluid p-pt-3 p-px-6">
                <div class="p-col-4 p-px-4 p-field">
                    <label class="p-mb-2">Z-Direction strategy</label>
                    <p-selectButton
                        [options]="[
                            {name: 'Positive Up', value: 'PositiveUp'},
                            {name: 'Positive Down', value: 'PositiveDown'}
                        ]"
                        optionLabel="name"
                        optionValue="value"
                        [(ngModel)]="postProcessor.zDirectionStrategy"
                        (ngModelChange)="onChange()"
                        [disabled]="!isEditable"
                    ></p-selectButton>
                </div>
                <div class="p-col-4 p-px-4 p-field">
                    <label class="p-mb-2">Offset strategy</label>
                    <p-selectButton
                        [options]="[
                            {name: 'Offsetting', value: 'Offsetting'},
                            {name: 'No Offsetting', value: 'NoOffsetting'}
                        ]"
                        optionLabel="name"
                        optionValue="value"
                        [(ngModel)]="postProcessor.offsetStrategy"
                        (ngModelChange)="onChange()"
                        [disabled]="!isEditable"
                    ></p-selectButton>
                </div>
                <div class="p-col-4 p-px-4 p-field">
                    <label class="p-mb-2">Arc strategy</label>
                    <p-selectButton
                        [options]="[
                            {name: 'Arcs', value: 'Arcs'},
                            {name: 'Lines', value: 'Lines'}
                        ]"
                        optionLabel="name"
                        optionValue="value"
                        [(ngModel)]="postProcessor.arcStrategy"
                        (ngModelChange)="onChange()"
                        [disabled]="!isEditable"
                    ></p-selectButton>
                </div>
            </div>
            <div class="p-grid p-fluid p-pt-3 p-px-6">
                <div class="p-col-4 p-px-4 p-field">
                    <label class="p-mb-2">Format</label>
                    <p-selectButton
                        [options]="[{name: 'Fabber', value: 'Fabber'}]"
                        optionLabel="name"
                        optionValue="value"
                        [(ngModel)]="postProcessor.format"
                        (ngModelChange)="onChange()"
                        [disabled]="!isEditable"
                    ></p-selectButton>
                </div>
                <div class="p-col-8 p-px-4 p-field">
                    <label class="p-mb-2">Description</label>
                    <textarea
                        rows="5"
                        cols="30"
                        pInputTextarea
                        [(ngModel)]="postProcessor.description"
                        (focusout)="onChange()"
                        [disabled]="!isEditable"
                    ></textarea>
                </div>
            </div>
            <div class="p-pt-3 p-px-4 p-d-flex p-jc-end p-px-6">
                <button
                    pButton
                    class="p-mr-2"
                    label="Configure"
                    pTooltip="Configure {{ postProcessor.name }}"
                    tooltipPosition="top"
                    (click)="copy($event)"
                    *ngIf="!isEditable && isInLibrary"
                ></button>
                <!-- <button
                    pButton
                    
                    pTooltip="Configure {{ postProcessor.name }}"
                    tooltipPosition="top"
                    *ngIf="!isEditable && isInLibrary"
                    class="p-mr-2"
                    label="Configure"
                    (click)="copy(versionedPost)"
                ></button> -->
            </div>
            <p-divider></p-divider>

            <div class="p-px-6 p-py-3">
                <h5>Editor:</h5>
                <div class="p-grid p-fluid p-pt-3 p-px-6">
                    <div class="p-col-4 p-mb-3 p-px-4 p-field">
                        <label class="p-mb-2">Project for example</label>
                        <pwk-typed-selector
                            type="Project"
                            [reference]="postProcessor.exampleProject"
                            (referenceChange)="onProjectChange($event)"
                            [disabled]="!isEditable"
                            [includePublic]="true"
                        ></pwk-typed-selector>
                    </div>
                    <div class="p-col-4 p-mb-3 p-px-4 p-field">
                        <label class="p-mb-2">Sheet for example</label>
                        <p-dropdown
                            [options]="projectSheetChoices"
                            [disabled]="projectSheetChoices.length === 0 || sheetsLoading || !isEditable"
                            optionValue="reference"
                            optionLabel="reference.path"
                            [showClear]="true"
                            [placeholder]="projectSheetChoices.length > 0 ? 'Select sheet' : 'Select a project with sheets first'"
                            [ngModel]="postProcessor.exampleProjectSheet"
                            (ngModelChange)="onSheetChange($event)"
                        ></p-dropdown>
                    </div>
                </div>
                <div class="p-d-flex p-jc-around p-py-2">
                    <h6 style="margin: 0">Input:</h6>
                    <h6 style="margin: 0">Output:</h6>
                </div>
                <as-split class="p-px-3" direction="horizontal" style="height: 500px">
                    <as-split-area [size]="50">
                        <ngx-codemirror
                            #codemirrorEditorRef
                            [(ngModel)]="postProcessor.template"
                            (ngModelChange)="onTemplateChange($event)"
                            [options]="{
                                lineNumbers: true,
                                lineWrapping: true,
                                readOnly: !isEditable,
                                mode: 'StringTemplate',
                                theme: 'default',
                                lint: true,
                                dragAndDrop: true,
                                autoCloseTags: true
                            }"
                        ></ngx-codemirror>
                    </as-split-area>

                    <as-split-area [size]="50">
                        <ngx-codemirror
                            #codemirrorEditorRef
                            [(ngModel)]="output"
                            [options]="{
                                lineNumbers: false,
                                readOnly: true,
                                theme: 'default'
                            }"
                        ></ngx-codemirror>
                    </as-split-area>
                </as-split>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Unit Preferences" class="p-px-4">
            <div style="padding: 0 4rem">
                <pwk-available-units
                    [allAvailableUnits]="appAvailableUnits"
                    [availableUnits]="postProcessor.availableUnits"
                    (availableUnitsChange)="onAvailableUnitsChange($event)"
                    [disabled]="!isEditable"
                ></pwk-available-units>
            </div>
        </p-tabPanel>
        <!-- <div class="submit-post p-mt-3 p-mb-3">
            <button
                pButton
                
                pTooltip="Configure {{ postProcessor.name }}"
                tooltipPosition="top"
                *ngIf="!isEditable && isInLibrary"
                class="p-mr-2"
                label="Configure"
                (click)="copy(versionedPost)"
            ></button>
        </div> -->
    </p-tabView>
</div>
