<fab-loading-container [loading]="isLoading">
    <div class="p-fluid">
        <div class="p-field">
            <label>Machine</label>
            <fab-dropdown-with-loading-spinner
                [values]="machines"
                [(selectedValue)]="selectedMachine"
                (selectedValueChange)="onMachineSelect($event)"
                optionField="name"
                valuePlaceholder="Select Machine"
                [isLoading]="machinesLoading"
                fabStopPropagation
            >
            </fab-dropdown-with-loading-spinner>
        </div>
        <div class="p-field">
            <label>Material</label>
            <fab-dropdown-with-loading-spinner
                [values]="materials"
                [(selectedValue)]="selectedMaterial"
                (selectedValueChange)="onMaterialSelect($event)"
                valuePlaceholder="Select Material"
                [isLoading]="materialsLoading"
                fabStopPropagation
            >
            </fab-dropdown-with-loading-spinner>
        </div>
        <div class="p-field p-px-2 p-mt-4">
            <pwk-quantity-multiple-input
                [(owner)]="sheetSize"
                (ownerChange)="onSheetSizeChange($event)"
                [fields]="[
                    {
                        field: 'length',
                        label: 'Sheet Size',
                        tooltip: 'Length',
                        min: minLength ? minLength : null,
                        max: selectedMachine ? selectedMachine.machine.document.xLength : null
                    },
                    {
                        field: 'width',
                        tooltip: 'Width',
                        min: minWidth ? minWidth : null,
                        max: selectedMachine ? selectedMachine.machine.document.yLength : null
                    }
                ]"
            ></pwk-quantity-multiple-input>
            <!-- <p class="p-text-bold" style="color: red">Some error</p> -->
        </div>
    </div>
    <div class="p-grid p-nogutter">
        <div class="p-col-2 p-offset-10 p-d-flex p-jc-end">
            <button
                pButton
                pTooltip="Save"
                tooltipPosition="top"
                label="Save"
                icon="pi pi-check"
                (click)="save()"
                [disabled]="isSaveDisabled"
            ></button>
        </div>
    </div>
</fab-loading-container>
