import { AvailableUnits } from './../../generated-src/model/availableUnits';
import { SelectedUnits } from './../../generated-src/model/selectedUnits';
import { InjectionToken } from '@angular/core';

export const APP_AVAILABLE_UNITS = new InjectionToken('app.availableUnits');

export enum APP_QUANTITIES {
    LENGTH = 'length',
    SPEED = 'speed',
    ANGULAR_VELOCITY = 'angular_velocity',
}

export const AppAvailableUnits: AvailableUnits = {
    quantities: {
        [APP_QUANTITIES.SPEED]: {
            units: [
                {
                    unit: 'm/s',
                    defaultPrecision: 3,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
                {
                    unit: 'mm/s',
                    defaultPrecision: 1,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
                {
                    unit: 'mm/minute',
                    defaultPrecision: 1,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
                {
                    unit: 'inch/s',
                    defaultPrecision: 2,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
                {
                    unit: 'in/s',
                    defaultPrecision: 2,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
                {
                    unit: 'inch/minute',
                    defaultPrecision: 2,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
                {
                    unit: 'ipm',
                    defaultPrecision: 3,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
            ],
        },
        [APP_QUANTITIES.ANGULAR_VELOCITY]: {
            units: [
                {
                    unit: 'rad/s',
                    defaultPrecision: 3,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
                {
                    unit: 'rpm',
                    defaultPrecision: 0,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
            ],
        },
        [APP_QUANTITIES.LENGTH]: {
            units: [
                {
                    unit: 'm',
                    defaultPrecision: 6,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
                {
                    unit: 'cm',
                    defaultPrecision: 3,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
                {
                    unit: 'mm',
                    defaultPrecision: 2,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
                {
                    unit: 'inch',
                    defaultPrecision: 3,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
                {
                    unit: 'in',
                    defaultPrecision: 3,
                    minPrecision: 0,
                    maxPrecision: 6,
                },
            ],
        },
    },
};

export const APP_DEFAULT_SELECTED_UNITS: SelectedUnits = {
    quantities: {
        [APP_QUANTITIES.SPEED]: { unit: 'm/s', precision: 3 },
        [APP_QUANTITIES.LENGTH]: { unit: 'm', precision: 6 },
        [APP_QUANTITIES.ANGULAR_VELOCITY]: { unit: 'rad/s', precision: 3 },
    },
};
