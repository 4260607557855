import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CuttingList} from 'generated-src';
import {MenuItem} from 'primeng/api';

@Component({
    selector: 'fab-parts-list-header',
    templateUrl: './parts-list-header.component.html',
    styleUrls: ['./parts-list-header.component.scss']
})
export class PartsListHeaderComponent implements OnChanges {
    @Input() showMenu = true;
    @Input() activeIndex = 1;
    @Input() disabled = false;
    buttonReady = false;
    @Output() make: EventEmitter<void> = new EventEmitter();
    items: MenuItem[] = [{label: 'Import'}, {label: 'Select'}, {label: 'Make!'}];
    @Input() cuttingList: CuttingList;

    constructor() {}

    onClick(): void {
        this.make.emit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['cuttingList']) {
            this.check();
        }
    }

    check(): void {
        this.buttonReady =
            this.cuttingList &&
            this.cuttingList.groups &&
            this.cuttingList.groups.length > 1 &&
            this.cuttingList.groups.slice(1).every((g) => g.machine && g.material);
        if (this.buttonReady) {
            this.activeIndex = 2;
        } else {
            this.activeIndex = 1;
        }
    }
}
