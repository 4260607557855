<div class="container-flex mt-1 ml-3 mr-3">
    <!--    <fab-history [id]="project.id"></fab-history>-->
    <fab-parts-list-header
        [activeIndex]="activeIndex"
        [cuttingList]="cuttingList"
        [disabled]="loading || saving"
        (make)="manufacture()"
    ></fab-parts-list-header>
    <section class="card parts-content p-d-flex">
        <fab-parts-project-summary
            class="p-my-5"
            [project]="project"
            [(cuttingList)]="cuttingList"
            (cuttingListChange)="cuttingListChange.next($event)"
            [disabled]="disabled || loading"
        ></fab-parts-project-summary>
        <p-divider class="p-col-1" layout="vertical"></p-divider>
        <fab-parts-cutting-list
            class="p-my-4"
            [project]="project"
            [workshop]="workshop"
            [(cuttingList)]="cuttingList"
            (cuttingListChange)="cuttingListChange.next($event)"
            [disabled]="disabled || loading"
        ></fab-parts-cutting-list>
    </section>
</div>
