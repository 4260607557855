import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Machine} from '../../../../../generated-src/model/machine';

@Component({
    selector: 'fab-machine-dim-speeds',
    templateUrl: './machine-dim-speeds.component.html',
})
export class MachineDimSpeedsComponent implements OnInit {
    @Input() machine: Machine;
    @Output() machineChange = new EventEmitter<Machine>();
    @Input() disabled = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    onChange() {
        this.machineChange.emit(this.machine);
    }
}
