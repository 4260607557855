<div class="container" *ngIf="machine">
    <h3 class="p-my-5 p-mx-6">
        <span *ngIf="isEditable">Edit</span>
        <span *ngIf="!isEditable">View</span>
        Machine: {{ machine.document.name }}
    </h3>
</div>

<div class="card" *ngIf="machine && bits">
    <div class="p-grid p-fluid p-pt-3">
        <div class="p-col-10 p-offset-1 p-grid p-fluid p-ai-center p-jc-between">
            <div class="p-col-2 p-d-flex p-flex-column p-ai-center">
                <img
                    [src]="getUrl"
                    [alt]="machine.document.name"
                    width="60"
                    height="60"
                    class="p-mb-2"
                    onError="this.onerror=null;this.src='../assets/brands/machine.png';"
                />
                <p-fileUpload
                    *ngIf="isEditable"
                    mode="basic"
                    [url]="thumbPostUrl"
                    name="file"
                    method="put"
                    accept="image/*"
                    [auto]="true"
                    (onUpload)="machineThumbUpload($event)"
                    chooseLabel="Choose image"
                ></p-fileUpload>
            </div>
            <div class="p-col-3 p-field p-pt-4 p-ml-4">
                <pwk-text-input
                    [label]="'Name'"
                    [(text)]="machine.document.name"
                    [disabled]="!isEditable"
                    [debounce]="1000"
                    (textChange)="onChange()"
                >
                </pwk-text-input>
            </div>
            <div class="p-col-3 p-field p-pt-4 p-ml-4">
                <pwk-text-input
                    [label]="'Brand'"
                    [(text)]="machine.document.brand"
                    [disabled]="!isEditable"
                    [debounce]="1000"
                    (textChange)="onChange()"
                >
                </pwk-text-input>
            </div>
            <div class="p-col-3 p-field p-pt-4 p-ml-4">
                <pwk-text-input
                    [label]="'Model'"
                    [(text)]="machine.document.model"
                    [disabled]="!isEditable"
                    [debounce]="1000"
                    (textChange)="onChange()"
                >
                </pwk-text-input>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="machine && bits">
    <fab-machine-dim-speeds [(machine)]="machine.document" [disabled]="!isEditable" (machineChange)="onChange()"> </fab-machine-dim-speeds>

    <fab-nesting-config
        [(nestingStrategy)]="machine.document.nestingStrategy"
        [disabled]="!isEditable"
        (nestingStrategyChange)="onChange()"
    >
    </fab-nesting-config>

    <fab-toolpath-config
        [(toolpathStrategy)]="machine.document.toolpathStrategy"
        [disabled]="!isEditable"
        (toolpathStrategyChange)="onChange()"
    >
    </fab-toolpath-config>

    <fab-postprocessor
        [(postProcessStrategy)]="machine.document.postProcessStrategy"
        [disabled]="!isEditable"
        (postProcessStrategyChange)="onChange()"
    ></fab-postprocessor>

    <fab-cutting-config
        [(cuttingStrategy)]="machine.document.cuttingStrategy"
        [disabled]="!isEditable"
        (cuttingStrategyChange)="onChange()"
    ></fab-cutting-config>

    <div class="submit-machine p-my-5">
        <button
            pButton
            pTooltip="Back to Library"
            tooltipPosition="top"
            *ngIf="isInLibrary && !isAdminDashboard"
            class="p-mr-2"
            label="Back"
            icon="pi pi-arrow-left"
            [routerLink]="['/library']"
            [state]="{activeTab: 0}"
        ></button>
        <button
            pButton
            pTooltip="Back to Workshop"
            tooltipPosition="top"
            *ngIf="!isInLibrary && !isAdminDashboard"
            class="p-mr-2"
            label="Back"
            icon="pi pi-arrow-left"
            [routerLink]="['/workshop', workshop.id]"
            [state]="{activeTab: 1}"
        ></button>
        <button
            pButton
            pTooltip="Configure {{ machine.document.name }}"
            tooltipPosition="top"
            *ngIf="!isEditable && isInLibrary && !isAdminDashboard"
            class="p-mr-2"
            label="Configure"
            (click)="copy($event)"
        ></button>
        <button
            pButton
            pTooltip="Add machine to 
                        Workshop"
            tooltipPosition="top"
            *ngIf="!isAdminDashboard"
            class="p-mr-2"
            label="Add machine"
            (click)="setupMachine(machine, $event)"
        ></button>
    </div>
</div>
