<section class="card">
    <h4 class="p-p-4">Toolpath Configuration</h4>
    <div class="p-grid p-fluid p-pt-3 p-nogutter">
        <div class="p-field p-col-4 p-offset-1">
            <label class="p-mb-2">Order Strategy</label>
            <div class="p-mb-2">
                <p-selectButton
                    [options]="[
                        {name: 'By Feature Type', value: 'ByFeatureType'},
                        {name: 'By Part', value: 'ByPart'}
                    ]"
                    optionLabel="name"
                    optionValue="value"
                    [(ngModel)]="toolpathStrategy.orderStrategy"
                    (ngModelChange)="onChange()"
                    [disabled]="disabled"
                ></p-selectButton>
            </div>
            <label class="p-mb-2">Infill strategy</label>
            <div class="p-mb-2">
                <p-selectButton
                    [options]="[
                        {name: 'Spiral In', value: 'SpiralIn'},
                        {name: 'Raster', value: 'Raster'},
                        {name: 'None', value: 'None'}
                    ]"
                    optionLabel="name"
                    optionValue="value"
                    [(ngModel)]="toolpathStrategy.infillStrategy"
                    (ngModelChange)="onChange()"
                    [disabled]="disabled"
                ></p-selectButton>
            </div>
            <label class="p-mb-2">Jog height strategy</label>
            <div class="p-mb-2">
                <p-selectButton
                    [options]="[
                        {name: 'Set Height', value: 'SetHeight'},
                        {name: 'Safe Z', value: 'SafeZ'}
                    ]"
                    optionLabel="name"
                    optionValue="value"
                    [(ngModel)]="toolpathStrategy.jogHeightStrategy"
                    (ngModelChange)="onChange()"
                    [disabled]="disabled"
                ></p-selectButton>
            </div>

            <div class="p-input-group p-p-2">
                <pwk-quantity-multiple-input
                    [(owner)]="toolpathStrategy"
                    (ownerChange)="onChange()"
                    [disabled]="disabled"
                    [debounce]="1000"
                    [fields]="[
                        {
                            field: 'jogHeight',
                            label: 'Jog Height',
                            tooltip: 'Edit absolute height for jog moves'
                        }
                    ]"
                >
                </pwk-quantity-multiple-input>
            </div>

            <label class="p-mb-2">Zero Strategy</label>
            <div class="p-mb-2">
                <p-selectButton
                    [options]="[
                        {name: 'Material Top', value: 'TopOfMaterial'},
                        {name: 'Table', value: 'Table'}
                    ]"
                    optionLabel="name"
                    optionValue="value"
                    [disabled]="disabled"
                    [(ngModel)]="toolpathStrategy.zeroStrategy"
                    (ngModelChange)="onChange()"
                ></p-selectButton>
            </div>

            <div class="p-input-group p-p-2">
                <pwk-quantity-multiple-input
                    [(owner)]="toolpathStrategy"
                    (ownerChange)="onChange()"
                    [disabled]="disabled"
                    [debounce]="1000"
                    [fields]="[
                        {
                            field: 'xHome',
                            label: 'Home Position',
                            tooltip: 'Edit zero x position'
                        },
                        {field: 'yHome', tooltip: 'Edit zero y position'}
                    ]"
                >
                </pwk-quantity-multiple-input>
            </div>

            <label class="p-mb-2">Drill strategy</label>
            <div class="p-mb-2">
                <p-selectButton
                    [options]="[
                        {name: 'None', value: 'None'},
                        {name: 'Peck', value: 'Peck'}
                    ]"
                    optionLabel="name"
                    optionValue="value"
                    [disabled]="disabled"
                    [(ngModel)]="toolpathStrategy.drillStrategy"
                    (ngModelChange)="onChange()"
                ></p-selectButton>
            </div>
        </div>
        <div class="p-field p-col-4 p-offset-2">
            <label class="p-mb-2">Internal corner strategy</label>
            <div class="p-mb-2">
                <p-selectButton
                    [options]="[
                        {name: 'None', value: 'None'},
                        {name: 'T Bone', value: 'TBone'},
                        {name: 'Dog Bone', value: 'DogBone'}
                    ]"
                    optionLabel="name"
                    optionValue="value"
                    [disabled]="disabled"
                    [(ngModel)]="toolpathStrategy.internalCornerStrategy"
                    (ngModelChange)="onChange()"
                ></p-selectButton>
            </div>
            <label class="p-mb-2">External corner strategy</label>
            <div class="p-mb-2">
                <p-selectButton
                    [options]="[
                        {name: 'Round', value: 'Round'},
                        {name: 'Fly Past', value: 'FlyPast'}
                    ]"
                    optionLabel="name"
                    optionValue="value"
                    [disabled]="disabled"
                    [(ngModel)]="toolpathStrategy.externalCornerStrategy"
                    (ngModelChange)="onChange()"
                ></p-selectButton>
            </div>
            <label class="p-mb-2">Edge strategy</label>
            <div class="p-mb-2">
                <p-selectButton
                    [options]="[
                        {name: 'None', value: 'None'},
                        {name: 'Super Edge', value: 'SuperEdge'}
                    ]"
                    optionLabel="name"
                    optionValue="value"
                    [disabled]="disabled"
                    [(ngModel)]="toolpathStrategy.edgeStrategy"
                    (ngModelChange)="onChange()"
                ></p-selectButton>
            </div>

            <label>Infill stepover</label>
            <div class="p-inputgroup p-mb-2">
                <!-- <p-inputNumber
                    class="input-width"
                    tooltipPosition="top"
                    pTooltip="Infill stepover"
                    [disabled]="disabled"
                    [(ngModel)]="toolpathStrategy.infillStepover"
                    (ngModelChange)="onChange()"
                ></p-inputNumber> -->
                <pwk-quantity-input
                    [(value)]="toolpathStrategy.infillStepover"
                    [disabled]="disabled"
                    [showLabel]="false"
                    tooltipText="Infill stepover"
                    (valueChange)="onChange()"
                    [debounce]="1000"
                    mode="decimal"
                    class="input-width"
                >
                </pwk-quantity-input>
            </div>

            <label class="p-mb-2">Holding strategy</label>
            <div class="p-mb-2">
                <p-selectButton
                    [options]="[
                        {name: 'Vacuum', value: 'Vacuum'},
                        {name: 'Super Skin', value: 'SuperSkin'},
                        {name: 'Tabs', value: 'Tabs'}
                    ]"
                    optionLabel="name"
                    optionValue="value"
                    [disabled]="disabled"
                    [(ngModel)]="toolpathStrategy.holdingStrategy"
                    (ngModelChange)="onChange()"
                ></p-selectButton>
            </div>

            <div class="p-input-group p-p-2">
                <pwk-quantity-multiple-input
                    [disabled]="toolpathStrategy.holdingStrategy === 'SuperSkin' || disabled"
                    [(owner)]="toolpathStrategy"
                    (ownerChange)="onChange()"
                    [debounce]="1000"
                    [fields]="[
                        {
                            field: 'punchThroughDepth',
                            label: 'Punch through depth',
                            tooltip: 'Depth to cut into spoilboard',
                            min: '0 m'
                        }
                    ]"
                ></pwk-quantity-multiple-input>
            </div>

            <div class="p-input-group p-p-2">
                <pwk-quantity-multiple-input
                    [disabled]="toolpathStrategy.holdingStrategy !== 'SuperSkin' || disabled"
                    [(owner)]="toolpathStrategy"
                    (ownerChange)="onChange()"
                    [debounce]="1000"
                    [fields]="[
                        {
                            field: 'superSkinThickness',
                            label: 'Super skin thickness',
                            tooltip: 'Edit thickness',
                            min: '0 m'
                        }
                    ]"
                ></pwk-quantity-multiple-input>
            </div>
        </div>
    </div>
</section>
