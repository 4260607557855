import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AlertService, PatchworkService} from '@harmanpa/ng-patchwork';
import {WorkshopService} from '@services/workshop.service';
import {DefaultService, MachineInstance, VersionedWorkshop} from 'generated-src';
import {AuthService} from 'ng2-ui-auth';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PromptDialogComponent } from 'src/app/shared/components/prompt-dialog/prompt-dialog.component';

@Component({
    selector: 'fab-user-workshop-machines-panel',
    templateUrl: './user-workshop-machines-panel.component.html',
    styleUrls: ['./user-workshop-machines-panel.component.scss']
})
export class UserWorkshopMachinesPanelComponent implements OnInit {
    @Input() machines: {key: string; value: MachineInstance}[];
    @Input() workshop: VersionedWorkshop;
    @Input() authToken: string;
    ref: DynamicDialogRef;
    @Output() reloadChange: EventEmitter<string> = new EventEmitter();

    constructor(
        private api: DefaultService,
        private workshopService: WorkshopService,
        private route: ActivatedRoute,
        private patchwork: PatchworkService,
        private alertService: AlertService,
        public authService: AuthService,
        public dialogService: DialogService,
    ) {}

    ngOnInit(): void {
        console.log(this.machines.map((element) => element.value.name));
    }

    reload(version: string): void {
        this.reloadChange.emit(version);
    }

    remove(machine: string) {
        this.api
            .deleteWorkshopMachineInstancesMachineInstance(this.workshop.id, this.workshop.version, machine)
            .pipe(this.patchwork.trackVersion(this.route))
            .subscribe({
                next: (w) => {
                    this.workshop.version = w.version;
                },
                error: (err) => {
                    this.alertService.error(err);
                },
                complete: () => {
                    this.alertService.success('Machine removed');
                    this.reload(this.workshop.version);
                }
            });
    }

    duplicate(machine: MachineInstance) {
        this.ref = this.dialogService.open(PromptDialogComponent, {
            header: 'Duplicate machine',
            width: '50%',
            contentStyle: {overflow: 'visible'},
            data: {
                type: "machine",
                currentName: machine.name,
                existingNames: this.machines.map((element) => element.value.name)
            }
        });

        this.ref.onClose.subscribe((machineName: string | undefined) => {
            if(machineName){
                const mInst: MachineInstance = {
                    name: machineName,
                    cuttingStrategy: machine.cuttingStrategy,
                    machine: {type: 'machine', id: machine.machine.id, version: machine.machine.version},
                    nestingStrategy: machine.nestingStrategy,
                    toolTable: machine.toolTable,
                    toolpathStrategy: machine.toolpathStrategy
                };
                this.workshopService.addMachine(this.workshop, mInst).subscribe({
                    next: (w) => {
                        this.workshop.version = w.version;
                    },
                    error: (err) => {
                        this.alertService.error(err);
                    },
                    complete: () => {
                        this.alertService.success('Machine ' + mInst.name + ' added to your Workshop');
                        this.reload(this.workshop.version);
                    }
                });
            }
        });
    }
}
