/**
 * Fabber API
 * CAD to CNC in seconds
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DocumentsGetDocumentResponseCreatedBy } from './documentsGetDocumentResponseCreatedBy';
import { DocumentsGetDocumentResponseModifiedBy } from './documentsGetDocumentResponseModifiedBy';
import { DocumentsGetDocumentResponseOwner } from './documentsGetDocumentResponseOwner';
import { DocumentsGetDocumentResponseThumbnail } from './documentsGetDocumentResponseThumbnail';
import { WorkspaceOrVersion } from './workspaceOrVersion';

export interface DocumentMetaData { 
    documentId?: string;
    wvmType?: DocumentMetaData.WvmTypeEnum;
    wvmId?: string;
    elementId?: string;
    workspacesOrVersions?: Array<WorkspaceOrVersion>;
    _public: boolean;
    owner: DocumentsGetDocumentResponseOwner;
    permissionSet: Array<string>;
    permission: string;
    starred: string;
    active: string;
    createdAt: Date;
    thumbnail: DocumentsGetDocumentResponseThumbnail;
    createdBy: DocumentsGetDocumentResponseCreatedBy;
    modifiedAt: Date;
    modifiedBy: DocumentsGetDocumentResponseModifiedBy;
    tags: Array<string>;
    sizeBytes: number;
    name: string;
    id: string;
}
export namespace DocumentMetaData {
    export type WvmTypeEnum = 'Workspace' | 'Version' | 'Microversion';
    export const WvmTypeEnum = {
        Workspace: 'Workspace' as WvmTypeEnum,
        Version: 'Version' as WvmTypeEnum,
        Microversion: 'Microversion' as WvmTypeEnum
    };
}
