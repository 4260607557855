<ng-container *ngIf="!loading">
    <p class="p-mb-1">
        <strong>Cutting Area:</strong> {{ userMachine.document.xLength | unit }} x {{ userMachine.document.yLength | unit }}
    </p>
    <p class="p-mb-1"><strong>Max z height:</strong> {{ userMachine.document.zHeight | unit }}</p>
    <p class="p-mb-1">
        <strong>Spindle speed:</strong> {{ userMachine.document.minSpindleSpeed | unit }} to
        {{ userMachine.document.maxSpindleSpeed | unit }}.
    </p>
    <p class="p-mb-1">
        <strong>Cutting speed: </strong> {{ userMachine.document.minCuttingSpeed | unit }} to
        {{ userMachine.document.maxCuttingSpeed | unit }}
    </p>
</ng-container>
