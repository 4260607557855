<h5>Download Code</h5>
<div class="p-d-flex p-ai-center p-flex-wrap">
    <button
        pButton
        pTooltip="Download Toolpath from Current Sheet"
        tooltipPosition="top"
        id="postProcess"
        class="p-button-text p-button-white p-mb-2 p-mr-3"
        label="Download sheet"
        icon="fas fa-download"
        (click)="download()"
        [loading]="downloadInProgress"
        [disabled]="downloadInProgress || disabled"
    ></button>
    <!-- <button
        pButton
        pTooltip="Send to OpenBuilds CONTROL"
        tooltipPosition="top"
        id="openBuilds"
        class="p-button-text p-button-white p-m-2"
        label="Send to OpenBuilds"
        icon="fas fa-download"
        (click)="openBuilds()"
        [loading]="openBuildsDownloadInProgress"
        [disabled]="openBuildsDownloadInProgress || disabled"
    ></button> -->
    <button
        pButton
        pTooltip="Download Toolpaths for All Project Sheets"
        tooltipPosition="top"
        id="downloads"
        class="p-button-text p-button-white p-mb-2"
        label="Download all sheets"
        icon="fas fa-download"
        (click)="downloadAll()"
        [loading]="zipDownloadInProgress"
        [disabled]="zipDownloadInProgress || disabled"
    ></button>
    <button
        pButton
        pTooltip="Download Toolpath by Features"
        tooltipPosition="top"
        id="downloads"
        class="p-button-text p-button-white p-mb-2"
        label="Download sheet by features"
        icon="fas fa-download"
        (click)="downloadFeatures()"
        [loading]="zipDownloadInProgress"
        [disabled]="zipDownloadInProgress || disabled"
    ></button>
    <button
        pButton
        pTooltip="Download Toolpaths by Features"
        tooltipPosition="top"
        id="downloads"
        class="p-button-text p-button-white p-mb-2"
        label="Download all sheets by features"
        icon="fas fa-download"
        (click)="downloadFeatures()"
        [loading]="zipDownloadInProgress"
        [disabled]="zipDownloadInProgress || disabled"
    ></button>
</div>

<!-- <p class="p-ml-3">
    Download the code here to cut the current sheet
</p> -->
