import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DefaultService, PostProcessStrategy, ReferencePostProcessor, VersionedPostProcessor,} from '../../../../../generated-src';
import {AlertService, UnitService} from '@harmanpa/ng-patchwork';

export interface PostProcessorChoice {
    name: string;
    reference: ReferencePostProcessor;
}

@Component({
    selector: 'fab-postprocessor',
    templateUrl: './postprocessor.component.html',
})
export class PostprocessorComponent implements OnInit {
    @Input() postProcessStrategy: PostProcessStrategy;
    @Output() postProcessStrategyChange =
        new EventEmitter<PostProcessStrategy>();
    @Input() disabled = false;
    public postProcessor: VersionedPostProcessor;
    public postProcessorChoices: PostProcessorChoice[];

    initial = true;

    constructor(
        private api: DefaultService,
        private unitService: UnitService,
        private alertService: AlertService
    ) {
    }

    ngOnInit(): void {
        // this.api
        //     .findPostProcessors(true, true, true, false, false, false, true)
        //     .subscribe((postProcessors) => {
        //         this.postProcessorChoices = postProcessors
        //             .map((postProcessor) => ({
        //                 name: postProcessor.data['name'],
        //                 reference: {
        //                     id: postProcessor.id,
        //                     version: postProcessor.head,
        //                 },
        //             }))
        //             .filter(
        //                 (postProcessor) => postProcessor.name !== undefined
        //             );
        //     });
        // this.onPostSelect();
    }

    onPostSelect(postProcessor: ReferencePostProcessor): void {
        this.postProcessStrategy.postProcessor = postProcessor;
        if (postProcessor) {
            this.api
                .getPostProcessor(
                    this.postProcessStrategy.postProcessor.id,
                    this.postProcessStrategy.postProcessor.version
                )
                .subscribe({
                    next: (pp) => {
                        this.postProcessor = pp;
                        this.postProcessStrategy.selectedUnits =
                            this.unitService.selectDefaults(
                                this.postProcessor.document.availableUnits
                            );
                        if(!this.initial){
                            this.onChange();
                        }
                        this.initial = false;
                    },
                    error: (err) => this.alertService.error(err)
                });
        } else {
            this.onChange();
        }
    }

    onChange(): void {
        this.postProcessStrategyChange.emit(this.postProcessStrategy);
    }
}
