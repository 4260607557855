import { Component, OnInit } from '@angular/core';
import { ConstraintsService } from '../services/constraints.service';

@Component({
  selector: 'fab-sheets-control-buttons',
  templateUrl: './sheets-control-buttons.component.html',
  styleUrls: ['./sheets-control-buttons.component.scss']
})
export class SheetsControlButtonsComponent implements OnInit {

  snapToGrid = 'no';
  gridOptions: any;

  constructor(private constraintsService: ConstraintsService) { }

  ngOnInit(): void {
    this.gridOptions = [
      {label: 'no', value: 'no'},
      {label: '10', value: '10'},
      {label: '50', value: '50'}
    ];
  }

  toggleSnapping(): void {
    if(this.snapToGrid === 'no'){
      this.constraintsService.isSnappingEnabled = false;
    }
    else{
      this.constraintsService.isSnappingEnabled = true;
      this.constraintsService.snappingGridSize = parseInt(this.snapToGrid);
    }
  }
}
