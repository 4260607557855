<section class="card">
    <h4 class="p-mx-4">Material settings</h4>
    <button
        pButton
        pTooltip="Add material"
        tooltipPosition="top"
        *ngIf="isAdminDashboard"
        class="add-material-button p-mx-4 p-mb-5"
        label="Add Material"
        icon="pi pi-plus"
        (click)="onAddMaterial()"
        fabStopPropagation
    ></button>
    <div class="material-editors p-col-10 p-offset-1 p-p-0">
        <fab-material-editor
            *ngFor="let material of bit.document.materialSettings; let i = index"
            [bitMaterial]="material"
            (bitMaterialChange)="onChange()"
            [disabled]="!isEditable"
            [isAdminDashboard]="isAdminDashboard"
            (removeMaterial)="onRemoveMaterial(i)"
        ></fab-material-editor>
    </div>
</section>
