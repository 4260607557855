import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentSummary, Sharing} from '../../../../generated-src';
import {AuthService} from 'ng2-ui-auth';

@Component({
    selector: 'fab-project-item',
    templateUrl: './project-item.component.html',
    styleUrls: ['./project-item.component.scss']
})
export class ProjectItemComponent {
    @Input() project: DocumentSummary;
    @Output() delete: EventEmitter<DocumentSummary> = new EventEmitter();

    constructor(public auth: AuthService) {}

    ngOnInit() {}
    deleteProject(project: DocumentSummary) {
        this.delete.emit(project);
    }

    sharingChanged(newSharing: Sharing) {
        this.project.sharing = newSharing;
    }
}
