<p-accordionTab [selected]="true" *ngIf="deletedParts.length > 0">
    <ng-template pTemplate="header">Deleted Parts</ng-template>
    <ng-template pTemplate="content" class="accordion-content">
        <ng-container *ngFor="let instance of deletedParts; let i = index; let last = last">
            <div class="p-d-flex p-ai-center p-jc-between p-py-3">
                <div class="p-d-flex p-jc-center">
                    <img
                        [src]="
                            'api/projects/' +
                            project.id +
                            '/v/' +
                            project.version +
                            '/parts/' +
                            instance.partId +
                            '/thumbnail.png?token=' +
                            authToken
                        "
                        width="45%"
                        height="auto"
                        onError="this.onerror=null;this.src='../../assets/default/fabber_part_default.png';"
                        alt="{{ instance.name }}"
                    />
                </div>
                <div class="p-mx-4 p-my-2 p-d-flex">
                    <div class="p-ac-center">
                        <h4>{{ instance.name }}</h4>
                        <p *ngIf="instance.slice" class="text-muted">
                            {{ instance.slice.minimumSheetSize.length | unit }}
                            x
                            {{ instance.slice.minimumSheetSize.width | unit }}
                            x
                            {{ instance.slice.thickness | unit }}
                        </p>
                    </div>
                </div>
                <div class="p-mx-4 p-my-2 p-d-flex p-jc-center p-ai-center">
                    <label for="copies" class="p-mr-2">Copies: </label>
                    <p-inputNumber
                        inputId="copies"
                        class="n-off"
                        id="{{ instance.partId }}-off"
                        name="{{ instance.partId }}-off"
                        [(ngModel)]="instance.off"
                        [showButtons]="true"
                        tooltipPosition="top"
                        pTooltip="Select number off"
                        [min]="0"
                        value="1"
                        [max]="100"
                        [disabled]="true"
                    >
                    </p-inputNumber>
                </div>
                <div class="p-mr-2 p-d-flex p-jc-center">
                    <button
                        pButton
                        pTooltip="Manufacture"
                        tooltipPosition="top"
                        class="p-mr-2"
                        icon="pi pi-chevron-circle-up"
                        (click)="revivePart(instance, instance.slice.thickness)"
                    ></button>
                </div>
            </div>
            <p-divider *ngIf="!last"></p-divider>
        </ng-container>
    </ng-template>
</p-accordionTab>
