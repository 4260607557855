import {Component, Input, OnInit} from '@angular/core';
import {saveAs} from 'file-saver/dist/FileSaver';
import {HttpClient} from '@angular/common/http';
import {combineLatest, Observable, of} from 'rxjs';
import {map, mapTo, mergeMap, tap} from 'rxjs/operators';
import {SheetsService} from '../services/sheets.service';
import * as JSZip from 'jszip';
import {CuttingGroup, DefaultService, Sheet, VersionedProject} from '../../../../generated-src';
import {AlertService} from '@harmanpa/ng-patchwork';
import {fromPromise} from 'rxjs/internal-compatibility';

interface NamedBlob {
    name: string;
    blob: Blob;
}

@Component({
    selector: 'fab-sheets-post-process-button',
    templateUrl: './sheets-post-process-button.component.html',
    styleUrls: ['./sheets-post-process-button.component.scss']
})
export class SheetsPostProcessButtonComponent implements OnInit {
    downloadInProgress = false;
    zipDownloadInProgress = false;
    openBuildsDownloadInProgress = false;
    groupsCount = 0;
    sheetsCount = 0;
    currentGroup = 1;
    currentSheet = 1;
    cuttingGroup: CuttingGroup;
    sheet: Sheet;
    disabled: boolean;
    @Input() project: VersionedProject;

    // @Input() disabled: boolean;

    constructor(
        private http: HttpClient,
        private api: DefaultService,
        private sheetsService: SheetsService,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.sheetsService.observeGroupChoice().subscribe((groupChoice) => {
            [this.currentGroup, this.groupsCount, this.cuttingGroup] = groupChoice;
            console.log(groupChoice);
        });
        this.sheetsService.observeSheetChoice().subscribe((sheetChoice) => {
            [this.currentSheet, this.sheetsCount, this.sheet] = sheetChoice;
            console.log(sheetChoice);
        });
        this.sheetsService.disabled.subscribe((value) => {
            this.disabled = value;
        });
    }

    download(): void {
        this.downloadInProgress = true;
        this.fetch(this.currentGroup + 1, this.currentSheet)
            .pipe(mergeMap((content) => this.saveToFile(content)))
            .subscribe({
                complete: () => {
                    this.downloadInProgress = false;
                    this.alertService.success('Export complete!');
                },
                error: (err) => {
                    this.downloadInProgress = false;
                    this.alertService.error(err);
                }
            });
    }

    downloadAll() {
        this.zipDownloadInProgress = true;
        this.fetchAll()
            .pipe(
                mergeMap((namedBlobs) => this.createZip(namedBlobs, this.project.document.name + '.zip')),
                mergeMap((content) => this.saveToFile(content))
            )
            .subscribe({
                complete: () => {
                    this.zipDownloadInProgress = false;
                    this.alertService.success('Export complete!');
                },
                error: (err) => {
                    this.zipDownloadInProgress = false;
                    this.alertService.error(err);
                }
            });
    }

    downloadFeatures() {
        console.log('download by features');
    }

    openBuilds(): void {
        this.openBuildsDownloadInProgress = true;
        this.fetch(this.currentGroup, this.currentSheet - 1)
            .pipe(mergeMap((content) => this.sendToOpenBuildsControl(content)))
            .subscribe({
                complete: () => {
                    this.openBuildsDownloadInProgress = false;
                    this.alertService.success('Export complete!');
                },
                error: (err) => {
                    this.openBuildsDownloadInProgress = false;
                    this.alertService.error(err);
                }
            });
    }

    fetch(group: number, sheet: number): Observable<NamedBlob> {
        return this.api
            .getProjectCuttingListGroupsSheetsSheetsOutput(this.project.id, this.project.version, group.toString(), sheet.toString())
            .pipe(
                map((vpo) => {
                    return {name: vpo.document.fileName, blob: new Blob([vpo.document.content], {type: vpo.document.contentType})};
                })
            );
    }

    fetchAll(): Observable<NamedBlob[]> {
        return this.api
            .getProjectSheetChoices(this.project.id, this.project.version)
            .pipe(
                mergeMap((sheetChoices) =>
                    combineLatest(sheetChoices.map((sheetChoice) => this.fetch(sheetChoice.groupId, sheetChoice.sheetId)))
                )
            );
    }

    createZip(files: NamedBlob[], zipName: string): Observable<NamedBlob> {
        const zip = new JSZip();
        const name = zipName + '.zip';
        // Note: Files must have different names
        files.forEach((file) => {
            zip.file(file.name, file.blob);
        });
        return fromPromise<Blob>(zip.generateAsync({type: 'blob'})).pipe(map((zipBlob) => ({name: zipName, blob: zipBlob})));
    }

    saveToFile(content: NamedBlob): Observable<boolean> {
        saveAs(content.blob, content.name);
        return of(true);
    }

    sendToOpenBuildsControl(content: NamedBlob): Observable<boolean> {
        const url = 'http://mymachine.openbuilds.com:3001/upload';
        const fd = new FormData();
        fd.append('data', content.blob, content.name);
        return this.http.post(url, fd, {observe: 'response'}).pipe(
            tap((response) => console.log(response)),
            mapTo(true)
        );
    }
}
