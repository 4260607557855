<section class="card">
    <h4 class="p-p-4">Cutting Strategy</h4>
    <div class="p-grid p-fluid p-pt-3 p-nogutter">
        <div class="p-field p-col-4 p-offset-1">
            <p class="p-mb-4">Cutting Speed: {{ cuttingStrategy.cutRate }}</p>
            <p-slider
                class="p-mr-3"
                [min]="0"
                [max]="1"
                [step]="0.05"
                [disabled]="disabled"
                [(ngModel)]="cuttingStrategy.cutRate"
                (onSlideEnd)="onChange()"
            ></p-slider>
            <p class="p-mb-4">Jog Speed {{ cuttingStrategy.jogRate }}</p>
            <p-slider
                class="p-mr-3"
                [min]="0"
                [max]="1"
                [step]="0.05"
                [disabled]="disabled"
                [(ngModel)]="cuttingStrategy.jogRate"
                (onSlideEnd)="onChange()"
            ></p-slider>
        </div>
    </div>
</section>
