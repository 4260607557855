import {Injectable} from '@angular/core';
import {AlertService, CacheService, PatchworkService, UnitService} from '@harmanpa/ng-patchwork';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {G, Matrix, Point, Svg} from '@svgdotjs/svg.js';
import {
    CuttingGroup,
    CuttingList,
    DefaultService,
    Placement,
    Sheet,
    SheetSet,
    Slice,
    Toolpath,
    VersionedProject,
    VersionedSheetSet
} from 'generated-src';
import {DrawPartsService} from './draw-parts.service';
import {DrawToolpathService} from './draw-toolpath.service';
import {combineLatest, merge, Observable, Subject, throwError} from 'rxjs';
import {auditTime, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {ConstraintsService} from './constraints.service';

type DrawingStatus = 'Loading' | 'Parts' | 'Toolpath' | 'Done';

@Injectable()
export class SheetsService {
    sheet: Sheet;
    sheets: SheetSet;
    sheetsChange = new Subject<SheetSet>();
    nextSheets: SheetSet;
    nextGroup: number;
    nextToolpathGroup: G;
    saved = new Subject<VersionedSheetSet>();
    disabled = new Subject<boolean>();

    sheetsCount = 0;
    currentGroup: number;
    currentGroupChange = new Subject<number>();
    currentSheet = 1;
    currentSheetChange = new Subject<number>();
    cuttingList: CuttingList;
    // loading = false;
    saving = false;
    project: VersionedProject;
    svgElement: Svg;
    svgPosition = new Subject<Point>();
    mousePosition = new Subject<{x: number; y: number}>();
    transformMatrix: Matrix;

    gridStrokeWidth = 0.8;
    xMax: number;
    yMax: number;

    constructor(
        private alertService: AlertService,
        private cache: CacheService,
        private unitService: UnitService,
        private loadingBar: LoadingBarService,
        private patchwork: PatchworkService,
        private route: ActivatedRoute,
        private api: DefaultService,
        private drawToolpathService: DrawToolpathService,
        private drawPartsService: DrawPartsService,
        private constraintsService: ConstraintsService
    ) {}

    init(vp: VersionedProject): void {
        this.project = vp;
        this.api.getProjectCuttingList(vp.id, vp.version).subscribe((cl) => {
            this.cuttingList = cl.document;
            this.selectGroup(1);
        });
        this.sheetsChange
            .pipe(
                tap((_) => this.disabled.next(true)),
                auditTime(1500)
                // distinctUntilChanged()
            )
            .subscribe({
                next: (ss) => {
                    this.nextSheets = ss;
                    console.log(ss);
                    this.save();
                },
                error: (err) => this.alertService.error(err)
            });
    }

    initSvgElement(target: Svg): Observable<DrawingStatus> {
        this.svgElement = target;
        this.getSvgCoordinates();
        return this.observeSheetAndToolpath().pipe(
            mergeMap((sheetAndToolpath) => this.drawSheet(this.svgElement, sheetAndToolpath[0], sheetAndToolpath[1]))
        );
    }

    getSvgCoordinates(): void {
        this.svgElement.mousemove((e: any) => {
            this.svgPosition.next(this.svgElement.point(e.pageX, e.pageY).transform(this.transformMatrix));
            this.mousePosition.next({x: e.pageX, y: e.pageY});
        });
    }

    resizeSvg(width: number, height: number): void {
        this.svgElement.size(width, height);
    }

    observeGroupChoice(): Observable<[number, number, CuttingGroup]> {
        return this.currentGroupChange.pipe(
            map((group) => [group - 1, this.cuttingList.groups.length - 1, this.cuttingList.groups[group]])
        );
    }

    observeSheetChoice(): Observable<[number, number, Sheet]> {
        return merge<[number, number, Sheet]>(
            this.currentSheetChange.pipe(
                mergeMap((s) =>
                    this.getSheets(this.currentGroup).pipe(
                        tap((sheets) => console.log(sheets)),
                        map((sheets) =>
                            sheets.sheets && sheets.sheets.length > 0 ? [s - 1, sheets.sheets.length, sheets.sheets[s - 1]] : [0, 0, null]
                        )
                    )
                )
            ),
            this.currentGroupChange.pipe(
                mergeMap((groupChoice) =>
                    this.getSheets(groupChoice).pipe(
                        tap((sheets) => {
                            console.log(sheets);
                            this.sheets = sheets;
                            this.sheet = sheets.sheets[this.currentSheet - 1];
                        }),
                        map((sheets) => {
                            // this.drawSheet(this.svgElement, sheets.sheets[0]);
                            return sheets.sheets && sheets.sheets.length > 0 ? [0, sheets.sheets.length, sheets.sheets[0]] : [0, 0, null];
                        })
                    )
                )
            )
        );
    }

    observeSheetAndToolpath(): Observable<[Sheet, Observable<Toolpath>]> {
        return this.observeSheetChoice().pipe(
            withLatestFrom(this.observeGroupChoice()),
            map((choices) => {
                console.log('Choices', choices);
                const out: [Sheet, Observable<Toolpath>] = [
                    choices[0][2],
                    this.api
                        .getProjectCuttingListGroupsSheetsSheetsToolpath(
                            this.project.id,
                            this.project.version,
                            (choices[1][0] + 1).toString(),
                            choices[0][0].toString()
                        )
                        .pipe(map((vtp) => vtp.document))
                ];
                return out;
            })
        );
    }

    drawSheet(target: Svg, sheet: Sheet, toolpath: Observable<Toolpath>): Observable<DrawingStatus> {
        if (!sheet || !target) {
            return throwError('No objects provided to draw routine');
        }
        const size = sheet.sheetSize;
        // this.loadingBar.start();

        target.clear();
        this.xMax = this.unitService.toValue(size.length, 'mm');
        this.yMax = this.unitService.toValue(size.width, 'mm');
        this.transformMatrix = new Matrix().scale(1, -1, 0, this.yMax / 2);

        target.viewbox({x: -1, y: -1, width: this.xMax + 2, height: this.yMax + 2});
        target.rect(this.xMax, this.yMax).attr({'stroke-width': 1, stroke: '#263d47', fill: '#dde5e9'});

        const gridGroup = target.group();
        const partsGroup = target.group();
        const toolpathGroup = target.group();

        gridGroup.transform(this.transformMatrix);
        partsGroup.transform(this.transformMatrix);
        toolpathGroup.transform(this.transformMatrix);

        this.constraintsService.sheetBoundingBox = target.bbox();

        this.drawPartsService.partList = [];
        this.constraintsService.partsStartingCoordinates = [];

        // Draw the parts, then the toolpath
        this.handleZoomEvent(target, gridGroup);
        this.drawGrid(target, gridGroup);
        return this.drawParts(sheet, toolpathGroup, partsGroup, toolpath);
    }

    handleZoomEvent(target: Svg, gridGroup: G): void {
        target.on('zoom', (el) => {
            const oldStrokeWidth = this.gridStrokeWidth;
            if (el.detail.level <= 1) {
                this.gridStrokeWidth = 1;
            } else if (el.detail.level > 1 && el.detail.level < 10) {
                this.gridStrokeWidth = 1 / el.detail.level;
            } else {
                this.gridStrokeWidth = 0.1;
            }
            if (oldStrokeWidth !== this.gridStrokeWidth) {
                this.drawGrid(target, gridGroup);
            }
        });
    }

    drawGrid(target: Svg, gridGroup: G): void {
        gridGroup.clear();
        const thinGrid = target.pattern(10, 10, (add) => {
            add.path('M 10 0 L 0 0 0 10')
                .stroke({width: this.gridStrokeWidth / 2, color: '#888'})
                .fill('none');
        });

        const thickGrid = target.pattern(100, 100, (add) => {
            add.rect(100, 100).fill(thinGrid);
            add.path('M 100 0 L 0 0 0 100').stroke({width: this.gridStrokeWidth, color: '#000'}).fill('none');
        });
        const rect = target.rect(this.xMax, this.yMax).attr({fill: thickGrid});
        gridGroup.add(rect);
    }

    drawParts(sheet: Sheet, toolpathGroup: G, partsGroup: G, toolpathObservable: Observable<Toolpath>): Observable<DrawingStatus> {
        const status = new Subject<DrawingStatus>();
        status.next('Loading');
        // Fetch the part slices from the server, then draw
        this.getPartsSlices(sheet).subscribe({
            next: (slices) => {
                // Draw the part outlines
                status.next('Parts');
                this.drawPartsService.partMap.clear();
                sheet.parts.forEach((part) =>
                    this.drawPartsService.drawPart(partsGroup, part, slices[part.part], toolpathGroup, this.updateToolpath)
                );
                // Fetch the toolpath from the server, then draw
                toolpathObservable.subscribe({
                    next: (toolpath) => {
                        // Draw the toolpath
                        console.log(toolpath);
                        status.next('Toolpath');
                        this.drawToolpathService.drawToolpath(toolpathGroup, toolpath);
                        status.next('Done');
                    },
                    error: (err) => status.error(err)
                });
            },
            error: (err) => status.error(err)
        });
        return status;
    }

    getPartSlice(part: string): Observable<Slice> {
        console.log('Fetching slice for part ' + part);
        return this.api.getProjectPartsSlice(this.project.id, this.project.version, part).pipe(map((vs) => vs.document));
        // return this.cache.get(
        //     'slices/' + this.project.id + '/' + this.project.version + '/' + part,
        //     this.api.getProjectPartsSlice(this.project.id, this.project.version, part).pipe(map((vs) => vs.document)),
        //     this.cache.DAY,
        //     true
        // );
    }

    getPartsSlices(sheet: Sheet): Observable<{[id: string]: Slice}> {
        console.log('Fetching slices for parts');
        const partIds = sheet.parts.map((placement) => placement.part).filter((value, index, array) => array.indexOf(value) === index);
        return combineLatest(partIds.map((id) => this.getPartSlice(id))).pipe(
            map((slices) =>
                slices.reduce((sliceMap, slice, i) => {
                    sliceMap[partIds[i]] = slice;
                    return sliceMap;
                }, {} as {[id: string]: Slice})
            )
        );
    }

    getSelectedToolpath(projectId: string, versionId: string): Observable<Toolpath> {
        return this.api
            .getProjectCuttingListGroupsSheetsSheetsToolpath(
                projectId,
                versionId,
                this.currentGroup.toString(),
                (this.currentSheet - 1).toString()
            )
            .pipe(map((vtp) => vtp.document));
    }

    getSheets(group: number): Observable<SheetSet> {
        return this.cache.get(
            '/sheets/' + this.project.id + '/' + this.project.version + '/' + group.toString(),
            this.api
                .getProjectCuttingListGroupsSheets(this.project.id, this.project.version, group.toString())
                .pipe(map((sheets) => sheets.document)),
            this.cache.DAY,
            true
        );
    }

    getSheet(group: number, index: number): Observable<Sheet> {
        return this.getSheets(group).pipe(map((sheets) => sheets.sheets[index - 1]));
    }

    setSheet(group: number, index: number, newSheet: Sheet, toolpathGroup: G) {
        this.getSheets(group).subscribe((ss) => {
            const newSheetSet = {
                sheetSize: ss.sheetSize,
                machine: ss.machine,
                material: ss.material,
                thickness: ss.thickness,
                sheets: ss.sheets.map((sheet, i) => (i === index - 1 ? newSheet : sheet))
            };
            this.nextGroup = group;
            this.nextToolpathGroup = toolpathGroup;
            this.sheetsChange.next(newSheetSet);
        });
    }

    save() {
        if (!this.saving && this.drawPartsService.isSavingEnabled) {
            this.saving = true;
            const toSave = this.nextSheets;
            this.nextSheets = null;
            this.api
                .updateProjectCuttingListGroupsSheets(this.project.id, this.project.version, this.nextGroup.toString(), toSave)
                .subscribe({
                    next: (value) => {
                        this.getSelectedToolpath(value.id, value.version).subscribe((toolpath) => {
                            this.project.version = value.version;
                            this.patchwork.setVersionQuery(this.route, value);
                            this.saved.next(value);
                            this.saving = false;
                            if (this.nextSheets) {
                                this.save();
                            } else {
                                this.drawToolpathService.drawToolpath(this.nextToolpathGroup, toolpath);
                                this.disabled.next(false);
                            }
                        });
                    },
                    error: (err) => {
                        this.saving = false;
                        this.alertService.error(err);
                    }
                });
        } else if (!this.drawPartsService.isSavingEnabled) {
            this.alertService.error('Parts cannot overlap!');
        }
    }

    updateToolpath = (part: Placement, transformer: (part: Placement) => Placement, toolpathGroup: G) => {
        console.log(this.sheet);
        this.sheet = {
            sheetSize: this.sheet.sheetSize,
            parts: this.sheet.parts.map((p) => {
                if (p.part === part.part && p.instance === part.instance) {
                    return transformer(p);
                } else {
                    return p;
                }
            })
        };
        this.setSheet(this.currentGroup, this.currentSheet, this.sheet, toolpathGroup);
    };

    selectGroup(n: number): void {
        if (n !== this.currentGroup) {
            this.currentGroup = n;
            this.currentGroupChange.next(this.currentGroup);
        }
    }

    selectSheet(n: number): void {
        if (n !== this.currentSheet) {
            this.currentSheet = n;
            this.currentSheetChange.next(this.currentSheet);
        }
    }
}
