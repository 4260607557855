// From https://stackoverflow.com/questions/34177221/angular2-how-to-inject-window-into-an-angular2-service
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

interface ADSK {
    fusionSendData(action: string, data: string);
}

interface IFabberWindow extends Window {
    adsk: ADSK;
}

function getWindow(): any {
    return window;
}

@Injectable()
export class Fusion360Service {
    constructor() {
        window['fusionJavaScriptHandler'] = {
            handle: function (action, data) {
                try {
                    alert(action + ' ' + JSON.stringify(data));
                    return 'OK';
                } catch (e) {
                    console.log(e);
                    console.log('exception caught with command: ' + action + ', data: ' + data);
                }
                return 'OK';
            }
        };
    }

    inFusion360(): boolean {
        if (window['qt']) {
            console.log('We are in Fusion360');
            return true;
        }
        return false;
    }

    // openBrowser(): Observable<boolean> {
    //     return this.call<boolean>('py_open_browser');
    // }
    get nativeWindow(): IFabberWindow {
        return getWindow();
    }

    openBrowser(): Observable<boolean> {
        const args = {};
        this.nativeWindow.adsk.fusionSendData('send', JSON.stringify(args));
        return of(true);
    }
}
