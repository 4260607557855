// From https://stackoverflow.com/questions/34177221/angular2-how-to-inject-window-into-an-angular2-service
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

interface Sketchup {
    openBrowser(browserUrl: string);
}

interface IFabberWindow extends Window {
    sketchup: Sketchup;
}

function getWindow(): any {
    return window;
}

@Injectable()
export class SketchupService {
    constructor() {
    }

    get nativeWindow(): IFabberWindow {
        return getWindow();
    }

    inSketchup(): boolean {
        if (this.nativeWindow.sketchup) {
            // this.route.snapshot.queryParams.skp
            return true;
        }
        return false;
    }

    openBrowser(): Observable<boolean> {
        this.nativeWindow.sketchup.openBrowser(this.nativeWindow.location.toString());
        return of(true);
    }
}
