<pwk-in-progress *ngIf="project" class="" type="Project" [id]="project.id" style="width: 100%" [path]="['cuttingList']">
    <!-- <fab-loading-container [loading]="cuttingListService.loading"> -->
    <div *ngIf="cuttingList">
        <div class="p-col-12">
            <h5>
                The following parts have been grouped by <strong>sheet thickness:</strong>
                <button
                    *ngIf="inApp"
                    pButton
                    type="button"
                    icon="pi pi-info-circle"
                    class="p-button-rounded p-button-text"
                    (click)="showSummary = !showSummary"
                    pTooltip="More info"
                    tooltipPosition="right"
                ></button>
            </h5>
        </div>
        <fab-parts-project-summary
            *ngIf="showSummary"
            [inApp]="true"
            [project]="project"
            [(cuttingList)]="cuttingList"
            (cuttingListChange)="cuttingListChange.next($event)"
        ></fab-parts-project-summary>
        <div class="p-col-12 p-nogutter">
            <p-accordion [multiple]="true">
                <fab-parts-list-group
                    *ngFor="let group of cuttingList.groups | slice: 1; let m = index"
                    [project]="project"
                    [(workshop)]="workshop"
                    [group]="group"
                    (groupChange)="handleGroupChanges(m + 1, $event)"
                    [disabled]="disabled"
                    (partEdit)="onPartEdit(m + 1, $event)"
                    (partRemove)="removePart(m, $event)"
                    [inApp]="inApp"
                ></fab-parts-list-group>
                <fab-parts-deleted
                    [project]="project"
                    [(cuttingList)]="cuttingList"
                    (cuttingListChange)="updateCuttingList($event)"
                ></fab-parts-deleted>
                <fab-parts-not-manufactured [project]="project" [cuttingList]="cuttingList"></fab-parts-not-manufactured>
            </p-accordion>
        </div>
    </div>
    <!-- </fab-loading-container> -->
</pwk-in-progress>
