<p class="dialog-message">{{ message }}</p>
<div class="dialog-btns">
    <button
        pButton
        label="Cancel"
        pTooltip="Cancel"
        tooltipZIndex="20000"
        tooltipPosition="top"
        type="button"
        class="p-mr-2"
        (click)="cancel()"
    ></button>
    <button
        pButton
        label="Ok"
        pTooltip="Ok"
        tooltipZIndex="20000"
        tooltipPosition="top"
        type="button"
        (click)="confirm()"
    ></button>
</div>
