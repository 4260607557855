<!--<ngx-loading-bar color="#fff"></ngx-loading-bar>-->
<!--<pwk-app-component></pwk-app-component>-->
<!--<div class="fabber-container">-->
<!--    <fab-header [inApp]="inSketchup || inFusion360" *ngIf="!noHeader"></fab-header>-->
<!--    <div [ngClass]="{'fabber-noscroll': noScroll, 'fabber-scroll': !noScroll}">-->
<!--        <router-outlet></router-outlet>-->
<!--    </div>-->
<!--</div>-->
<!--&lt;!&ndash;<fab-tasks></fab-tasks>&ndash;&gt;-->

<pwk-app-component loadingBarColor="#ffffff"></pwk-app-component>
<router-outlet></router-outlet>
<ng-template pwkApplicationTemplate="logo">
    <img
        class="fabber-logo"
        src="assets/fabber-1line-1color-white-lowres.png"
        alt="FABBER"
        height="35"
    />
</ng-template>

<ng-template let-data="data" let-dataChange="dataChange" pwkTypedTemplate="machines">
    <fab-machine-editor [machine]="data" (dataChange)="dataChange.emit($event)"></fab-machine-editor>
</ng-template>
<ng-template let-data="data" let-dataChange="dataChange" pwkTypedTemplate="bits">
    <fab-bit-editor [bit]="data" (dataChange)="dataChange.emit($event)"></fab-bit-editor>
</ng-template>
<ng-template let-data="data" let-dataChange="dataChange" pwkTypedTemplate="postprocessors">
    <fab-post-processor-editor [versionedPost]="data" (dataChange)="dataChange.emit($event)"></fab-post-processor-editor>
</ng-template>