import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {
    AvailableUnits,
    DefaultService,
    PostProcessor,
    ReferenceProject,
    SheetChoice,
    VersionedPostProcessor,
    VersionedWorkshop
} from '../../../../generated-src';
import {ActivatedRoute, Router} from '@angular/router';
import {CodemirrorComponent} from '@ctrl/ngx-codemirror';
import {AppAvailableUnits} from 'src/app/app.config';
import {AlertService, PatchworkService} from '@harmanpa/ng-patchwork';
import {Title} from '@angular/platform-browser';
import {Subject} from 'rxjs';
import {auditTime} from 'rxjs/operators';
import {NamingService} from '../services/naming.service';
import {DefaultValuesService} from '../services/default-values.service';

@Component({
    selector: 'fab-post-processor-editor',
    templateUrl: './post-processor-editor.component.html',
    styleUrls: ['./post-processor-editor.component.scss']
})
export class PostProcessorEditorComponent implements OnInit, AfterViewInit {
    @Input() versionedPost: VersionedPostProcessor;
    @Output() dataChange = new EventEmitter<VersionedPostProcessor>();
    @ViewChildren('codemirrorEditorRef')
    editorsRef: QueryList<CodemirrorComponent>;
    templateEditor: CodemirrorComponent;
    outputEditor: CodemirrorComponent;
    workshop: VersionedWorkshop;
    postProcessor: PostProcessor;
    postProcessorChange = new Subject<PostProcessor>();
    nextPostProcessor: PostProcessor;
    output: string;
    projectSheetChoices: SheetChoice[] = [];
    appAvailableUnits = AppAvailableUnits;

    initial = true;
    isAdminDashboard = false;
    isEditable = false;
    isInLibrary = false;
    outputLoading = false;
    saving = false;
    sheetsLoading = true;

    constructor(
        private alertService: AlertService,
        private api: DefaultService,
        private route: ActivatedRoute,
        private router: Router,
        private patchwork: PatchworkService,
        private title: Title,
        private namingService: NamingService,
        private defaultValuesService: DefaultValuesService
    ) {}

    ngOnInit() {
        this.assignResolvedData();
        this.handleEmptyPostProcessor();
        this.setPageTitle();
        this.setEditable();
        this.setHistorySource();
        this.handlePostProcessorChanges();
        // this.patchwork.setVersionQuery(this.route, this.versionedPost);
        this.postProcessor = this.versionedPost.document;
        if (this.versionedPost.id) {
            this.getPostProcessorExampleOutput();
        }
    }

    private assignResolvedData(): void {
        const {postprocessor, workshop} = this.route.snapshot.data;
        this.versionedPost = postprocessor as VersionedPostProcessor;
        this.workshop = workshop as VersionedWorkshop;
    }

    private handleEmptyPostProcessor(): void {
        if (!this.versionedPost) {
            this.isAdminDashboard = true;
            this.versionedPost = {
                document: this.defaultValuesService.getDefaultPostProcessor(),
                permissions: {
                    read: true,
                    write: true
                }
            };
        }
    }

    private setPageTitle(): void {
        if (!this.isAdminDashboard) {
            this.title.setTitle(`Editor - ${this.versionedPost.document.name}`);
        }
    }

    private setEditable(): void {
        this.isEditable = this.versionedPost.permissions.write;
    }

    private setHistorySource(): void {
        history.state.source === 'Library' ? (this.isInLibrary = true) : (this.isInLibrary = false);
    }

    handlePostProcessorChanges(): void {
        this.postProcessorChange
            .pipe(
                auditTime(500)
                // distinctUntilChanged()
            )
            .subscribe({
                next: (pp) => {
                    this.nextPostProcessor = pp;
                    console.log(pp);
                    this.save();
                },
                error: (err) => this.alertService.error(err)
            });
    }

    ngAfterViewInit() {
        this.editorsRef.changes.subscribe((editors: QueryList<CodemirrorComponent>) => {
            this.templateEditor = editors.first;
            this.outputEditor = editors[1];
        });
    }

    private save(): void {
        if (!this.saving && this.versionedPost.id) {
            this.saving = true;
            const toSave = this.postProcessor;
            this.nextPostProcessor = null;
            this.api.updatePostProcessor(this.versionedPost.id, this.versionedPost.version, toSave).subscribe({
                next: (value) => {
                    this.versionedPost.version = value.version;
                    this.patchwork.setVersionQuery(this.route, value);
                    this.saving = false;
                    if (this.nextPostProcessor) {
                        this.save();
                    }
                    this.getPostProcessorExampleOutput();
                },
                error: (err) => {
                    this.saving = false;
                    this.alertService.error(err);
                }
            });
        }
    }

    onAvailableUnitsChange(availableUnits: AvailableUnits): void {
        this.postProcessor.availableUnits = availableUnits;
        this.onChange();
    }

    onTemplateChange(template: string): void {
        console.log(this.templateEditor);
        // TODO: Check syntax before saving
    }

    onProjectChange(project: ReferenceProject): void {
        if (this.initial) {
            this.initial = false;
        } else {
            this.onChange();
        }
        this.postProcessor.exampleProject = project;
        this.getProjectSheetChoices(project);
    }

    onSheetChange(sheet: any) {
        this.postProcessor.exampleProjectSheet = sheet;
        this.onChange();
    }

    onChange(): void {
        this.postProcessorChange.next(this.postProcessor);
        this.dataChange.emit(this.versionedPost);
    }

    private getProjectSheetChoices(project: ReferenceProject): void {
        this.sheetsLoading = true;
        if (project) {
            this.api.getProjectSheetChoices(project.id, project.version).subscribe({
                next: (sheets) => {
                    this.projectSheetChoices = sheets;
                    this.sheetsLoading = false;
                },
                error: (err) => {
                    this.sheetsLoading = false;
                    this.alertService.error(err);
                }
            });
        } else {
            this.projectSheetChoices = [];
        }
    }

    private getPostProcessorExampleOutput(): void {
        this.outputLoading = true;
        this.api.getPostProcessorExampleOutput(this.versionedPost.id, this.versionedPost.version).subscribe({
            next: (output) => {
                console.log(output);
                if (output && output.document && output.document.content) {
                    this.output = output.document.content;
                } else {
                    this.output = '';
                }
                this.outputLoading = false;
            },
            error: (err) => {
                this.output = '';
                this.alertService.error(err);
                this.outputLoading = false;
            }
        });
    }

    copy(event: any): void {
        this.namingService.copyPostprocessor();
        event.target.blur();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState() {
        if (this.isInLibrary) {
            this.router.navigate(['/library/machines'], {state: {activeTab: 2}});
        } else {
            this.router.navigate(['/workshop', this.workshop.id], {queryParams: {version: this.workshop.version}, state: {activeTab: 3}});
        }
    }
}
