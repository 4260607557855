import { Component, HostListener, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'fab-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent implements OnInit {

  elementType: string;
  value: string;
  existingNames: string[];
  disabled = true;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    this.elementType = this.config.data.type;
    this.value = this.config.data.currentName;
    this.existingNames = this.config.data.existingNames;
    this.disabled = this.validateInput();
  }

  onChange(): void {
    this.disabled = this.validateInput();
  }

  validateInput(): boolean {
    if(this.value.length > 0 && !this.existingNames.find(element => element === this.value)){
      return false;
    }
    else {
      return true;
    }
  }

  save(): void {
    this.ref.close(this.value);
  }

  @HostListener('window:keyup.enter')
  onEnter() {
    if (!this.disabled) {
      this.save();
    }
  }

}
