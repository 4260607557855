<!-- <div class="fabber-top-overlay">
    <div class="container-flex mt-1 ml-3 mr-3">
        <div class="row">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="['/project', projectId]">Select Parts</a></li>
                    <li class="breadcrumb-item active" aria-current="page">... Viewing
                        part {{ part?.document.name }}</li>
                    <li class="breadcrumb-item"><a [routerLink]="['/project', projectId, 'sheets']">Manufacture</a></li>
                </ol>
            </nav>
        </div>
    </div>
</div> -->
<div>
    <div class="p-p-5 p-d-flex">
        <button
            pButton
            pTooltip="Go to Parts"
            tooltipPosition="top"
            class="p-button-raised p-mr-2 p-ml-auto z"
            label="Parts List"
            icon="pi pi-arrow-right"
            [routerLink]="['/projects', project.id]"
            [queryParams]="{version: project.version}"
        ></button>
    </div>
    <!-- <button pButton pTooltip="Go to Parts" tooltipPosition="top" label="Parts List"
        class="z p-mr-2 p-ml-auto p-button-raised" type="button" icon="pi pi-arrow-right"
        [routerLink]="['/project', path]"></button> -->
    <cae-toolframe *ngIf="geometryUrl" [marginTop]="96" [marginBottom]="58">
        <cae-views-container #viewContainer>
            <cae-view clearColor="#FAFAFA" clearAlpha="1">
                <cae-camera-controls
                    #cameraControlsComponent
                    [alwaysDynamicFit]="true"
                    [maxDistance]="20"
                    [maxZoom]="100"
                    [minDistance]="3"
                    [minZoom]="0.25"
                    [wheelAction]="wheelAction"
                    [zoomToFitMax]="0.65"
                    [zoomToFitTrigger]="initialLoadComplete"
                    [xbox]="false"
                    [trill]="false"
                    [leap]="false"
                >
                </cae-camera-controls>
                <cae-orthographic-camera [fov]="6" [positionX]="0" [positionY]="5" [positionZ]="5">
                    <cae-directional-light
                        [color]="white"
                        [intensity]="1"
                        [helper]="false"
                        [translateX]="0"
                        [translateY]="0"
                        [translateZ]="0"
                    >
                    </cae-directional-light>
                </cae-orthographic-camera>
                <cae-outline-pass [visibleEdgeColor]="black"></cae-outline-pass>
                <cae-scene [rotateX]="halfpi">
                    <cae-directional-light
                        [color]="white"
                        [intensity]="2"
                        [translateX]="0"
                        [translateY]="0"
                        [translateZ]="10"
                        [castShadow]="true"
                        [helper]="false"
                        [target]="assy"
                    ></cae-directional-light>
                    <cae-ambient-light [color]="white" [intensity]="1"></cae-ambient-light>
                    <cae-stl-loader #assy [model]="geometryUrl"></cae-stl-loader>
                    <!-- <fab-slice-geometry [slice]="slice"></fab-slice-geometry> -->
                </cae-scene>
                <!-- <cae-hovered-outline-pass [visibleEdgeColor]="'#FF0000'"></cae-hovered-outline-pass> -->
            </cae-view>
        </cae-views-container>
        <!-- <cae-toolbar placement="Bottom" [controls]="cameraControlsComponent"></cae-toolbar> -->
        <cae-toolbar
            [controls]="cameraControlsComponent"
            [(multiView)]="multipleViews"
            [newToolbar]="true"
            [showScreenModelToolbar]="true"
            [showDataToolbar]="true"
            [distanceFromBottom]="80"
        ></cae-toolbar>
    </cae-toolframe>
</div>
