/**
 * Fabber API
 * CAD to CNC in seconds
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConfigurationParameter } from './configurationParameter';

export interface Configurator { 
    documentId?: string;
    wvmType?: Configurator.WvmTypeEnum;
    wvmId?: string;
    elementId?: string;
    parameters?: Array<ConfigurationParameter>;
    documentName?: string;
    elementName?: string;
    elementType?: string;
}
export namespace Configurator {
    export type WvmTypeEnum = 'Workspace' | 'Version' | 'Microversion';
    export const WvmTypeEnum = {
        Workspace: 'Workspace' as WvmTypeEnum,
        Version: 'Version' as WvmTypeEnum,
        Microversion: 'Microversion' as WvmTypeEnum
    };
}
