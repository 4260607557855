<h5>Edit Sheet Nesting</h5>
<div class="sheet-nesting">
    <div class="p-d-inline-flex p-ai-center p-mr-1">
        <!-- <p class="p-ml-3">Enable editing of sheet nesting</p> -->
        <button
            *ngIf="!editing"
            pTooltip="Editing"
            tooltipPosition="top"
            pButton
            icon="fas fa-lock-alt"
            label="Editing Locked"
            (click)="toggleEditing()"
            class="p-button-text p-button-white"
        ></button>
        <button
            *ngIf="editing"
            pTooltip="Editing"
            tooltipPosition="top"
            pButton
            icon="fas fa-lock-open-alt"
            label="Editing Enabled"
            (click)="toggleEditing()"
        ></button>
    </div>
    <div *ngIf="editing" class="p-d-inline-flex">
        <button
            pButton
            pTooltip="Rotate -15 degrees"
            tooltipPosition="bottom"
            id="rotateCCW1"
            class="p-mr-1"
            icon="fas fa-undo"
            (click)="rotator(-15)"
            [disabled]="rotator == null || disabled"
        ></button>
        <button
            pButton
            pTooltip="Rotate -1 degree"
            tooltipPosition="bottom"
            id="rotateCCW15"
            class="p-mr-1"
            icon="fas fa-reply"
            (click)="rotator(-1)"
            [disabled]="rotator == null || disabled"
        ></button>
        <button
            pButton
            pTooltip="Rotate 1 degree"
            tooltipPosition="bottom"
            id="rotateCW15"
            class="p-mr-1"
            icon="fas fa-share"
            (click)="rotator(1)"
            [disabled]="rotator == null || disabled"
        ></button>
        <button
            pButton
            pTooltip="Rotate 15 degrees"
            tooltipPosition="bottom"
            id="rotateCW1"
            class="p-mr-1"
            icon="fas fa-redo"
            (click)="rotator(15)"
            [disabled]="rotator == null || disabled"
        ></button>
    </div>
</div>
