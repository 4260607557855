import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {AlertService, ConfirmService, PatchworkService} from '@harmanpa/ng-patchwork';
import {VersionedResponse} from '@harmanpa/ng-patchwork/lib/services/patchwork.service';
import {DocumentSummary, Workshop, DefaultService, VersionedWorkshop, PostProcessor} from 'generated-src';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PromptDialogComponent} from 'src/app/shared/components/prompt-dialog/prompt-dialog.component';
import {NamingService} from '../../services/naming.service';

@Component({
    selector: 'fab-post-processors-panel',
    templateUrl: './post-processors-panel.component.html',
    styleUrls: ['./post-processors-panel.component.scss']
})
export class PostProcessorsPanelComponent implements OnInit {
    @Input() postpos: DocumentSummary[];
    @Input() loading: boolean;
    // public loading: boolean;
    @Output() reloadChange: EventEmitter<boolean> = new EventEmitter();
    isDisabled = true;
    ref: DynamicDialogRef;

    public workshops: DocumentSummary[];
    public workshop: VersionedResponse<Workshop>;

    constructor(
        private api: DefaultService,
        private router: Router,
        private alertService: AlertService,
        private title: Title,
        private namingService: NamingService,
        private dialogService: DialogService,
        private confirmService: ConfirmService,
        private patchworkService: PatchworkService
    ) {
        this.postpos = new Array<DocumentSummary>();
        this.workshops = new Array<DocumentSummary>();
    }

    ngOnInit(): void {
        // this.reload();
        console.log(this.postpos);
    }

    reload(): void {
        this.reloadChange.emit(true);
        // this.loading = true;
    }

    // private getUniqueDuplicateNumber(postpo: DocumentSummary): number {
    //     const currentNumbers = this.postpos
    //         .map((data) => data.data.name)
    //         .filter((v) => v.includes(postpo.data.name.split('-')[0]))
    //         .map((v) => parseInt(v.split('-')[1]))
    //         .filter((n) => !Number.isNaN(n))
    //         .sort();
    //     const selectedNumber =
    //         currentNumbers.find((v, index) => {
    //             return v !== index + 1;
    //         }) - 1;
    //     return Number.isNaN(selectedNumber) ? currentNumbers.length + 1 : selectedNumber;
    // }

    // private preparePostProcessorDuplicateName(postpo: DocumentSummary): string {
    //     return postpo.data.name.split('-')[0] + '-' + this.getUniqueDuplicateNumber(postpo);
    // }

    copy(postpo: DocumentSummary, event: any): void {
        this.namingService.setPostProcessor(postpo);
        this.namingService.copyPostprocessor();
        event.target.blur();
        // this.api.findPostProcessors(true, false, true, false, false, false, false).subscribe((postpos) => {
        //     this.ref = this.dialogService.open(PromptDialogComponent, {
        //         header: 'Configure post processor',
        //         width: '50%',
        //         contentStyle: {overflow: 'visible'},
        //         data: {
        //             type: "post processor",
        //             currentName: postpo.data.name,
        //             existingNames: postpos.map((element) => element.data.name)
        //         }
        //     });

        //     this.ref.onClose.subscribe((postpoName: string | undefined) => {
        //         if(postpoName){
        //             const postpoCopy = JSON.parse(JSON.stringify(postpo));
        //             this.api.getMe().subscribe((user) => {
        //                 postpoCopy.owner = user.email;
        //                 postpoCopy.data.name = postpoName;
        //                 this.api.addPostProcessor(postpoCopy.data, postpo.id, postpo.head).subscribe({
        //                     next: (p) => {
        //                         this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //                         this.router.navigate(['/post', p.id], {queryParams: {version: p.version}, state: {source: 'Workshop'}});
        //                     },
        //                     error: (err) => {
        //                         this.alertService.error(err);
        //                     },
        //                     complete: () => {
        //                         this.alertService.success('Post Processor ' + postpoCopy.data.name + ' was added to your Workshop');
        //                     }
        //                 });
        //             });
        //         }
        //     });
        // })

        // const postpoCopy = JSON.parse(JSON.stringify(postpo));
        // this.api.getMe().subscribe((user) => {
        //     postpoCopy.owner = user.email;
        //     postpoCopy.data.name = this.preparePostProcessorDuplicateName(postpo);
        //     this.api.addPostProcessor(postpoCopy.data, postpo.id, postpo.head).subscribe({
        //         next: (p) => {
        //             this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //             this.router.navigate(['/post', p.id], {queryParams: {version: p.version}, state: {source: 'Workshop'}});
        //         },
        //         error: (err) => {
        //             this.alertService.error(err);
        //         },
        //         complete: () => {
        //             this.alertService.success('Post Processor ' + postpoCopy.data.name + ' was added to your Workshop');
        //         }
        //     });
        // });
    }

    remove(postpo: DocumentSummary) {
        console.log(postpo);
        this.api.deletePostProcessor(postpo.id).subscribe({
            next: () => {
                this.reload();
            },
            error: (err) => {
                this.alertService.error(err);
            },
            complete: () => {
                this.alertService.success('Post Processor was removed from Library');
            }
        });
    }
    viewPostpo(postpo: DocumentSummary) {
        this.namingService.setPostProcessor(postpo);
        this.router.navigate([`/${this.title.getTitle().toLowerCase()}/post`, postpo.id], {
            queryParams: {version: postpo.head},
            state: {source: this.title.getTitle()}
        });
    }

    // setupPostpo(postpo: DocumentSummary, event: any): void {
    //     const {data} = postpo;
    //     this.showPopUp(postpo);
    //     event.target.blur();
    // }

    // private showPopUp(postpo: DocumentSummary): void {
    //     this.ref = this.dialogService.open(AddPostpoPopUpComponent, {
    //         header: 'Add postprocessor to My Post Processors',
    //         width: '40%',
    //         contentStyle: {height: 'auto', overflow: 'auto'},
    //         baseZIndex: 10000,
    //         data: {
    //             postpo: postpo
    //         }
    //     });

    //     this.ref.onClose
    //         .subscribe
    //         // this.namingService.setPostProcessor(postpo);
    //         // this.workshopChange.emit(this.namingService.addUserWorkshopMachine(this.workshop, postpo));
    //         // this.addMachine(machine, chosenBits);
    //         ();
    // }
}
