import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CuttingList, CuttingPartInstance, VersionedProject} from 'generated-src';
import {AuthService} from 'ng2-ui-auth';

@Component({
    selector: 'fab-parts-not-manufactured',
    templateUrl: './parts-not-manufactured.component.html'
})
export class PartsNotManufacturedComponent implements OnInit, OnChanges {
    authToken: string;
    @Input() project: VersionedProject;
    @Input() cuttingList: CuttingList;
    notManufactured: CuttingPartInstance[] = [];

    constructor(public auth: AuthService) {
    }

    ngOnInit(): void {
        this.authToken = this.auth.getToken();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['cuttingList']) {
            if (this.cuttingList && this.cuttingList.groups && this.cuttingList.groups[0]) {
                this.notManufactured = this.cuttingList.groups[0].instances.filter(instance => !instance.slice);
            } else {
                this.notManufactured = [];
            }
        }
    }
}
