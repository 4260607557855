<ng-container *ngIf="!isEdited">
    <div class="p-d-flex p-ai-center">
        <h6 class="p-mr-3 p-mb-0 p-text-bold">
            Sheet Size:
            {{ sheetSize.length | unit }} x
            {{ sheetSize.width | unit }}
        </h6>
        <button
            pButton
            pTooltip="Edit Sheet Size"
            tooltipPosition="top"
            class="p-button-text"
            icon="pi pi-pencil"
            (click)="onEditStart()"
            fabStopPropagation
        ></button>
    </div>
</ng-container>
<ng-container *ngIf="isEdited">
    <div class="p-grid p-ai-end vertical-container">
        <div class="p-col-10 p-pb-0">
            <pwk-quantity-multiple-input
                fabStopPropagation
                [(owner)]="sheetSize"
                (ownerChange)="onSheetSizeChange($event)"
                [fields]="[
                    {
                        field: 'length',
                        label: '',
                        tooltip: 'Length'
                    },
                    {
                        field: 'width',
                        label: '',
                        tooltip: 'Width'
                    }
                ]"
            ></pwk-quantity-multiple-input>
        </div>
        <div class="p-col-1 p-pb-0">
            <button
                pButton
                pTooltip="Accept changes"
                tooltipPosition="top"
                class="p-button-text"
                icon="pi pi-check"
                (click)="onEditAccept()"
                fabStopPropagation
            ></button>
        </div>
        <div class="p-col-1 p-pb-0">
            <button
                pButton
                pTooltip="Discard changes"
                tooltipPosition="top"
                class="p-button-text"
                icon="pi pi-times"
                (click)="onEditEnd()"
                fabStopPropagation
            ></button>
        </div>
    </div>
</ng-container>
