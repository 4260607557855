<p class="p-mb-2 p-text-bold">
    Please select at least one bit from the list below. You can change this
    setting anytime.
</p>
<p>Available Bits:</p>
<!-- <ng-template ngFor let-bit [ngForOf]="bits">
            <p>{{ bit.data.name }}</p>
        </ng-template> -->
<div *ngFor="let bit of bits" class="p-field-checkbox">
    <p-checkbox
        name="bit"
        value="bit.data.name"
        [value]="bit"
        [(ngModel)]="selectedBits"
        (ngModelChange)="onChange()"
    ></p-checkbox>
    <label [for]="bit.data.name">{{ bit.data.name }}</label>
</div>
<div class="p-grid p-nogutter">
    <button
        pButton
        [disabled]="disabled"
        label="Add Machine"
        pTooltip="Add Machine"
        tooltipZIndex="20000"
        tooltipPosition="top"
        type="button"
        class="p-ml-auto"
        (click)="save()"
    ></button>
</div>
