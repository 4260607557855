<p *ngIf="machines.length == 0" class="p-text-bold" style="color: red">
    Please add a machine first to your workshop. At the moment no machine in your workshop fits with this bit.
</p>
<ng-container *ngIf="machines.length > 0">
    <h6>Please select machines from the list below.</h6>
    <p>Available Machines:</p>
</ng-container>

<div *ngFor="let machine of machines" class="p-field-checkbox">
    <p-checkbox [name]="machine.name" [value]="machine" [(ngModel)]="selectedMachines" (ngModelChange)="onChange()"></p-checkbox>
    <label class="p-mr-2">{{ machine.name }}</label>
    <div>
        <p-chip *ngFor="let bit of machine.toolTable.bits" [label]="bit.name" styleClass="p-mr-1 p-mb-1"></p-chip>
    </div>
</div>
<div class="p-grid p-nogutter">
    <button pButton [disabled]="disabled" label="Add Bit" pTooltip="Add Bit" tooltipZIndex="20000" tooltipPosition="top" type="button" class="p-ml-auto" (click)="save()"></button>
</div>
