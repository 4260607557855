import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DefaultService, DocumentSummary, MachineInstance, VersionedMachineInstanceMap, VersionedWorkshop} from '../../../../generated-src';
import {ActivatedRoute, Router} from '@angular/router';
import {CacheService} from '@harmanpa/ng-patchwork';
import {switchMap} from 'rxjs/operators';
import {AuthService} from 'ng2-ui-auth';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
    selector: 'fab-workshop',
    templateUrl: './workshop.component.html',
    styleUrls: ['./workshop.component.scss'],
    providers: [DialogService]
})
export class WorkshopComponent implements OnInit {
    public loading: boolean;
    public workshop: VersionedWorkshop;
    public workshopMachines: VersionedMachineInstanceMap;
    public machinesArray: {key: string; value: MachineInstance}[];
    bits: DocumentSummary[];
    machines: DocumentSummary[];
    postpos: DocumentSummary[];
    authToken: string;
    // Modal ref
    ref: DynamicDialogRef;
    activeTab: number = 0;

    constructor(
        private api: DefaultService,
        private title: Title,
        private route: ActivatedRoute,
        public authService: AuthService,
        private cacheService: CacheService
    ) {
        this.machinesArray = [];
        this.bits = [];
    }

    ngOnInit() {
        this.title.setTitle('Workshop');
        this.workshop = this.route.snapshot.data['workshop'] as VersionedWorkshop;
        this.loading = true;
        this.reload(this.workshop.version);
        this.authToken = this.authService.getToken();
        this.setActiveTab();
    }

    reload(version?: string, force?: boolean): void {
        console.log('reload called');
        if (version || force) {
            this.cacheService
                .reload('machines', this.api.findMachines(true, false, true, false, false, false, false), this.cacheService.DAY, true)
                .pipe(
                    switchMap((machines: DocumentSummary[]) => {
                        console.log(machines);
                        this.machines = machines;
                        return this.cacheService.reload(
                            'bits',
                            this.api.findBits(true, false, true, false, false, false, false),
                            this.cacheService.DAY,
                            true
                        );
                    }),
                    switchMap((bits: DocumentSummary[]) => {
                        this.bits = bits;
                        console.log(this.bits);
                        return this.cacheService.reload(
                            'postprocessors',
                            this.api.findPostProcessors(true, false, true, false, false, false, false),
                            this.cacheService.DAY,
                            true
                        );
                    }),
                    switchMap((postprocessors: DocumentSummary[]) => {
                        this.postpos = postprocessors;
                        return this.cacheService.reload(
                            'postprocessors',
                            this.api.getWorkshopMachineInstances(this.workshop.id, this.workshop.version),
                            this.cacheService.DAY,
                            true
                        );
                    })
                )
                .subscribe((userWSmachines) => {
                    this.workshopMachines = userWSmachines;
                    this.machinesArray = Object.keys(this.workshopMachines.document).map((key) => ({
                        key: key,
                        value: this.workshopMachines.document[key]
                    }));
                    this.loading = false;
                    console.log(this.machinesArray);
                });
        } else {
            this.cacheService
                .get('machines', this.api.findMachines(true, false, true, false, true, false, false), this.cacheService.DAY, true)
                .pipe(
                    switchMap((machines: DocumentSummary[]) => {
                        this.machines = machines;
                        return this.cacheService.get(
                            'bits',
                            this.api.findBits(true, true, true, false, false, false, true),
                            this.cacheService.DAY,
                            true
                        );
                    }),
                    switchMap((bits: DocumentSummary[]) => {
                        this.bits = bits;
                        return this.cacheService.get(
                            'postprocessors',
                            this.api.findPostProcessors(false, true, true, false, false, false, true),
                            this.cacheService.DAY,
                            true
                        );
                    }),
                    switchMap((postprocessors: DocumentSummary[]) => {
                        this.postpos = postprocessors;
                        return this.cacheService.reload(
                            'postprocessors',
                            this.api.getWorkshopMachineInstances(this.workshop.id, this.workshop.version),
                            this.cacheService.DAY,
                            true
                        );
                    })
                )
                .subscribe((userWSmachines) => {
                    this.workshopMachines = userWSmachines;
                    this.machinesArray = Object.keys(this.workshopMachines.document).map((key) => ({
                        key: key,
                        value: this.workshopMachines.document[key]
                    }));
                    this.loading = false;
                    console.log(this.machinesArray);
                });
        }
    }

    setActiveTab(): void {
        if (history.state.activeTab) {
            this.activeTab = history.state.activeTab;
        }
    }
}
