<p-dropdown
    [options]="values"
    [(ngModel)]="selectedValue"
    (ngModelChange)="onSelect($event)"
    [optionLabel]="optionField"
    [placeholder]="valuePlaceholder"
    [showClear]="true"
    [disabled]="isLoading"
>
    <ng-template pTemplate="selectedItem">
        <fab-loading-spinner *ngIf="isSpinnerEnabled" [size]="loadingSpinnerSize"></fab-loading-spinner>
        <div *ngIf="!isSpinnerEnabled">
            <div *ngIf="!optionField">
                {{ selectedValue }}
            </div>
            <div *ngIf="optionField">
                {{ selectedValue ? selectedValue[optionField] : '' }}
            </div>
        </div>
    </ng-template>
</p-dropdown>
