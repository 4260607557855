import {NestingStrategy} from './../../../../../generated-src/model/nestingStrategy';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'fab-nesting-config',
    templateUrl: './nesting-config.component.html',
})
export class NestingConfigComponent implements OnInit {
    @Input() nestingStrategy: NestingStrategy;
    @Output() nestingStrategyChange = new EventEmitter<NestingStrategy>();
    @Input() disabled = false;
    readonly rotationOptions: {
        label: string;
        value: number;
        beta: boolean;
    }[] = [
        {label: '1', value: 1, beta: false},
        {label: '2', value: 2, beta: false},
        {label: '4', value: 4, beta: false},
        {label: '8', value: 8, beta: false},
        {label: 'Auto', value: -1, beta: true},
    ];

    constructor() {
    }

    ngOnInit(): void {
    }

    onNestingRotationsChange(nestingRotations: number) {
        this.nestingStrategy.nestingRotations = nestingRotations;
        this.onChange();
    }

    onChange() {
        this.nestingStrategyChange.emit(this.nestingStrategy);
    }
}
