<pwk-layout-component
    [includeBreadcrumb]="false"
    [footerIncludeDeveloper]="false"
    menuLayout="horizontal"
    footerCopyright="Fabber Inc."
    footerCopyrightUrl="https://getfabber.com"
    [menu]="menu"
    [inApp]="inApp"
    [version]="version"
    [autoOpenNotifications]="false"
>
</pwk-layout-component>
