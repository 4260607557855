import {Injectable} from '@angular/core';
import {User} from '@harmanpa/ng-patchwork';
import {AnalyticsProvider} from '@harmanpa/ng-patchwork/lib/analytics/analytics-provider';
import {Intercom} from 'ng-intercom';

@Injectable()
export class IntercomAnalyticsProvider implements AnalyticsProvider {

    constructor(private intercom: Intercom) {
    }

    setUser(user: User): void {
        if (user) {
            this.intercom.update({name: user.name, email: user.email, created_at: user.signup_date});
        } else {
            this.intercom.update({name: undefined, email: undefined, created_at: undefined});
        }
    }
}
