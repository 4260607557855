<section class="card">
    <h4 class="p-p-4">Machine Dimensions and Speeds</h4>
    <div class="p-grid p-py-3">
        <div class="p-col-10 p-offset-1">
            <pwk-quantity-multiple-input
                    [(owner)]="machine"
                    (ownerChange)="onChange()"
                    [disabled]="disabled"
                    [debounce]="1000"
                    [fields]="[
                    {field: 'xLength', label: 'Cutting Area', tooltip: 'Maximum length of cutting area', min: '0 m'},
                    {field: 'yLength', tooltip: 'Maximum width of cutting area', min: '0 m'},
                    {field: 'zHeight', tooltip: 'Maximum height of cutting area', min: '0 m'}]">
            </pwk-quantity-multiple-input>
        </div>
        <div class="p-col-10 p-offset-1 p-input-group p-px-2">
            <pwk-quantity-multiple-input
                    [(owner)]="machine"
                    (ownerChange)="onChange()"
                    [disabled]="disabled"
                    [debounce]="1000"
                    [fields]="[
                    {field: 'minSpindleSpeed', label: 'Spindle Speed', tooltip: 'Minimum spindle speed', min: '0 rpm', maxReference: 'maxSpindleSpeed'},
                    {field: 'maxSpindleSpeed', tooltip: 'Maximum spindle speed', minReference: 'minSpindleSpeed'}]">
            </pwk-quantity-multiple-input>
        </div>
        <div class="p-col-10 p-offset-1 p-input-group p-px-2">
            <pwk-quantity-multiple-input
                    [(owner)]="machine"
                    (ownerChange)="onChange()"
                    [disabled]="disabled"
                    [debounce]="1000"
                    [fields]="[
                    {field: 'minColletSize', label: 'Collet Size', tooltip: 'Minimum collet diameter', min: '0 m', maxReference: 'maxColletSize'},
                    {field: 'maxColletSize', tooltip: 'Maximum collet diameter', minReference: 'minColletSize'}]">
            </pwk-quantity-multiple-input>
        </div>
        <div class="p-col-10 p-offset-1 p-input-group p-px-2">
            <pwk-quantity-multiple-input
                    [(owner)]="machine"
                    (ownerChange)="onChange()"
                    [disabled]="disabled"
                    [debounce]="1000"
                    [fields]="[
                    {field: 'minCuttingSpeed', label: 'Cutting Speed', tooltip: 'Minimum cutting speed', min: '0 m/s', maxReference: 'maxCuttingSpeed'},
                    {field: 'maxCuttingSpeed', tooltip: 'Maximum cutting speed', minReference: 'minCuttingSpeed'}]">
            </pwk-quantity-multiple-input>
        </div>
    </div>
</section>
