import {DialogService} from 'primeng/dynamicdialog';
import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {BitInstance, Machine, ToolTable} from '../../../../../generated-src';
import {ConfirmDialogComponent} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {AlertService} from '@harmanpa/ng-patchwork';

@Component({
    selector: 'fab-bits-config',
    templateUrl: './bits-config.component.html',
    styleUrls: ['./bits-config.component.scss'],
    providers: [DialogService]
})
export class BitsConfigComponent implements OnInit {
    @Input() toolTable: ToolTable;
    @Output() toolTableChange: EventEmitter<ToolTable> = new EventEmitter();
    bits: BitInstance[] = [];

    @Input() atc: Machine.ATCEnum;

    isATC = false;
    currentlyEdited: string;

    constructor(private alertService: AlertService, private dialogService: DialogService) {}

    ngOnInit(): void {
        this.setATC();
        this.bits = this.toolTable.bits;
    }

    onBitDelete(bitToDelete: BitInstance): void {
        const ref = this.dialogService.open(ConfirmDialogComponent, {
            header: `Confirmation`,
            width: '40%',
            contentStyle: {height: 'auto', overflow: 'auto'},
            baseZIndex: 10000,
            data: {
                message: 'Do you want to delete this bit?'
            }
        });

        ref.onClose.subscribe((result: true | false) => {
            if (result) {
                this.bits = this.filterBitFromBits(bitToDelete, this.bits);
                this.toolTable.bits = this.bits;
                this.toolTableChange.emit(this.toolTable);
            }
        });
    }

    onBitEdit(bitToEdit: BitInstance): void {
        this.setCurrentlyEditedBit(bitToEdit.bit.id);
    }

    onSave(bitToSave: BitInstance): void {
        if (this.checkIfToolNumberExists(bitToSave)) {
            this.alertService.error('Tool number has to be unique');
        } else {
            this.toolTable.bits = this.bits;
            this.toolTableChange.emit(this.toolTable);
            this.setCurrentlyEditedBit();
            this.alertService.info('Tool number has been updated');
        }
    }

    private setATC(){
        if(this.atc === 'ATC'){
            this.isATC = true;
        }
        console.log(this.atc, this.isATC);
    }

    private setCurrentlyEditedBit(bitId?: string) {
        this.currentlyEdited = bitId;
    }

    private checkIfToolNumberExists(bitToSave: BitInstance): boolean {
        if (
            this.bits.find((bit) => {
                if (bit.bit.id !== bitToSave.bit.id) {
                    return bit.toolNumber == bitToSave.toolNumber;
                }
            })
        ) {
            return true;
        }
        return false;
    }

    private filterBitFromBits(bitToFilter: BitInstance, bits: BitInstance[]): BitInstance[] {
        console.log(bits);
        return bits.filter(({bit: {id}}) => bitToFilter.bit.id !== id);
    }
}
