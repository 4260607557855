import {Injectable} from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from 'ng2-ui-auth';
import {HttpClient} from '@angular/common/http';
import {SketchupService} from '../services/sketchup.service';
import {Fusion360Service} from '../services/fusion360.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OnlyInAppGuard implements CanActivate {
    nameValue: string;
    constructor(
        private router: Router,
        private auth: AuthService,
        private http: HttpClient,
        private sketchupService: SketchupService,
        private fusion360Service: Fusion360Service
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (
            this.sketchupService.inSketchup() ||
            this.fusion360Service.inFusion360()
        ) {
            if (!this.auth.isAuthenticated()) {
                if (this.sketchupService.inSketchup()) {
                    this.nameValue = 'SketchUp';
                } else if (this.fusion360Service.inFusion360()) {
                    this.nameValue = 'fusion360';
                }
                // Post info on Sketchup to server
                return this.http
                    .post('/api/auth/application', {
                        name: this.nameValue,
                        id: next.queryParams.skp,
                        data: {}
                    })
                    .pipe(
                        map((token) => {
                            this.auth.setToken(token['token']);
                            return true;
                        })
                    );
            }
            return true;
        }
        return this.router.createUrlTree(['/']);
    }
}
