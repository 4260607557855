import {Component, OnInit} from '@angular/core';
import {Gtag} from 'angular-gtag';
import {ActivatedRoute} from '@angular/router';
import {SketchupService} from '@services/sketchup.service';
import {Fusion360Service} from '@services/fusion360.service';
import {Intercom} from 'ng-intercom';

@Component({
    selector: 'fab-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'fabber';
    noScroll = false;
    noHeader: false;
    inSketchup: boolean;
    inFusion360: boolean;

    constructor(
        private route: ActivatedRoute,
        gtag: Gtag,
        private sketchup: SketchupService,
        private fusion360: Fusion360Service,
        public intercom: Intercom
    ) {}

    ngOnInit() {
        this.inSketchup = this.sketchup.inSketchup();
        this.inFusion360 = this.fusion360.inFusion360();
        this.noScroll = this.route.snapshot.data.noScroll || false;
        this.noHeader = this.route.snapshot.data.noHeader || false;
        this.intercom.boot({
            app_id: 't7tq0zk6'
        });
    }
}
