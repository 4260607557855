import {UnitService} from '@harmanpa/ng-patchwork';
import {GroupSettings} from '@models/group-settings.model';
import {CuttingGroup} from 'generated-src';
import {PartSettings} from '../../models/part-settings.model';

export interface Equatable {
    areEqual(partSettings: PartSettings, cuttingGroup: CuttingGroup): boolean;
}

export class MachineComparator implements Equatable {
    areEqual({machine: {value}}: PartSettings, {machine}: CuttingGroup): boolean {
        if (value && machine) {
            return value.path === machine.path;
        }
        return false;
    }
}

export class GroupMachineComparator implements Equatable {
    areEqual(settings: GroupSettings, {machine}: CuttingGroup): boolean {
        if (settings.machine && machine) {
            return settings.machine.path === machine.path;
        }
        return false;
    }
}

export class MaterialComparator implements Equatable {
    areEqual(partSettings: PartSettings, cuttingGroup: CuttingGroup): boolean {
        if (partSettings && cuttingGroup) {
            return partSettings.material === cuttingGroup.material;
        }
        return false;
    }
}

export class SheetSizeComparator implements Equatable {
    constructor(private unitService: UnitService) {}

    areEqual(partSettings: PartSettings, cuttingGroup: CuttingGroup): boolean {
        if (partSettings && cuttingGroup) {
            const lengthCompare = this.unitService.compare(partSettings.sheetSize.length, cuttingGroup.sheetSize.length, 1e-4);
            const widthCompare = this.unitService.compare(partSettings.sheetSize.width, cuttingGroup.sheetSize.width, 1e-4);
            return (lengthCompare === widthCompare) === true;
            // return partSettings.sheetSize.length === cuttingGroup.sheetSize.length && partSettings.sheetSize.width === partSettings.sheetSize.width;
        }
        return false;
    }
}

export class ThicknessComparator implements Equatable {
    constructor(private unitService: UnitService) {}

    areEqual(partSettings: PartSettings, cuttingGroup: CuttingGroup): boolean {
        if (partSettings && cuttingGroup && partSettings.thickness && cuttingGroup.thickness) {
            return this.unitService.compare(partSettings.thickness, cuttingGroup.thickness, 1e-4) === 0;
        }
        return false;
    }
}
