<h5 class="p-mt-3">Choose from {{ postpos.length }} standard post-processor configurations.</h5>
<p-dataView #dataViewPostPos [value]="postpos" [paginator]="true" [rows]="12" filterBy="data.name" layout="grid">
    <ng-template pTemplate="header">
        <div class="p-d-flex p-flex-column p-ai-center p-flex-md-row p-jc-md-between">
            <div class="p-d-flex">
                <span class="p-input-icon-left p-mb-2 p-mb-md-0 p-mr-2">
                    <i class="pi pi-search"></i>
                    <input type="search" pInputText placeholder="Search by name..." (input)="dataViewPostPos.filter($event.target.value)" />
                </span>
            </div>
        </div>
    </ng-template>

    <ng-template let-postpo pTemplate="gridItem">
        <div class="p-col-3 p-p-2">
            <p-panel>
                <p-header class="p-d-flex p-jc-between p-ai-center view">
                    <div class="post-processor-card p-p-3" (click)="viewPostpo(postpo)">
                        <div class="p-d-flex p-ai-center post-processor-header">
                            <h5 class="p-m-0 p-text-bold">{{ postpo.data['name'] }}</h5>
                        </div>
                    </div>
                    <div class="p-d-flex p-pr-3">
                        <button
                            pButton
                            pTooltip="Add {{ postpo.data['name'] }} to my workshop"
                            tooltipPosition="top"
                            class="p-button-text p-mr-1"
                            icon="pi pi-plus"
                            (click)="copy(postpo, $event)"
                            fabStopPropagation
                        ></button>
                        <!-- <button
                            pButton
                            pTooltip="Configure {{ postpo.data['name'] }}"
                            tooltipPosition="top"
                            class="p-button-secondary p-mr-1"
                            icon="pi pi-sliders-v"
                            (click)="copy(postpo, $event)"
                            fabStopPropagation
                        ></button> -->
                        <button
                            pButton
                            pTooltip="Delete"
                            tooltipPosition="top"
                            *ngIf="postpo.permissions.write"
                            class="p-button-danger"
                            icon="pi pi-trash"
                            (click)="remove(postpo)"
                            fabStopPropagation
                        ></button>
                    </div>
                </p-header>
                <div class="p-d-flex p-ai-center p-jc-between">
                    <div class="p-pr-2">
                        <p class="p-mb-1">{{ postpo.data.description | truncate: [70, '...'] }}</p>
                    </div>
                </div>
            </p-panel>
        </div>
    </ng-template>
</p-dataView>
