<!-- <section class="p-col-4 p-offset-1"> -->
<button
    pButton
    pTooltip="Remove material"
    tooltipPosition="top"
    *ngIf="isAdminDashboard"
    class="p-button-danger p-mb-5"
    label="Remove material"
    icon="pi pi-times"
    (click)="onRemoveMaterial()"
    fabStopPropagation
></button>
<h5 class="p-text-bold">{{ bitMaterial.material }}</h5>
<div class="p-mb-4 p-px-2">
    <pwk-quantity-multiple-input
        [(owner)]="bitMaterial"
        [disabled]="disabled"
        [debounce]="1000"
        [fields]="[
            {
                field: 'entryFeedRate',
                label: 'Entry, Exit feed rate',
                tooltip: 'Entry feed rate'
            },
            {
                field: 'exitFeedRate',
                tooltip: 'Exit feed rate'
            }
        ]"
        (ownerChange)="onChange()"
    ></pwk-quantity-multiple-input>
</div>
<div class="p-mb-4 p-px-2">
    <pwk-quantity-multiple-input
        [(owner)]="bitMaterial"
        [disabled]="disabled"
        [debounce]="1000"
        [fields]="[
            {
                field: 'plungeFeedRate',
                label: 'Plunge Feed Rate',
                tooltip: 'Plunge Feed Rate'
            }
        ]"
        (ownerChange)="onChange()"
    ></pwk-quantity-multiple-input>
</div>
<div class="p-mb-4 p-px-2">
    <pwk-quantity-multiple-input
        [(owner)]="bitMaterial"
        [disabled]="disabled"
        [debounce]="1000"
        [fields]="[
            {
                field: 'rampFeedRate',
                label: 'Ramp Feed Rate',
                tooltip: 'Ramp Feed Rate'
            }
        ]"
        (ownerChange)="onChange()"
    ></pwk-quantity-multiple-input>
</div>
<div class="p-mb-4 p-px-2">
    <pwk-quantity-multiple-input
        [(owner)]="bitMaterial"
        [disabled]="disabled"
        [debounce]="1000"
        [fields]="[
            {
                field: 'rampSpindle',
                label: 'Ramp Spindle',
                tooltip: 'Ramp Spindle'
            }
        ]"
        (ownerChange)="onChange()"
    ></pwk-quantity-multiple-input>
</div>
<div class="p-mb-4 p-px-2">
    <pwk-quantity-multiple-input
        [(owner)]="bitMaterial"
        [disabled]="disabled"
        [debounce]="1000"
        [fields]="[
            {
                field: 'retractFeedRate',
                label: 'Retract Feed Rate',
                tooltip: 'Retract Feed Rate'
            }
        ]"
        (ownerChange)="onChange()"
    ></pwk-quantity-multiple-input>
</div>
<div class="p-mb-3 p-px-2">
    <pwk-quantity-multiple-input
        [(owner)]="bitMaterial"
        [disabled]="disabled"
        [debounce]="1000"
        [fields]="[
            {
                field: 'spindleSpeed',
                label: 'Spindle Speed',
                tooltip: 'Spindle Speed'
            }
        ]"
        (ownerChange)="onChange()"
    ></pwk-quantity-multiple-input>
</div>
<div class="p-mb-4">
    <!-- <pwk-number-input
        [(value)]="bitMaterial.passDepthMultiplier"
        [disabled]="disabled"
        label="Pass Depth Multiplier"
        tooltipText="Pass Depth Multiplier"
        (valueChange)="onChange()"
    >
    </pwk-number-input> -->
    <pwk-quantity-input
        [(value)]="bitMaterial.passDepthMultiplier"
        [disabled]="disabled"
        label="Pass Depth Multiplier"
        [showLabel]="true"
        tooltipText="Pass Depth Multiplier"
        (valueChange)="onChange()"
        [debounce]="1000"
        mode="decimal"
    >
    </pwk-quantity-input>
</div>
<!-- </section> -->
