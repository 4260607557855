<p-accordionTab [selected]="true" *ngIf="notManufactured.length > 0">
    <ng-template pTemplate="header">Not Manufactured Parts</ng-template>
    <ng-template pTemplate="content" class="accordion-content">
        <p-table [value]="notManufactured" [resizableColumns]="true" [autoLayout]="true" [responsive]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 34%">Thumbnail</th>
                    <th style="width: 33%">Name</th>
                    <th style="width: 33%">Copies #</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-instance>
                <tr>
                    <td>
                        <img
                            [src]="'api/projects/' + project.id + '/v/' + project.version + '/parts/' + instance.partId + '/thumbnail.png?token=' + authToken"
                            width="35%"
                            height="auto"
                            onError="this.onerror=null;this.src='../../assets/default/fabber_part_default.png';"
                            alt="{{ instance.name }}"
                        />
                    </td>
                    <td>{{ instance.name }}</td>
                    <td>
                        {{ instance.instances }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-accordionTab>
