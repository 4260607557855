import {Directive, HostListener} from '@angular/core';

@Directive({
    selector: '[fabStopPropagation]'
})
export class StopPropagationDirective {
    @HostListener('click', ['$event'])
    onClick(event: Event): void {
        event.stopPropagation();
    }
}
