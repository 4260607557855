import {Component, HostListener, OnInit} from '@angular/core';
import {MachineInstanceWithId} from '@models/machine-instance-with-id.model';
import {DocumentSummary} from 'generated-src';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
    selector: 'fab-assign-bit',
    templateUrl: './assign-bit.component.html',
    styleUrls: ['./assign-bit.component.scss']
})
export class AssignBitComponent implements OnInit {
    machines: MachineInstanceWithId[];
    bit: DocumentSummary;
    selectedMachines: MachineInstanceWithId[] = [];
    disabled = true;

    constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef) {}

    ngOnInit(): void {
        this.machines = this.config.data['machines'];
        this.bit = this.config.data['bit'];
        console.log(this.machines, this.bit);
    }

    onChange() {
        if (this.selectedMachines.length > 0) {
            this.disabled = false;
        } else {
            this.disabled = true;
        }
    }

    save() {
        console.log('save');
        console.log(this.selectedMachines);
        this.ref.close(this.selectedMachines);
    }

    @HostListener('window:keyup.enter')
    onEnter() {
        if (!this.disabled) {
            this.save();
        }
    }
}
