import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'fab-dropdown-with-loading-spinner',
    templateUrl: './dropdown-with-loading-spinner.component.html',
    styleUrls: ['./dropdown-with-loading-spinner.component.scss']
})
export class DropdownWithLoadingSpinnerComponent implements OnInit {
    @Input() values: any[] = [];
    @Input() selectedValue: any;
    @Input() optionField = '';
    @Input() valuePlaceholder = '';
    @Input() isLoading = false;
    @Input() isSpinnerEnabled = false;
    @Output() selectedValueChange: EventEmitter<any> = new EventEmitter();

    loadingSpinnerSize = 15;

    ngOnInit(): void {}

    onSelect(arg: any): void {
        this.selectedValueChange.emit(this.selectedValue);
    }
}
