import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Resolve, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {VersionedWorkshop} from 'generated-src/model/versionedWorkshop';
import {Observable, throwError} from 'rxjs';
import {
    DefaultService,
    DocumentSummary,
    MachineInstance,
    VersionedMachineInstance,
    VersionedMachineInstanceMap,
    VersionedProject
} from '../../../generated-src';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {AlertService, UserService} from '@harmanpa/ng-patchwork';

@Injectable({
    providedIn: 'root'
})
export class WorkshopService implements Resolve<VersionedWorkshop>, CanActivate {
    constructor(private api: DefaultService, private router: Router, private userService: UserService, private alertService: AlertService) {
    }

    public getUserWorkshop(): Observable<VersionedWorkshop> {
        return this.api.findWorkshops(true, false, true).pipe(
            tap((workshops) => console.log('[GET USER WORKSHOPS]', workshops)),
            switchMap((workshops) => {
                if (workshops.length < 1) {
                    return this.createUserWorkshop();
                }
                const {id, head} = workshops[0];
                return this.api.getWorkshop(id, head);
            })
        );
    }

    public getProjectWorkshop(project: string | VersionedProject, projectVersion?: string): Observable<VersionedWorkshop> {
        if (typeof project === 'string') {
            return (projectVersion ? this.api.getProject(project as string, projectVersion) : this.api.getProjectHead(project as string)).pipe(switchMap((vp) => this.getProjectWorkshop(vp)));
        }
        const referenced = (project as VersionedProject).document.workshop;
        if (referenced) {
            if (referenced.version) {
                return this.api.getWorkshop(referenced.id, referenced.version);
            }
            return this.api.getWorkshopHead(referenced.id);
        }
        return this.getUserWorkshop();
    }

    createUserWorkshop(): Observable<VersionedWorkshop> {
        return this.userService.me().pipe(switchMap((me) => this.api.addWorkshop({name: me.name + '\'s workshop'})));
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<VersionedWorkshop> {
        if (route.params['workshop_id']) {
            if (route.queryParams['version']) {
                return this.api.getWorkshop(route.params['workshop_id'], route.queryParams['version']).pipe(tap((ws) => console.log('Resolved workshop', ws)));
            }
            return this.api.getWorkshopHead(route.params['workshop_id']).pipe(tap((ws) => console.log('Resolved workshop', ws)));
        }
        if (route.params['project_id']) {
            return this.getProjectWorkshop(route.params['project_id'], route.queryParams['version']).pipe(tap((ws) => console.log('Resolved workshop', ws)));
        }
        return this.getUserWorkshop().pipe(tap((ws) => console.log('Resolved workshop', ws)));
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree> | boolean {
        if (!route.params['workshop_id']) {
            return this.getUserWorkshop().pipe(
                tap((workshop) => console.warn('[USER WORKSHOP]', workshop)),
                map((workshop) => this.router.createUrlTree(['workshop', workshop.id], {queryParams: {version: workshop.version}}))
            );
        }
        return true;
    }

    addMachine(workshop: VersionedWorkshop, machineInstance: MachineInstance): Observable<VersionedMachineInstance> {
        return this.api.addWorkshopMachineInstances(workshop.id, workshop.version, machineInstance);
    }

    getUserMachines(workshopId: string, workshopVersion: string): Observable<VersionedMachineInstanceMap> {
        return this.api.getWorkshopMachineInstances(workshopId, workshopVersion).pipe(
            catchError((err) => {
                this.alertService.error('Error occured');
                return throwError(err);
            })
        );
    }

    isMachineAlreadyInWorkshop(machineId: string, workshop: VersionedWorkshop): boolean {
        const workshopMachines: { [key: string]: MachineInstance } = workshop.document['machineInstances'];
        return Object.values(workshopMachines).some(({machine: {id}}) => id === machineId);
    }

    copyMachine(machine: DocumentSummary, title: string): void {
        const machineCopy = JSON.parse(JSON.stringify(machine));
        this.api.getMe().subscribe((user) => {
            machineCopy.owner = user.email;
            machineCopy.data.name = machine.data.name + ' - Copy';
            this.api.addMachine(machineCopy.data, machine.id, machine.head).subscribe({
                next: (m) => {
                    this.router.navigate(['/machines', m.id], {state: {source: title}});
                },
                error: (err) => {
                    this.alertService.error(err);
                },
                complete: () => {
                    this.alertService.success('Machine ' + machineCopy.data.name + ' was added to your ToolBox');
                }
            });
        });
    }

    copyBit(bit: DocumentSummary, title: string) {
        const bitCopy = JSON.parse(JSON.stringify(bit));
        this.api.getMe().subscribe((user) => {
            bitCopy.owner = user.email;
            bitCopy.document.name = bitCopy.document.name + ' - Copy';
            this.api.addBit(bitCopy.document, bit.id, bit.head).subscribe({
                next: (b) => {
                    this.router.navigate(['/bits', b.id], {state: {source: title}});
                },
                error: (err) => {
                    this.alertService.error(err);
                },
                complete: () => {
                    this.alertService.success('Bit ' + bitCopy.document.name + ' was added to your Workshop');
                }
            });
        });
    }

    copyPostProcessor(postpo: DocumentSummary, title: string) {
        const postpoCopy = JSON.parse(JSON.stringify(postpo));
        this.api.getMe().subscribe((user) => {
            postpoCopy.owner = user.email;
            postpoCopy.document.name = postpoCopy.document.name + ' - Copy';
            this.api.addPostProcessor(postpoCopy.document, postpo.id, postpo.head).subscribe({
                next: (p) => {
                    console.log(p);
                    this.router.navigate(['/post', p.id], {state: {source: title}});
                },
                error: (err) => {
                    this.alertService.error(err);
                },
                complete: () => {
                    this.alertService.success('Post Processor ' + postpoCopy.document.name + ' was added to your Workshop');
                }
            });
        });
    }
}
