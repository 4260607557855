import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from 'ng2-ui-auth';
import {Subject} from 'rxjs';
import {DefaultService, VersionedPart, VersionedProject, VersionedSlice} from '../../../../generated-src';
import {LoadingManagerService} from '@harmanpa/ng-cae';
import {debounceTime} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import * as THREE from 'three';

@Component({
    selector: 'fab-part',
    templateUrl: './part.component.html',
    styleUrls: ['./part.component.scss']
})
export class PartComponent implements OnInit {
    path: string;
    project: VersionedProject;
    partId: string;
    geometryUrl: string;
    pi = Math.PI;
    halfpi = (-1 * this.pi) / 2;
    // minushalfpi = -1 * this.halfpi;
    wheelAction = 4;
    part: VersionedPart;
    slice: VersionedSlice;
    initialLoadCompleted = false;
    initialLoadComplete = new Subject<boolean>();
    white = new THREE.Color(1, 1, 1).convertLinearToSRGB();
    black = new THREE.Color(0, 0, 0).convertLinearToSRGB();
    multipleViews = false;

    constructor(
        private route: ActivatedRoute,
        public auth: AuthService,
        private api: DefaultService,
        private loadingManager: LoadingManagerService,
        private title: Title
    ) {}

    ngOnInit() {
        this.project = this.route.snapshot.data['project'];
        this.partId = this.route.snapshot.params.partid;
        this.api.getProjectPartsPart(this.project.id, this.project.version, this.partId).subscribe((part) => {
            this.part = part;
            this.title.setTitle(`View - ${this.part.document.name}`);
            this.api.getProjectPartsSlice(this.project.id, this.project.version, this.partId).subscribe((slice) => {
                this.slice = slice;
                this.geometryUrl =
                    '/api/projects/' +
                    this.project.id +
                    '/v/' +
                    this.project.version +
                    '/parts/' +
                    this.partId +
                    '/stl.stl?token=' +
                    this.auth.getToken();
            });
        });
        this.loadingManager.loading.pipe(debounceTime(200)).subscribe((loading) => {
            if (!loading) {
                this.loadComplete();
            }
        });
    }

    loadComplete(): void {
        // // Trigger actions on initial load
        if (!this.initialLoadCompleted) {
            this.initialLoadComplete.next(true);
            this.initialLoadComplete.complete();
            this.initialLoadCompleted = true;
            console.log('load complete');
        }
    }
}
