<section class="card">
    <h4 class="p-p-4">Nesting Configuration</h4>
    <div class="p-grid p-ai-end p-fluid p-py-3">
        <div class="p-col-4 p-offset-1 p-mb-2">
            <pwk-quantity-multiple-input
                    [(owner)]="nestingStrategy"
                    (ownerChange)="onChange()"
                    [disabled]="disabled"
                    [debounce]="1000"
                    [fields]="[{field: 'nestingGap', label: 'Nesting Gap', tooltip: 'Minimum distance between parts', min: '0 m'}]">
            </pwk-quantity-multiple-input>
        </div>
        <div class="p-col-4 p-offset-2 p-mb-2">
            <p-selectButton
                    [options]="rotationOptions"
                    [disabled]="disabled"
                    optionLabel="label"
                    optionValue="value"
                    [ngModel]="nestingStrategy.nestingRotations"
                    (ngModelChange)="onNestingRotationsChange($event)"
            ></p-selectButton>
        </div>
    </div>
</section>
