import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Fusion360Service} from '@services/fusion360.service';
import {SketchupService} from '@services/sketchup.service';
import {CuttingList, VersionedProject} from 'generated-src';

@Component({
    selector: 'fab-parts-project-summary',
    templateUrl: './parts-project-summary.component.html',
    styleUrls: ['./parts-project-summary.component.scss']
})
export class PartsProjectSummaryComponent implements OnInit, OnChanges {
    @Input() project: VersionedProject;
    @Input() cuttingList: CuttingList;
    @Output() cuttingListChange = new EventEmitter<CuttingList>();
    @Input() disabled = false;
    @Input() inApp = false;
    projectOff: number;
    nParts: number;
    nInstances: number;

    constructor(private sketchupService: SketchupService, private fusion360Service: Fusion360Service) {}

    ngOnInit(): void {
        if (this.sketchupService.inSketchup() || this.fusion360Service.inFusion360()) {
            this.inApp = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['cuttingList']) {
            this.projectOff = this.cuttingList ? this.cuttingList.projectOff : 1;
            this.calculateNumberOfParts();
        }
    }

    updateOff() {
        this.cuttingList.projectOff = this.projectOff;
        this.calculateNumberOfParts();
        this.cuttingListChange.emit(Object.assign({}, this.cuttingList));
    }

    calculateNumberOfParts(): void {
        let partsCount = 0;
        let instanceCount = 0;
        if (this.cuttingList && this.cuttingList.groups) {
            for (let i = 0; i < this.cuttingList.groups.length; i++) {
                if (i > 0) {
                    this.cuttingList.groups[i].instances.forEach((instance) => {
                        if (instance.off > 0) {
                            partsCount++;
                        }
                        instanceCount += instance.off;
                    });
                }
            }
            instanceCount *= this.cuttingList.projectOff;
        }
        this.nParts = partsCount;
        this.nInstances = instanceCount;
    }
}
