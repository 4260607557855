import {Component, Input} from '@angular/core';

@Component({
    selector: 'fab-loading-container',
    template: `
        <ng-container *ngIf="loading; else children">
            <fab-loading-spinner></fab-loading-spinner>
        </ng-container>
        <ng-template #children>
            <ng-content></ng-content>
        </ng-template>
    `,
    styles: [
        `
            :host {
                width: 100%;
                margin: 2rem 0;
            }
        `
    ]
})
export class LoadingContainerComponent {
    @Input() loading: boolean = false;
}
