<div class="card" style="min-height: 250px">
    <h4 class="p-p-4 p-pb-0">Bits Configuration</h4>
    <ng-container *ngIf="bits.length == 0">
        <p class="p-pl-4 no-bits-info">*Please add at least one bit to your workshop.</p>
    </ng-container>
    <div class="p-field p-offset-1 p-nogutter">
        <div class="p-col-4">
            <p-table [value]="bits" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="column-l">BIT</th>
                        <th class="column-l"># REF</th>
                        <th class="column-m">LOADED</th>
                        <th class="column-l"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-selectedBit let-index="rowIndex">
                    <tr *ngIf="currentlyEdited != selectedBit.bit.id">
                        <td>
                            {{ selectedBit.name }}
                        </td>
                        <td>
                            {{ selectedBit.toolNumber }}
                        </td>
                        <td>
                            <p-checkbox [(ngModel)]="selectedBit.loaded" [binary]="true" [disabled]="!isATC"></p-checkbox>
                        </td>
                        <td>
                            <button pButton class="p-button-primary p-mr-2" icon="pi pi-pencil" (click)="onBitEdit(selectedBit)"></button>
                            <button pButton class="p-button-danger" icon="pi pi-times" (click)="onBitDelete(selectedBit)"></button>
                        </td>
                    </tr>
                    <tr *ngIf="currentlyEdited == selectedBit.bit.id">
                        <td>
                            {{ selectedBit.name }}
                        </td>
                        <td>
                            <p-inputNumber
                                [(ngModel)]="selectedBit.toolNumber"
                                [showButtons]="true"
                                size="1"
                                [min]="1"
                                buttonLayout="horizontal"
                                spinnerMode="horizontal"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                            ></p-inputNumber>
                        </td>
                        <td>
                            <p-checkbox [(ngModel)]="selectedBit.loaded" [binary]="true"></p-checkbox>
                        </td>
                        <td>
                            <button pButton class="p-button-primary p-ml-5" label="Save" (click)="onSave(selectedBit)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
