import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {ActivatedRoute} from '@angular/router';
import {AuthService} from 'ng2-ui-auth';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {Point, SVG} from '@svgdotjs/svg.js';
import '@svgdotjs/svg.panzoom.js';
import '@svgdotjs/svg.draggable.js';
import {VersionedProject} from '../../../generated-src';
import {SheetsService} from './services/sheets.service';
import {Title} from '@angular/platform-browser';
import {AlertService} from '@harmanpa/ng-patchwork';

@Component({
    selector: 'fab-sheets',
    templateUrl: './sheets.component.html',
    styleUrls: ['./sheets.component.scss'],
    providers: [{provide: SheetsService, useClass: SheetsService}]
})
export class SheetsComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() project: VersionedProject = this.route.snapshot.data['project'];
    @Input() onshapeExtension = false;
    @Input() visible: boolean;
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    // currentPart: Observable<Part>;
    @ViewChild('viewerDiv', {static: true}) viewerDiv: ElementRef;
    @ViewChild('rightPanel', {static: false}) rightPanel: ElementRef;
    @ViewChild('attachedCoordinates', {static: true}) attachedCoordinates: ElementRef;
    displayDialog = false;
    loadingScreen = true;
    status = '';
    heightOffset = 154;
    mousePosition = new Point(0,0);
    xMax = 0;
    yMax = 0;

    constructor(
        private route: ActivatedRoute,
        public auth: AuthService,
        private loadingBar: LoadingBarService,
        private sheetsService: SheetsService,
        private title: Title,
        private alertService: AlertService
    ) {
    }

    ngOnInit() {
        this.title.setTitle(`Sheets - ${this.project.document.name}`);
        if (this.onshapeExtension) {
            this.heightOffset = 244;
        }
        this.getSvgCoordinates();
        this.setAttachedCoordinatesDivPosition();
    }

    getSvgCoordinates(): void {
        this.sheetsService.svgPosition.subscribe((point) => {
            this.mousePosition.x = +(point.x).toFixed(2);
            this.mousePosition.y = +(point.y).toFixed(2);
            this.xMax = this.sheetsService.xMax;
            this.yMax = this.sheetsService.yMax;
        });
    }

    setAttachedCoordinatesDivPosition(): void {
        this.sheetsService.mousePosition.subscribe((mouse) => {
            if(this.enableCoordinates()){
                this.attachedCoordinates.nativeElement.style.transform = 'translateY('+ (mouse.y-110)+'px)';
                this.attachedCoordinates.nativeElement.style.transform += 'translateX('+ (mouse.x+10)+'px)';
            }
        })
    }

    enableCoordinates(): boolean {
        return this.mousePosition.x >= 0 && this.mousePosition.x <= this.xMax && this.mousePosition.y >= 0 && this.mousePosition.y <= this.yMax;
    }

    ngOnDestroy(): void {
    }

    @HostListener('window:resize', ['$event'])
    onResize(e: any): void {
        this.sheetsService.resizeSvg(
            this.rightPanel ? e.target.innerWidth - this.rightPanel.nativeElement.clientWidth : e.target.innerWidth,
            e.target.innerHeight - this.heightOffset
        );
    }

    ngAfterViewInit(): void {
        this.sheetsService.init(this.project);
        this.sheetsService.initSvgElement(
            SVG()
                .addTo(this.viewerDiv.nativeElement)
                .size(this.viewerDiv.nativeElement.clientWidth, this.viewerDiv.nativeElement.clientHeight)
                .panZoom({zoomMin: 0.05, zoomMax: 200, zoomFactor: 0.2})
        ).subscribe({
            next: status => {
                this.status = status;
                console.log('Drawing status ', this.status);
                if(this.status === 'Done'){
                    this.loadingScreen = false;
                }
            },
            error: err => this.alertService.error(err)
        });
    }
}
