import {Component, Input, OnInit} from '@angular/core';
import {VersionedProject} from '../../../../generated-src';

@Component({
    selector: 'fab-sheets-options',
    templateUrl: './sheets-options.component.html',
    styleUrls: ['./sheets-options.component.scss']
})
export class SheetsOptionsComponent implements OnInit {
    @Input() project: VersionedProject;

    constructor() {}

    ngOnInit(): void {}
}
