import {Component, Input, OnInit} from '@angular/core';
import {DrawPartsService} from '../services/draw-parts.service';

@Component({
    selector: 'fab-sheets-edit-nesting',
    templateUrl: './sheets-edit-nesting.component.html',
    styleUrls: ['./sheets-edit-nesting.component.scss']
})
export class SheetsEditNestingComponent implements OnInit {
    editing = false;
    disabled = true;
    rotator: (angle: number) => void;

    constructor(private drawPartsService: DrawPartsService) {}

    ngOnInit(): void {}

    toggleEditing() {
        this.editing = !this.editing;
        this.drawPartsService.isEditingEnabled = this.editing;
        this.drawPartsService.selected.subscribe((value) => {
            this.disabled = value;
            this.rotator = this.drawPartsService.rotator;
        });
        this.drawPartsService.partMap.forEach((value, key) => value.draggable(this.editing));
    }
}
