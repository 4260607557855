import {APP_AVAILABLE_UNITS, AppAvailableUnits} from './app.config';
import {Inject, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CookieService} from 'ngx-cookie-service';
import {Ng2UiAuthModule} from 'ng2-ui-auth';
import {GtagModule} from 'angular-gtag';
import {HttpClientModule} from '@angular/common/http';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {AngularSplitModule} from 'angular-split';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SketchupService} from '@services/sketchup.service';
import {Fusion360Service} from '@services/fusion360.service';
import {OnshapeService} from '@services/onshape.service';
import {ProjectsComponent} from './projects/projects.component';
import {PartsListComponent} from './parts-list/parts-list.component';
import {ProfileComponent} from './profile/profile.component';
import {environment} from '../environments/environment';
import {LinkComponent} from './integrations/link/link.component';
import {MachinesComponent} from './machines/machines.component';
import 'codemirror/mode/handlebars/handlebars';
import 'codemirror/mode/xml/xml';
import './js/mode/gcode/gcode';
import './js/mode/shopbot/shopbot';
import {MachineEditorComponent} from './machines/machine-editor/machine-editor.component';
import {SheetsComponent} from './sheets/sheets.component';
import {PartComponent} from './parts-list/part/part.component';
import {TOSComponent} from './layout/header/version/tos/tos.component';
import {AssemblyComponent} from './parts-list/assembly/assembly.component';
import {PathGeometryDirective} from './parts-list/part/path-geometry.directive';
import {SheetsPostProcessButtonComponent} from './sheets/sheets-post-process-button/sheets-post-process-button.component';
import {BitEditorComponent} from './machines/bit-editor/bit-editor.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {WaitingComponent} from './waiting/waiting.component';
import {Intercom, IntercomModule} from 'ng-intercom';
import {PostProcessorEditorComponent} from './machines/post-processor-editor/post-processor-editor.component';
import {VersionComponent} from './layout/header/version/version.component';
import {StrategyPickerComponent} from './machines/machine-editor/strategy-picker/strategy-picker.component';
import {LayerSetGeometryDirective} from './parts-list/part/layer-set-geometry.directive';
import {SliceGeometryDirective} from './parts-list/part/slice-geometry.directive';
import {WorkshopComponent} from './machines/workshop/workshop.component';
import {UserMachineEditorComponent} from './machines/workshop/user-machine-editor/user-machine-editor.component';
import {SkeletonModule} from 'primeng/skeleton';
import {BadgeModule} from 'primeng/badge';
import {AccordionModule} from 'primeng/accordion';
import {TimelineModule} from 'primeng/timeline';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {CheckboxModule} from 'primeng/checkbox';
import {CommonModule} from '@angular/common';
import {
    AdminRoutingModule,
    ANALYTICS_PROVIDER,
    DeveloperRoutingModule,
    NgPatchworkModule,
    PatchworkAPI,
    UserflowRoutingModule
} from '@harmanpa/ng-patchwork';
import {ApiModule, DefaultService} from '../../generated-src';
import {DropdownModule} from 'primeng/dropdown';
import {CardModule} from 'primeng/card';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {TableModule} from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {ToolbarModule} from 'primeng/toolbar';
import {DialogModule} from 'primeng/dialog';
import {TooltipModule} from 'primeng/tooltip';
import {ProjectsPipe} from './projects/projects.pipe';
import {MachineDimSpeedsComponent} from './machines/machine-editor/machine-dim-speeds/machine-dim-speeds.component';
import {NestingConfigComponent} from './machines/machine-editor/nesting-config/nesting-config.component';
import {ToolpathConfigComponent} from './machines/machine-editor/toolpath-config/toolpath-config.component';
import {PostprocessorComponent} from './machines/machine-editor/postprocessor/postprocessor.component';
import {BitsConfigComponent} from './machines/machine-editor/bits-config/bits-config.component';
import {SliderModule} from 'primeng/slider';
import {NgxPermissionsModule} from 'ngx-permissions';
import {LayoutComponent} from './layout/layout.component';
import {OnshapeComponent} from './integrations/onshape/onshape.component';
import {TabViewModule} from 'primeng/tabview';
import {NgCaeModule} from '@harmanpa/ng-cae';
import {CatalogueComponent} from './machines/catalogue/catalogue.component';
import {MachineInfoComponent} from './machines/workshop/machine-info/machine-info.component';
import {PaginatorModule} from 'primeng/paginator';
import {DividerModule} from 'primeng/divider';
import {ApmService} from '@elastic/apm-rum-angular';
import {Router} from '@angular/router';
import {MultiSelectModule} from 'primeng/multiselect';
import {DataViewModule} from 'primeng/dataview';
import {InputTextModule} from 'primeng/inputtext';
import {InputNumberModule} from 'primeng/inputnumber';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {SidebarModule} from 'primeng/sidebar';
import {ProgressBarModule} from 'primeng/progressbar';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {BitInfoComponent} from './machines/workshop/bit-info/bit-info.component';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {CuttingConfigComponent} from './machines/machine-editor/cutting-config/cutting-config.component';
import {SubscriptionComponent} from './profile/subscription/subscription.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {AddBitsPopUpComponent} from './machines/catalogue/add-bits-pop-up/add-bits-pop-up.component';
import {AssignBitComponent} from './machines/catalogue/assign-bit/assign-bit.component';
import {StepsModule} from 'primeng/steps';
import {FileUploadModule} from 'primeng/fileupload';
import {BitsPanelComponent} from './machines/catalogue/bits-panel/bits-panel.component';
import {MachinesPanelComponent} from './machines/catalogue/machines-panel/machines-panel.component';
import {PostProcessorsPanelComponent} from './machines/catalogue/post-processors-panel/post-processors-panel.component';
import {ProjectItemComponent} from './projects/project-item/project-item.component';
import {ChipModule} from 'primeng/chip';
import {MaterialEditorComponent} from './machines/bit-editor/material-settings/material-editor/material-editor.component';
import {InplaceModule} from 'primeng/inplace';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {ConfirmDialogComponent} from './shared/components/confirm-dialog/confirm-dialog.component';
import {SheetSizeEditorComponent} from './parts-list/sheet-size-editor/sheet-size-editor.component';
import {StopPropagationDirective} from './shared/directives/stop-propagation.directive';
import {PartItemEditorComponent} from './parts-list/parts-cutting-list/part-item-editor/part-item-editor.component';
import {LoadingSpinnerComponent} from './shared/components/loading-spinner/loading-spinner.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {LoadingContainerComponent} from './shared/components/loading-container/loading-container.component';
import {PartsListHeaderComponent} from './parts-list/parts-list-header/parts-list-header.component';
import {PartsListGroupComponent} from './parts-list/parts-cutting-list/parts-list-group/parts-list-group.component';
import {
    DropdownWithLoadingSpinnerComponent
} from './shared/components/dropdown-with-loading-spinner/dropdown-with-loading-spinner.component';
import {SheetsAdvancedSettingsComponent} from './sheets/sheets-advanced-settings/sheets-advanced-settings.component';
import {SheetsEditNestingComponent} from './sheets/sheets-edit-nesting/sheets-edit-nesting.component';
import {QuickOptionsComponent} from './sheets/sheets-advanced-settings/quick-options/quick-options.component';
import {ToolingComponent} from './sheets/sheets-advanced-settings/tooling/tooling.component';
import {SheetsSelectComponent} from './sheets/sheets-select/sheets-select.component';
import {SheetsSelectedPartComponent} from './sheets/sheets-selected-part/sheets-selected-part.component';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TruncatePipe} from './pipes/text.pipe';
import {PartsProjectSummaryComponent} from './parts-list/parts-project-summary/parts-project-summary.component';
import {PartsCuttingListComponent} from './parts-list/parts-cutting-list/parts-cutting-list.component';
import {PartsDeletedComponent} from './parts-list/parts-cutting-list/parts-deleted/parts-deleted.component';
import {PartsNotManufacturedComponent} from './parts-list/parts-cutting-list/parts-not-manufactured/parts-not-manufactured.component';
import {BitParamsComponent} from './machines/bit-editor/bit-params/bit-params.component';
import {MaterialSettingsComponent} from './machines/bit-editor/material-settings/material-settings.component';
import {SheetsOptionsComponent} from './sheets/sheets-options/sheets-options.component';
import {UserWorkshopMachinesPanelComponent} from './machines/workshop/user-workshop-machines-panel/user-workshop-machines-panel.component';
import {SheetGroupSelectComponent} from './sheets/sheet-group-select/sheet-group-select.component';
import {PromptDialogComponent} from './shared/components/prompt-dialog/prompt-dialog.component';
import {GitgraphComponent} from './shared/components/gitgraph/gitgraph.component';
import {HistoryComponent} from './shared/components/history/history.component';
import {GeometryDebugComponent} from './admin/geometry-debug/geometry-debug.component';
import {SheetsControlButtonsComponent} from './sheets/sheets-control-buttons/sheets-control-buttons.component';
import {IntercomAnalyticsProvider} from '@services/intercom-analytics-provider';

@NgModule({
    declarations: [
        AppComponent,
        ProjectsComponent,
        PartsListComponent,
        ProfileComponent,
        LinkComponent,
        MachinesComponent,
        MachineEditorComponent,
        SheetsComponent,
        PartComponent,
        TOSComponent,
        AssemblyComponent,
        PathGeometryDirective,
        SheetsPostProcessButtonComponent,
        BitEditorComponent,
        WaitingComponent,
        PostProcessorEditorComponent,
        VersionComponent,
        StrategyPickerComponent,
        LayerSetGeometryDirective,
        SliceGeometryDirective,
        WorkshopComponent,
        ProjectsPipe,
        TruncatePipe,
        MachineDimSpeedsComponent,
        NestingConfigComponent,
        ToolpathConfigComponent,
        PostprocessorComponent,
        BitsConfigComponent,
        LayoutComponent,
        OnshapeComponent,
        CatalogueComponent,
        MachineInfoComponent,
        UserMachineEditorComponent,
        BitInfoComponent,
        CuttingConfigComponent,
        SubscriptionComponent,
        AddBitsPopUpComponent,
        AssignBitComponent,
        BitsPanelComponent,
        MachinesPanelComponent,
        PostProcessorsPanelComponent,
        ProjectItemComponent,
        MaterialEditorComponent,
        ConfirmDialogComponent,
        SheetSizeEditorComponent,
        StopPropagationDirective,
        PartItemEditorComponent,
        LoadingSpinnerComponent,
        LoadingContainerComponent,
        PartsListHeaderComponent,
        PartsListGroupComponent,
        DropdownWithLoadingSpinnerComponent,
        SheetsAdvancedSettingsComponent,
        SheetsEditNestingComponent,
        QuickOptionsComponent,
        ToolingComponent,
        SheetsSelectComponent,
        SheetsSelectedPartComponent,
        PartsProjectSummaryComponent,
        PartsCuttingListComponent,
        PartsDeletedComponent,
        PartsNotManufacturedComponent,
        BitParamsComponent,
        MaterialSettingsComponent,
        SheetsOptionsComponent,
        UserWorkshopMachinesPanelComponent,
        SheetGroupSelectComponent,
        PromptDialogComponent,
        GitgraphComponent,
        HistoryComponent,
        GeometryDebugComponent,
        SheetsControlButtonsComponent
    ],
    imports: [
        ApiModule,
        NgPatchworkModule.forRoot({
            userflow: {
                enableSignup: false,
                login: {
                    providerLogin: {
                        memberful: {name: 'Login with Fabber account'}
                    },
                    enablePassword: false,
                    logoutTo: 'https://getfabber.memberful.com/auth/sign_out'
                },
                roleLogin: {
                    user: {
                        providerLogin: {
                            onshape: {name: 'Onshape'},
                            autodesk: {name: 'Autodesk'}
                        },
                        enablePassword: false
                    }
                }
            },
            types: {
                Machines: {urlMapper: (id, version) => ['/machines', id]},
                Bits: {urlMapper: (id, version) => ['/bits', id]},
                Workshops: {urlMapper: (id, version) => ['/workshop', id]},
                PostProcessors: {urlMapper: (id, version) => ['/post', id]},
                Projects: {urlMapper: (id, version) => ['/project', id]}
            },
            units: []
        }),
        AdminRoutingModule,
        UserflowRoutingModule,
        DeveloperRoutingModule,
        NgCaeModule,
        NgxPermissionsModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        AngularSplitModule,
        CheckboxModule,
        BadgeModule,
        InplaceModule,
        SkeletonModule,
        GtagModule.forRoot({
            trackingId: environment.google.analytics.uid,
            trackPageviews: true
        }),
        Ng2UiAuthModule.forRoot({
            baseUrl: environment.location + '/api',
            providers: {
                memberful: {
                    name: 'memberful',
                    url: '/auth/memberful',
                    authorizationEndpoint: 'https://getfabber.memberful.com/oauth',
                    oauthType: '2.0',
                    popupOptions: {width: 600, height: 600}
                },
                memberfulsignup: {
                    name: 'memberful',
                    url: '/auth/memberful',
                    authorizationEndpoint: 'https://getfabber.memberful.com/checkout?plan=38910',
                    oauthType: '2.0',
                    popupOptions: {width: 600, height: 600}
                },
                onshape: {
                    name: 'onshape',
                    url: '/auth/onshape',
                    authorizationEndpoint: 'https://oauth.onshape.com/oauth/authorize',
                    oauthType: '2.0',
                    popupOptions: {width: 600, height: 600}
                },
                autodesk: {
                    name: 'autodesk',
                    url: '/auth/autodesk',
                    authorizationEndpoint: 'https://developer.api.autodesk.com/authentication/v1/authorize',
                    oauthType: '2.0',
                    popupOptions: {width: 600, height: 600},
                    scope: [
                        'data:read',
                        'data:write',
                        'data:create',
                        'data:search',
                        'bucket:create',
                        'bucket:read',
                        'bucket:update',
                        'bucket:delete',
                        'code:all',
                        'account:read',
                        'account:write',
                        'user-profile:read',
                        'viewables:read'
                    ],
                    scopeDelimiter: ' '
                },
                google: {
                    url: '/auth/google'
                }
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: true}),
        IntercomModule.forRoot({
            appId: 't7tq0zk6',
            updateOnRouterChange: true
        }),
        CodemirrorModule,
        AccordionModule,
        TimelineModule,
        ButtonModule,
        RippleModule,
        CommonModule,
        DropdownModule,
        CardModule,
        SelectButtonModule,
        ConfirmDialogModule,
        TableModule,
        PanelModule,
        ToolbarModule,
        DialogModule,
        TooltipModule,
        SliderModule,
        TabViewModule,
        PaginatorModule,
        DividerModule,
        AvatarModule,
        AvatarGroupModule,
        SidebarModule,
        ProgressBarModule,
        MultiSelectModule,
        DataViewModule,
        InputTextModule,
        InputNumberModule,
        ScrollPanelModule,
        ToggleButtonModule,
        InputTextareaModule,
        CheckboxModule,
        StepsModule,
        FileUploadModule,
        ChipModule,
        DynamicDialogModule,
        ProgressSpinnerModule,
        SplitButtonModule
    ],
    providers: [
        CookieService,
        SketchupService,
        Fusion360Service,
        OnshapeService,
        DialogService,
        {provide: ANALYTICS_PROVIDER, useClass: IntercomAnalyticsProvider, deps: [Intercom], multi: true},
        {provide: PatchworkAPI, useExisting: DefaultService},
        {provide: APP_AVAILABLE_UNITS, useValue: AppAvailableUnits},
        {provide: ApmService, useClass: ApmService, deps: [Router]}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(@Inject(ApmService) apmService: ApmService) {
        // const apm = apmService.init({
        //     serviceName: 'fabber',
        //     serverUrl: 'https://89d5053c12e24c86a40cd1928522ffc6.apm.us-east1.gcp.elastic-cloud.com'
        // });
    }
}
