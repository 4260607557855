import {Component, Input, OnInit} from '@angular/core';
import {VersionedProject} from '../../../../generated-src';

@Component({
    selector: 'fab-sheets-advanced-settings',
    templateUrl: './sheets-advanced-settings.component.html',
    styleUrls: ['./sheets-advanced-settings.component.scss']
})
export class SheetsAdvancedSettingsComponent implements OnInit {
    @Input() project: VersionedProject;
    ngOnInit(): void {
    }
}
