import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VersionedBit } from 'generated-src';

@Component({
  selector: 'fab-bit-params',
  templateUrl: './bit-params.component.html',
  styleUrls: ['./bit-params.component.scss']
})
export class BitParamsComponent implements OnInit {

  @Input() bit: VersionedBit;
  @Input() isEditable: boolean;
  @Output() bitChange = new EventEmitter<VersionedBit>();

  constructor() { }

  ngOnInit(): void {
  }

  onChange(){
    this.bitChange.emit(Object.assign({}, this.bit));
  }

}
