import {Pipe, PipeTransform} from '@angular/core';
import {DocumentSummary} from '../../../generated-src';

@Pipe({
    name: 'projectName',
    pure: false
})
export class ProjectsPipe implements PipeTransform {
    transform(value: DocumentSummary[], query: string, field: string): DocumentSummary[] {
        // console.log('Filtering', query, field);
        return query ? value.filter((ds) => ds.data[field] && ds.data[field].toString().toLowerCase().includes(query.toLowerCase())) : value;
    }
}
