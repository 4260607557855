/**
 * Fabber API
 * CAD to CNC in seconds
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Artefact } from './artefact';
import { CuttingStrategy } from './cuttingStrategy';
import { NestingStrategy } from './nestingStrategy';
import { PostProcessStrategy } from './postProcessStrategy';
import { ToolpathStrategy } from './toolpathStrategy';

export interface Machine { 
    name?: string;
    brand?: string;
    model?: string;
    ATC?: Machine.ATCEnum;
    xLength?: string;
    yLength?: string;
    zHeight?: string;
    minSpindleSpeed?: string;
    maxSpindleSpeed?: string;
    minColletSize?: string;
    maxColletSize?: string;
    minCuttingSpeed?: string;
    maxCuttingSpeed?: string;
    nestingStrategy?: NestingStrategy;
    toolpathStrategy?: ToolpathStrategy;
    postProcessStrategy?: PostProcessStrategy;
    cuttingStrategy?: CuttingStrategy;
    atcstrategy?: Machine.AtcstrategyEnum;
    thumbnailId?: Artefact;
}
export namespace Machine {
    export type ATCEnum = 'None' | 'ATC';
    export const ATCEnum = {
        None: 'None' as ATCEnum,
        ATC: 'ATC' as ATCEnum
    };
    export type AtcstrategyEnum = 'None' | 'ATC';
    export const AtcstrategyEnum = {
        None: 'None' as AtcstrategyEnum,
        ATC: 'ATC' as AtcstrategyEnum
    };
}
