<h5>Advanced Settings</h5>
<div>
    <p-accordion>
        <p-accordionTab header="Quick Options - Coming soon">
            <ng-template pTemplate="content p-grid p-text-center p-nogutter">
                <fab-quick-options></fab-quick-options>
            </ng-template>
        </p-accordionTab>
        <p-accordionTab header="Tooling">
            <fab-tooling [project]="project"></fab-tooling>
        </p-accordionTab>
    </p-accordion>
</div>
