<div *ngIf="machine">
    <h3 class="p-my-5 p-mx-6">
        <span *ngIf="isEditable">Edit My</span>
        <span *ngIf="!isEditable">View My</span>
        Machine: {{ machine.document.name }}
    </h3>
</div>

<section class="card" *ngIf="machine">
    <div class="p-grid p-fluid p-pt-3">
        <div class="p-col-10 p-offset-1 p-grid p-fluid p-ai-center p-jc-between">
            <div class="p-col-2 p-d-flex p-flex-column p-ai-center">
                <img
                    [src]="getUrl"
                    [alt]="machine.document.name"
                    width="60"
                    height="60"
                    onError="this.onerror=null;this.src='../assets/brands/machine.png';"
                />
                <p-fileUpload
                    *ngIf="isEditable"
                    mode="basic"
                    [url]="url"
                    name="file"
                    method="put"
                    accept="image/*"
                    [auto]="true"
                    (onUpload)="machineThumbUpload()"
                    chooseLabel="Choose image"
                ></p-fileUpload>
            </div>
            <div class="p-col-3 p-field p-pt-4 p-ml-4">
                <pwk-text-input [label]="'Name'" [(text)]="machine.document.name" [disabled]="true"> </pwk-text-input>
            </div>
            <div class="p-col-3 p-field p-pt-4 p-ml-4">
                <pwk-text-input [label]="'Brand'" [(text)]="machine.document.brand" [disabled]="true"> </pwk-text-input>
            </div>
            <div class="p-col-3 p-field p-pt-4 p-ml-4">
                <pwk-text-input [label]="'Model'" [(text)]="machine.document.model" [disabled]="true"> </pwk-text-input>
            </div>
        </div>
    </div>
</section>

<section *ngIf="machine && machineInstance">
    <fab-machine-dim-speeds [machine]="machine.document" (machineChange)="onMachineChange()" [disabled]="true"> </fab-machine-dim-speeds>

    <fab-nesting-config
        [nestingStrategy]="machineInstance.nestingStrategy"
        (nestingStrategyChange)="onMachineChange()"
        [disabled]="!isEditable"
    >
    </fab-nesting-config>

    <fab-toolpath-config
        [toolpathStrategy]="machineInstance.toolpathStrategy"
        (toolpathStrategyChange)="onMachineChange()"
        [disabled]="!isEditable"
    >
    </fab-toolpath-config>

    <fab-postprocessor
        [postProcessStrategy]="machine.document.postProcessStrategy"
        (postProcessStrategyChange)="onMachineChange()"
        [disabled]="true"
    ></fab-postprocessor>

    <fab-bits-config
        [toolTable]="machineInstance.toolTable"
        (toolTableChange)="onMachineChange()"
        [atc]="machine.document.ATC"
    ></fab-bits-config>

    <fab-cutting-config
        [cuttingStrategy]="machineInstance.cuttingStrategy"
        (cuttingStrategyChange)="onMachineChange()"
        [disabled]="!isEditable"
    ></fab-cutting-config>

    <div class="submit-machine p-mt-3 p-mb-3 p-d-flex p-ai-center p-jc-end">
        <button
            pButton
            pTooltip="Back"
            tooltipPosition="top"
            class="p-mr-2"
            label="Back"
            icon="pi pi-arrow-left"
            [routerLink]="['/workshop']"
        ></button>
    </div>
</section>
