<div class="timeline p-mt-6 p-my-6" *ngIf="showMenu">
    <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="true"></p-steps>
</div>
<div class="p-d-flex p-jc-center p-mt-6 p-mb-6">
    <button
        pButton
        pTooltip="Make it!"
        tooltipPosition="top"
        class="p-button-lg p-button-raised p-button-rounded"
        label="Make it!"
        (click)="onClick()"
        [ngClass]="{'rotate-scale-up': buttonReady && (!disabled || buttonReady)}"
        [disabled]="(disabled && !buttonReady) || !buttonReady"
    ></button>
</div>
