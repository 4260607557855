<h5 class="p-mt-3">Choose from {{ machines.length }} standard machine configurations.</h5>
<p-dataView #dataViewMachines [value]="machines" [paginator]="true" [rows]="12" filterBy="data.name" layout="grid">
    <ng-template pTemplate="header">
        <div class="p-d-flex p-flex-column p-ai-center p-flex-md-row p-jc-md-between">
            <div class="p-d-flex">
                <span class="p-input-icon-left p-mb-2 p-mb-md-0 p-mr-2">
                    <i class="pi pi-search"></i>
                    <input
                        type="search"
                        pInputText
                        placeholder="Search by name..."
                        (input)="dataViewMachines.filter($event.target.value)"
                    />
                </span>
            </div>
            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
        </div>
    </ng-template>
    <ng-template let-machine pTemplate="gridItem">
        <div class="p-col-3 p-p-2">
            <p-panel class="public">
                <p-header class="p-d-flex p-jc-between p-ai-center view">
                    <div class="p-d-flex p-jc-start p-ai-center p-p-3 machine-card-header" (click)="viewMachine(machine)">
                        <img
                            [src]="'api/machines/' + machine.id + '/v/' + machine.head + '/thumbnail.png?token=' + authToken"
                            width="40"
                            height="40"
                            onError="this.onerror=null;this.src='../assets/brands/machine.png';"
                            alt="{{ machine.name }}"
                        />
                        <h5 class="p-pl-3 p-m-0 p-text-bold">{{ machine.data['name'] }}</h5>
                    </div>
                    <div class="p-d-flex p-pr-3">
                        <button
                            pButton
                            pTooltip="Add {{ machine.data.name }} to my workshop"
                            tooltipPosition="top"
                            class="p-button-text p-mr-1"
                            icon="pi pi-plus"
                            (click)="setupMachine(machine, $event)"
                            fabStopPropagation
                        ></button>
                        <button
                            pButton
                            pTooltip="Configure {{ machine.data.name }}"
                            tooltipPosition="top"
                            *ngIf="!machine.permissions.write"
                            class="p-button-secondary"
                            icon="pi pi-sliders-v"
                            (click)="copy(machine, $event)"
                            fabStopPropagation
                        ></button>
                        <button
                            pButton
                            pTooltip="Delete {{ machine.data.name }}"
                            tooltipPosition="top"
                            *ngIf="machine.permissions.write"
                            class="p-button-danger"
                            icon="pi pi-trash"
                            (click)="remove(machine)"
                            fabStopPropagation
                        ></button>
                    </div>
                </p-header>
                <div class="p-d-flex p-ai-end p-jc-between">
                    <!-- <div class="machine-card"> -->
                    <div>
                        <p class="p-mb-1">
                            <strong>Cutting Area:</strong> {{ machine.data.xLength | unit }} x {{ machine.data.yLength | unit }}
                        </p>
                        <p class="p-mb-1"><strong>Max z height:</strong> {{ machine.data.zHeight | unit }}</p>
                        <p class="p-mb-1">
                            <strong>Spindle speed:</strong> {{ machine.data.minSpindleSpeed | unit }} to
                            {{ machine.data.maxSpindleSpeed | unit }}.
                        </p>
                        <p class="p-mb-1">
                            <strong>Cutting Speed: </strong> {{ machine.data.minCuttingSpeed | unit }} to
                            {{ machine.data.maxCuttingSpeed | unit }}
                        </p>
                    </div>
                </div>
            </p-panel>
        </div>
    </ng-template>
    <ng-template let-machine pTemplate="listItem">
        <div class="p-col-12 p-my-2">
            <p-panel class="public">
                <p-header class="view" (click)="viewMachine(machine)">
                    {{ machine.data.name }}
                </p-header>
                <div class="p-d-flex p-jc-between">
                    <div class="p-mr-2 p-as-stretch">
                        <small
                            >Cutting area:
                            {{ machine.data.xLength | unit }}
                            x
                            {{ machine.data.yLength | unit }}, with maximum z height of {{ machine.data.zHeight | unit }}. <br />Spindle
                            speed:
                            {{ machine.data.minSpindleSpeed | unit }}
                            to
                            {{ machine.data.maxSpindleSpeed | unit }}. <br />Cutting speed:
                            {{ machine.data.minCuttingSpeed | unit }}
                            to
                            {{ machine.data.maxCuttingSpeed | unit }}.</small
                        >
                    </div>
                    <div class="p-mr-2 p-as-end p-d-flex">
                        <button
                            pButton
                            pTooltip="Add {{ machine.data.name }} to my workshop"
                            tooltipPosition="top"
                            class="p-button-text p-mr-1"
                            icon="pi pi-plus"
                            (click)="setupMachine(machine, $event)"
                            fabStopPropagation
                        ></button>
                        <button
                            pButton
                            pTooltip="Configure {{ machine.data.name }}"
                            tooltipPosition="top"
                            *ngIf="!machine.permissions.write"
                            class="p-button-secondary"
                            icon="pi pi-sliders-v"
                            (click)="copy(machine, $event)"
                            fabStopPropagation
                        ></button>
                        <button
                            pButton
                            pTooltip="Delete {{ machine.data.name }}"
                            tooltipPosition="top"
                            *ngIf="machine.permissions.write"
                            class="p-button-danger"
                            icon="pi pi-trash"
                            (click)="remove(machine)"
                            fabStopPropagation
                        ></button>
                    </div>
                </div>
            </p-panel>
        </div>
    </ng-template>
</p-dataView>
