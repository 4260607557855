import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService, PatchworkService, UnitService} from '@harmanpa/ng-patchwork';
import {CuttingList, DefaultService, VersionedCuttingList, VersionedProject, VersionedWorkshop} from 'generated-src';
import {Subject} from 'rxjs';
import {auditTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'fab-parts-list',
    templateUrl: './parts-list.component.html',
    styleUrls: ['./parts-list.component.scss']
})
export class PartsListComponent implements OnInit {
    project: VersionedProject;
    workshop: VersionedWorkshop;
    cuttingList: CuttingList;
    cuttingListChange = new Subject<CuttingList>();
    saved = new Subject<VersionedCuttingList>();
    nextCuttingList: CuttingList;

    activeIndex = 1;
    numOfParts = 0;
    sumOfParts = 0;
    projectOff = 1;
    disabled: boolean;
    buttonReady = false;
    loading = false;
    saving = false;

    constructor(
        private alertService: AlertService,
        private api: DefaultService,
        private patchwork: PatchworkService,
        private route: ActivatedRoute,
        private router: Router,
        private title: Title
    ) {}

    ngOnInit() {
        this.project = this.route.snapshot.data['project'];
        this.workshop = this.route.snapshot.data['workshop'];
        this.title.setTitle(this.project.document.name);
        this.disabled = !this.project.permissions.write;
        this.patchwork.setVersionQuery(this.route, this.project);
        this.api.getProjectCuttingList(this.project.id, this.project.version).subscribe({
            next: (vcl) => {
                this.cuttingList = vcl.document;
                this.cuttingListChange.pipe(auditTime(650), distinctUntilChanged()).subscribe({
                    next: (cl) => {
                        this.nextCuttingList = cl;
                        console.log(cl);
                        this.save();
                    },
                    error: (err) => this.alertService.error(err)
                });
            },
            error: (err) => this.alertService.error(err)
        });
    }

    private save(): void {
        if (!this.saving) {
            this.saving = true;
            const toSave = this.nextCuttingList;
            this.nextCuttingList = null;
            this.api.updateProjectCuttingList(this.project.id, this.project.version, toSave).subscribe({
                next: (value) => {
                    this.project.version = value.version;
                    this.patchwork.setVersionQuery(this.route, value);
                    this.saved.next(value);
                    this.saving = false;
                    if (this.nextCuttingList) {
                        this.save();
                    }
                },
                error: (err) => {
                    this.saving = false;
                    this.alertService.error(err);
                }
            });
        }
    }

    manufacture(): void {
        this.router.navigate(['/projects', this.project.id, 'sheets'], {
            queryParams: {version: this.project.version}
        });
    }
}
