/**
 * Fabber API
 * CAD to CNC in seconds
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BoMTable } from './boMTable';
import { BoundingBox } from './boundingBox';
import { ConfiguredPart } from './configuredPart';
import { Mechanism } from './mechanism';
import { SubAssembly } from './subAssembly';

export interface ConfiguredAssembly { 
    documentId?: string;
    wvmType?: ConfiguredAssembly.WvmTypeEnum;
    wvmId?: string;
    elementId?: string;
    configuration?: string;
    subAssemblies?: Array<SubAssembly>;
    parts?: Array<ConfiguredPart>;
    boundingBox?: BoundingBox;
    bom?: BoMTable;
    mechanism?: Mechanism;
}
export namespace ConfiguredAssembly {
    export type WvmTypeEnum = 'Workspace' | 'Version' | 'Microversion';
    export const WvmTypeEnum = {
        Workspace: 'Workspace' as WvmTypeEnum,
        Version: 'Version' as WvmTypeEnum,
        Microversion: 'Microversion' as WvmTypeEnum
    };
}
