import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SketchupService } from '../services/sketchup.service';
import { Fusion360Service } from '../services/fusion360.service';
import { AuthService } from 'ng2-ui-auth';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AppGuard implements CanActivate {
    nameValue: string;
    constructor(private router: Router,
        private auth: AuthService,
        private http: HttpClient,
        private sketchupService: SketchupService,
        private fusion360Service: Fusion360Service) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.sketchupService.inSketchup() || this.fusion360Service.inFusion360()) {
            if (!this.auth.isAuthenticated()) {
                if (this.sketchupService.inSketchup()) {
                    this.nameValue = 'SketchUp';
                } else if (this.fusion360Service.inFusion360()) {
                    this.nameValue = 'fusion360';
                }
                // Post info on Sketchup/Fusion to server
                return this.http.post('/api/auth/application', { name: this.nameValue, id: next.queryParams.skp, data: {} })
                    .pipe(map(token => {
                        this.auth.setToken(token['token']);
                        return true;
                    }));
            }
            return true;
        }
        return true;
    }

}
