import {Component, OnInit} from '@angular/core';
import {AuthService} from 'ng2-ui-auth';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService, UserService} from '@harmanpa/ng-patchwork';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'fab-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {
    public inProgress: boolean;
    public application: string;

    constructor(
        private auth: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private userService: UserService,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.inProgress = true;
        this.application = this.route.snapshot.params['application'];
        this.userService.addApplication(this.application, this.route.snapshot.params['code']).subscribe({
            next: (app) => (this.inProgress = false),
            error: (err) => this.alertService.error(err)
        });
    }
}
