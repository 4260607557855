import {BitInstance} from './../../../../../generated-src/model/bitInstance';
import {Component, HostListener, OnInit} from '@angular/core';
import {DocumentSummary} from 'generated-src';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
    selector: 'fab-add-bits-pop-up',
    templateUrl: './add-bits-pop-up.component.html',
    styleUrls: ['./add-bits-pop-up.component.scss']
})
export class AddBitsPopUpComponent implements OnInit {
    bits: DocumentSummary[];
    selectedBits: DocumentSummary[] = [];
    disabled: boolean = true;

    constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef) {}

    ngOnInit(): void {
        this.bits = this.config.data.bits;
    }

    onChange(): void {
        if (this.selectedBits.length > 0) {
            this.disabled = false;
        } else {
            this.disabled = true;
        }
    }

    save(): void {
        const chosenBits = this.createBitInstances();
        this.ref.close(chosenBits);
    }

    private createBitInstances(): BitInstance[] {
        const bitInstances: BitInstance[] = [];
        this.selectedBits.forEach((bit, index) => {
            bitInstances.push({
                name: bit.data['name'],
                bit: {
                    type: bit.type,
                    id: bit.id,
                    version: bit.head
                },
                toolNumber: index + 1,
                loaded: true
            });
        });
        return bitInstances;
    }
    
    @HostListener('window:keyup.enter')
    onEnter() {
        if (!this.disabled) {
            this.save();
        }
    }
}
