import {ConfirmDialogComponent} from './../../shared/components/confirm-dialog/confirm-dialog.component';
import {DocumentSummary} from './../../../../generated-src/model/documentSummary';
import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {BitInstance, DefaultService, VersionedMachine, VersionedWorkshop} from '../../../../generated-src';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService, PatchworkService} from '@harmanpa/ng-patchwork';
import {Title} from '@angular/platform-browser';
import {WorkshopService} from '@services/workshop.service';
import {AuthService} from 'ng2-ui-auth';
import {auditTime, tap} from 'rxjs/operators';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AddBitsPopUpComponent} from '../catalogue/add-bits-pop-up/add-bits-pop-up.component';
import {BitService} from '@services/bit.service';
import {Observable, Subject} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {NamingService} from '../services/naming.service';
import {DefaultValuesService} from '../services/default-values.service';

@Component({
    selector: 'fab-machine-editor',
    templateUrl: './machine-editor.component.html',
    styleUrls: ['./machine-editor.component.scss'],
    providers: [DialogService]
})
export class MachineEditorComponent implements OnInit {
    @Input() machine: VersionedMachine;
    @Output() dataChange = new EventEmitter<VersionedMachine>();
    workshop: VersionedWorkshop;
    machineChange = new Subject<VersionedMachine>();
    nextMachine: VersionedMachine;
    loading: boolean;
    thumbPostUrl: string;
    getUrl: string;
    bits: DocumentSummary[];
    ref: DynamicDialogRef;
    brands: string[];

    isAdminDashboard = false;
    isEditable = true;
    isInLibrary = false;
    saving = false;

    constructor(
        private api: DefaultService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private title: Title,
        private workshopService: WorkshopService,
        private authService: AuthService,
        private dialogService: DialogService,
        private bitService: BitService,
        private router: Router,
        private patchwork: PatchworkService,
        private namingService: NamingService,
        private defaultValuesService: DefaultValuesService
    ) {
        this.loading = false;
        this.brands = new Array<string>();
    }

    ngOnInit() {
        this.assignResolvedData();
        this.handleEmptyMachine();
        this.setPageTitle();
        this.setThumbnailData();
        this.setEditable();
        this.setHistorySource();
        this.getBits();
        this.handleMachineChanges();
    }

    private assignResolvedData(): void {
        const {machine, workshop} = this.route.snapshot.data;
        this.machine = machine as VersionedMachine;
        this.workshop = workshop as VersionedWorkshop;
    }

    private handleEmptyMachine(): void {
        if (!this.machine) {
            this.isAdminDashboard = true;
            this.machine = {
                document: this.defaultValuesService.getDefaultMachine(),
                permissions: {
                    read: true,
                    write: true
                }
            };
        }
    }

    private setPageTitle(): void {
        if (!this.isAdminDashboard) {
            this.title.setTitle(`Editor - ${this.machine.document.name}`);
        }
    }

    private setThumbnailData(): void {
        this.thumbPostUrl = `api/machines/${this.machine.id}/v/${this.machine.version}/thumbnail.png`;
        this.getUrl = `api/machines/${this.machine.id}/v/${this.machine.version}/thumbnail.png?token=${this.authService.getToken()}`;
    }

    private setEditable(): void {
        this.isEditable = this.machine.permissions.write;
    }

    private setHistorySource(): void {
        history.state.source === 'Library' ? (this.isInLibrary = true) : (this.isInLibrary = false);
    }

    private save(): void {
        if (!this.saving && this.machine.id) {
            this.saving = true;
            const toSave = this.machine.document;
            this.nextMachine = null;
            this.api.updateMachine(this.machine.id, this.machine.version, toSave).subscribe({
                next: (value) => {
                    this.machine.version = value.version;
                    this.patchwork.setVersionQuery(this.route, value);
                    this.saving = false;
                    if (this.nextMachine) {
                        this.save();
                    }
                },
                error: (err) => {
                    this.saving = false;
                    this.alertService.error(err);
                }
            });
        }
    }

    handleMachineChanges(): void {
        this.machineChange
            .pipe(
                auditTime(500)
                // distinctUntilChanged()
            )
            .subscribe({
                next: (m) => {
                    this.nextMachine = m;
                    console.log(m);
                    this.save();
                },
                error: (err) => this.alertService.error(err)
            });
    }

    onChange(): void {
        this.machineChange.next(this.machine);
        this.dataChange.emit(this.machine);
    }

    setupMachine(machine: VersionedMachine, event: any): void {
        if (this.workshopService.isMachineAlreadyInWorkshop(machine.id, this.workshop)) {
            this.confirmOrCancel().subscribe((result) => {
                if (result) {
                    this.proceed(machine);
                }
                return;
            });
        }
        this.proceed(machine);
        event.target.blur();
    }

    private proceed(machine: VersionedMachine): void {
        const {
            document: {minColletSize, maxColletSize}
        } = machine;
        const bitsList = this.bitService.filterMatchingBitsByColletSizeRange(this.bits, minColletSize, maxColletSize);
        this.showPopUp(machine, bitsList);
    }

    private confirmOrCancel(): Observable<true | false> {
        this.ref = this.dialogService.open(ConfirmDialogComponent, {
            header: 'Info',
            width: '40%',
            contentStyle: {height: 'auto', overflow: 'auto'},
            baseZIndex: 10000,
            data: {
                message: 'You already have a Machine with this specifications. Do you want to add a new one?'
            }
        });

        return this.ref.onClose;
    }

    private getBits(): void {
        this.api
            .findBits(true, true, true, false, false, false, true)
            .pipe(tap((bits) => (this.bits = bits)))
            .subscribe();
    }

    private showPopUp(versionedMachine: VersionedMachine, bitsList: DocumentSummary[]): void {
        this.ref = this.dialogService.open(AddBitsPopUpComponent, {
            header: `Add bits to ${versionedMachine.document.name}`,
            width: '40%',
            contentStyle: {height: 'auto', overflow: 'auto'},
            baseZIndex: 10000,
            data: {
                bits: bitsList
            }
        });

        this.ref.onClose.subscribe((chosenBits: BitInstance[]) => {
            if (chosenBits) {
                this.namingService.addUserWorkshopMachine(this.workshop, chosenBits);
                // this.addMachine(versionedMachine, chosenBits);
            }
        });
    }

    copy(event: any): void {
        this.namingService.copyMachine();
        event.target.blur();
    }

    machineThumbUpload(event: {originalEvent: HttpResponse<VersionedMachine>; files: Array<File>}): void {
        this.patchwork.setVersionQuery(this.route, event.originalEvent.body);
        this.alertService.success('Machine thumbnail set, reload page to update');
    }

    @HostListener('window:popstate', ['$event'])
    onPopState() {
        if (this.isInLibrary) {
            this.router.navigate(['/library/machines'], {state: {activeTab: 0}});
        } else {
            this.router.navigate(['/workshop', this.workshop.id], {queryParams: {version: this.workshop.version}, state: {activeTab: 1}});
        }
    }
}
