import {BitMetaData} from './../../models/bit-meta-data.model';
import {BitService} from '@services/bit.service';
import {WorkshopService} from '@services/workshop.service';
import {VersionedWorkshop} from 'generated-src/model/versionedWorkshop';
import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {BitMaterial, DefaultService, VersionedBit} from '../../../../generated-src';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {auditTime, switchMap} from 'rxjs/operators';
import {AssignBitComponent} from '../catalogue/assign-bit/assign-bit.component';
import {of, Subject} from 'rxjs';
import {MachineInstanceWithId} from '@models/machine-instance-with-id.model';
import {Title} from '@angular/platform-browser';
import {AlertService, PatchworkService} from '@harmanpa/ng-patchwork';
import {NamingService} from '../services/naming.service';
import {DefaultValuesService} from '../services/default-values.service';

@Component({
    selector: 'fab-bit-editor',
    templateUrl: './bit-editor.component.html',
    styleUrls: ['./bit-editor.component.scss'],
    providers: [DialogService]
})
export class BitEditorComponent implements OnInit {
    @Input() bit: VersionedBit;
    @Output() dataChange = new EventEmitter<VersionedBit>();
    bitInstanceId: string;
    bitChange = new Subject<VersionedBit>();
    nextBit: VersionedBit;
    newMaterial: BitMaterial;
    workshop: VersionedWorkshop;

    disabled = false;
    isAdminDashboard = false;
    isEditable = true;
    isInLibrary = false;
    saving = false;

    constructor(
        private api: DefaultService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private workshopService: WorkshopService,
        private bitService: BitService,
        private dialogService: DialogService,
        private patchwork: PatchworkService,
        private title: Title,
        private namingService: NamingService,
        private defaultValuesService: DefaultValuesService
    ) {
        this.newMaterial = {};
    }

    ngOnInit(): void {
        this.assignResolvedData();
        this.handleEmptyBit();
        this.setPageTitle();
        this.setEditable();
        this.setHistorySource();
        this.handleBitChanges();
    }

    private assignResolvedData(): void {
        const {bit, workshop} = this.route.snapshot.data;
        this.bit = bit as VersionedBit;
        this.workshop = workshop as VersionedWorkshop;
    }

    private handleEmptyBit(): void {
        if (!this.bit) {
            this.isAdminDashboard = true;
            this.bit = {
                document: this.defaultValuesService.getDefaultBit(),
                permissions: {
                    read: true,
                    write: true
                }
            };
        }
    }

    private setPageTitle(): void {
        if (!this.isAdminDashboard) {
            this.title.setTitle(`Editor - ${this.bit.document.name}`);
        }
    }

    private setEditable(): void {
        this.isEditable = this.bit.permissions.write;
    }

    private setHistorySource(): void {
        history.state.source === 'Library' ? (this.isInLibrary = true) : (this.isInLibrary = false);
    }

    private save(): void {
        if (!this.saving && this.bit.id) {
            this.saving = true;
            const toSave = this.bit.document;
            this.nextBit = null;
            this.api.updateBit(this.bit.id, this.bit.version, toSave).subscribe({
                next: (value) => {
                    this.bit.version = value.version;
                    this.patchwork.setVersionQuery(this.route, value);
                    this.saving = false;
                    if (this.nextBit) {
                        this.save();
                    }
                },
                error: (err) => {
                    this.saving = false;
                    this.alertService.error(err);
                }
            });
        }
    }

    handleBitChanges(): void {
        this.bitChange
            .pipe(
                auditTime(500)
                // distinctUntilChanged()
            )
            .subscribe({
                next: (b) => {
                    this.nextBit = b;
                    console.log(b);
                    this.save();
                },
                error: (err) => this.alertService.error(err)
            });
    }

    onChange(bit: VersionedBit): void {
        this.bit = bit;
        this.bitChange.next(this.bit);
        this.dataChange.emit(this.bit);
    }

    cancel(): void {
        this.location.back();
    }

    addBit(): void {
        this.workshopService.getUserMachines(this.workshop.id, this.workshop.version).subscribe((machineInstances) => {
            const machines: MachineInstanceWithId[] = Object.keys(machineInstances.document).map((instanceId) =>
                Object.assign({}, machineInstances.document[instanceId], {_id: instanceId})
            );
            const filteredMachines = this.bitService.getMachinesNotContainBit(this.bit.id, machines);
            this.showMachinesPopUp(this.bit, filteredMachines);
        });
    }

    copy(event: any): void {
        this.namingService.copyBit();
        event.target.blur();
    }

    private showMachinesPopUp(bit: VersionedBit, machines: MachineInstanceWithId[]): void {
        const ref: DynamicDialogRef = this.dialogService.open(AssignBitComponent, {
            header: `Add ${bit.document.name} to machine(s)`,
            width: '40%',
            contentStyle: {height: 'auto', overflow: 'auto'},
            baseZIndex: 10000,
            data: {
                machines,
                bit
            }
        });

        const bitMetaData: BitMetaData = {
            id: bit.id,
            head: bit.version,
            type: 'bit',
            name: bit.document.name
        };

        ref.onClose
            .pipe(
                switchMap((selectedMachines) => {
                    if (selectedMachines) {
                        return this.bitService.updateBitInMachineInstancesForWorkshop(this.workshop, bitMetaData, selectedMachines);
                    }
                    return of(this.workshop);
                })
            )
            .subscribe((vw) => {
                this.workshop = vw;
                this.router.navigate(['/machines'], {state: {activeTab: 1}});
            });
    }

    @HostListener('window:popstate', ['$event'])
    onPopState() {
        if (this.isInLibrary) {
            this.router.navigate(['/library/machines'], {state: {activeTab: 1}});
        } else {
            this.router.navigate(['/workshop', this.workshop.id], {queryParams: {version: this.workshop.version}, state: {activeTab: 2}});
        }
    }
}
