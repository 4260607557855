<p-panel
    styleClass="p-shadow-3"
    [style]="{width: '20rem'}"
    [ngClass]="{
        onshape: project.data['cad'].provider == 'onshape',
        fusion360: project.data['cad'].provider == 'fusion360',
        sketchup: project.data['cad'].provider == 'sketchup'
    }"
>
    <p-header class="p-d-flex p-ai-center">
        <p-avatar image="../assets/CAD/{{ project.data['cad'].provider }}.svg" styleClass="p-ml-2" shape="circle"></p-avatar>
        <div class="p-ml-4 p-as-stretch">
            <h5 data-title="{{ project.data?.name }}" class="p-text-bold p-mb-1">{{ project.data?.name | truncate: [20, '...'] }}</h5>
            <small class="p-text-secondary">Edited: {{ project.lastEdited | since | truncate: [20, '...'] }}</small>
        </div>
    </p-header>
    <div class="p-d-flex p-p-0" style="height: auto">
        <img
            [style]="{width: '20rem', height: '14rem'}"
            src="/api/projects/{{ project.id }}/v/{{ project.head }}/thumbnail.png?token={{ auth.getToken() }}"
            alt="{{ project.data?.name }}"
        />
    </div>
    <ng-template pTemplate="footer">
        <!-- <div class="p-mb-3 sharing-info"> -->
        <!-- <p>
                {{ project.sharing.sharedWithPublic ? 'Public' : 'Private' }}, {{ project.sharing.collaborators.length }} collaborators,
                {{ project.sharing.viewers.length }} viewers.
            </p> -->
        <!-- <p class="p-p-0 p-m-0">
                This project is <strong>{{ project.sharing.sharedWithPublic ? 'Public' : 'Private' }}</strong
                >.
            </p>
            <p *ngIf="!project.sharing.sharedWithPublic" class="p-p-0 p-m-0">
                Shared with <strong>{{ project.sharing.collaborators.length }} collaborators</strong>.
            </p>
            <p class="p-p-0 p-m-0">
                Shared with <strong>{{ project.sharing.viewers.length }} viewers</strong>.
            </p> -->
        <!-- </div> -->
        <div class="p-ai-center p-d-flex sharing-info">
            <button
                pButton
                type="button"
                label="Open"
                icon="pi pi-external-link"
                iconPos="right"
                [routerLink]="['/projects', project.id]"
                pTooltip="open project"
                tooltipPosition="bottom"
            ></button>
            <button
                pButton
                class="p-button-text p-ml-auto"
                icon="pi pi-info-circle"
                pTooltip="{{ project.sharing.sharedWithPublic ? 'Public' : 'Private' }}, {{
                    project.sharing.collaborators.length
                }} collaborators, {{ project.sharing.viewers.length }} viewers."
                tooltipPosition="top"
            ></button>
            <pwk-sharing-summary
                [document]="project"
                [longInfo]="false"
                (sharedChange)="sharingChanged($event)"
                class="p-button-text"
            ></pwk-sharing-summary>
            <!-- *ngxPermissionsOnly="['superadmin', 'admin']" -->
            <button
                pButton
                pTooltip="delete project"
                tooltipPosition="bottom"
                class="p-button-secondary p-button-danger p-button-text"
                icon="pi pi-trash"
                (click)="deleteProject(project)"
            ></button>
        </div>
    </ng-template>
</p-panel>
<!-- [style]="{'margin-left': '.5em'}" -->
