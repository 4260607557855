<div class="z">
    <!-- <p-toggleButton
        
        pTooltip="Snap to grid"
        tooltipPosition="right"
        class="p-button-raised z p-m-5 p-mr-2 p-mr-auto"
        onIcon="pi pi-table"
        offIcon="pi pi-table"
        [(ngModel)]="snapToGrid"
        (onChange)="toggleSnapping()"
    ></p-toggleButton> -->
    <p-selectButton [options]="gridOptions" [(ngModel)]="snapToGrid" (onChange)="toggleSnapping()">
        <ng-template let-item>
            <div pTooltip="Snap to grid" class="verticalSelectButtonTemplate">
                <span>{{ item.label }}</span>
            </div>
        </ng-template>
    </p-selectButton>
</div>
