/**
 * Fabber API
 * CAD to CNC in seconds
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AvailableUnits } from './availableUnits';
import { DeepReferenceProjectSheet } from './deepReferenceProjectSheet';
import { ReferenceProject } from './referenceProject';

export interface PostProcessor { 
    name?: string;
    description?: string;
    contentType?: string;
    fileExtension?: string;
    availableUnits?: AvailableUnits;
    offsetStrategy?: PostProcessor.OffsetStrategyEnum;
    zDirectionStrategy?: PostProcessor.ZDirectionStrategyEnum;
    arcStrategy?: PostProcessor.ArcStrategyEnum;
    format?: PostProcessor.FormatEnum;
    template?: string;
    exampleProject?: ReferenceProject;
    exampleProjectSheet?: DeepReferenceProjectSheet;
}
export namespace PostProcessor {
    export type OffsetStrategyEnum = 'Offsetting' | 'NoOffsetting';
    export const OffsetStrategyEnum = {
        Offsetting: 'Offsetting' as OffsetStrategyEnum,
        NoOffsetting: 'NoOffsetting' as OffsetStrategyEnum
    };
    export type ZDirectionStrategyEnum = 'PositiveUp' | 'PositiveDown';
    export const ZDirectionStrategyEnum = {
        PositiveUp: 'PositiveUp' as ZDirectionStrategyEnum,
        PositiveDown: 'PositiveDown' as ZDirectionStrategyEnum
    };
    export type ArcStrategyEnum = 'Arcs' | 'Lines';
    export const ArcStrategyEnum = {
        Arcs: 'Arcs' as ArcStrategyEnum,
        Lines: 'Lines' as ArcStrategyEnum
    };
    export type FormatEnum = 'Fabber';
    export const FormatEnum = {
        Fabber: 'Fabber' as FormatEnum
    };
}
