import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    DefaultService,
    DocumentSummary,
    Machine,
    VersionedMachine,
    VersionedMachineInstanceMap,
    VersionedWorkshop
} from '../../../../generated-src';
import {Title} from '@angular/platform-browser';
import {CacheService, UnitService} from '@harmanpa/ng-patchwork';
import {ActivatedRoute} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from 'ng2-ui-auth';

@Component({
    selector: 'fab-catalogue',
    templateUrl: './catalogue.component.html',
    styleUrls: ['./catalogue.component.scss'],
    providers: [DialogService]
})
export class CatalogueComponent implements OnInit, OnDestroy {
    loading: boolean;
    machines: DocumentSummary[];
    // userMachines: VersionedMachineInstanceMap;
    userMInst: VersionedMachine[] = [];
    machinesArray: {key: string; value: Machine}[];
    bits: DocumentSummary[];
    postpos: DocumentSummary[];
    workshop: VersionedWorkshop;
    authToken: string;
    newBitName: string;
    newMachineName: string;
    // Modal ref
    ref: DynamicDialogRef;
    activeTab: number = 0;

    constructor(
        private route: ActivatedRoute,
        private api: DefaultService,
        private title: Title,
        public dialogService: DialogService,
        public unitService: UnitService,
        public authService: AuthService,
        private cacheService: CacheService
    ) {
        this.machines = new Array<DocumentSummary>();
        this.bits = new Array<DocumentSummary>();
        this.newBitName = 'My new bit';
        this.newMachineName = 'My new machine';
        this.title.setTitle('Library');
    }

    ngOnInit(): void {
        this.workshop = this.route.snapshot.data['workshop'] as VersionedWorkshop;
        this.reload(true);
        this.authToken = this.authService.getToken();
        this.setActiveTab();
    }

    ngOnDestroy(): void {
        if (this.ref) {
            this.ref.close();
        }
    }

    reload(force = false) {
        this.loading = true;
        if (force) {
            this.cacheService
                .reload('machines', this.api.findMachines(false, false, true, false, false, false, true), this.cacheService.DAY, true)
                .pipe(
                    switchMap((machines: DocumentSummary[]) => {
                        this.machines = machines;
                        return this.cacheService.reload(
                            'bits',
                            this.api.findBits(true, true, true, false, false, false, true),
                            this.cacheService.DAY,
                            true
                        );
                    }),
                    switchMap((bits: DocumentSummary[]) => {
                        this.bits = bits;
                        return this.cacheService.reload(
                            'postprocessors',
                            this.api.findPostProcessors(false, false, true, false, false, false, true),
                            this.cacheService.DAY,
                            true
                        );
                    })
                )
                .subscribe((postprocessors) => {
                    this.postpos = postprocessors;
                    this.loading = false;
                });
        } else {
            this.cacheService
                .get('machines', this.api.findMachines(false, true, true, false, false, false, true), this.cacheService.DAY, true)
                .pipe(
                    switchMap((machines: DocumentSummary[]) => {
                        this.machines = machines;
                        return this.cacheService.get(
                            'bits',
                            this.api.findBits(true, true, true, false, false, false, true),
                            this.cacheService.DAY,
                            true
                        );
                    }),
                    switchMap((bits: DocumentSummary[]) => {
                        this.bits = bits;
                        return this.cacheService.get(
                            'postprocessors',
                            this.api.findPostProcessors(false, true, true, false, false, false, true),
                            this.cacheService.DAY,
                            true
                        );
                    })
                )
                .subscribe((postprocessors) => {
                    this.postpos = postprocessors;
                    this.loading = false;
                });
        }
    }

    materialNames(bit: DocumentSummary) {
        return bit.data['materialSettings'].map((ms) => ms.material).join(', ');
    }

    bitFitsMachines(bit) {
        const filteredMachines = this.userMInst.filter(
            (machine) =>
                this.unitService.compare(machine.document.minColletSize, bit.data['shaftDiameter']) <= 0 &&
                this.unitService.compare(machine.document.maxColletSize, bit.data['shaftDiameter']) >= 0
        );
        return filteredMachines;
    }

    setActiveTab(): void {
        if (history.state.activeTab) {
            this.activeTab = history.state.activeTab;
        }
    }
}
