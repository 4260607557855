import {Component, OnInit, Input} from '@angular/core';

interface Styles {
    width: string;
    height: string;
}

@Component({
    selector: 'fab-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
    @Input() size: number = 100;

    styles: Styles;

    constructor() {}

    ngOnInit(): void {
        this.styles = {
            width: `${this.size}px`,
            height: `${this.size}px`
        };
    }
}
