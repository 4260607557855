import {Component, OnInit} from '@angular/core';
import {CuttingList, DefaultService, VersionedProject, VersionedWorkshop} from '../../../../generated-src';
import {AlertService, PatchworkService, UnitService} from '@harmanpa/ng-patchwork';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'fab-geometry-debug',
    templateUrl: './geometry-debug.component.html'
})
export class GeometryDebugComponent implements OnInit {

    project: VersionedProject;
    workshop: VersionedWorkshop;
    cuttingList: CuttingList;

    constructor(
        private alertService: AlertService,
        private api: DefaultService,
        private patchwork: PatchworkService,
        private route: ActivatedRoute,
        private title: Title
    ) {
    }

    ngOnInit() {
        this.project = this.route.snapshot.data['project'];
        this.workshop = this.route.snapshot.data['workshop'];
        this.title.setTitle(this.project.document.name);
        this.patchwork.setVersionQuery(this.route, this.project);
        this.api.getProjectCuttingList(this.project.id, this.project.version)
            .subscribe({
                next: vcl => {
                    this.cuttingList = vcl.document;
                },
                error: err => this.alertService.error(err)
            });
    }

}
