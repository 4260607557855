import {Component, Input, OnInit} from '@angular/core';
import { UnitService } from '@harmanpa/ng-patchwork';
import {CuttingGroup, DefaultService, Sheet, VersionedProject} from 'generated-src';
import { map } from 'rxjs/operators';
import {SheetsService} from '../services/sheets.service';

@Component({
    selector: 'fab-sheet-group-select',
    templateUrl: './sheet-group-select.component.html',
    styleUrls: ['./sheet-group-select.component.scss']
})
export class SheetGroupSelectComponent implements OnInit {
    @Input() project: VersionedProject;
    currentGroup = 1;
    currentSheet = 1;
    sheetsLoading = false;
    cuttingGroups: CuttingGroup[];
    selectedGroup: CuttingGroup;
    sheets: Sheet[];
    selectedSheet: Sheet;
    constructor(private sheetsService: SheetsService, private api: DefaultService, private unitService: UnitService) {}

    ngOnInit(): void {
        this.api.getProjectCuttingList(this.project.id, this.project.version).subscribe((p) => {
            this.cuttingGroups = p.document.groups;
            this.cuttingGroups.shift();
            this.selectedGroup = this.cuttingGroups[0];
            this.getSheets();
        });
    }

    getSheets(): void {
        this.sheetsLoading = true;
        this.api.getProjectCuttingListGroupsSheets(this.project.id, this.project.version, this.currentGroup.toString())
            .subscribe((g) => {
                this.sheets = g.document.sheets;
                this.selectedSheet = this.sheets[0];
                this.sheetsLoading = false;
            })
    }

    changeGroup(cuttingGroup: CuttingGroup) {
        this.currentGroup = this.cuttingGroups.indexOf(cuttingGroup) + 1;
        this.sheetsService.selectGroup(this.currentGroup);
        this.getSheets();
    }

    changePage(sheet: Sheet): void {
        this.currentSheet = this.sheets.indexOf(sheet) + 1;
        this.sheetsService.selectSheet(this.currentSheet);
    }

    trimValue(size: string): string {
        const unit = size.split(' ')[1];
        return `${(Math.round(this.unitService.toValue(size, unit) * 100) / 100).toFixed(2)} ${unit}`;
    }
}
