import {BitMetaData} from './../models/bit-meta-data.model';
import {DocumentSummary} from '../../../generated-src/model/documentSummary';
import {AlertService, UnitService} from '@harmanpa/ng-patchwork';
import {Injectable} from '@angular/core';
import {BitInstance, DefaultService, MachineInstance, VersionedWorkshop} from 'generated-src';
import {Observable} from 'rxjs';
import {MachineInstanceWithId} from '@models/machine-instance-with-id.model';
import {map, switchMap, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BitService {
    constructor(private unitService: UnitService, private api: DefaultService, private alertService: AlertService) {
    }

    getMachinesNotContainBit(bitId: string, machines: MachineInstanceWithId[]): MachineInstanceWithId[] {
        const filteredMachines = machines.filter(({toolTable}) => {
            if (toolTable) {
                return !this.isBitInMachineBits(bitId, toolTable.bits);
            }
        });
        return filteredMachines;
    }

    updateBitInMachineInstancesForWorkshop(workshop: VersionedWorkshop, bit: BitMetaData, machines: MachineInstanceWithId[]): Observable<VersionedWorkshop> {
        return this.api.getWorkshopMachineInstances(workshop.id, workshop.version).pipe(
            switchMap((workshopMachineInstances) => {
                machines.forEach((machine) => {
                    workshopMachineInstances.document[machine._id] = this.addBitToMachineInstance(bit, machine);
                });
                return this.api.updateWorkshopMachineInstances(workshop.id, workshop.version, workshopMachineInstances.document).pipe(
                    tap(() => this.alertService.success('Bit was added successfully')),
                    map((patch) => Object.assign(workshop, {version: patch.version}))
                );
            })
        );
    }

    private addBitToMachineInstance(bit: BitMetaData, machine: MachineInstance): MachineInstance {
        const {id, type, head, name} = bit;
        const {
            toolTable: {bits}
        } = machine;
        const newBit: BitInstance = {
            bit: {
                id,
                type,
                version: head
            },
            loaded: true,
            name: name,
            toolNumber: this.provideUniqueToolNumber(machine)
        };
        const updatedMachineInstance: MachineInstance = {
            ...machine,
            toolTable: {
                bits: [...bits, newBit]
            }
        };
        return updatedMachineInstance;
    }

    filterMatchingBitsByColletSizeRange(bits: DocumentSummary[], minColletSize: string, maxColletSize: string): DocumentSummary[] {
        return bits.filter(({data}) => this.isBitDiameterInColletSizeRange(data.diameter, minColletSize, maxColletSize));
    }

    private provideUniqueToolNumber(machine: MachineInstance): number {
        const indices = machine.toolTable.bits.map((bit) => bit.toolNumber);
        for (let i = 1; ; ++i) {
            if (!indices.includes(i)) {
                return i;
            }
        }
    }

    private isBitInMachineBits(bitId: string, machineBits: BitInstance[]): boolean {
        return machineBits.some(({bit}) => bit.id === bitId);
    }

    private isBitDiameterInColletSizeRange(bitDiameter: string, minColletSize: string, maxColletSize: string): boolean {
        return this.unitService.compare(bitDiameter, minColletSize) >= 0 && this.unitService.compare(bitDiameter, maxColletSize) <= 0;
    }
}
