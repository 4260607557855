import {Injectable} from '@angular/core';
import {Bit, BitMaterial, Machine, PostProcessor} from 'generated-src';

@Injectable({
    providedIn: 'root'
})
export class DefaultValuesService {

    constructor() {
    }

    getDefaultMachine(): Machine {
        return {
            ATC: 'None',
            atcstrategy: 'None',
            brand: '',
            cuttingStrategy: {
                cutRate: 0,
                jogRate: 0
            },
            maxColletSize: '0.5 in',
            maxCuttingSpeed: '0 ipm',
            maxSpindleSpeed: '0 rpm',
            minColletSize: '0 in',
            minCuttingSpeed: '0 ipm',
            minSpindleSpeed: '0 rpm',
            model: '',
            name: '',
            nestingStrategy: {
                nestingGap: '0 mm',
                nestingRotations: 0
            },
            postProcessStrategy: {
                postProcessor: null
            },
            toolpathStrategy: {
                drillStrategy: 'None',
                edgeStrategy: 'None',
                externalCornerStrategy: 'Round',
                holdingStrategy: 'Vacuum',
                infillStepover: 0,
                infillStrategy: 'None',
                internalCornerStrategy: 'None',
                jogHeight: '0 in',
                jogHeightStrategy: 'SetHeight',
                orderStrategy: 'ByFeatureType',
                punchThroughDepth: '0 in',
                rasterAngle: '0 in',
                superSkinThickness: '0 in',
                xHome: '0 in',
                yHome: '0 in',
                zeroStrategy: 'Table'
            },
            xLength: '0 in',
            yLength: '0 in',
            zHeight: '0 in'
        };
    }

    getDefaultBit(): Bit {
        return {
            clockwise: 'yes',
            diameter: '0 in',
            fluteLength: '0 in',
            lengthOffset: '0 in',
            manufacturer: '',
            materialSettings: [],
            name: '',
            numberOfFlutes: '0',
            overallLength: '0 in',
            productId: '',
            shaftDiameter: '0 in',
            tipDiameter: '0 in',
            type: ''
        };
    }

    getDefaultBitMaterial(): BitMaterial {
        return {
            entryFeedRate: '0 ipm',
            exitFeedRate: '0 ipm',
            material: '',
            passDepthMultiplier: 0,
            plungeFeedRate: '0 ipm',
            rampFeedRate: '0 ipm',
            rampSpindle: '0 ipm',
            retractFeedRate: '0 ipm',
            spindleSpeed: '0 ipm'
        };
    }

    getDefaultPostProcessor(): PostProcessor {
        return {
            arcStrategy: 'Arcs',
            availableUnits: {
                quantities: {
                    ANGULAR_VELOCITY: {
                        units: [
                            {
                                defaultPrecision: 0,
                                maxPrecision: 0,
                                minPrecision: 0,
                                unit: 'rpm'
                            }
                        ]
                    },
                    LENGTH: {
                        units: [
                            {
                                defaultPrecision: 0,
                                maxPrecision: 0,
                                minPrecision: 0,
                                unit: 'in'
                            }
                        ]
                    },
                    VELOCITY: {
                        units: [
                            {
                                defaultPrecision: 0,
                                maxPrecision: 0,
                                minPrecision: 0,
                                unit: 'in/s'
                            }
                        ]
                    },
                }
            },
            contentType: '',
            description: '',
            exampleProject: null,
            exampleProjectSheet: null,
            fileExtension: '',
            format: 'Fabber',
            name: '',
            offsetStrategy: 'Offsetting',
            template: '',
            zDirectionStrategy: 'PositiveUp'
        };
    }
}
