import {VersionedWorkshop} from '../../../../../generated-src';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CuttingGroup, CuttingPartInstance, MachineChoice, SheetSize, VersionedProject} from 'generated-src';
import {AuthService} from 'ng2-ui-auth';
import {PartEditorService} from '../../services/part-editor.service';
import {SketchupService} from '@services/sketchup.service';
import {Fusion360Service} from '@services/fusion360.service';
import {AlertService, UnitService} from '@harmanpa/ng-patchwork';
import {WorkshopService} from '@services/workshop.service';

@Component({
    selector: 'fab-parts-list-group',
    templateUrl: './parts-list-group.component.html'
})
export class PartsListGroupComponent implements OnInit {
    @Input() workshop: VersionedWorkshop;
    @Input() project: VersionedProject;
    @Input() inApp = false;
    @Input() group: CuttingGroup;
    @Input() disabled = false;
    @Output() partRemove = new EventEmitter<CuttingPartInstance>();
    @Output() groupChange = new EventEmitter<CuttingGroup>();
    @Output() partEdit = new EventEmitter<CuttingPartInstance>();
    @Output() workshopChange = new EventEmitter<VersionedWorkshop>();

    selectedMachine: MachineChoice;
    authToken: string;
    selectedMaterial: string;
    adjustedValue: string[] = [];
    isSheetSizeEdited = false;
    machinesLoading = false;
    materialsLoading = false;
    machineError = false;
    machines: MachineChoice[] = [];
    materials: string[] = [];
    filteredMachines: MachineChoice[] = [];

    constructor(
        public auth: AuthService,
        private alertService: AlertService,
        private partEditorService: PartEditorService,
        private sketchupService: SketchupService,
        private fusion360Service: Fusion360Service,
        private unitService: UnitService,
        private workshopService: WorkshopService
    ) {}

    ngOnInit(): void {
        if (this.sketchupService.inSketchup() || this.fusion360Service.inFusion360()) {
            this.inApp = true;
        }
        this.authToken = this.auth.getToken();
        this.workshopService.getProjectWorkshop(this.project).subscribe((workshop) => {
            this.workshop = workshop;
            this.workshopChange.emit(this.workshop);
            this.setSelectedMachineAndMaterial();
        });
    }

    private setSelectedMachine(): void {
        if (this.group.machine) {
            const foundMachine: MachineChoice = this.machines.find(({value: {path}}) => path === this.group.machine.path);
            if (this.group.machine && !foundMachine) {
                this.machineError = true;
            }
            if (foundMachine) {
                this.selectedMachine = foundMachine;
                this.materialsLoading = true;
                this.partEditorService
                    .getFilteredMaterialsByMachineForWorkshop(this.workshop.id, this.workshop.version, this.selectedMachine.id)
                    .subscribe(
                        (materials) => {
                            this.materials = materials;
                            this.materialsLoading = false;
                        },
                        () => {
                            this.materialsLoading = false;
                        }
                    );
            } else {
                this.partEditorService.getDefaultMachinesAndMaterialsForWorkshop(this.workshop.id, this.workshop.version).subscribe({
                    next: (machines) => {
                        this.materials = machines[1];
                        this.materialsLoading = false;
                    },
                    error: () => {
                        this.materialsLoading = false;
                    }
                });
            }
        }
    }

    private setSelectedMachineAndMaterial(): void {
        this.selectedMaterial = this.group.material;
        if (this.selectedMaterial) {
            this.machinesLoading = true;
            this.partEditorService
                .getFilteredMachinesByMaterialForWorkshop(this.workshop.id, this.workshop.version, this.selectedMaterial)
                .subscribe({
                    next: (machines) => {
                        this.machines = this.filteredMachines = this.partEditorService.filterMachinesBySize(
                            machines,
                            this.group.sheetSize.length,
                            this.group.sheetSize.width
                        );
                        this.machinesLoading = false;
                        this.machines.length
                            ? this.setSelectedMachine()
                            : this.alertService.error(
                                  "There aren't any machines compatible with the current sheet size! Please add new machine to your workshop."
                              );
                    },
                    error: () => {
                        this.machinesLoading = false;
                    }
                });
        } else {
            this.partEditorService.getDefaultMachinesAndMaterialsForWorkshop(this.workshop.id, this.workshop.version).subscribe({
                next: (machines) => {
                    this.machines = machines[0];
                    this.machinesLoading = false;
                    this.setSelectedMachine();
                },
                error: () => {
                    this.machinesLoading = false;
                }
            });
        }
    }

    onMachineSelect(machine: MachineChoice | null): void {
        this.selectedMachine = machine;
        this.materialsLoading = true;
        if (!machine) {
            this.selectedMachine = null;
            this.partEditorService.getDefaultMachinesAndMaterialsForWorkshop(this.workshop.id, this.workshop.version).subscribe({
                next: (machines) => {
                    this.materials = machines[1];
                    this.group.machine = null;
                    this.handleInvalidMaterial();
                    this.onUpdateGroup();
                    this.materialsLoading = false;
                },
                error: () => {
                    this.materialsLoading = false;
                }
            });
            return;
        }
        this.partEditorService.getFilteredMaterialsByMachineForWorkshop(this.workshop.id, this.workshop.version, machine.id).subscribe(
            (materials) => {
                this.materials = materials;
                this.group.machine = machine.value;
                this.group.sheetSize = {length: machine.machine.document.xLength, width: machine.machine.document.yLength};
                this.handleInvalidMaterial();
                this.onUpdateGroup();
                this.materialsLoading = false;
            },
            () => {
                this.materialsLoading = false;
            }
        );
    }

    onMaterialSelect(material: string): void {
        this.selectedMaterial = material;
        this.machinesLoading = true;
        if (!material) {
            this.partEditorService.getDefaultMachinesAndMaterialsForWorkshop(this.workshop.id, this.workshop.version).subscribe({
                next: (machines) => {
                    this.machines = machines[0];
                    this.group.material = material;
                    this.handleInvalidMachine();
                    this.onUpdateGroup();
                    this.machinesLoading = false;
                },
                error: () => {
                    this.machinesLoading = false;
                }
            });
            return;
        }
        this.partEditorService.getFilteredMachinesByMaterialForWorkshop(this.workshop.id, this.workshop.version, material).subscribe(
            (machines) => {
                this.machines = machines;
                this.group.material = material;
                this.handleInvalidMachine();
                this.onUpdateGroup();
                this.machinesLoading = false;
            },
            () => {
                this.machinesLoading = false;
            }
        );
    }

    handleInvalidMachine(): void {
        if (this.selectedMachine && !this.machines.some(({id}) => id === this.selectedMachine.id)) {
            this.selectedMachine = null;
        }
    }

    handleInvalidMaterial(): void {
        if (!this.materials.includes(this.selectedMaterial)) {
            this.selectedMaterial = null;
        }
    }

    onUpdateGroup(): void {
        if (!this.isSheetSizeEdited && this.adjustedValue.some((v) => v !== '')) {
            let infoString = '';
            this.adjustedValue.forEach((v) =>
                infoString === '' ? (infoString = v) : v !== '' ? (infoString = `${infoString} and ${v}`) : null
            );
            this.alertService.info(`Input value exceeds ${infoString} value`);
            this.adjustedValue.splice(0, this.adjustedValue.length);
        }
        this.groupChange.emit(this.group);
    }

    onPartEdit(instance: CuttingPartInstance): void {
        this.partEdit.emit(instance);
    }

    onPartRemove(instance: CuttingPartInstance): void {
        this.partRemove.emit(instance);
    }

    onSheetSizeChange(sheetSize: SheetSize): void {
        let {length, width} = sheetSize;
        const maxLength = this.selectedMachine.machine.document.xLength;
        const maxWidth = this.selectedMachine.machine.document.yLength;
        if (this.unitService.compare(maxLength, length, 0.001) === -1) {
            length = maxLength;
            this.adjustedValue[0] = 'max length';
            this.adjustedValue[2] = '';
        }
        if (this.unitService.compare(maxWidth, width, 0.001) === -1) {
            width = maxWidth;
            this.adjustedValue[1] = 'max width';
            this.adjustedValue[3] = '';
        }
        this.group.instances.forEach((part) => {
            const minLength = part.slice.minimumSheetSize.length;
            const minWidth = part.slice.minimumSheetSize.width;
            const unit = length.split(' ')[1];
            console.log(this.adjustedValue);
            if (this.unitService.compare(minLength, length, 0.001) !== -1) {
                length = this.unitService.toValue(minLength, unit) + ' ' + unit;
                this.adjustedValue[0] = '';
                this.adjustedValue[2] = 'min length';
            }
            console.log(minWidth, width);
            if (this.unitService.compare(minWidth, width, 0.001) !== -1) {
                console.log("i'ma here");
                width = this.unitService.toValue(minWidth, unit) + ' ' + unit;
                this.adjustedValue[1] = '';
                this.adjustedValue[3] = 'min width';
            }
        });
        this.group.sheetSize = {length: length, width: width};
        this.filteredMachines = this.partEditorService.filterMachinesBySize(this.machines, length, width);
        console.log(this.filteredMachines);
    }
}
