import {Injectable} from '@angular/core';
import {CacheService, UnitService} from '@harmanpa/ng-patchwork';
import {MachineChoice} from '../../../../generated-src/model/machineChoice';
import {DefaultService, VersionedMachine} from 'generated-src';
import {combineLatest, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PartEditorService {
    constructor(private api: DefaultService, private unitService: UnitService, private cacheService: CacheService) {}

    getDefaultMachinesAndMaterialsForWorkshop(workshopId: string, workshopVersion: string): Observable<[MachineChoice[], string[]]> {
        const key = 'workshop/' + workshopId + '/v/' + workshopVersion + '/machinesmaterials';
        return this.cacheService.get(
            key,
            combineLatest([
                this.api.getWorkshopMachineInstanceChoices(workshopId, workshopVersion),
                this.api.getWorkshopMaterialChoices(workshopId, workshopVersion)
            ]),
            this.cacheService.WEEK,
            true
        );
    }

    getFilteredMaterialsByMachineForWorkshop(workshopId: string, workshopVersion: string, machineInstanceId: string): Observable<string[]> {
        const key = 'workshop/' + workshopId + '/v/' + workshopVersion + '/materials/' + machineInstanceId;
        return this.cacheService.get(
            key,
            this.api.getWorkshopMachineInstanceMaterialChoices(workshopId, workshopVersion, machineInstanceId),
            this.cacheService.WEEK,
            true
        );
    }

    getFilteredMachinesByMaterialForWorkshop(workshopId: string, workshopVersion: string, material: string): Observable<MachineChoice[]> {
        const key = 'workshop/' + workshopId + '/v/' + workshopVersion + '/machines/' + material;
        return this.cacheService.get(
            key,
            this.api.getWorkshopMaterialMachineInstanceChoices(workshopId, workshopVersion, material),
            this.cacheService.WEEK,
            true
        );
    }

    filterMachinesBySize(machines: MachineChoice[], length: string, width: string): MachineChoice[] {
        return machines.filter(({machine}) => this.isInRange(machine, length, width));
    }

    private isInRange(machineDetails: VersionedMachine, length: string, width: string): boolean {
        const result =
            this.unitService.compare(machineDetails.document.xLength, length) !== -1 &&
            this.unitService.compare(machineDetails.document.yLength, width) !== -1;
        return result;
    }
}
