<div class="p-d-flex p-jc-center">
    <div class="p-mb-2">
        <p-selectButton [options]="toolingOptions" [(ngModel)]="selectedTooling" optionLabel="name"></p-selectButton>
    </div>
</div>
<div class="p-d-flex">
    <p-table [value]="features">
        <ng-template pTemplate="header">
            <tr>
                <th>FEATURE</th>
                <th>BIT #REF</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-feature let-i="index">
            <tr>
                <td>{{feature.name}}</td>
                <td>
                    <p-dropdown placeholder="Bit" [showClear]="true"></p-dropdown>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
