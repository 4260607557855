import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AlertService, UnitService} from '@harmanpa/ng-patchwork';
import {PartSettings} from '@models/part-settings.model';
import {
    CuttingGroup,
    CuttingList,
    CuttingPartInstance,
    MachineChoice,
    VersionedMachine,
    VersionedProject,
    VersionedWorkshop
} from 'generated-src';
import {AuthService} from 'ng2-ui-auth';
import {MessageService} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {isArrayIndexFound} from 'src/app/shared/utils/is-array-index-found';
import {PartItemEditorComponent} from './part-item-editor/part-item-editor.component';
import {
    Equatable,
    GroupMachineComparator,
    MachineComparator,
    MaterialComparator,
    SheetSizeComparator,
    ThicknessComparator
} from '../utils/equality-comparers';
import {GroupSettings} from '@models/group-settings.model';

@Component({
    selector: 'fab-parts-cutting-list',
    templateUrl: './parts-cutting-list.component.html',
    providers: [DialogService, MessageService]
})
export class PartsCuttingListComponent implements OnInit, OnDestroy {
    @Input() project: VersionedProject;
    @Input() workshop: VersionedWorkshop;
    @Input() cuttingList: CuttingList;
    @Output() cuttingListChange = new EventEmitter<CuttingList>();
    @Input() disabled = false;
    @Input() inApp: boolean;

    partComparators: Equatable[];
    groupComparators: Equatable[];
    ref: DynamicDialogRef;
    summaryDisabled = true;
    showSummary = false;
    constructor(
        public auth: AuthService,
        public dialogService: DialogService,
        private alertService: AlertService,
        private unitService: UnitService
    ) {
        this.partComparators = [
            new ThicknessComparator(this.unitService),
            new MachineComparator(),
            new MaterialComparator(),
            new SheetSizeComparator(this.unitService)
        ];
        this.groupComparators = [
            new ThicknessComparator(this.unitService),
            new GroupMachineComparator(),
            new MaterialComparator(),
            new SheetSizeComparator(this.unitService)
        ];
    }

    ngOnInit(): void {}

    updateMachine(cuttingGroupIndex: number, machine: MachineChoice | undefined): void {
        if (machine) {
            this.updateSheetSize(cuttingGroupIndex, machine.machine, true);
            this.cuttingList.groups[cuttingGroupIndex].machine = machine.value;
        } else {
            this.cuttingList.groups[cuttingGroupIndex].machine = null;
        }
        this.performPostChangeTasks(cuttingGroupIndex);
    }

    updateMaterial(cuttingGroupIndex: number, material: string) {
        this.cuttingList.groups[cuttingGroupIndex].material = material;
        this.performPostChangeTasks(cuttingGroupIndex);
    }

    updateSheetSize(cuttingGroupIndex: number, {document}: VersionedMachine, isMachineUpdated: boolean = false): void {
        this.cuttingList.groups[cuttingGroupIndex].sheetSize = {
            length: document.xLength,
            width: document.yLength
        };
        if (!isMachineUpdated) {
            this.performPostChangeTasks(cuttingGroupIndex);
        }
    }

    private performPostChangeTasks(cuttingGroupIndex: number) {
        this.handleGroupChanges(cuttingGroupIndex);
        this.updateCuttingList();
    }

    onPartEdit(groupIndex: number, instance: CuttingPartInstance): void {
        const groups: CuttingGroup[] = this.cuttingList.groups;
        const currentGroup = groups[groupIndex];

        this.ref = this.dialogService.open(PartItemEditorComponent, {
            header: `Edit ${instance.name.toLowerCase()} features`,
            width: '50%',
            contentStyle: {overflow: 'visible'},
            baseZIndex: 10,
            data: {
                cuttingPartInstance: instance,
                groupMachine: currentGroup.machine,
                groupMaterial: currentGroup.material,
                sheetSize: currentGroup.sheetSize,
                workshop: this.workshop
            }
        });

        this.ref.onClose.subscribe((partSettings: PartSettings | undefined) => {
            if (partSettings) {
                if (this.isPartSuitableForGroup(partSettings, currentGroup)) {
                    this.alertService.info('Part is suitable for current group');
                    return;
                }

                const groupForPartIndex = this.getIndexOfOtherSuitableGroupForPart(partSettings, groups);

                if (!isArrayIndexFound(groupForPartIndex)) {
                    const {material, sheetSize, machine} = partSettings;
                    const newCuttingGroup: CuttingGroup = {
                        instances: [instance],
                        machine: machine.value,
                        material,
                        sheetSize,
                        thickness: instance.slice.thickness
                    };
                    this.cuttingList.groups.push(newCuttingGroup);
                    currentGroup.instances = this.filterPartFromGroup(currentGroup, instance);
                    this.removeGroupIfEmpty(groupIndex);
                    this.alertService.success('New group for part was created');
                    this.updateCuttingList();
                    return;
                }
                this.cuttingList.groups[groupForPartIndex].instances.push(instance);
                currentGroup.instances = this.filterPartFromGroup(currentGroup, instance);
                this.removeGroupIfEmpty(groupIndex);
                this.alertService.info('Part was added to other existing group');
                this.updateCuttingList();
            }
        });
    }

    handleGroupChanges(groupIndex: number, groupData?: CuttingGroup): void {
        if (groupData) {
            this.cuttingList.groups[groupIndex] = groupData;
        }
        const currentGroup = this.cuttingList.groups[groupIndex];
        const partSettings: GroupSettings = {
            machine: currentGroup.machine,
            material: currentGroup.material,
            sheetSize: currentGroup.sheetSize,
            thickness: currentGroup.thickness
        };
        const groupForPartIndex = this.getIndexOfOtherSuitableGroupForGroup(partSettings, this.cuttingList.groups, groupIndex);
        // console.log(groupForPartIndex);
        if (isArrayIndexFound(groupForPartIndex) && groupForPartIndex !== groupIndex) {
            currentGroup.instances.forEach((instance) => {
                this.cuttingList.groups[groupForPartIndex].instances.push(instance);
                currentGroup.instances = this.filterPartFromGroup(currentGroup, instance);
                this.removeGroupIfEmpty(groupIndex);
                this.alertService.info('Part was added to other existing group');
            });
        }
        this.updateCuttingList();
    }

    removePart(i: number, instance: CuttingPartInstance) {
        const index = this.cuttingList.groups[i + 1].instances.indexOf(instance);
        this.cuttingList.groups[i + 1].instances.splice(index, 1);
        if (this.cuttingList.groups[i + 1].instances.length === 0) {
            this.cuttingList.groups.splice(i + 1, 1);
        }
        this.cuttingList.groups[0].instances.push(instance);
        this.updateCuttingList();
    }

    ngOnDestroy(): void {
        if (this.ref) {
            this.ref.close();
        }
    }

    updateCuttingList(cl?: CuttingList): void {
        if (cl) {
            this.cuttingList = cl;
        }
        this.cuttingListChange.emit(Object.assign({}, this.cuttingList));
    }

    private isPartSuitableForGroup(partSettings: PartSettings, cuttingGroup: CuttingGroup): boolean {
        return this.partComparators.every((comparator) => comparator.areEqual(partSettings, cuttingGroup));
    }

    private getIndexOfOtherSuitableGroupForPart(partSettings: PartSettings, groups: CuttingGroup[]): number | undefined {
        return groups.findIndex((group) => this.partComparators.every((comparator) => comparator.areEqual(partSettings, group)));
    }

    private getIndexOfOtherSuitableGroupForGroup(
        partSettings: PartSettings,
        groups: CuttingGroup[],
        currentGroupIndex: number
    ): number | undefined {
        let foundGroupIndex = 0;
        return groups.findIndex((group) => {
            const temp = this.groupComparators.every((comparator) => comparator.areEqual(partSettings, group));
            if (!temp || (temp && foundGroupIndex === currentGroupIndex)) {
                ++foundGroupIndex;
                return false;
            }
            return true;
        });
    }

    private filterPartFromGroup(group: CuttingGroup, instance: CuttingPartInstance): CuttingPartInstance[] {
        return group.instances.filter(({partId}) => partId !== instance.partId);
    }

    private removeGroupIfEmpty(groupIndex: number): void {
        const isEmpty = this.cuttingList.groups[groupIndex].instances.length === 0;
        if (isEmpty) {
            this.cuttingList.groups.splice(groupIndex, 1);
        }
    }
}
