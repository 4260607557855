import {BitMetaData} from './../../../models/bit-meta-data.model';
import {BitService} from '@services/bit.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DefaultService, DocumentSummary, VersionedWorkshop} from 'generated-src';
import {AssignBitComponent} from '../assign-bit/assign-bit.component';
import {WorkshopService} from '@services/workshop.service';
import {MachineInstanceWithId} from '@models/machine-instance-with-id.model';
import {AlertService} from '@harmanpa/ng-patchwork';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {PromptDialogComponent} from 'src/app/shared/components/prompt-dialog/prompt-dialog.component';
import {NamingService} from '../../services/naming.service';

@Component({
    selector: 'fab-bits-panel',
    templateUrl: './bits-panel.component.html',
    styleUrls: ['./bits-panel.component.scss']
})
export class BitsPanelComponent {
    @Input() bits: DocumentSummary[];
    @Input() workshop: VersionedWorkshop;
    @Output() workshopChange: EventEmitter<VersionedWorkshop> = new EventEmitter();
    @Output() reloadChange: EventEmitter<boolean> = new EventEmitter();
    ref: DynamicDialogRef;

    constructor(
        private bitService: BitService,
        private workshopService: WorkshopService,
        private dialogService: DialogService,
        private api: DefaultService,
        private alertService: AlertService,
        private router: Router,
        private title: Title,
        private namingService: NamingService
    ) {}

    ngOnInit() {
        // this.api.getMe().subscribe((user) => {
        //     this.bits = this.bits.filter((bit) => bit.owner != user.email);
        // });
    }

    addBit(bit: DocumentSummary, event: any): void {
        this.workshopService.getUserMachines(this.workshop.id, this.workshop.version).subscribe((machineInstances) => {
            console.log(machineInstances);
            const machines: MachineInstanceWithId[] = Object.keys(machineInstances.document).map((instanceId) =>
                Object.assign({}, machineInstances.document[instanceId], {_id: instanceId})
            );
            const filteredMachines = this.bitService.getMachinesNotContainBit(bit.id, machines);
            this.showMachinesPopUp(bit, filteredMachines);
        });
        event.target.blur();
    }

    private showMachinesPopUp(bit: DocumentSummary, machines: MachineInstanceWithId[]): void {
        const ref: DynamicDialogRef = this.dialogService.open(AssignBitComponent, {
            header: `Add ${bit.data.name} to machine(s)`,
            width: '40%',
            contentStyle: {height: 'auto', overflow: 'auto'},
            baseZIndex: 10000,
            data: {
                machines,
                bit
            }
        });

        const bitMetaData: BitMetaData = {
            id: bit.id,
            head: bit.head,
            type: bit.type,
            name: bit.data.name
        };

        ref.onClose.subscribe((selectedMachines) => this.updateMachineInstancesForWorkshop(bitMetaData, selectedMachines));
    }

    private updateMachineInstancesForWorkshop(bitMetaData: BitMetaData, selectedMachines: MachineInstanceWithId[] | undefined): void {
        if (selectedMachines) {
            this.bitService
                .updateBitInMachineInstancesForWorkshop(this.workshop, bitMetaData, selectedMachines)
                .subscribe((vw) => this.workshopChange.emit(vw));
        }
    }
    reload(): void {
        this.reloadChange.emit(true);
    }

    copy(bit: DocumentSummary, event: any): void {
        this.namingService.setBit(bit);
        this.namingService.copyBit();
        event.target.blur();

        // this.api.findBits(true, false, true, false, false, false, false).subscribe((bits) => {
        //     this.ref = this.dialogService.open(PromptDialogComponent, {
        //         header: 'Configure bit',
        //         width: '50%',
        //         contentStyle: {overflow: 'visible'},
        //         data: {
        //             type: "bit",
        //             currentName: bit.data.name,
        //             existingNames: bits.map((element) => element.data.name)
        //         }
        //     });

        //     this.ref.onClose.subscribe((bitName: string | undefined) => {
        //         if(bitName){
        //             const bitCopy = JSON.parse(JSON.stringify(bit));
        //             this.api.getMe().subscribe((user) => {
        //                 bitCopy.owner = user.email;
        //                 bitCopy.data.name = bitName;
        //                 this.api.addBit(bitCopy.data, bit.id, bit.head).subscribe({
        //                     next: (b) => {
        //                         this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //                         this.router.navigate(['/bits', b.id], {queryParams: {version: b.version}, state: { source: "Workshop"}});
        //                     },
        //                     error: (err) => {
        //                         this.alertService.error(err);
        //                     },
        //                     complete: () => {
        //                         this.alertService.success('Bit ' + bitCopy.data.name + ' was added to your Workshop');
        //                     }
        //                 });
        //             });
        //         }
        //     });
        // })

        // const bitCopy = JSON.parse(JSON.stringify(bit));
        // this.api.getMe().subscribe((user) => {
        //     bitCopy.owner = user.email;
        //     bitCopy.data.name = bit.data.name + ' - Copy';
        //     this.api.addBit(bitCopy.data, bit.id, bit.head).subscribe({
        //         next: (b) => {
        //             this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //             this.router.navigate(['/bits', b.id], {queryParams: {version: b.version}, state: { source: "Workshop"}});
        //         },
        //         error: (err) => {
        //             this.alertService.error(err);
        //         },
        //         complete: () => {
        //             this.alertService.success('Bit ' + bitCopy.data.name + ' was added to your Workshop');
        //         }
        //     });
        // });
    }

    viewBit(bit: DocumentSummary) {
        this.namingService.setBit(bit);
        this.router.navigate([`/${this.title.getTitle().toLowerCase()}/bits`, bit.id], {
            queryParams: {version: bit.head},
            state: {source: this.title.getTitle()}
        });
    }
    remove(bit: DocumentSummary) {
        this.api.deleteBit(bit.id).subscribe({
            next: () => {
                this.reload();
            },
            error: (err) => {
                this.alertService.error(err);
            },
            complete: () => {
                this.alertService.success('Bit was removed');
            }
        });
    }
}
