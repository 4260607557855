<div>
    <div class="p-p-5 p-d-flex">
        <button
            pButton
            pTooltip="Go to Parts"
            tooltipPosition="top"
            class="p-button-raised p-mr-2 p-ml-auto z"
            label="Parts List"
            icon="pi pi-arrow-right"
            [routerLink]="['/projects', project.id]"
            [queryParams]="{version: project.version}"
        ></button>
    </div>
    <cae-toolframe *ngIf="parts" [marginTop]="96" [marginBottom]="58">
        <cae-views-container>
            <cae-view clearColor="#FAFAFA" clearAlpha="1">
                <cae-camera-controls
                    #cameraControlsComponent
                    [alwaysDynamicFit]="true"
                    [maxDistance]="20"
                    [maxZoom]="100"
                    [minDistance]="3"
                    [minZoom]="0.03"
                    [minPolarAngle]="0"
                    [maxPolarAngle]="pi"
                    [wheelAction]="wheelAction"
                    [zoomToFitMax]="0.65"
                    [zoomToFitTrigger]="initialLoadComplete"
                >
                </cae-camera-controls>
                <cae-orthographic-camera [fov]="6" [positionX]="0" [positionY]="5" [positionZ]="5"></cae-orthographic-camera>
                <cae-scene [rotateX]="halfpi">
                    <cae-directional-light
                        [color]="white"
                        [intensity]="2"
                        [translateX]="0"
                        [translateY]="0"
                        [translateZ]="10"
                        [castShadow]="true"
                        [helper]="false"
                        [target]="geometryUrl"
                    ></cae-directional-light>
                    <cae-ambient-light [color]="white" [intensity]="1"></cae-ambient-light>
                    <cae-empty *ngFor="let part of parts.document | keyvalue">
                        <cae-stl-loader
                            *ngFor="let instance of part.value.instances"
                            [model]="geometryUrl(part.key)"
                            [matrix]="instance.T"
                        ></cae-stl-loader>
                    </cae-empty>
                    <!-- <fab-slice-geometry [slice]="slice"></fab-slice-geometry> -->
                </cae-scene>
                <cae-outline-pass [visibleEdgeColor]="black"></cae-outline-pass>
                <cae-hovered-outline-pass></cae-hovered-outline-pass>
            </cae-view>
        </cae-views-container>
        <!-- <cae-toolbar placement="Bottom" [controls]="cameraControlsComponent" #toolbar> -->
        <!-- <cae-camera-controls [button]="true"></cae-camera-controls> -->
        <!-- </cae-toolbar> -->
        <cae-toolbar
            [controls]="cameraControlsComponent"
            [(multiView)]="multipleViews"
            [newToolbar]="true"
            [showScreenModelToolbar]="true"
            [showDataToolbar]="true"
            [distanceFromBottom]="80"
        ></cae-toolbar>
    </cae-toolframe>
</div>
