import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'fab-machines',
    templateUrl: './machines.component.html',
    styleUrls: ['./machines.component.scss']
})
export class MachinesComponent implements OnInit {
    constructor(private title: Title) {}

    ngOnInit() {
        this.title.setTitle('Library');
    }
}
