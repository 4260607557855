<div class="p-d-flex p-jc-left p-m-6 p-pl-6">
    <form class="form p-d-flex p-ai-center">
        <div class="input p-d-inline-flex">
            <i class="search-field-icon search pi pi-search"></i>
            <i
                *ngIf="query"
                class="search-field-icon close pi pi-times"
                (click)="clearQuery()"
                pTooltip="Clear query"
                tooltipPosition="top"
            ></i>
            <input
                type="text"
                id="searchBar"
                class="p-mr-2"
                [(ngModel)]="query"
                placeholder="Search your Projects"
                [ngModelOptions]="{standalone: true}"
            />
        </div>
        <button
            pButton
            pTooltip="Reload projects"
            tooltipPosition="top"
            class="p-px-2 refresh p-mr-2"
            icon="pi pi-refresh"
            (click)="reload()"
        ></button>
    </form>

    <div class="p-d-flex p-jc-left">
        <p-toggleButton
            [(ngModel)]="filtersShown"
            onIcon="pi pi-filter"
            offIcon="pi pi-filter-slash"
            class="p-as-center"
            pTooltip="Filter projects"
            tooltipPosition="top"
        ></p-toggleButton>
        <div class="p-d-flex p-ai-center" *ngIf="filtersShown">
            <p-dropdown
                [options]="sharingItems"
                [(ngModel)]="selectedSharingStatus"
                [optionValue]="'value'"
                (ngModelChange)="changeFilterBySharing($event)"
                placeholder="Privacy"
                class="p-ml-2"
            >
                <ng-template let-option pTemplate="selectedItem">
                    <div>
                        <i [class]="option.icon" class="selector"></i>
                        {{ option.label }}
                    </div>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <div>
                        <i [class]="option.icon" class="selector"></i>
                        {{ option.label }}
                    </div>
                </ng-template>
            </p-dropdown>
            <p-dropdown
                [options]="providers"
                [(ngModel)]="selectedProvider"
                [optionValue]="'value'"
                (ngModelChange)="changeFilterByProvider($event)"
                placeholder="Provider"
                class="p-mx-2"
            >
                <ng-template let-option pTemplate="selectedItem">
                    <div>
                        <img *ngIf="option.img" src="{{ option.img }}" alt="{{ option.label }}" height="18" />
                        <i [class]="option.icon" class="selector"></i>
                        {{ option.label }}
                    </div>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <div>
                        <img *ngIf="option.img" src="{{ option.img }}" alt="{{ option.label }}" height="18" />
                        <i [class]="option.icon" class="selector"></i>
                        {{ option.label }}
                    </div>
                </ng-template>
            </p-dropdown>
            <button pButton pTooltip="Clear all filters" tooltipPosition="top" label="Clear" (click)="clearFilters()"></button>
        </div>
    </div>
</div>

<div class="p-px-6 p-mx-6 p-pb-5" *ngIf="loading">
    <p-divider align="left">
        <div class="p-d-inline-flex p-ai-center">
            <b>My Projects: -- models</b>
        </div>
    </p-divider>
</div>
<div class="p-px-6 p-mx-6 p-pb-5" *ngIf="loading">
    <div class="p-d-flex p-jc-center p-ai-center p-px-6 p-mx-6 p-pb-5">
        <div class="projects-grid">
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
            <p-skeleton class="p-mx-2" width="20rem" height="25rem"></p-skeleton>
        </div>
    </div>
</div>
<div class="p-px-6 p-mx-6 p-pb-5" *ngIf="!loading">
    <p-divider align="left">
        <div class="p-d-inline-flex p-ai-center">
            <b>My Projects: {{ filteredProjects.length }} models</b>
        </div>
    </p-divider>
</div>
<section class="p-d-flex p-jc-center p-ai-center p-px-6 p-mx-6 p-pb-5" *ngIf="!loading">
    <div class="projects-grid">
        <fab-project-item
            *ngFor="let project of filteredProjects | projectName: query:'name'; let i = index"
            [project]="project"
            (delete)="deleteProject($event)"
        ></fab-project-item>
    </div>
</section>
