<div class="p-fluid">
    <div class="p-field p-pt-4">
        <pwk-text-input [label]="'Set ' + elementType + ' name'" [(text)]="value" (textChange)="onChange()"> </pwk-text-input>
    </div>
</div>
<div class="p-grid p-nogutter">
    <div class="p-col-4 p-offset-8 p-d-flex p-jc-end">
        <div
            [pTooltip]="disabled ? (value ? 'Name must be unique' : 'Name can\'t be empty ') : 'Save new ' + elementType"
            tooltipPosition="bottom"
            style="display: inline-block"
        >
            <button pButton [label]="'Save ' + elementType" icon="pi pi-check" (click)="save()" [disabled]="disabled"></button>
        </div>
    </div>
</div>
