import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CuttingStrategy} from '../../../../../generated-src';

@Component({
    selector: 'fab-cutting-config',
    templateUrl: './cutting-config.component.html'
})
export class CuttingConfigComponent implements OnInit {
    @Input() cuttingStrategy: CuttingStrategy;
    @Output() cuttingStrategyChange =
        new EventEmitter<CuttingStrategy>();
    @Input() disabled = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    onChange(): void {
        this.cuttingStrategyChange.emit(this.cuttingStrategy);
    }

}
