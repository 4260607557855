import {VersionedWorkshop} from '../../../../../generated-src/model/versionedWorkshop';
import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService, PatchworkService, UserService} from '@harmanpa/ng-patchwork';
import {DefaultService, DocumentSummary, MachineInstance, VersionedMachine} from 'generated-src';
import {auditTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {ReferenceMachine, VersionedMachineInstance} from '../../../../../generated-src';
import {AuthService} from 'ng2-ui-auth';
import {Subject} from 'rxjs';

@Component({
    selector: 'fab-user-machine-editor',
    templateUrl: './user-machine-editor.component.html'
})
export class UserMachineEditorComponent implements OnInit {
    loading = true;
    bits: DocumentSummary[];
    machine: VersionedMachine;
    workshop: VersionedWorkshop;
    machineInstance: MachineInstance;
    machineInstanceId: string;
    machineInstanceChange = new Subject<MachineInstance>();
    nextMachineInstance: MachineInstance;
    saved = new Subject<VersionedMachineInstance>();
    isEditable = true;
    hasATC = false;
    url: string;
    getUrl: string;
    saving = false;

    constructor(
        private title: Title,
        private api: DefaultService,
        private route: ActivatedRoute,
        private router: Router,
        private patchwork: PatchworkService,
        private alertService: AlertService,
        private authService: AuthService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.workshop = this.route.snapshot.data['workshop'];
        this.patchwork.setVersionQuery(this.route, this.workshop);
        this.machineInstanceId = this.route.snapshot.params['machineInstanceId'];
        this.api
            .getWorkshopMachineInstancesMachineInstance(this.workshop.id, this.workshop.version, this.machineInstanceId)
            .pipe(
                map((machineInstance: VersionedMachineInstance) => {
                    this.machineInstance = machineInstance.document;
                    return this.machineInstance.machine;
                }),
                switchMap((machineReference: ReferenceMachine) => {
                    return this.api.getMachine(machineReference.id, machineReference.version);
                })
            )
            .subscribe({
                next: machine => {
                    this.machine = machine;
                    console.log(machine.document.postProcessStrategy);
                    this.title.setTitle(this.machine.document.name);
                    this.machineInstance.toolpathStrategy = this.machineInstance.toolpathStrategy || this.machine.document.toolpathStrategy;
                    this.machineInstance.nestingStrategy = this.machineInstance.nestingStrategy || this.machine.document.nestingStrategy;
                    this.machineInstance.cuttingStrategy = this.machineInstance.cuttingStrategy || this.machine.document.cuttingStrategy;
                    this.url = `api/machines/${this.machine.id}/v/${this.machine.version}/thumbnail.png`;
                    this.getUrl = `api/machines/${this.machine.id}/v/${
                        this.machine.version
                    }/thumbnail.png?token=${this.authService.getToken()}`;
                },
                error: err => this.alertService.error(err)
            });
        this.handleMachineChanges();
    }

    handleMachineChanges(): void {
        this.machineInstanceChange.pipe(
            auditTime(500),
            // distinctUntilChanged()
        ).subscribe({
            next: m => {
                this.nextMachineInstance = m;
                console.log(m);
                this.save();
            },
            error: err => this.alertService.error(err)
        });
    }

    onSubmit(): void {
        // this.api
        //     .updateWorkshopMachineInstancesMachineInstance(
        //         this.workshop.id,
        //         this.workshop.version,
        //         this.machineInstanceId,
        //         this.machineInstance
        //     )
        //     .pipe(
        //         this.patchwork.trackVersion(this.route),
        //         tap((machine) => (this.workshop.version = machine.version))
        //     )
        //     .subscribe({
        //         next: () => {
        //             this.alertService.info('Machine has been updated');
        //             this.router.navigate(['workshop']);
        //         },
        //         error: (err) => this.alertService.error(err)
        //     });
    }

    machineThumbUpload(): void {
        this.alertService.success('Machine thumbnail set, reload page to update');
    }

    onMachineChange(): void {
        this.machineInstanceChange.next(this.machineInstance);
    }

    private save(): void {
        if (!this.saving) {
            this.saving = true;
            const toSave = this.nextMachineInstance;
            this.nextMachineInstance = null;
            this.api.updateWorkshopMachineInstancesMachineInstance(
                this.workshop.id,
                this.workshop.version,
                this.machineInstanceId,
                this.machineInstance,
                true
            )
            .subscribe({
                next: value => {
                    this.workshop.version = value.version;
                    this.patchwork.setVersionQuery(this.route, value);
                    this.saved.next(value);
                    this.saving = false;
                    this.alertService.info('Machine has been updated');
                    if (this.nextMachineInstance) {
                        this.save();
                    }
                    // this.router.navigate(['workshop']);
                },
                error: err => {
                    this.saving = false;
                    this.alertService.error(err);
                }
            });
        }
    }
}
