import {SheetSize} from './../../../../generated-src/model/sheetSize';
import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'fab-sheet-size-editor',
    templateUrl: './sheet-size-editor.component.html',
    styleUrls: ['./sheet-size-editor.component.scss']
})
export class SheetSizeEditorComponent {
    @Input() isEdited = false;
    @Input() sheetSize: SheetSize;
    @Output() isEditedChange: EventEmitter<boolean> = new EventEmitter();
    @Output() sheetSizeChange: EventEmitter<SheetSize> = new EventEmitter();
    @Output() onUpdateGroup: EventEmitter<void> = new EventEmitter();

    defaultValue: SheetSize;

    onEditStart(): void {
        this.defaultValue = {...this.sheetSize};
        this.isEdited = true;
        this.isEditedChange.emit(this.isEdited);
    }

    onEditEnd(): void {
        this.isEdited = false;
        this.isEditedChange.emit(this.isEdited);
        this.sheetSize = this.defaultValue;
        this.onSheetSizeChange(this.sheetSize);
    }

    onEditAccept(): void {
        this.isEdited = false;
        this.isEditedChange.emit(this.isEdited);
        this.onUpdateGroup.emit();
    }

    onSheetSizeChange(sheetSize: SheetSize): void {
        this.sheetSizeChange.emit(sheetSize);
    }
}
