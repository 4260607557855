<div class="p-p-5 p-d-flex p-ai-center">
    <h4 class="p-mr-2" style="margin-bottom: 0px">FABBER LIBRARY</h4>
    <button
        pButton
        pTooltip="Refresh Catalogue"
        tooltipPosition="top"
        class="p-mr-2 p-button-text"
        icon="pi pi-refresh"
        (click)="reload(true)"
        [loading]="loading"
    ></button>
    <!-- <button
        pButton
        pTooltip="Go to My Workshop"
        tooltipPosition="top"
        class="p-mr-2 p-ml-auto p-button-raised"
        label="My Workshop"
        icon="pi pi-arrow-right"
        [routerLink]="['/workshop']"
    ></button> -->
</div>
<p-tabView styleClass="tabview-custom" *ngIf="!loading && machines && bits" [(activeIndex)]="activeTab">
    <p-tabPanel header="Machines">
        <fab-machines-panel [machines]="machines" [bits]="bits" [(workshop)]="workshop"></fab-machines-panel>
    </p-tabPanel>
    <p-tabPanel header="Bits">
        <fab-bits-panel [bits]="bits" [(workshop)]="workshop" (reloadChange)="reload($event)"></fab-bits-panel>
    </p-tabPanel>
    <p-tabPanel header="Post Processors">
        <fab-post-processors-panel [postpos]="postpos" [loading]="loading" (reloadChange)="reload($event)"></fab-post-processors-panel>
    </p-tabPanel>
</p-tabView>
