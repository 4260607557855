import {Component, OnInit} from '@angular/core';
import {OnshapeService} from '@services/onshape.service';
import {
    CuttingList,
    DefaultService,
    OnshapeDocument,
    VersionedCuttingList,
    VersionedOnshapeImport,
    VersionedProject,
    VersionedWorkshop
} from '../../../../generated-src';
import {AlertService, PatchworkService, UnitService, UserService} from '@harmanpa/ng-patchwork';
import {auditTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {WorkshopService} from '@services/workshop.service';

@Component({
    selector: 'fab-onshape',
    templateUrl: './onshape.component.html',
    styleUrls: ['./onshape.component.scss']
})
export class OnshapeComponent implements OnInit {
    onshapeDocument: OnshapeDocument;
    public workshop: VersionedWorkshop;
    public onshapeImport: VersionedOnshapeImport;
    public project: VersionedProject;
    public url: string;
    cuttingList: CuttingList;
    cuttingListChange = new Subject<CuttingList>();
    nextCuttingList: CuttingList;
    saved = new Subject<VersionedCuttingList>();

    saving = false;
    makeIt = false;

    constructor(
        private userService: UserService,
        private onshape: OnshapeService,
        private http: HttpClient,
        private api: DefaultService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private unitService: UnitService,
        private patchwork: PatchworkService,
        private workshopService: WorkshopService,
        // private cuttingListService: CuttingListService
    ) {
    }

    ngOnInit(): void {
        this.onshape
            .onInit()
            .pipe(
                switchMap((context) =>
                    this.findOrCreateProject(context.documentId, context.workspaceOrVersion,
                        context.workspaceOrVersionId, context.elementId, context.configuration)),
                tap(vp => this.project = vp),
                switchMap(vp => this.workshopService.getProjectWorkshop(vp)),
                tap(vw => this.workshop = vw),
                switchMap(vw => this.api.getProjectCuttingList(this.project.id, this.project.version)))
            .subscribe({
                next: vcl => {
                    this.cuttingList = vcl.document;
                    this.nextCuttingList = this.cuttingList;
                    this.save();
                    this.cuttingListChange.pipe(
                        auditTime(500),
                        distinctUntilChanged()
                    ).subscribe({
                        next: cl => {
                            this.nextCuttingList = cl;
                            console.log(cl);
                            this.save();
                            // this.project.version = cl.version;
                        },
                        error: err => this.alertService.error(err)
                    });
                },
                error: (err) => this.alertService.error(err)
            });
    }

    manufacture(): void {
        this.makeIt = true;
    }

    findOrCreateProject(documentId: string, workspaceOrVersion: 'w' | 'v',
                        workspaceOrVersionId: string, elementId: string,
                        configuration: string): Observable<VersionedProject> {
        return this.http.get<VersionedProject>(
            '/api/onshapeprojects/d/' + documentId
            + '/' + workspaceOrVersion
            + '/' + workspaceOrVersionId
            + '/e/' + elementId
            + '/c/' + configuration);
    }

    private save(): void {
        if (!this.saving) {
            this.saving = true;
            const toSave = this.nextCuttingList;
            this.nextCuttingList = null;
            this.api.updateProjectCuttingList(this.project.id, this.project.version, toSave).subscribe({
                next: value => {
                    this.project.version = value.version;
                    this.patchwork.setVersionQuery(this.route, value);
                    this.saved.next(value);
                    this.saving = false;
                    if (this.nextCuttingList) {
                        this.save();
                    }
                },
                error: err => {
                    this.saving = false;
                    this.alertService.error(err);
                }
            });
        }
    }
}
