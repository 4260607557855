import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'fab-quick-options',
    templateUrl: './quick-options.component.html',
    styleUrls: ['./quick-options.component.scss']
})
export class QuickOptionsComponent implements OnInit {
    corner: any[];
    selectedCorner: string;
    holdDown: any[];
    selectedHoldDown: string;
    zeroing: any[];
    selectedZero: string;

    constructor() {
        this.corner = [{name: 'None'}, {name: 'T Bone'}, {name: 'Dog Bone'}];
        this.holdDown = [
            {name: 'None'},
            {name: 'Super Skin'},
            {name: 'Vacuum'}
        ];
        this.zeroing = [{name: 'Material Top'}, {name: 'Table'}];
    }

    ngOnInit(): void {}
}
