<div class="container-flex mt-1 ml-3 mr-3" *ngIf="!project">
    <h2>Please wait while we import your model...</h2>
</div>
<div class="container-flex mt-1 ml-3 mr-3" *ngIf="project && !workshop">
    <h2>Please wait while we setup your workshop...</h2>
</div>
<ng-container *ngIf="workshop && project">
    <div class="container-flex mt-1 ml-3 mr-3" *ngIf="!makeIt">
        <fab-parts-list-header [cuttingList]="cuttingList"
                               (make)="manufacture()"
                               [disabled]="saving"
                               [showMenu]="false"></fab-parts-list-header>
        <fab-parts-cutting-list
            class="p-my-5"
            [project]="project"
            [workshop]="workshop"
            [(cuttingList)]="cuttingList"
            (cuttingListChange)="cuttingListChange.next($event)"
            [inApp]="true"
        ></fab-parts-cutting-list>
    </div>
    <div class="container-flex mt-1 ml-3 mr-3" *ngIf="makeIt">
        <fab-sheets [project]="project" [onshapeExtension]="true" [(visible)]="makeIt"></fab-sheets>
    </div>
</ng-container>
