<div class="p-p-3">
    <!-- <div class="fabber-sidebar-row">
        <fab-sheets-select></fab-sheets-select>
    </div> -->
    <div class="fabber-sidebar-row">
        <fab-sheet-group-select [project]="project"></fab-sheet-group-select>
    </div>
    <div class="fabber-sidebar-row">
        <!-- <div class="fabber-sidebar-row" *ngIf="!loading && !drawingParts"> -->
        <fab-sheets-edit-nesting></fab-sheets-edit-nesting>
    </div>
    <div class="fabber-sidebar-row">
        <fab-sheets-post-process-button [project]="project"></fab-sheets-post-process-button>
    </div>
    <div class="fabber-sidebar-row">
        <fab-sheets-advanced-settings [project]="project"></fab-sheets-advanced-settings>
    </div>
</div>
