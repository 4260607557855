import {Component, OnInit} from '@angular/core';
import {AuthService} from 'ng2-ui-auth';
import {Observable, of, Subject, throwError} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {SketchupService} from '@services/sketchup.service';
import {Fusion360Service} from '@services/fusion360.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '@harmanpa/ng-patchwork';

@Component({
    selector: 'fab-layout',
    templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {
    public inApp;
    name$: Observable<string>;
    version = window['fabberVersion'];
    menu: MenuItem[];

    constructor(
        public auth: AuthService,
        private sketchupService: SketchupService,
        private fusion360Service: Fusion360Service,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        if (!window['fabberVersion'] || window['fabberVersion'] === '${VERSION}') {
            this.version = 'dev';
        } else {
            this.version = window['fabberVersion'];
        }
        this.inApp = this.route.snapshot.data['inApp'] || this.sketchupService.inSketchup() || this.fusion360Service.inFusion360();
        if (this.inApp) {
            this.menu = [
                {
                    label: 'open Fabber in Browser',
                    icon: 'pi pi-external-link',
                    command: () => {
                        this.openBrowser().subscribe({
                            next: (result) => {
                                if (!result) {
                                    this.alertService.error('Failed to open Fabber externally');
                                }
                            },
                            error: (err) => this.alertService.error(err)
                        });
                    }
                }
            ];
        } else {
            this.menu = [
                {
                    label: 'Forum',
                    icon: 'fas fa-comments',
                    url: 'https://forum.getfabber.com/',
                    target: 'fabberforum'
                },
                {
                    label: 'Tour',
                    icon: 'fas fa-play-circle'
                },
                {
                    label: 'Projects',
                    icon: 'fas fa-folder-open',
                    routerLink: ['/projects']
                },
                {
                    label: 'Library',
                    icon: 'fas fa-album-collection',
                    routerLink: ['/library']
                },
                {
                    label: 'Workshop',
                    icon: 'fas fa-industry-alt',
                    routerLink: ['/workshop']
                }
            ];
        }
        // if (this.inApp) {
        //     this.menu = this.menu.filter(({label}) => label !== 'Projects');
        // }
    }

    startTour() {}

    logout() {}

    popout() {}

    private call<T>(func: string, ...args: any): Observable<T> {
        const f: Function = window['qt'][func] as Function;
        if (!f) {
            return throwError('No such function in CAD tool: ' + func);
        }
        let out: Subject<T>;
        let callback: (result: T) => void;
        if (func.includes('watch')) {
            if (!window[func]) {
                window[func] = new Subject<T>();
            }
            out = window[func] as Subject<T>;
            callback = (result: T) => {
                out.next(result);
            };
        } else {
            out = new Subject<T>();
            callback = (result: T) => {
                out.next(result);
                out.complete();
            };
        }
        f.apply(null, args.concat([callback]));
        return out;
    }

    public openBrowser(): Observable<boolean> {
        if (this.sketchupService.inSketchup()) {
            return this.sketchupService.openBrowser();
        } else if (this.fusion360Service.inFusion360()) {
            return this.fusion360Service.openBrowser();
        } else {
            window.open(window.location.origin, 'Fabber');
            return of(true);
        }
    }
}
