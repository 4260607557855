import {Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'fab-profile',
    templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {

    constructor(
        private title: Title
    ) {}

    ngOnInit() {
        this.title.setTitle('Profile');
    }
}
