import {ConfirmDialogModel} from './../../../models/confirm-dialog-data.model';
import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
    selector: 'fab-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
    message: string;

    constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef) {}

    ngOnInit(): void {
        this.message = (this.config.data as ConfirmDialogModel).message;
    }

    cancel(): void {
        this.ref.close(false);
    }

    confirm(): void {
        this.ref.close(true);
    }
}
