import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UnitService} from '@harmanpa/ng-patchwork';
import {CuttingList, CuttingPartInstance, VersionedProject} from 'generated-src';
import {AuthService} from 'ng2-ui-auth';

@Component({
    selector: 'fab-parts-deleted',
    templateUrl: './parts-deleted.component.html'
})
export class PartsDeletedComponent implements OnInit, OnChanges {
    authToken: string;
    @Input() project: VersionedProject;
    @Input() cuttingList: CuttingList;
    @Output() cuttingListChange: EventEmitter<CuttingList> = new EventEmitter<CuttingList>();

    deletedParts: CuttingPartInstance[] = [];

    constructor(
        public auth: AuthService,
        private unitService: UnitService
    ) {
    }

    ngOnInit(): void {
        this.authToken = this.auth.getToken();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['cuttingList']) {
            if (this.cuttingList && this.cuttingList.groups && this.cuttingList.groups[0]) {
                this.deletedParts = this.cuttingList.groups[0].instances.filter(instance => instance.slice);
            } else {
                this.deletedParts = [];
            }
        }
    }

    revivePart(part: CuttingPartInstance, thickness: string) {
        console.log('part:', part, 'thickness:', thickness);
        const index = this.cuttingList.groups[0].instances.indexOf(part);
        this.cuttingList.groups[0].instances.splice(index, 1);
        // TODO: if the part has specifications in material or machine these should be compared against the thickness groups found.
        const CTgroup = this.cuttingList.groups.slice(1).find((group) => {
            return this.unitService.compare(group.thickness, thickness, 1e-4) === 0;
        });
        if (CTgroup) {
            CTgroup.instances.push(part);
        } else {
            this.cuttingList.groups.push({
                instances: [part],
                thickness: part.slice.thickness
            });
        }
        this.updateCuttingList();
    }

    private updateCuttingList(): void {
        if (this.cuttingList && this.cuttingList.groups && this.cuttingList.groups[0]) {
            this.deletedParts = this.cuttingList.groups[0].instances.filter(instance => instance.slice);
        } else {
            this.deletedParts = [];
        }
        this.cuttingListChange.emit(this.cuttingList);
    }
}
