import {MachineChoice} from '../../../../../generated-src/model/machineChoice';
import {VersionedWorkshop} from '../../../../../generated-src/model/versionedWorkshop';
import {PartEditorService} from '../../services/part-editor.service';
import {SheetSize} from '../../../../../generated-src/model/sheetSize';
import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PartSettings} from '../../../models/part-settings.model';
import { UnitService } from '@harmanpa/ng-patchwork';

@Component({
    selector: 'fab-part-item-editor',
    templateUrl: './part-item-editor.component.html'
})
export class PartItemEditorComponent implements OnInit {
    sheetSize: SheetSize = {length: '0 in', width: '0 in'};
    partEditorData: any;
    workshop: VersionedWorkshop;

    machines: MachineChoice[] = [];
    filteredMachines: MachineChoice[] = [];
    materials: string[] = [];

    selectedMachine: MachineChoice;
    selectedMaterial: string;

    minLength: string;
    minWidth: string;

    isLoading = false;
    isSaveDisabled = false;
    machinesLoading = false;
    materialsLoading = false;

    constructor(
        private config: DynamicDialogConfig,
        private ref: DynamicDialogRef,
        private partEditorService: PartEditorService,
        private unitService: UnitService
    ) {
    }

    ngOnInit(): void {
        this.partEditorData = this.config.data;
        this.workshop = this.partEditorData.workshop as VersionedWorkshop;
        this.selectSheetSize();
        this.setSelectedMachineAndMaterial();
    }

    private selectSheetSize(): void {
        if (this.partEditorData.sheetSize) {
            const {length, width} = this.partEditorData.sheetSize;
            this.sheetSize = {
                length,
                width
            };
        }
    }

    private setSelectedMachine(): void {
        if (this.partEditorData.groupMachine) {
            const foundMachine: MachineChoice = this.machines.find(({value: {path}}) => path === this.partEditorData.groupMachine.path);
            if (foundMachine) {
                this.selectedMachine = foundMachine;
                this.setMinLength();
                this.setMinWidth();
                this.materialsLoading = true;
                this.partEditorService.getFilteredMaterialsByMachineForWorkshop(
                    this.workshop.id,
                    this.workshop.version,
                    this.selectedMachine.id)
                    .subscribe(
                        (materials) => {
                            this.materials = materials;
                            this.materialsLoading = false;
                        },
                        () => {
                            this.materialsLoading = false;
                        }
                    );
            } else {
                this.partEditorService.getDefaultMachinesAndMaterialsForWorkshop(this.workshop.id, this.workshop.version).subscribe({
                    next: (machines) => {
                        this.materials = machines[1];
                        this.materialsLoading = false;
                    },
                    error: () => {
                        this.materialsLoading = false;
                    }
                });
            }
        }
    }

    private setSelectedMachineAndMaterial(): void {
        this.selectedMaterial = this.partEditorData.groupMaterial;
        if (this.selectedMaterial) {
            this.machinesLoading = true;
            this.partEditorService.getFilteredMachinesByMaterialForWorkshop(this.workshop.id, this.workshop.version, this.selectedMaterial)
                .subscribe(
                    {
                        next: (machines) => {
                            this.machines = machines;
                            this.machinesLoading = false;
                            this.setSelectedMachine();
                        },
                        error: () => {
                            this.machinesLoading = false;
                        }
                    }
                );
        } else {
            this.partEditorService.getDefaultMachinesAndMaterialsForWorkshop(this.workshop.id, this.workshop.version).subscribe({
                next: (machines) => {
                    this.machines = machines[0];
                    this.machinesLoading = false;
                    this.setSelectedMachine();
                },
                error: () => {
                    this.machinesLoading = false;
                }
            });
        }
    }

    onMachineSelect(machine: MachineChoice | null): void {
        this.materialsLoading = true;
        if (!machine) {
            this.selectedMachine = null;
            this.partEditorService.getDefaultMachinesAndMaterialsForWorkshop(this.workshop.id, this.workshop.version).subscribe({
                next: (machines) => {
                    this.materials = machines[1];
                    this.handleInvalidMaterial();
                    this.enableSave();
                    this.materialsLoading = false;
                },
                error: () => {
                    this.materialsLoading = false;
                }
            });
            return;
        }
        this.partEditorService.getFilteredMaterialsByMachineForWorkshop(this.workshop.id, this.workshop.version, machine.id).subscribe(
            (materials) => {
                this.selectedMachine = machine;
                this.materials = materials;
                this.handleInvalidMaterial();
                this.enableSave();
                this.materialsLoading = false;
            },
            () => {
                this.materialsLoading = false;
            }
        );
    }

    onMaterialSelect(material: string): void {
        this.machinesLoading = true;
        if (!material) {
            this.selectedMaterial = null;
            this.partEditorService.getDefaultMachinesAndMaterialsForWorkshop(this.workshop.id, this.workshop.version).subscribe({
                next: (machines) => {
                    this.machines = machines[0];
                    this.handleInvalidMachine();
                    this.enableSave();
                    this.machinesLoading = false;
                },
                error: () => {
                    this.machinesLoading = false;
                }
            });
            return;
        }
        this.partEditorService.getFilteredMachinesByMaterialForWorkshop(this.workshop.id, this.workshop.version, material).subscribe(
            (machines) => {
                this.selectedMaterial = material;
                this.machines = machines;
                this.handleInvalidMachine();
                this.enableSave();
                this.machinesLoading = false;
            },
            () => {
                this.machinesLoading = false;
            }
        );
    }

    // private getDefaultChoicesOptions(): Observable<[MachineChoice[], string[]]> {
    //     const {id, version} = this.workshop;
    //     return this.partEditorService.getDefaultMachinesAndMaterialsForWorkshop(id, version).pipe(
    //         tap(([machineChoices, materials]) => {
    //             this.machines = machineChoices;
    //             this.materials = materials;
    //         })
    //     );
    // }

    // private filterMachinesByPartSize(machines: MachineChoice[], sheetSize: SheetSize): MachineChoice[] {
    //     const {length, width} = sheetSize;
    //     return this.partEditorService.filterMachinesBySize(machines, length, width);
    // }

    // private selectFields(): void {
    //     if (this.partEditorData.groupMachine) {
    //         this.selectedMachine = this.machines.find(({value: {path}}) => path === this.partEditorData.groupMachine.path);
    //     }
    //     if (this.partEditorData.groupMaterial) {
    //         this.selectedMaterial = this.materials.find((material) => material === this.partEditorData.groupMaterial);
    //     }
    // }

    onSheetSizeChange(sheetSize: SheetSize): void {
        const {length, width} = sheetSize;
        this.filteredMachines = this.partEditorService.filterMachinesBySize(this.machines, length, width);
        // TODO: What if selected machine doesn't fit?
    }

    handleInvalidMachine(): void {
        if (this.selectedMachine && !this.machines.some(({id}) => id === this.selectedMachine.id)) {
            this.selectedMachine = null;
        }
    }

    handleInvalidMaterial(): void {
        if (!this.materials.includes(this.selectedMaterial)) {
            this.selectedMaterial = '';
        }
    }

    enableSave(): void {
        if (this.selectedMachine && this.selectedMaterial && this.sheetSize.width && this.sheetSize.length) {
            this.isSaveDisabled = false;
            return;
        }
        this.isSaveDisabled = true;
    }

    save(): void {
        const result: PartSettings = {
            machine: this.selectedMachine,
            material: this.selectedMaterial,
            sheetSize: this.sheetSize,
            thickness: this.partEditorData.cuttingPartInstance.slice.thickness
        };
        console.log(result);
        this.ref.close(result);
    }

    setMinLength(): void {
        const length = this.partEditorData.cuttingPartInstance.slice.minimumSheetSize.length;
        const unit = length.split(' ')[1];
        this.minLength = this.unitService.toValue(length, unit) + ' ' + unit;
    }

    setMinWidth(): void {
        const width = this.partEditorData.cuttingPartInstance.slice.minimumSheetSize.width;
        const unit = width.split(' ')[1];
        this.minWidth = this.unitService.toValue(width, unit) + ' ' + unit;
    }
}
