<div class="p-d-flex p-jc-center p-ai-center" *ngIf="inProgress">
    <div class="p-my-6 p-py-6 p-d-flex p-flex-column p-jc-center">
        <p class="p-text-center" style="font-size: 3rem">{{ application | titlecase }} to fabrication in seconds</p>
        <p class="p-text-center" style="font-size: 1.5rem">Please wait while we connect {{ application | titlecase }} to Fabber</p>
        <div class="p-d-flex p-jc-center p-mt-3">
            <div class="snippet" data-title=".dot-carousel">
                <div class="stage">
                    <div class="dot-carousel"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="p-d-flex p-jc-center p-ai-center" *ngIf="!inProgress">
    <div class="p-my-6 p-py-6 p-d-flex p-flex-column">
        <p class="p-text-center" style="font-size: 3rem">{{ application | titlecase }} to fabrication in seconds</p>
        <p class="p-text-center" style="font-size: 1.5rem">Thank you for connecting {{ application | titlecase }} to Fabber</p>
        <p class="p-text-center" style="font-size: 1.5rem">Return to {{ application | titlecase }}</p>
    </div>
</div>
