import {ToolpathStrategy} from './../../../../../generated-src/model/toolpathStrategy';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'fab-toolpath-config',
    templateUrl: './toolpath-config.component.html',
    styleUrls: ['./toolpath-config.component.scss'],
})
export class ToolpathConfigComponent implements OnInit {
    @Input() toolpathStrategy: ToolpathStrategy;
    @Output() toolpathStrategyChange = new EventEmitter<ToolpathStrategy>();
    @Input() disabled = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    onChange() {
        this.toolpathStrategyChange.emit(this.toolpathStrategy);
    }
}
