<section class="card">
    <div class="p-col-10 p-offset-1 p-p-0">
        <div class="p-p-0 p-field">
            <pwk-text-input
                label="Name"
                [(text)]="bit.document.name"
                (textChange)="onChange()"
                [debounce]="1000"
                [disabled]="!isEditable"
            ></pwk-text-input>
        </div>
    </div>
    <div class="p-grid p-fluid p-pt-3 p-nogutter">
        <div class="p-field p-col-4 p-offset-1">
            <div class="p-input-group p-p-2">
                <pwk-quantity-multiple-input
                    [(owner)]="bit.document"
                    [disabled]="!isEditable"
                    [debounce]="1000"
                    [fields]="[
                        {
                            field: 'diameter',
                            label: 'Diameter (Cutting Edge, Shaft and Tip)',
                            tooltip: 'Cutting edge diameter'
                        },
                        {field: 'shaftDiameter', tooltip: 'Shaft diameter'},
                        {field: 'tipDiameter', tooltip: 'Tip diameter'}
                    ]"
                    (ownerChange)="onChange()"
                ></pwk-quantity-multiple-input>
            </div>
            <div class="p-input-group p-p-2">
                <pwk-quantity-multiple-input
                    [(owner)]="bit.document"
                    [disabled]="!isEditable"
                    [debounce]="1000"
                    [fields]="[
                        {
                            field: 'fluteLength',
                            label: 'Flute length',
                            tooltip: 'Flute length'
                        }
                    ]"
                    (ownerChange)="onChange()"
                ></pwk-quantity-multiple-input>
            </div>
            <div class="p-input-group p-p-2">
                <pwk-quantity-multiple-input
                    [(owner)]="bit.document"
                    [disabled]="!isEditable"
                    [debounce]="1000"
                    [fields]="[
                        {
                            field: 'lengthOffset',
                            label: 'Length offset',
                            tooltip: 'Length offset'
                        }
                    ]"
                    (ownerChange)="onChange()"
                ></pwk-quantity-multiple-input>
            </div>
            <div class="p-input-group p-p-0 p-field">
                <!-- <pwk-number-input
                    [(value)]="bit.document.numberOfFlutes"
                    [disabled]="!isEditable"
                    label="Number of flutes"
                    tooltipText="Number of flutes"
                    (valueChange)="onChange()"
                >
                </pwk-number-input> -->
                <pwk-quantity-input
                    [(value)]="bit.document.numberOfFlutes"
                    [disabled]="!isEditable"
                    label="Number of flutes"
                    [showLabel]="true"
                    tooltipText="Number of flutes"
                    (valueChange)="onChange()"
                    [debounce]="1000"
                >
                </pwk-quantity-input>
            </div>
        </div>
        <div class="p-field p-col-4 p-offset-2">
            <div class="p-input-group p-p-2">
                <pwk-quantity-multiple-input
                    [(owner)]="bit.document"
                    [disabled]="!isEditable"
                    [debounce]="1000"
                    [fields]="[
                        {
                            field: 'overallLength',
                            label: 'Overall length',
                            tooltip: 'Overall length'
                        }
                    ]"
                    (ownerChange)="onChange()"
                ></pwk-quantity-multiple-input>
            </div>
            <ng-container *ngIf="bit.document.cuttingEdgeLength" class="p-mb-2">
                <pwk-quantity-multiple-input
                    [(owner)]="bit.document"
                    [disabled]="!isEditable"
                    [debounce]="1000"
                    [fields]="[
                        {
                            field: 'cuttingEdgeLength',
                            label: 'Length (Cutting Edge and Overall)',
                            tooltip: 'Cutting edge length'
                        }
                    ]"
                    (ownerChange)="onChange()"
                ></pwk-quantity-multiple-input>
            </ng-container>

            <div class="p-input-group p-p-0 p-field">
                <label>Clockwise</label>
                <p-selectButton
                    [options]="[
                        {name: 'Yes', value: 'yes'},
                        {name: 'No', value: 'No'}
                    ]"
                    optionLabel="name"
                    optionValue="value"
                    [disabled]="!isEditable"
                    [(ngModel)]="bit.document.clockwise"
                    (ngModelChange)="onChange()"
                ></p-selectButton>
            </div>
        </div>
    </div>
</section>
