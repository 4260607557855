import {Component, OnInit} from '@angular/core';
import {DefaultService, DocumentSummary} from '../../../generated-src';
import {AuthService} from 'ng2-ui-auth';
import {Title} from '@angular/platform-browser';
import {AlertService, ConfirmService} from '@harmanpa/ng-patchwork';
import {tap} from 'rxjs/operators';
import {MenuItem} from 'primeng/api';
import {FilterService} from '@harmanpa/ng-patchwork';

@Component({
    selector: 'fab-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
    public filteredProjects: Array<DocumentSummary> = [];
    public fetchedProjects: Array<DocumentSummary>;
    public newProjectName: string;
    public loading: boolean;
    public query = '';
    sharingItems: {label: string; icon: string; value: any}[];
    providers: {label: string; img?: string; icon?: string; value: any}[];
    selectedSharingStatus: 'Public' | 'Private' | 'All';
    selectedProvider: 'sketchup' | 'fusion360' | 'onshape' | 'all';
    provider = 'none';
    filterService = new FilterService();
    filtersShown = false;

    constructor(
        private api: DefaultService,
        public auth: AuthService,
        private alertService: AlertService,
        private title: Title,
        private confirmService: ConfirmService
    ) {
        this.fetchedProjects = new Array<DocumentSummary>();
        this.newProjectName = 'New Project';
        this.loading = true;
        this.sharingItems = [
            {label: 'Public', icon: 'fal fa-lock-open-alt', value: true},
            {label: 'Private', icon: 'fal fa-lock-alt', value: false},
            {label: 'All', icon: 'fal fa-times', value: 'all'}
        ];
        this.providers = [
            {label: 'SketchUp', img: 'assets\\CAD\\sketchup.png', value: 'sketchup'},
            {label: 'Fusion 360', img: 'assets\\CAD\\fusion360.png', value: 'fusion360'},
            {label: 'Onshape', img: 'assets\\CAD\\onshape.png', value: 'onshape'},
            {label: 'All', icon: 'fal fa-times', value: 'all'}
        ];
    }

    ngOnInit(): void {
        this.loading = true;
        this.reload();
        this.title.setTitle('Projects');
    }

    reload(): void {
        this.api.findProjects(true, true, true, false, false, false, false).subscribe({
            next: (projects) => {
                this.fetchedProjects = projects;
                console.log(this.fetchedProjects);
                this.loading = false;
                this.filterChange();
            },
            error: (err) => this.alertService.error(err)
        });
    }

    create(name: string): void {
        this.api.addProject({name}).subscribe({
            next: (project) => {
                this.fetchedProjects.push(project);
                // this.projectService.navigateToUpload(project);
            },
            error: (err) => this.alertService.error(err)
        });
    }

    deleteProject(project: DocumentSummary): void {
        this.confirmService.onConfirmed(
            this.api.deleteProject(project.id).pipe(tap((p) => this.reload())),
            'Are you sure you wish to delete ' + project.data['name'] + '?',
            'Project deleted'
        );
    }

    changeFilter(type: true | false | 'all' | 'sketchup' | 'fusion360' | 'onshape') {
        const sharedFunction = ({sharing}) => sharing.sharedWithPublic !== type;
        const cadProviderFunction = ({data}) => data.cad.provider === type;

        this.filterService.removeFilterTypeFromFilters('provider');

        if (type === true || type === false) {
            this.filterService.addFilter('provider', sharedFunction);
        } else if (type === 'sketchup' || type === 'fusion360' || type === 'onshape') {
            this.provider = type;
            this.filterService.addFilter('provider', cadProviderFunction);
        } else if (type === 'all') {
            // this.provider
            // return this.projects;
        }
    }

    changeFilterByProvider(provider: 'sketchup' | 'fusion360' | 'onshape' | 'all') {
        console.log(provider);
        const func = (documentSummary: DocumentSummary) => documentSummary.data.cad.provider === provider;
        this.filterService.removeFilterTypeFromFilters('provider');
        if (provider !== 'all') {
            this.filterService.addFilter('provider', func);
        } else {
            this.selectedProvider = null;
        }
        this.filterChange();
    }

    changeFilterBySharing(type: true | false | 'all') {
        const func = (documentSummary: DocumentSummary) => documentSummary.sharing.sharedWithPublic === type;
        this.filterService.removeFilterTypeFromFilters('sharing');
        if (type === true || type === false) {
            this.filterService.addFilter('sharing', func);
        } else if (type === 'all') {
            this.selectedSharingStatus = null;
        }
        this.filterChange();
    }

    clearFilters() {
        this.selectedProvider = null;
        this.selectedSharingStatus = null;
        this.filterService.filters = [];
        this.filterChange();
    }
    private filterChange() {
        this.filteredProjects = this.filterService.filterChange(this.fetchedProjects);
    }

    clearQuery() {
        this.query = null;
    }
}
