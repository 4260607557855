<div *ngIf="machines.length == 0">
    <h5 class="p-mt-3">Please add at least one machine to start Manufacturing.</h5>
    <!-- <h6>Please add at least one machine to start Manufacturing.</h6> -->
</div>
<p-dataView #userViewMachines [value]="machines" [paginator]="false" rows="10" filterBy="value.name" layout="grid">
    <ng-template pTemplate="header">
        <div class="p-d-flex p-flex-column p-ai-center p-flex-md-row p-jc-md-between">
            <div class="p-d-flex">
                <span class="p-input-icon-left p-mb-2 p-mb-md-0 p-mr-2">
                    <i class="pi pi-search"></i>
                    <input
                        type="search"
                        pInputText
                        placeholder="Search by name..."
                        (input)="userViewMachines.filter($event.target.value)"
                    />
                </span>
            </div>
            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
        </div>
    </ng-template>
    <ng-template let-machine pTemplate="gridItem">
        <div class="p-col-3 p-p-2" *ngIf="machine.value">
            <p-panel class="public">
                <p-header class="p-d-flex p-jc-between p-ai-center">
                    <img
                        [src]="
                            'api/machines/' +
                            machine.value.machine.id +
                            '/v/' +
                            machine.value.machine.version +
                            '/thumbnail.png?token=' +
                            authToken
                        "
                        width="40"
                        height="40"
                        onError="this.onerror=null;this.src='../assets/brands/machine.png';"
                        alt="{{ machine.value.name }}"
                    />
                    <h5 class="p-pl-3 p-m-0 p-text-bold">
                        {{ machine.value.name }}
                    </h5>
                </p-header>
                <div class="p-d-flex p-ai-end p-jc-between">
                    <div class="machine-card">
                        <fab-machine-info [machine]="machine.value"></fab-machine-info>
                    </div>
                    <div class="p-d-flex">
                        <button
                            pButton
                            pTooltip="Edit {{ machine.name }}"
                            tooltipPosition="top"
                            class="user-machine-icon"
                            icon="pi pi-pencil"
                            [routerLink]="['/workshop', workshop.id, 'machine', machine.key]"
                        ></button>
                        <button
                            pButton
                            pTooltip="Duplicate {{ machine.name }}"
                            tooltipPosition="top"
                            class="p-button-secondary user-machine-icon"
                            icon="pi pi-clone"
                            (click)="duplicate(machine.value)"
                        ></button>
                        <button
                            pButton
                            pTooltip="Delete {{ machine.name }}"
                            tooltipPosition="top"
                            class="p-button-danger user-machine-icon"
                            icon="pi pi-trash"
                            (click)="remove(machine.key)"
                        ></button>
                    </div>
                </div>
            </p-panel>
        </div>
    </ng-template>
    <ng-template let-machine pTemplate="listItem">
        <div class="p-col-12 p-my-2">
            <p-panel class="public">
                <p-header class="p-d-flex p-jc-between p-ai-center">
                    <img
                        [src]="'api/machines/' + machine.id + '/v/' + machine.head + '/thumbnail.png?token=' + authToken"
                        width="40"
                        height="40"
                        onError="this.onerror=null;this.src='../assets/brands/machine.png';"
                        alt="{{ machine.name }}"
                    />
                    <p class="p-ml-3">
                        {{ machine.value.name }}
                    </p>
                </p-header>
                <div class="p-d-flex p-jc-between">
                    <div class="p-ml-6 p-as-stretch">
                        <fab-machine-info [machine]="machine.value"></fab-machine-info>
                    </div>
                    <div class="p-mr-2 p-mt-3 p-as-end p-d-flex">
                        <button
                            pButton
                            pTooltip="Edit {{ machine.value.name }}"
                            tooltipPosition="top"
                            class="p-ml-auto p-mr-1"
                            icon="pi pi-pencil"
                            [routerLink]="['/workshop', workshop.id, 'machine', machine.key]"
                        ></button>
                        <button
                            pButton
                            pTooltip="Duplicate {{ machine.name }}"
                            tooltipPosition="top"
                            class="p-button-secondary p-mr-1"
                            icon="pi pi-clone"
                            (click)="duplicate(machine.value)"
                        ></button>
                        <button
                            pButton
                            pTooltip="Delete {{ machine.value.name }}"
                            tooltipPosition="top"
                            class="p-button-danger"
                            icon="pi pi-trash"
                            (click)="remove(machine.key)"
                        ></button>
                    </div>
                </div>
            </p-panel>
        </div>
    </ng-template>
</p-dataView>
