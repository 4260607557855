import {Injectable} from '@angular/core';
import {ArrayXY, Box, Circle, Ellipse, G, Matrix, Path, PathArray, PathCommand, Point, PointArray} from '@svgdotjs/svg.js';
import {Polygon, testPolygonPolygon, Vector} from 'sat';
import {makeCCW, quickDecomp, removeDuplicatePoints} from 'poly-decomp';
import {PathLineInfo} from '../models/path-line-info.model';

@Injectable({
    providedIn: 'root'
})
export class ConstraintsService {
    partBoundingBox: Box;
    sheetBoundingBox: Box;
    box: Box;
    snappingGridSize = 50;
    isSnappingEnabled: boolean;
    handler: any;
    x: number;
    y: number;
    partsPolygons: Polygon[][];
    selectedPartPolygon: Polygon[];
    toolpathOffset = 20;
    offset = 5;

    partsStartingCoordinates: Vector[] = [];
    partsVertices: PathLineInfo[][] = [];
    selectedPartIndex: number;

    constructor() {}

    init(part: {group: G; path: Path | Circle | Ellipse}, event: any, otherParts: Box[]): number[] {
        this.partBoundingBox = part.group.bbox();
        this.handler = event.detail.handler;
        this.box = event.detail.box;
        this.x = this.box.x;
        this.y = this.box.y;

        this.setPositionInsideSheet();
        const position = this.getPositionWithinConstraints();

        this.selectedPartPolygon = this.calculatePartPolygon(part, this.partsVertices[this.selectedPartIndex]);
        let collisionList = otherParts
            .map((p, index) => {
                if (this.isMovementStopped(p, position)) {
                    return index;
                }
            })
            .filter((e) => e !== undefined);
        if (!this.checkCollisions(collisionList)) {
            collisionList = [];
        }
        this.handler.move(position.x, position.y);
        return collisionList;
    }

    checkCollisions(collisionList: number[]): boolean {
        return collisionList.some((part) => {
            for (let i = 0; i < this.selectedPartPolygon.length; ++i) {
                for (let j = 0; j < this.partsPolygons[part].length; ++j) {
                    if (testPolygonPolygon(this.selectedPartPolygon[i], this.partsPolygons[part][j])) {
                        return true;
                    }
                }
            }
        });
    }

    getPartVerticesPosition(partPath: Path | Circle | Ellipse): PathLineInfo[] {
        const points = [] as {point: Point; type: string; index: number; pathCommand?: PathCommand}[];
        this.getPathCommands(partPath).forEach((e, index) => {
            if (e[0] === 'A') {
                points.push({point: new Point(e[6], e[7]), type: 'A', index: index, pathCommand: e});
            } else if (e[0] === 'M' || e[0] === 'L') {
                points.push({point: new Point(e[1], e[2]), type: 'L', index: index});
            }
        });
        return this.calculatePartVectors(partPath, points);
    }

    getPathCommands(partPath: Path | Circle | Ellipse): PathArray {
        if (partPath instanceof Path) {
            const path = partPath as Path;
            return path.array();
        } else if (partPath instanceof Circle) {
            const circle = partPath as Circle;
            const radius = circle.width() / 2;
            const cx = circle.cx();
            const cy = circle.cy();
            return new PathArray(
                [
                    'M',
                    cx,
                    cy + radius,
                    'A',
                    radius,
                    radius,
                    0,
                    0,
                    0,
                    cx + radius,
                    cy,
                    'A',
                    radius,
                    radius,
                    0,
                    0,
                    0,
                    cx,
                    cy - radius,
                    'A',
                    radius,
                    radius,
                    0,
                    0,
                    0,
                    cx - radius,
                    cy,
                    'A',
                    radius,
                    radius,
                    0,
                    0,
                    0,
                    cx,
                    cy + radius,
                    'L',
                    cx,
                    cy + radius + 0.001
                ]
                    .map((e) => e.toString())
                    .join(' ')
            );
        } else {
            console.log('Something unknown in constraints service', partPath);
            return new PathArray();
        }
    }

    calculatePartVectors(
        path: Path | Circle | Ellipse,
        points: {point: Point; type: string; index: number; pathCommand?: PathCommand}[]
    ): PathLineInfo[] {
        const partVectors = points.map((p) => {
            const pivot = this.calculatePivot(
                points.map((e) => e.point),
                path.bbox()
            );
            const transformedPoint = p.point.transform(
                new Matrix().rotate(path.transform().rotate, pivot.x, pivot.y).translate(-pivot.x, -pivot.y)
            );
            return {
                vector: new Vector(transformedPoint.x, transformedPoint.y),
                type: p.type,
                index: p.index,
                pathCommand: p.pathCommand ? p.pathCommand : null
            };
        });
        partVectors.pop();
        return partVectors;
    }

    radian(u: Vector, v: Vector): number {
        const dot = u.x * v.x + u.y * v.y;
        const mod = Math.sqrt((u.x * u.x + u.y * u.y) * (v.x * v.x + v.y * v.y));
        let rad = Math.acos(dot / mod);
        if (u.x * v.y - u.y * v.x < 0.0) {
            rad = -rad;
        }
        return rad;
    }

    svgArcToCenterParam(e: PathCommand, lastPoint: Point, nextPoint: Point): Point[] {
        const x1 = lastPoint.x;
        const y1 = lastPoint.y;
        let rx = e[1] + this.offset;
        let ry = e[2] + this.offset;
        const phi = e[3];
        const fA = e[4];
        const fS = e[5];
        const x2 = nextPoint.x;
        const y2 = nextPoint.y;

        let cx, cy, startAngle, deltaAngle, endAngle;
        const PIx2 = Math.PI * 2.0;

        if (rx < 0) {
            rx = -rx;
        }
        if (ry < 0) {
            ry = -ry;
        }
        if (rx === 0.0 || ry === 0.0) {
            // invalid arguments
            return null;
        }

        const s_phi = Math.sin(phi);
        const c_phi = Math.cos(phi);
        const hd_x = (x1 - x2) / 2.0; // half diff of x
        const hd_y = (y1 - y2) / 2.0; // half diff of y
        const hs_x = (x1 + x2) / 2.0; // half sum of x
        const hs_y = (y1 + y2) / 2.0; // half sum of y

        // F6.5.1
        const x1_ = c_phi * hd_x + s_phi * hd_y;
        const y1_ = c_phi * hd_y - s_phi * hd_x;

        // F.6.6 Correction of out-of-range radii
        //   Step 3: Ensure radii are large enough
        const lambda = Math.pow(x1_, 2) / Math.pow(rx, 2) + Math.pow(y1_, 2) / Math.pow(ry, 2);
        if (lambda > 1) {
            rx = rx * Math.sqrt(lambda);
            ry = ry * Math.sqrt(lambda);
        }

        const rxry = rx * ry;
        const rxy1_ = rx * y1_;
        const ryx1_ = ry * x1_;
        const sum_of_sq = rxy1_ * rxy1_ + ryx1_ * ryx1_; // sum of square
        if (!sum_of_sq) {
            return null;
        }
        let coe = Math.sqrt(Math.abs((Math.pow(rxry, 2) - sum_of_sq) / sum_of_sq));
        if (fA === fS) {
            coe = -coe;
        }

        // F6.5.2
        const cx_ = (coe * rxy1_) / ry;
        const cy_ = (-coe * ryx1_) / rx;

        // F6.5.3
        cx = c_phi * cx_ - s_phi * cy_ + hs_x;
        cy = s_phi * cx_ + c_phi * cy_ + hs_y;

        const xcr1 = (x1_ - cx_) / rx;
        const xcr2 = (x1_ + cx_) / rx;
        const ycr1 = (y1_ - cy_) / ry;
        const ycr2 = (y1_ + cy_) / ry;

        // F6.5.5
        startAngle = this.radian(new Vector(1.0, 0.0), new Vector(xcr1, ycr1));

        // F6.5.6
        deltaAngle = this.radian(new Vector(xcr1, ycr1), new Vector(-xcr2, -ycr2));
        while (deltaAngle > PIx2) {
            deltaAngle -= PIx2;
        }
        while (deltaAngle < 0.0) {
            deltaAngle += PIx2;
        }
        if (!fS || fS == 0) {
            deltaAngle -= PIx2;
        }
        endAngle = startAngle + deltaAngle;
        while (endAngle > PIx2) {
            endAngle -= PIx2;
        }
        while (endAngle < 0.0) {
            endAngle += PIx2;
        }

        const outputObj = {
            cx: cx,
            cy: cy,
            startAngle: startAngle,
            deltaAngle: deltaAngle,
            endAngle: endAngle,
            clockwise: fS || fS === 1
        };
        // console.log(outputObj);

        const resPoints = [];
        const pointsCount = 3;
        for (let i = 1; i < pointsCount + 1; ++i) {
            const f_x = cx + rx * Math.cos(startAngle + i * (deltaAngle / (pointsCount + 1)));
            const f_y = cy + ry * Math.sin(startAngle + i * (deltaAngle / (pointsCount + 1)));
            resPoints.push(new Point(f_x, f_y));
        }

        return resPoints;
    }

    calculatePivot(partVertices: Point[], box: Box): Vector {
        const minX = Math.min(...partVertices.map((e) => e.x));
        const minY = Math.min(...partVertices.map((e) => e.y));
        return new Vector(minX + box.w / 2, minY + box.h / 2);
    }

    calculatePartPolygon(part: {group: G; path: Path | Circle | Ellipse}, partVertices: PathLineInfo[]): Polygon[] {
        const path = part.path as Path;
        const verticesArray = partVertices.map((p) => [p.vector.x, p.vector.y]);
        if (path) {
            const convexParts = this.getPartPolygons(verticesArray);
            const convexPartsWithTypes = this.getVerticesWithTypes(convexParts, partVertices);
            const referencePoints = this.getReferencePoints(convexPartsWithTypes);
            const polys = this.getTempPolys(convexParts, part.group);
            const resPolys = this.calculateFinalPolys(polys, referencePoints, convexPartsWithTypes, part.group);
            return resPolys;
        }
    }

    getPartPolygons(verticesArray: number[][]): number[][] {
        makeCCW(verticesArray);
        const convexParts = quickDecomp(verticesArray);
        // convexParts.forEach((pa) => removeDuplicatePoints(pa, 0.0001));
        return convexParts;
    }

    getVerticesWithTypes(convexParts: number[][], partVertices: PathLineInfo[]): PathLineInfo[][] {
        const convexPartsWithTypes: PathLineInfo[][] = [];
        convexParts.forEach((poly) => {
            const tempParts: PathLineInfo[] = [];
            poly.forEach((vertex) => {
                if (!tempParts.find((el) => el.vector.x === vertex[0] && el.vector.y === vertex[1])) {
                    partVertices.forEach((partVertex) => {
                        if (partVertex.vector.x === vertex[0] && partVertex.vector.y === vertex[1]) {
                            tempParts.push(partVertex);
                        }
                    });
                }
            });
            convexPartsWithTypes.push(tempParts.reverse());
        });
        return convexPartsWithTypes;
    }

    getReferencePoints(convexPartsWithTypes: PathLineInfo[][]): Vector[] {
        const referencePoints = [];
        for (let i = 0; i < convexPartsWithTypes.length; ++i) {
            referencePoints.push(convexPartsWithTypes[i][0].vector);
        }
        return referencePoints;
    }

    getTempPolys(convexParts: number[][], group: G): {poly: Polygon; offset: number}[] {
        // getAABBAsBox() is not yet in types/sat, throwing error on call, hence any[] type
        const polys = [];
        for (let i = 0; i < convexParts.length; ++i) {
            const vertices = [];
            for (let j = 0; j < convexParts[i].length; ++j) {
                vertices.push(new Vector(convexParts[i][j][0], convexParts[i][j][1]));
            }
            const diff = vertices.length;
            const poly = {poly: new Polygon(new Vector(group.bbox().cx, group.bbox().cy), vertices), offset: false};
            poly.offset = diff - poly.poly.points.length > 0 ? true : false;
            polys.push(poly);
        }
        return polys;
    }

    calculateFinalPolys(polys: any[], referencePoints: Vector[], convexPartsWithTypes: PathLineInfo[][], group): Polygon[] {
        const resPolys = [];
        polys.forEach((poly, polyIndex) => {
            const boundingBox = poly.poly.getAABBAsBox();
            const centroid = poly.poly.getCentroid();
            const scaleX = (boundingBox.w / 2 + this.offset) / (boundingBox.w / 2);
            const scaleY = (boundingBox.h / 2 + this.offset) / (boundingBox.h / 2);
            const points = new PointArray(poly.poly.points.map((vector) => [vector.x, vector.y]))
                .transform(new Matrix().scale(scaleX, scaleY, centroid.x, centroid.y))
                .reverse();
            const offset = this.getVertexArrayOffset(poly.poly, referencePoints, polyIndex);
            this.reorderPointsArrayToFitTypesArray(points, offset, convexPartsWithTypes, polyIndex);
            const resPoints = this.getFinalPartVerticesPosition(points, convexPartsWithTypes, polyIndex, poly.offset);
            const convexParts = this.getPartPolygons(resPoints);
            this.getTempPolys(convexParts, group).forEach((p) => resPolys.push(p.poly));
        });
        return resPolys;
    }

    getVertexArrayOffset(poly: Polygon, referencePoints: Vector[], polyIndex: number): number {
        let offset = 0;
        poly.points.forEach((point, index) => {
            if (point.x === referencePoints[polyIndex].x && point.y === referencePoints[polyIndex].y) {
                offset = index;
            }
        });
        return offset;
    }

    reorderPointsArrayToFitTypesArray(points: ArrayXY[], offset: number, convexPartsWithTypes: PathLineInfo[][], polyIndex: number) {
        for (let i = 0; i < offset + 1; ++i) {
            points.push(points.shift());
        }
        while (convexPartsWithTypes[polyIndex][0].type === 'A') {
            points.push(points.shift());
            // convexPartsWithTypes[polyIndex][0].type = 'L';
            convexPartsWithTypes[polyIndex].push(convexPartsWithTypes[polyIndex].shift());
        }
        convexPartsWithTypes[polyIndex].forEach((poly, index) => {
            if (poly.type === 'A') {
                if (convexPartsWithTypes[polyIndex][index - 1].index !== poly.index - 1) {
                    poly.type = 'L';
                }
            }
        });
    }

    getFinalPartVerticesPosition(points: ArrayXY[], convexPartsWithTypes: PathLineInfo[][], polyIndex: number, polyOffset: boolean) {
        const resPoints = [];
        points.forEach((point, index) => {
            if (convexPartsWithTypes[polyIndex][index].type === 'A') {
                const res = this.svgArcToCenterParam(
                    convexPartsWithTypes[polyIndex][index].pathCommand,
                    new Point(resPoints[resPoints.length - 1][0], resPoints[resPoints.length - 1][1]),
                    new Point(point[0], point[1])
                );
                res.forEach((p) => resPoints.push([p.x, p.y]));
            }
            resPoints.push([point[0], point[1]]);
        });
        if (polyOffset) {
            const index = points.length;
            if (convexPartsWithTypes[polyIndex][index].type === 'A') {
                const res = this.svgArcToCenterParam(
                    convexPartsWithTypes[polyIndex][index].pathCommand,
                    new Point(resPoints[resPoints.length - 1][0], resPoints[resPoints.length - 1][1]),
                    new Point(points[0][0], points[0][1])
                );
                res.forEach((p) => resPoints.push([p.x, p.y]));
            }
            resPoints.push([points[0][0], points[0][1]]);
        }
        return resPoints;
    }

    isMovementStopped(otherPart: Box, position: {x: number; y: number}): boolean {
        return position.x + this.box.w + this.toolpathOffset > otherPart.x &&
            position.x < otherPart.x + otherPart.w + this.toolpathOffset &&
            position.y + this.box.h + this.toolpathOffset > otherPart.y &&
            position.y < otherPart.y + otherPart.h + this.toolpathOffset
            ? true
            : false;
    }

    setPositionInsideSheet(): void {
        if (this.partBoundingBox.x < this.sheetBoundingBox.x) {
            this.x = this.sheetBoundingBox.x;
        }
        if (this.partBoundingBox.y < this.sheetBoundingBox.y) {
            this.y = this.sheetBoundingBox.y;
        }
        if (this.partBoundingBox.x2 > this.sheetBoundingBox.x2) {
            this.x = this.sheetBoundingBox.x2 - this.box.w;
        }
        if (this.partBoundingBox.y2 > this.sheetBoundingBox.y2) {
            this.y = this.sheetBoundingBox.y2 - this.box.h;
        }
    }

    getPositionWithinConstraints(): Vector {
        if (this.checkIfInsideConstraints()) {
            return this.isSnappingEnabled
                ? new Vector(this.x - (this.x % this.snappingGridSize), this.y - (this.y % this.snappingGridSize))
                : new Vector(this.x, this.y);
        }
        if (this.checkLeftConstraint()) {
            return this.isSnappingEnabled
                ? new Vector(
                      this.sheetBoundingBox.x - (this.sheetBoundingBox.x % this.snappingGridSize),
                      this.y - (this.y % this.snappingGridSize)
                  )
                : new Vector(this.sheetBoundingBox.x, this.y);
        }
        if (this.checkRightConstraint()) {
            return this.isSnappingEnabled
                ? new Vector(
                      this.sheetBoundingBox.x2 - this.box.w - ((this.sheetBoundingBox.x2 - this.box.w) % this.snappingGridSize),
                      this.y - (this.y % this.snappingGridSize)
                  )
                : new Vector(this.sheetBoundingBox.x2 - this.box.w, this.y);
        }
        if (this.checkTopConstraint()) {
            return this.isSnappingEnabled
                ? new Vector(
                      this.x - (this.x % this.snappingGridSize),
                      this.sheetBoundingBox.y - (this.sheetBoundingBox.y % this.snappingGridSize)
                  )
                : new Vector(this.x, this.sheetBoundingBox.y);
        }
        if (this.checkBottomConstraint()) {
            return this.isSnappingEnabled
                ? new Vector(
                      this.x - (this.x % this.snappingGridSize),
                      this.sheetBoundingBox.y2 - this.box.h - ((this.sheetBoundingBox.y2 - this.box.h) % this.snappingGridSize)
                  )
                : new Vector(this.x, this.sheetBoundingBox.y2 - this.box.h);
        }
        if (this.checkTopLeftConstraint()) {
            return this.isSnappingEnabled
                ? new Vector(
                      this.sheetBoundingBox.x - (this.sheetBoundingBox.x % this.snappingGridSize),
                      this.sheetBoundingBox.y - (this.sheetBoundingBox.y % this.snappingGridSize)
                  )
                : new Vector(this.sheetBoundingBox.x, this.sheetBoundingBox.y);
        }
        if (this.checkBottomLeftConstraint()) {
            return this.isSnappingEnabled
                ? new Vector(
                      this.sheetBoundingBox.x - (this.sheetBoundingBox.x % this.snappingGridSize),
                      this.sheetBoundingBox.y2 - this.box.h - ((this.sheetBoundingBox.y2 - this.box.h) % this.snappingGridSize)
                  )
                : new Vector(this.sheetBoundingBox.x, this.sheetBoundingBox.y2 - this.box.h);
        }
        if (this.checkTopRightConstraint()) {
            return this.isSnappingEnabled
                ? new Vector(
                      this.sheetBoundingBox.x2 - this.box.w - ((this.sheetBoundingBox.x2 - this.box.w) % this.snappingGridSize),
                      this.sheetBoundingBox.y - (this.sheetBoundingBox.y % this.snappingGridSize)
                  )
                : new Vector(this.sheetBoundingBox.x2 - this.box.w, this.sheetBoundingBox.y);
        }
        if (this.checkBottomRightConstraint()) {
            return this.isSnappingEnabled
                ? new Vector(
                      this.sheetBoundingBox.x2 - this.box.w - ((this.sheetBoundingBox.x2 - this.box.w) % this.snappingGridSize),
                      this.sheetBoundingBox.y2 - this.box.h - ((this.sheetBoundingBox.y2 - this.box.h) % this.snappingGridSize)
                  )
                : new Vector(this.sheetBoundingBox.x2 - this.box.w, this.sheetBoundingBox.y2 - this.box.h);
        }
    }

    checkIfInsideConstraints(): boolean {
        return this.x >= this.sheetBoundingBox.x &&
            this.x <= this.sheetBoundingBox.x2 - this.box.w &&
            this.y >= this.sheetBoundingBox.y &&
            this.y <= this.sheetBoundingBox.y2 - this.box.h
            ? true
            : false;
    }

    checkLeftConstraint(): boolean {
        return this.x <= this.sheetBoundingBox.x && this.y >= this.sheetBoundingBox.y && this.y <= this.sheetBoundingBox.y2 - this.box.h
            ? true
            : false;
    }

    checkRightConstraint(): boolean {
        return this.x >= this.sheetBoundingBox.x2 - this.box.w &&
            this.y >= this.sheetBoundingBox.y &&
            this.y <= this.sheetBoundingBox.y2 - this.box.h
            ? true
            : false;
    }

    checkTopConstraint(): boolean {
        return this.x >= this.sheetBoundingBox.x && this.x <= this.sheetBoundingBox.x2 - this.box.w && this.y <= this.sheetBoundingBox.y
            ? true
            : false;
    }

    checkBottomConstraint(): boolean {
        return this.x >= this.sheetBoundingBox.x &&
            this.x <= this.sheetBoundingBox.x2 - this.box.w &&
            this.y >= this.sheetBoundingBox.y2 - this.box.h
            ? true
            : false;
    }

    checkTopLeftConstraint(): boolean {
        return this.x < this.sheetBoundingBox.x && this.y < this.sheetBoundingBox.y ? true : false;
    }

    checkBottomLeftConstraint(): boolean {
        return this.x < this.sheetBoundingBox.x && this.y > this.sheetBoundingBox.y2 - this.box.h ? true : false;
    }

    checkTopRightConstraint(): boolean {
        return this.x > this.sheetBoundingBox.x2 - this.box.w && this.y < this.sheetBoundingBox.y ? true : false;
    }

    checkBottomRightConstraint(): boolean {
        return this.x > this.sheetBoundingBox.x2 - this.box.w && this.y > this.sheetBoundingBox.y2 - this.box.h ? true : false;
    }
}
