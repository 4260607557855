import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '@harmanpa/ng-patchwork';
import {WorkshopService} from '@services/workshop.service';
import {BitInstance, DefaultService, DocumentSummary, MachineInstance, VersionedWorkshop} from 'generated-src';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PromptDialogComponent} from 'src/app/shared/components/prompt-dialog/prompt-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class NamingService {
    private ref: DynamicDialogRef;
    machine: DocumentSummary;
    bit: DocumentSummary;
    postpo: DocumentSummary;

    constructor(
        private alertService: AlertService,
        private api: DefaultService,
        private dialogService: DialogService,
        private router: Router,
        private workshopService: WorkshopService
    ) {}

    setMachine(machine: DocumentSummary): void {
        this.machine = machine;
    }

    setBit(bit: DocumentSummary): void {
        this.bit = bit;
    }

    setPostProcessor(postpo: DocumentSummary): void {
        this.postpo = postpo;
    }

    copyMachine(): void {
        this.api.findMachines(true, false, true, false, false, false, false).subscribe((machines) => {
            this.ref = this.dialogService.open(PromptDialogComponent, {
                header: 'Configure machine',
                width: '50%',
                contentStyle: {overflow: 'visible'},
                data: {
                    type: 'machine',
                    currentName: this.machine.data.name,
                    existingNames: machines.map((element) => element.data.name)
                }
            });

            this.ref.onClose.subscribe((machineName: string | undefined) => {
                if (machineName) {
                    const machineCopy = JSON.parse(JSON.stringify(this.machine));
                    this.api.getMe().subscribe((user) => {
                        machineCopy.owner = user.email;
                        machineCopy.data.name = machineName;
                        this.api.addMachine(machineCopy.data, this.machine.id, this.machine.head).subscribe({
                            next: (m) => {
                                // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                                this.router.navigate(['/workshop/machines/', m.id], {
                                    queryParams: {version: m.version},
                                    state: {source: 'Workshop'}
                                });
                            },
                            error: (err) => {
                                this.alertService.error(err);
                            },
                            complete: () => {
                                this.alertService.success('Machine ' + machineCopy.data.name + ' was added to your ToolBox');
                            }
                        });
                    });
                }
            });
        });
    }

    copyBit(): void {
        this.api.findBits(true, false, true, false, false, false, false).subscribe((bits) => {
            this.ref = this.dialogService.open(PromptDialogComponent, {
                header: 'Configure bit',
                width: '50%',
                contentStyle: {overflow: 'visible'},
                data: {
                    type: 'bit',
                    currentName: this.bit.data.name,
                    existingNames: bits.map((element) => element.data.name)
                }
            });

            this.ref.onClose.subscribe((bitName: string | undefined) => {
                if (bitName) {
                    const bitCopy = JSON.parse(JSON.stringify(this.bit));
                    this.api.getMe().subscribe((user) => {
                        bitCopy.owner = user.email;
                        bitCopy.data.name = bitName;
                        this.api.addBit(bitCopy.data, this.bit.id, this.bit.head).subscribe({
                            next: (b) => {
                                // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                                this.router.navigate(['/workshop/bits/', b.id], {
                                    queryParams: {version: b.version},
                                    state: {source: 'Workshop'}
                                });
                            },
                            error: (err) => {
                                this.alertService.error(err);
                            },
                            complete: () => {
                                this.alertService.success('Bit ' + bitCopy.data.name + ' was added to your Workshop');
                            }
                        });
                    });
                }
            });
        });
    }

    copyPostprocessor(): void {
        this.api.findPostProcessors(true, false, true, false, false, false, false).subscribe((postpos) => {
            this.ref = this.dialogService.open(PromptDialogComponent, {
                header: 'Configure post processor',
                width: '50%',
                contentStyle: {overflow: 'visible'},
                data: {
                    type: 'post processor',
                    currentName: this.postpo.data.name,
                    existingNames: postpos.map((element) => element.data.name)
                }
            });

            this.ref.onClose.subscribe((postpoName: string | undefined) => {
                if (postpoName) {
                    const postpoCopy = JSON.parse(JSON.stringify(this.postpo));
                    this.api.getMe().subscribe((user) => {
                        postpoCopy.owner = user.email;
                        postpoCopy.data.name = postpoName;
                        this.api.addPostProcessor(postpoCopy.data, this.postpo.id, this.postpo.head).subscribe({
                            next: (p) => {
                                // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                                this.router.navigate(['/workshop/post/', p.id], {
                                    queryParams: {version: p.version},
                                    state: {source: 'Workshop'}
                                });
                            },
                            error: (err) => {
                                this.alertService.error(err);
                            },
                            complete: () => {
                                this.alertService.success('Post Processor ' + postpoCopy.data.name + ' was added to your Workshop');
                            }
                        });
                    });
                }
            });
        });
    }

    addUserWorkshopMachine(workshop: VersionedWorkshop, bits: BitInstance[]): VersionedWorkshop {
        this.api.getWorkshopMachineInstances(workshop.id, workshop.version).subscribe((userMachines) => {
            this.ref = this.dialogService.open(PromptDialogComponent, {
                header: 'Configure user machine',
                width: '50%',
                contentStyle: {overflow: 'visible'},
                data: {
                    type: 'user machine',
                    currentName: this.machine.data.name,
                    existingNames: Object.values(userMachines.document).map((element) => element.name)
                }
            });

            this.ref.onClose.subscribe((userMachineName: string | undefined) => {
                if (userMachineName) {
                    const machineInstance: MachineInstance = {
                        name: userMachineName,
                        machine: {
                            type: this.machine.type,
                            id: this.machine.id,
                            version: this.machine.head
                        },
                        toolTable: {bits: bits}
                    };
                    this.workshopService.addMachine(workshop, machineInstance).subscribe({
                        next: (w) => {
                            workshop.version = w.version;
                        },
                        error: (err) => {
                            this.alertService.error(err);
                        },
                        complete: () => {
                            this.alertService.success(`Machine ${machineInstance.name} added to your Workshop`);
                            this.router.navigate(['/workshop', workshop.id], {queryParams: {version: workshop.version}});
                            return workshop;
                        }
                    });
                }
            });
        });

        return workshop;
    }
}
