/**
 * Fabber API
 * CAD to CNC in seconds
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PointXY } from './pointXY';
import { ToolpathPathSegment } from './toolpathPathSegment';

export interface ToolpathFeature { 
    featureType?: ToolpathFeature.FeatureTypeEnum;
    part?: string;
    segments?: Array<ToolpathPathSegment>;
    start?: PointXY;
    end?: PointXY;
}
export namespace ToolpathFeature {
    export type FeatureTypeEnum = 'Drill' | 'Pocket' | 'Surface' | 'Hole' | 'Outline' | 'Jog';
    export const FeatureTypeEnum = {
        Drill: 'Drill' as FeatureTypeEnum,
        Pocket: 'Pocket' as FeatureTypeEnum,
        Surface: 'Surface' as FeatureTypeEnum,
        Hole: 'Hole' as FeatureTypeEnum,
        Outline: 'Outline' as FeatureTypeEnum,
        Jog: 'Jog' as FeatureTypeEnum
    };
}
