<div class="p-d-flex p-flex-column">
    <label class="p-mb-2">Corners</label>
    <div class="p-mb-2">
        <p-selectButton [options]="corner" [(ngModel)]="selectedCorner" disabled optionLabel="name"> </p-selectButton>
    </div>
</div>
<div class="p-d-flex p-flex-column">
    <label class="p-mb-2">Hold Down</label>
    <div class="p-mb-2">
        <p-selectButton [options]="holdDown" [(ngModel)]="selectedHoldDown" disabled optionLabel="name"> </p-selectButton>
    </div>
</div>
<div class="p-d-flex p-flex-column">
    <!-- <fab-select-button [label]="'Zero strategy'" [field]="['zeroStrategy']"
                    [disabled]="editable" [options]="[{name: 'Material Top',value: 'MaterialTop'},{name:'Table',value:'Table'}]"
                    [selectedVar]="machine.toolpathStrategy" [optionName]="'name'" [id]="'zeroStrategy'"></fab-select-button>  -->
    <label class="p-mb-2">Zeroing</label>
    <div class="p-mb-2">
        <p-selectButton [options]="zeroing" [(ngModel)]="selectedZero" disabled optionLabel="name"> </p-selectButton>
    </div>
</div>
