import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {filter, first, mergeMap} from 'rxjs/operators';
import {Observable, of, Subject, throwError, timer} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class OnshapeService {

    private messageStream = new Subject<object>();
    private contextStream = new Subject<OnshapeContext>();
    private context: OnshapeContext;

// https://dev-portal.onshape.com/doc/clientmessaging.html
    constructor(private router: Router, private route: ActivatedRoute) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event) => {
                let r = this.route;
                while (r.firstChild) {
                    r = r.firstChild;
                }
                this.init(r.snapshot.params, r.snapshot.queryParams);
            });
    }

    private init(params: Params, queryParams: Params): void {
        const context: OnshapeContext = Object.assign({}, params, queryParams) as OnshapeContext;
        Object.keys(context).forEach(key => {
            if (context[key] && context[key].toString().startsWith('{$')) {
                context[key] = undefined;
            }
        });
        if (context.documentId) {
            window.addEventListener('keydown', (event: KeyboardEvent) => this.handleKeydown(event), false);
            window.addEventListener('mousedown', (event: MouseEvent) => this.postMessage('closeFlyoutsAndMenus'), false);
            window.addEventListener('message', (event: MessageEvent) => this.handleMessage(event), false);
            this.postMessage('applicationInit', {notifyWhenSaveRequired: true});
            this.context = context;
            this.contextStream.next(context);
            timer(10000, 10000).subscribe(() => this.postMessage('keepAlive'));
        }
    }

    public onInit(): Observable<OnshapeContext> {
        if (this.context) {
            return of(this.context);
        }
        return this.contextStream;
    }

    private handleKeydown(event: KeyboardEvent): void {
        this.postMessage('closeFlyoutsAndMenus');
        // Shift-S
        if (event.shiftKey && event.key === 's') {
            this.postMessage('saveAVersion');
        }
        // Shift-?
        if (event.shiftKey && event.key === '?') {
            this.postMessage('showKeyboardShortcutsHelp');
        }
    }

    private handleMessage(event: MessageEvent): void {
        if (event.data && event.data.messageName) {
            this.messageStream.next(event.data);
        }
    }

    private listenForType(type: string): Observable<object> {
        return this.messageStream.pipe(filter(message => type === message['messageName']));
    }

    private listenForTypes(types: string[]): Observable<object> {
        return this.messageStream.pipe(filter(message => types.includes(message['messageName'])));
    }

    private listenFor<T>(listeners: { [key: string]: (data: object) => Observable<T> }): Observable<T> {
        return this.listenForTypes(Object.keys(listeners)).pipe(mergeMap(message => listeners[message['messageName']](message)));
    }

    private listenForFirst<T>(listeners: { [key: string]: (data: object) => Observable<T> }): Observable<T> {
        return this.listenFor(listeners).pipe(first());
    }

    protected postMessage(type: string, data: object = {}): void {
        window.parent.postMessage(Object.assign({messageName: type}, data), '*');
    }

    public showMessageBubble(message: string = ''): void {
        this.postMessage('showMessageBubble', {message: message});
    }

    public showLoadingSpinner(message: string = ''): () => void {
        this.postMessage('startLoadingSpinner', {message: message});
        return () => this.postMessage('stopLoadingSpinner');
    }

    public showWorkingSpinner(): () => void {
        this.postMessage('startWorkingSpinner');
        return () => this.postMessage('stopWorkingSpinner');
    }

    public connectionLost(): void {
        this.postMessage('connectionLost');
    }

    public errorReload(message: string = ''): void {
        this.postMessage('errorReload', {message: message});
    }

    public selectItem(dialogTitle = '', selectBlobs = false,
                      selectParts = false, selectPartStudios = false,
                      selectAssemblies = false,
                      selectMultiple = false, selectBlobMimeTypes = '',
                      showBrowseDocuments = true, showStandardContent = false): Observable<SelectedItem> {
        this.postMessage('openSelectItemDialog', {
            dialogTitle, selectBlobs,
            selectParts, selectPartStudios,
            selectAssemblies,
            selectMultiple, selectBlobMimeTypes,
            showBrowseDocuments, showStandardContent
        });
        return this.listenForFirst({
            'selectItemDialogClosed': () => throwError('Dialog closed'),
            'itemSelectedInSelectItemDialog': (message) => of(message as SelectedItem)
        });
    }

    public requestCameraProperties(graphicsElementId: string): Observable<CameraProperties> {
        this.postMessage('requestCameraProperties', {graphicsElementId});
        return this.listenForFirst({
            'cameraProperties': (message) => of(message as CameraProperties)
        });
    }

    public registerSaveHandler(handler: () => void): void {
        this.listenForType('saveChanges').subscribe(message => {
            handler();
            this.postMessage('finishedSaving', {messageId: message['messageId']});
        });
    }

}

export interface SelectedItem {
    documentId?: string;
    workspaceId?: string;
    versionId?: string;
    elementId?: string;
    elementName?: string;
    elementType?: 'partstudio' | 'assembly' | 'blob';
    elementMicroversionId?: string;
    itemType?: 'part' | 'partStudio' | 'assembly';
    partName?: string;
    idTag?: string;
}

export interface CameraProperties {
    graphicsElementId: string;
    isValid: boolean;
    projectionType: 'orthographic' | 'perspective' | '';
    viewMatrix: number[];
    verticalFieldOfView: number;
    viewportHeight: number;
    viewportWidth: number;
}

export interface OnshapeContext {
    documentId?: string;
    workspaceOrVersion?: 'w' | 'v';
    workspaceOrVersionId?: string;
    elementId?: string;
    partId?: string;
    partNumber?: string;
    revision?: string;
    featureId?: string;
    nodeId?: string;
    locale?: string;
    userId?: string;
    clientId?: string;
    companyId?: string;
    server?: string;
    configuration?: string;
}
