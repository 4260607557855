import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BitMaterial} from 'generated-src';

@Component({
    selector: 'fab-material-editor',
    templateUrl: './material-editor.component.html',
    styleUrls: ['./material-editor.component.scss']
})
export class MaterialEditorComponent {
    @Input() bitMaterial: BitMaterial;
    @Input() disabled: boolean;
    @Input() isAdminDashboard: boolean;
    @Output() bitMaterialChange = new EventEmitter<BitMaterial>();
    @Output() removeMaterial = new EventEmitter<void>();

    onChange() {
        this.bitMaterialChange.emit(Object.assign({}, this.bitMaterial));
    }

    onRemoveMaterial() {
        this.removeMaterial.emit();
    }
}
