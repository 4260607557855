<section class="p-grid p-d-flex">
    <div class="p-d-column-flex toolbar">
        <button
            pButton
            pTooltip="Go to Parts"
            tooltipPosition="top"
            *ngIf="!onshapeExtension"
            class="p-button-raised z p-m-5 p-mr-2 p-mr-auto"
            label="Parts List"
            icon="pi pi-arrow-left"
            [routerLink]="['/projects', project.id]"
            [queryParams]="{version: project.version}"
        ></button>
        <button
            pButton
            pTooltip="Go to Parts"
            tooltipPosition="top"
            *ngIf="onshapeExtension"
            class="p-button-raised z p-m-5 p-mr-2 p-mr-auto"
            label="Parts List"
            icon="pi pi-arrow-left"
            (click)="visibleChange.emit(false)"
        ></button>
        <button
            pButton
            pTooltip="Sheets Settings"
            tooltipPosition="top"
            *ngIf="onshapeExtension"
            class="p-button-raised z p-m-5 p-mr-2 p-mr-auto"
            icon="pi pi-cog"
            (click)="displayDialog = !displayDialog"
        ></button>
    </div>
    <div #attachedCoordinates id="attachedCoordinates">
        <span *ngIf="enableCoordinates()" class="coordinates">
            x: {{ mousePosition ? mousePosition.x : 0 }} y: {{ mousePosition ? mousePosition.y : 0 }}
        </span>
    </div>
    <div #viewerDiv [ngClass]="onshapeExtension ? 'onshapeSvgViewer' : 'fabberSvgViewer'"></div>
    <fab-sheets-control-buttons class="p-d-flex p-m-5"></fab-sheets-control-buttons>
    <p-sidebar *ngIf="onshapeExtension" position="right" [(visible)]="displayDialog">
        <fab-sheets-options [project]="project"></fab-sheets-options>
    </p-sidebar>

    <div #rightPanel *ngIf="!onshapeExtension" class="p-col-3 fabber-sidebar">
        <fab-sheets-options [project]="project"></fab-sheets-options>
    </div>
</section>
<section *ngIf="loadingScreen" class="loading-screen">
    <div class="loading-icon-container">
        <object id="svg1" data="/assets/waiting.svg" type="image/svg+xml"></object>
        <h3 class="p-pt-2">Wait while we process your nesting.</h3>
        <h3>Drawing {{ status }}...</h3>
    </div>
</section>
