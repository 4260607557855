<div class="p-grid" *ngIf="cuttingList">
    <ng-container *ngFor="let group of cuttingList.groups">
        <ng-container *ngFor="let instance of group.instances">
            <div class="p-col-6">
                <img src="/api/debug/project/{{project.id}}/v/{{project.version}}/part/{{instance.partId}}/layers.svg" style="width: 500px;">
            </div>
            <div class="p-col-6">
                <img src="/api/debug/project/{{project.id}}/v/{{project.version}}/part/{{instance.partId}}/slice.svg" style="width: 500px;">
            </div>
        </ng-container>
    </ng-container>
</div>
