<section class="card">
    <h4 class="p-p-4">Post-Processor</h4>
    <div class="p-grid p-fluid p-pt-3 p-nogutter">
        <div class="p-field p-col-4 p-offset-1">
            <label class="p-mb-2">Post-processor Format</label>
            <pwk-typed-selector
                type="PostProcessor"
                [reference]="postProcessStrategy.postProcessor"
                (referenceChange)="onPostSelect($event)"
                [disabled]="disabled"
                [includePublic]="true"
            ></pwk-typed-selector>
            <!--            <p-dropdown-->
            <!--                    class="p-mr-3"-->
            <!--                    [options]="postProcessorChoices"-->
            <!--                    [disabled]="disabled"-->
            <!--                    optionValue="reference"-->
            <!--                    optionLabel="name"-->
            <!--                    placeholder="Select postprocessor"-->
            <!--                    [(ngModel)]="postProcessStrategy.postProcessor"-->
            <!--                    (ngModelChange)="onPostSelect()"-->
            <!--            ></p-dropdown>-->
        </div>
        <div class="p-col-4 p-offset-2 p-grid p-nogutter">
            <pwk-selected-units
                *ngIf="postProcessor?.document?.availableUnits && postProcessStrategy?.selectedUnits"
                [availableUnits]="postProcessor.document.availableUnits"
                [(selectedUnits)]="postProcessStrategy.selectedUnits"
                [disabled]="disabled"
                style="width: 100%"
            >
            </pwk-selected-units>
        </div>
    </div>
</section>
