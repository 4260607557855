import {Injectable} from '@angular/core';
import {UnitService} from '@harmanpa/ng-patchwork';
import {G} from '@svgdotjs/svg.js';
import {
    JogHome,
    JogStart,
    Toolpath,
    ToolpathFeature,
    ToolpathGroup,
    ToolpathPathSegment,
    XYArcCut,
    XYCut,
    XYJog,
    ZCut,
    ZJog
} from 'generated-src';
import {DrawArcCutSegmentOptions} from '../models/draw-arccut-segment-options.model';
import {DrawSegmentOptions} from '../models/draw-segment-options.model';

@Injectable({
    providedIn: 'root'
})
export class DrawToolpathService {
    constructor(private unitService: UnitService) {
    }

    drawToolpath(group: G, toolpath: Toolpath): void {
        toolpath.groups.forEach((toolpathGroup) =>
            this.drawToolpathGroup(group, toolpathGroup)
        );
    }

    drawToolpathGroup(group: G, toolpathGroup: ToolpathGroup): void {
        toolpathGroup.features.forEach((feature) =>
            this.drawToolpathFeature(group, feature)
        );
    }

    drawToolpathFeature(group: G, feature: ToolpathFeature): void {
        let xy = [
            this.unitService.toValue(feature.start.x, 'mm'),
            this.unitService.toValue(feature.start.y, 'mm')
        ];
        const color: string = this.selectFeatureType(feature.featureType);

        this.drawMarker(group, xy);
        feature.segments.forEach((segment) => {
            xy = this.drawToolpathSegment(group, xy, segment, color);
        });
    }

    private selectFeatureType(type: ToolpathFeature.FeatureTypeEnum): string {
        switch (type) {
            case 'Drill':
                return '#F0FF00'; // yellow
            case 'Hole':
                return '#005FFF'; // blue
            case 'Pocket':
                return '#00BE0C'; // green
            case 'Outline':
                return '#FF05BF'; // purple
            default:
                return '#05ffcd';
        }
    }

    drawMarker(group: G, xy: number[]): void {
        group
            .circle(4)
            .move(xy[0] - 2, xy[1] - 2)
            .attr({fill: '#ff0000'});
    }

    drawToolpathSegment(
        group: G,
        xy: number[],
        segment: ToolpathPathSegment,
        color_str: string
    ): number[] {
        const options: DrawSegmentOptions = {
            group: group,
            xy: xy,
            segment: segment,
            color_str: color_str
        };
        options.segment = this.selectSegmentType(segment);
        if (segment.type === 'xyArcCut') {
            return this.drawXYArcCutSegment(options);
        } else {
            return this.drawSegment(options);
        }
    }

    private selectSegmentType(
        segment: ToolpathPathSegment
    ): XYJog | JogStart | JogHome | XYCut | XYArcCut | ZCut | ZJog {
        switch (segment.type) {
            case 'xyJog':
                return segment as XYJog;
            case 'JogStart':
                return segment as JogStart;
            case 'JogHome':
                return segment as JogHome;
            case 'xyCut':
                return segment as XYCut;
            case 'xyArcCut':
                return segment as XYArcCut;
            case 'zCut':
                return segment as ZCut;
            case 'zJog':
                return segment as ZJog;
        }
        throw new Error('Unknown segment type ' + segment.type);
    }

    private prepareCoords(
        xy: number[],
        segment: XYJog | JogStart | JogHome | XYCut | XYArcCut
    ): { startxy: number[]; endxy: number[] } {
        const startxy = [xy[0].valueOf(), xy[1].valueOf()];
        const endxy = new Array<number>(2);
        endxy[0] = this.unitService.toValue(segment.x, 'mm');
        endxy[1] = this.unitService.toValue(segment.y, 'mm');
        // startxy[0] = endxy[0] - (segment.dx ? this.unitService.toValue(segment.dx, 'mm') : 0);
        // startxy[1] = endxy[1] - (segment.dy ? this.unitService.toValue(segment.dy, 'mm') : 0);
        return {startxy, endxy};
    }

    private drawSegment(options: DrawSegmentOptions): number[] {
        const coords = this.prepareCoords(options.xy, options.segment);
        if (options.segment.type === 'xyCut') {
            options.group
                .line(
                    coords.startxy[0],
                    coords.startxy[1],
                    coords.endxy[0],
                    coords.endxy[1]
                )
                .stroke({width: 0.5, color: options.color_str});
        } else {
            options.group
                .line(
                    options.xy[0],
                    options.xy[1],
                    coords.endxy[0],
                    coords.endxy[1]
                )
                .stroke({width: 0.5, color: options.color_str});
        }
        return coords.endxy;
    }

    private drawXYArcCutSegment(options: DrawArcCutSegmentOptions): number[] {
        const coords = this.prepareCoords(options.xy, options.segment);
        const centre = [
            this.unitService.toValue(options.segment.xc, 'mm'),
            this.unitService.toValue(options.segment.yc, 'mm')
        ];
        const radius = Math.sqrt(
            Math.pow(centre[0] - coords.endxy[0], 2) +
            Math.pow(centre[1] - coords.endxy[1], 2)
        );
        options.group
            .path(
                'M ' +
                coords.startxy[0] +
                ' ' +
                coords.startxy[1] +
                ' A ' +
                [
                    radius,
                    radius,
                    0.0,
                    this.largeArc(
                        options.xy,
                        centre,
                        coords.endxy,
                        options.segment.clockwise
                    )
                        ? 1
                        : 0,
                    options.segment.clockwise ? 0 : 1,
                    coords.endxy[0],
                    coords.endxy[1]
                ].join(' ')
            )
            .attr({
                fill: null,
                'fill-opacity': 0,
                'stroke-width': 0.5,
                stroke: options.color_str
            });
        return coords.endxy;
    }

    largeArc(
        start: number[],
        centre: number[],
        end: number[],
        clockwise: boolean
    ): boolean {
        // If centre is on clockwise (right) side, it is !clockwise, else clockwise
        const centreIsClockwise =
            0 <
            (centre[0] - start[0]) * (end[1] - start[1]) -
            (centre[1] - start[1]) * (end[0] - start[0]);
        return centreIsClockwise !== clockwise;
    }
}
