import {Injectable} from '@angular/core';
import {UnitService} from '@harmanpa/ng-patchwork';
import {Color} from '../color';
import {Box, Circle, Ellipse, G, Matrix, Path, PathArray, PathArrayAlias, Point} from '@svgdotjs/svg.js';
import {CirclePath, EllipsePath, LineArcPath, Part, Placement, Slice} from 'generated-src';
import {BehaviorSubject, Observable} from 'rxjs';
import {DrawPathOptions} from '../models/draw-path-options.model';
import {DrawSliceFeaturesOptions} from '../models/draw-slice-features-options.model';
import '@svgdotjs/svg.draggable.js';
import {ConstraintsService} from './constraints.service';
import {Vector} from 'sat';

@Injectable({
    providedIn: 'root'
})
export class DrawPartsService {
    partMap = new Map<string, G>();
    partCentres = new Map<string, number[]>();
    partList: {group: G; path: Path | Circle | Ellipse}[] = [];
    // sheetBoundingBox: Box;
    partsBoundingBoxes: Box[] = [];
    currentPart: Observable<Part>;
    currentPartId: string;
    currentPartInstance: string;
    selected = new BehaviorSubject<boolean>(false);
    isEditingEnabled = false;
    isSavingEnabled = true;

    rotator: (angle: number) => void;
    updateToolpath: (part: Placement, transformer: (part: Placement) => Placement, toolpathGroup: G) => void;

    constructor(private unitService: UnitService, private constraintsService: ConstraintsService) {}

    drawPart(
        group: G,
        part: Placement,
        slice: Slice,
        toolpathGroup: G,
        updateToolpath: (part: Placement, transformer: (part: Placement) => Placement, toolpathGroup: G) => void
    ) {
        this.updateToolpath = updateToolpath;
        console.log('Drawing', part);
        const partGroup = group.group();
        const xyOriginal = part.translation;
        const matrix: Matrix = this.matrix(part);
        const outerPath = this.drawPath(partGroup, slice.outer, matrix, 'outer', 0);

        this.drawSliceFeatures(partGroup, slice, matrix);
        this.partMap.set(part.instance, partGroup);
        this.partList.push({group: partGroup, path: outerPath});
        this.partCentres.set(part.instance, [partGroup.cx(), partGroup.cy()]);
        this.handlePartGroupEvents(group, partGroup, toolpathGroup, outerPath, part);
        this.partsBoundingBoxes.push(partGroup.bbox());
        // console.log(this.sheetBoundingBox);
    }

    matrix(part: Placement): Matrix {
        return new Matrix(
            part.rotation[0],
            part.rotation[1],
            -part.rotation[1],
            part.rotation[0],
            part.translation[0],
            part.translation[1]
        );
    }

    drawPath(
        group: G,
        path: LineArcPath | CirclePath | EllipsePath,
        matrix: Matrix,
        type: string,
        cutDepth: number
    ): Path | Circle | Ellipse {
        const topColor = new Color('#d6c7b9');
        const deepPocketColor = new Color('#5c4f45');
        const holeColor = new Color('#dde5e9');
        const outerLineColor = new Color('#b1a08b');
        const innerLineColor = new Color('#68594d');
        const fillColor = type === 'hole' ? holeColor : topColor.interpolate(deepPocketColor, cutDepth);
        const lineColor = new Color('#68594d'); // type === 'pocket' ? innerLineColor : outerLineColor;
        const factor = this.scaling(path.units);
        const options: DrawPathOptions = {
            group: group,
            path: path,
            matrix: matrix,
            scale: factor,
            lineColor: lineColor,
            fillColor: fillColor
        };
        return this.drawSelectedPath(options);
        //     return null;
    }

    private drawSelectedPath(options: DrawPathOptions): Path | Circle | Ellipse {
        switch (options.path.type) {
            case 'linearc':
                options.path = options.path as LineArcPath;
                return this.drawLineArcPath(options);
            case 'circle':
                options.path = options.path as CirclePath;
                return this.drawCirclePath(options);
            case 'ellipse':
                options.path = options.path as EllipsePath;
                return this.drawEllipsePath(options);
        }
    }

    private drawLineArcPath(options: DrawPathOptions): Path {
        const lineArcPath = options.path as LineArcPath;
        return options.group
            .path(this.scalePath(lineArcPath.path, options.scale))
            .attr({fill: options.fillColor.toHex()})
            .stroke({width: 0.5, color: options.lineColor.toHex()})
            .transform(options.matrix);
    }

    private scalePath(path: PathArrayAlias, factor: number): PathArrayAlias {
        return new PathArray()
            .parse(path)
            .map((pc) => this.scalePathCommand(pc, factor))
            .reduce((p, c) => p.concat(c));
    }

    private scalePathCommand(command: (string | number)[], factor: number): (string | number)[] {
        const isArc = command[0] === 'a' || command[0] === 'A';
        return command.map((value, index) =>
            typeof value === 'number' ? (isArc && (index === 3 || index === 4 || index === 5) ? value : value * factor) : value
        );
    }

    private drawCirclePath(options: DrawPathOptions): Circle {
        const circlePath = options.path as CirclePath;
        return options.group
            .circle(circlePath.r * 2)
            .move(circlePath.x * options.scale - circlePath.r * options.scale, circlePath.y * options.scale - circlePath.r * options.scale)
            .attr({fill: options.fillColor.toHex()})
            .stroke({width: 0.5, color: options.lineColor.toHex()})
            .transform(options.matrix);
    }

    private drawEllipsePath(options: DrawPathOptions): Ellipse {
        const ellipsePath = options.path as EllipsePath;
        return options.group
            .ellipse(ellipsePath.rx * 2 * options.scale, ellipsePath.ry * 2 * options.scale)
            .move(
                ellipsePath.x * options.scale - ellipsePath.rx * options.scale,
                ellipsePath.y * options.scale - ellipsePath.ry * options.scale
            )
            .rotate(ellipsePath.rotation)
            .attr({fill: options.fillColor.toHex()})
            .stroke({width: 0.5, color: options.lineColor.toHex()})
            .transform(options.matrix);
    }

    private drawSliceFeatures(partGroup: G, slice: Slice, matrix: Matrix) {
        const depth = this.unitService.toValue(slice.thickness, 'mm');
        slice.features
            .sort((a, b) => this.unitService.toValue(a.depth, 'mm') - this.unitService.toValue(b.depth, 'mm'))
            .forEach((feature) => {
                this.drawHoles({feature, depth, partGroup, matrix});
                feature.innerPaths.forEach((island) => {
                    this.drawIslands({
                        feature,
                        depth,
                        partGroup,
                        matrix,
                        island
                    });
                });
            });
    }

    private drawHoles(options: DrawSliceFeaturesOptions) {
        const cutDepth = options.feature.hole ? 1 : this.unitService.toValue(options.feature.depth, 'mm') / options.depth;
        console.log('Drawing feature at depth of ' + cutDepth);
        this.drawPath(options.partGroup, options.feature.path, options.matrix, options.feature.hole ? 'hole' : 'pocket', cutDepth);
    }

    private drawIslands(options: DrawSliceFeaturesOptions) {
        const islandDepth = this.unitService.toValue(options.feature.startDepth, 'mm') / options.depth;
        console.log('Drawing island at depth of ' + islandDepth);
        console.log(options.island);
        this.drawPath(options.partGroup, options.island, options.matrix, 'top', islandDepth);
    }

    applyMatrix(part: Placement, matrix: Matrix): Placement {
        console.log('applyMatrix:', matrix);
        console.log('part matrix: ', this.matrix(part));
        const resultant = this.matrix(part).transform(matrix);
        console.log(resultant);
        const startXY = new Point(part.startX, part.startY).transform(matrix);
        // const startXY = new Point(part.startX, part.startY);
        console.log(part.startX, part.startY);
        return {
            part: part.part,
            instance: part.instance,
            translation: [resultant.e, resultant.f],
            rotation: [resultant.a, resultant.b],
            startX: startXY.x,
            startY: startXY.y
        };
    }

    private handlePartGroupEvents(group: G, partGroup: G, toolpathGroup: G, outerPath: Path | Circle | Ellipse, part: Placement) {
        this.constraintsService.partsStartingCoordinates.push(new Vector(partGroup.bbox().cx, partGroup.bbox().cy));
        // console.log(this.constraintsService.partsStartingCoordinates);

        partGroup.on('mouseover', (ev) => {
            // outerPath.stroke({width: 5});
            outerPath.css('cursor', 'pointer');
        });
        partGroup.on('mouseout', (ev) => {
            outerPath.stroke({width: 0.5});
        });
        partGroup.on('dragstart', (e1) => {
            // TODO: Limit to within sheet bounds
            // gets canvas dimensions which can be used for constrains when grabbing parts
            e1.preventDefault();
            this.handlePartSelection(toolpathGroup, partGroup, outerPath, part, true);
            outerPath.css('cursor', 'grabbing');
            partGroup.off('dragend');

            this.constraintsService.partsVertices = [];
            this.constraintsService.partsPolygons = [];

            this.partList.forEach((part) => {
                this.constraintsService.partsVertices.push(this.constraintsService.getPartVerticesPosition(part.path));
            });
            // console.log(this.constraintsService.partsVertices);

            const otherParts = this.partList.filter((v) => v.group !== partGroup);
            this.constraintsService.selectedPartIndex = this.partList.findIndex((v) => v.group === partGroup);

            this.partList.forEach((part, index) => {
                if (index !== this.constraintsService.selectedPartIndex) {
                    this.constraintsService.partsPolygons.push(
                        this.constraintsService.calculatePartPolygon(part, this.constraintsService.partsVertices[index])
                    );
                }
            });

            partGroup.on('dragmove', (me1) => {
                me1.preventDefault();
                const collidingParts = this.constraintsService.init(
                    this.partList[this.constraintsService.selectedPartIndex],
                    me1,
                    otherParts.map((e) => e.group.bbox())
                );
                let collides = false;
                otherParts.forEach((part, index) => {
                    if (collidingParts.includes(index)) {
                        collides = true;
                        part.path.attr({fill: '#eb6952'});
                    } else {
                        part.path.attr({fill: '#D6C7B9'});
                    }
                });
                if (collides) {
                    this.isSavingEnabled = false;
                    this.partList[this.constraintsService.selectedPartIndex].path.attr({fill: '#9e493a'});
                } else {
                    this.isSavingEnabled = true;
                    this.partList[this.constraintsService.selectedPartIndex].path.attr({fill: '#9DB2B6'});
                }
            });

            partGroup.on('dragend', (e2) => {
                partGroup.off('dragmove');
                outerPath.css('cursor', 'grab');
                const tx = partGroup.cx() - this.partCentres.get(part.instance)[0];
                const ty = partGroup.cy() - this.partCentres.get(part.instance)[1];
                this.partCentres.set(part.instance, [partGroup.cx(), partGroup.cy()]);
                // Only apply it if an actual change happened
                if (Math.abs(tx) > 0.1 || Math.abs(ty) > 0.1) {
                    this.updatePart(part, (p: Placement) => this.applyMatrix(p, new Matrix().translate(tx, ty)), toolpathGroup);
                }
            });
        });

        partGroup.on('click', (ev) => {
            ev.preventDefault();
            if (!this.isEditingEnabled) {
                this.handlePartSelection(toolpathGroup, partGroup, outerPath, part);
            }
        });
    }

    private handlePartSelection(toolpathGroup: G, partGroup: G, outerPath: Path | Circle | Ellipse, part: Placement, isDragEvent = false) {
        this.setPart(part.part, part.instance);
        this.rotator = (rotateBy: number) => {
            const rotMatrix = new Matrix().rotate(
                -rotateBy,
                this.partCentres.get(part.instance)[0],
                this.partCentres.get(part.instance)[1]
            );
            partGroup.transform(partGroup.matrix().transform(rotMatrix));
            this.partCentres.set(part.instance, [partGroup.cx(), partGroup.cy()]);
            this.updatePart(part, (p: Placement) => this.applyMatrix(p, rotMatrix), toolpathGroup);
        };
        this.toggleSelection(partGroup, outerPath, isDragEvent);
    }

    updatePart(part: Placement, transformer: (part: Placement) => Placement, toolpathGroup: G): void {
        // TODO: Possibly shouldn't clear toolpath until new toolpath ready to draw?
        toolpathGroup.clear();
        this.updateToolpath(part, transformer, toolpathGroup);
    }

    setPart(partId: string, instance: string) {
        this.currentPartId = partId;
        this.currentPartInstance = instance;
    }

    toggleSelection(partGroup: G, outerPath: Path | Circle | Ellipse, isDragEvent: boolean) {
        // console.log('toggle working', partGroup.attr('selected'),
        // partGroup.attr('selected') == 'true', typeof partGroup.attr('selected'));
        if (!partGroup.attr('selected') || partGroup.attr('selected') === 'false') {
            this.partList.forEach((element) => {
                element.group.attr({selected: false});
                element.path.attr({fill: '#D6C7B9'});
                this.selected.next(true);
            });
            // console.log('in1: ', partGroup, partGroup.attr('selected'));
            partGroup.attr({selected: true});
            outerPath.attr({fill: '#9DB2B6'});
            this.selected.next(false);
            // console.log('out1: ', partGroup.attr('selected'));
        } else if ((partGroup.attr('selected') || partGroup.attr('true')) && !isDragEvent) {
            // console.log('in2: ', partGroup, partGroup.attr('selected'));
            partGroup.attr({selected: false});
            outerPath.attr({fill: '#D6C7B9'});
            this.selected.next(true);
            // console.log('out2: ', partGroup.attr('selected'));
        }
    }

    scaling(fromUnits: string): number {
        return this.unitService.convertValue(1, fromUnits, 'mm');
    }
}
