import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProjectsComponent} from './projects/projects.component';
import {ProfileComponent} from './profile/profile.component';
import {LinkComponent} from './integrations/link/link.component';
import {MachinesComponent} from './machines/machines.component';
import {SheetsComponent} from './sheets/sheets.component';
import {PartComponent} from './parts-list/part/part.component';
import {MachineEditorComponent} from './machines/machine-editor/machine-editor.component';
import {TOSComponent} from './layout/header/version/tos/tos.component';
import {AssemblyComponent} from './parts-list/assembly/assembly.component';
import {BitEditorComponent} from './machines/bit-editor/bit-editor.component';
import {WaitingComponent} from './waiting/waiting.component';
import {AppGuard} from './guards/app.guard';
import {PostProcessorEditorComponent} from './machines/post-processor-editor/post-processor-editor.component';
import {WorkshopComponent} from './machines/workshop/workshop.component';
import {UserMachineEditorComponent} from './machines/workshop/user-machine-editor/user-machine-editor.component';
import {AuthGuard, PatchworkResolver, RequiresAccountGuard, UserResolver} from '@harmanpa/ng-patchwork';
import {LayoutComponent} from './layout/layout.component';
import {OnshapeComponent} from './integrations/onshape/onshape.component';
import {OnlyInAppGuard} from './guards/only-in-app.guard';
import {WorkshopService} from '@services/workshop.service';
import {PartsListComponent} from './parts-list/parts-list.component';
import {GeometryDebugComponent} from './admin/geometry-debug/geometry-debug.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'post/:post_id',
                redirectTo: 'library/post/:post_id',
                pathMatch: 'full'
            },
            {
                path: 'bit/:bit_id',
                redirectTo: 'library/bit/:bit_id',
                pathMatch: 'full'
            },
            {
                path: 'machine/:machine_id',
                redirectTo: 'library/machine/:machine_id',
                pathMatch: 'full'
            },
            {
                path: '',
                redirectTo: '/projects',
                pathMatch: 'full'
            },
            {
                path: 'projects',
                children: [
                    {path: '', component: ProjectsComponent, canActivate: [AuthGuard], resolve: {user: UserResolver}},
                    {
                        path: ':project_id',
                        component: PartsListComponent,
                        canActivate: [AppGuard, AuthGuard],
                        data: {type: 'Project'},
                        resolve: {
                            user: UserResolver,
                            project: PatchworkResolver
                        }
                    },
                    {
                        path: ':project_id/sheets',
                        component: SheetsComponent,
                        canActivate: [AppGuard, AuthGuard],
                        data: {type: 'Project'},
                        resolve: {user: UserResolver, project: PatchworkResolver}
                    },
                    {
                        path: ':project_id/view',
                        component: AssemblyComponent,
                        canActivate: [AppGuard, AuthGuard],
                        data: {type: 'Project'},
                        resolve: {user: UserResolver, project: PatchworkResolver}
                    },
                    {
                        path: ':project_id/debug',
                        component: GeometryDebugComponent,
                        canActivate: [AppGuard, AuthGuard],
                        data: {type: 'Project'},
                        resolve: {user: UserResolver, project: PatchworkResolver}
                    },
                    {
                        path: ':project_id/part/:partid',
                        component: PartComponent,
                        canActivate: [AppGuard, AuthGuard],
                        data: {noScroll: true, type: 'Project'},
                        resolve: {user: UserResolver, project: PatchworkResolver}
                    }
                ]
            },
            {
                path: 'library',
                children: [
                    {
                        path: '',
                        redirectTo: 'machines',
                        pathMatch: 'full'
                    },
                    {
                        path: 'machines',
                        component: MachinesComponent,
                        canActivate: [AuthGuard],
                        resolve: {user: UserResolver, workshop: WorkshopService}
                    },
                    {
                        path: 'machines/:machine_id',
                        component: MachineEditorComponent,
                        canActivate: [AuthGuard],
                        data: {type: 'Machine'},
                        resolve: {
                            user: UserResolver,
                            machine: PatchworkResolver,
                            workshop: WorkshopService
                        }
                    },
                    {
                        path: 'post/:postprocessor_id',
                        component: PostProcessorEditorComponent,
                        canActivate: [AuthGuard],
                        data: {type: 'PostProcessor'},
                        resolve: {
                            user: UserResolver,
                            postprocessor: PatchworkResolver,
                            workshop: WorkshopService
                        }
                    },
                    {
                        path: 'bits/:bit_id',
                        component: BitEditorComponent,
                        canActivate: [AuthGuard],
                        data: {type: 'Bit'},
                        resolve: {
                            user: UserResolver,
                            bit: PatchworkResolver,
                            workshop: WorkshopService
                        }
                    }
                ]
            },
            {
                path: 'workshop',
                children: [
                    {
                        path: '',
                        redirectTo: '/workshop/',
                        pathMatch: 'full'
                    },
                    {
                        path: ':workshop_id',
                        component: WorkshopComponent,
                        canActivate: [AuthGuard, WorkshopService],
                        resolve: {user: UserResolver, workshop: WorkshopService}
                    },
                    {
                        path: ':workshop_id/machine/:machineInstanceId',
                        component: UserMachineEditorComponent,
                        canActivate: [AuthGuard],
                        resolve: {user: UserResolver, workshop: WorkshopService}
                    },

                    {
                        path: 'machines/:machine_id',
                        component: MachineEditorComponent,
                        canActivate: [AuthGuard],
                        data: {type: 'Machine'},
                        resolve: {
                            user: UserResolver,
                            machine: PatchworkResolver,
                            workshop: WorkshopService
                        }
                    },
                    {
                        path: 'post/:postprocessor_id',
                        component: PostProcessorEditorComponent,
                        canActivate: [AuthGuard],
                        data: {type: 'PostProcessor'},
                        resolve: {
                            user: UserResolver,
                            postprocessor: PatchworkResolver,
                            workshop: WorkshopService
                        }
                    },
                    {
                        path: 'bits/:bit_id',
                        component: BitEditorComponent,
                        canActivate: [AuthGuard],
                        data: {type: 'Bit'},
                        resolve: {
                            user: UserResolver,
                            bit: PatchworkResolver,
                            workshop: WorkshopService
                        }
                    }
                ]
            },
            {
                path: 'profile',
                component: ProfileComponent,
                canActivate: [AppGuard, AuthGuard],
                resolve: {user: UserResolver}
            },
            {
                path: 'link/:application/:code',
                component: LinkComponent,
                canActivate: [AppGuard, AuthGuard]
            }
        ]
    },
    {
        path: 'o',
        component: LayoutComponent,
        children: [
            {
                path: '',
                component: OnshapeComponent,
                canActivate: [AuthGuard, RequiresAccountGuard],
                data: {provider: 'onshape'}
            }
        ],
        data: {inApp: true}
    },
    {path: 'tos', component: TOSComponent},
    {
        path: 'wait',
        component: WaitingComponent,
        canActivate: [OnlyInAppGuard],
        resolve: {user: UserResolver}
    },
    {path: '**', redirectTo: '/notfound'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    providers: [],
    exports: [RouterModule]
})
export class AppRoutingModule {}
