/**
 * Fabber API
 * CAD to CNC in seconds
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Activity } from '../model/activity';
import { Bit } from '../model/bit';
import { BitMaterial } from '../model/bitMaterial';
import { CuttingList } from '../model/cuttingList';
import { DocumentSummary } from '../model/documentSummary';
import { Machine } from '../model/machine';
import { MachineChoice } from '../model/machineChoice';
import { MachineInstance } from '../model/machineInstance';
import { OnshapeImport } from '../model/onshapeImport';
import { Part } from '../model/part';
import { Patch } from '../model/patch';
import { PostProcessor } from '../model/postProcessor';
import { Project } from '../model/project';
import { Reference } from '../model/reference';
import { Sharing } from '../model/sharing';
import { SheetChoice } from '../model/sheetChoice';
import { SheetSet } from '../model/sheetSet';
import { ToolChoice } from '../model/toolChoice';
import { ToolSelectionConstraints } from '../model/toolSelectionConstraints';
import { User } from '../model/user';
import { VersionGraph } from '../model/versionGraph';
import { VersionLog } from '../model/versionLog';
import { VersionLogVersion } from '../model/versionLogVersion';
import { VersionedArtefact } from '../model/versionedArtefact';
import { VersionedArtefactPatch } from '../model/versionedArtefactPatch';
import { VersionedBit } from '../model/versionedBit';
import { VersionedBitDiff } from '../model/versionedBitDiff';
import { VersionedBitPatch } from '../model/versionedBitPatch';
import { VersionedCuttingList } from '../model/versionedCuttingList';
import { VersionedCuttingListPatch } from '../model/versionedCuttingListPatch';
import { VersionedMachine } from '../model/versionedMachine';
import { VersionedMachineDiff } from '../model/versionedMachineDiff';
import { VersionedMachineInstance } from '../model/versionedMachineInstance';
import { VersionedMachineInstanceMap } from '../model/versionedMachineInstanceMap';
import { VersionedMachineInstanceMapPatch } from '../model/versionedMachineInstanceMapPatch';
import { VersionedMachineInstancePatch } from '../model/versionedMachineInstancePatch';
import { VersionedMachinePatch } from '../model/versionedMachinePatch';
import { VersionedOnshapeImport } from '../model/versionedOnshapeImport';
import { VersionedOnshapeImportDiff } from '../model/versionedOnshapeImportDiff';
import { VersionedOnshapeImportPatch } from '../model/versionedOnshapeImportPatch';
import { VersionedPart } from '../model/versionedPart';
import { VersionedPartMap } from '../model/versionedPartMap';
import { VersionedPartMapPatch } from '../model/versionedPartMapPatch';
import { VersionedPartPatch } from '../model/versionedPartPatch';
import { VersionedPostProcessOutput } from '../model/versionedPostProcessOutput';
import { VersionedPostProcessor } from '../model/versionedPostProcessor';
import { VersionedPostProcessorDiff } from '../model/versionedPostProcessorDiff';
import { VersionedPostProcessorPatch } from '../model/versionedPostProcessorPatch';
import { VersionedProject } from '../model/versionedProject';
import { VersionedProjectDiff } from '../model/versionedProjectDiff';
import { VersionedProjectPatch } from '../model/versionedProjectPatch';
import { VersionedReference } from '../model/versionedReference';
import { VersionedSheetSet } from '../model/versionedSheetSet';
import { VersionedSheetSetPatch } from '../model/versionedSheetSetPatch';
import { VersionedSlice } from '../model/versionedSlice';
import { VersionedToolSelectionConstraints } from '../model/versionedToolSelectionConstraints';
import { VersionedToolSelectionConstraintsPatch } from '../model/versionedToolSelectionConstraintsPatch';
import { VersionedToolpath } from '../model/versionedToolpath';
import { VersionedWorkshop } from '../model/versionedWorkshop';
import { VersionedWorkshopDiff } from '../model/versionedWorkshopDiff';
import { VersionedWorkshopPatch } from '../model/versionedWorkshopPatch';
import { Workshop } from '../model/workshop';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Add a new Bit
     * @param body 
     * @param duplicateId ID of Bit to duplicate
     * @param duplicateVersion Version of Bit to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addBit(body?: Bit, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedBit>;
    public addBit(body?: Bit, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedBit>>;
    public addBit(body?: Bit, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedBit>>;
    public addBit(body?: Bit, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedBit>('post',`${this.basePath}/bits`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new Machine
     * @param body 
     * @param duplicateId ID of Machine to duplicate
     * @param duplicateVersion Version of Machine to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMachine(body?: Machine, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachine>;
    public addMachine(body?: Machine, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachine>>;
    public addMachine(body?: Machine, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachine>>;
    public addMachine(body?: Machine, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachine>('post',`${this.basePath}/machines`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new OnshapeImport
     * @param body 
     * @param duplicateId ID of OnshapeImport to duplicate
     * @param duplicateVersion Version of OnshapeImport to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOnshapeImport(body?: OnshapeImport, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOnshapeImport>;
    public addOnshapeImport(body?: OnshapeImport, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOnshapeImport>>;
    public addOnshapeImport(body?: OnshapeImport, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOnshapeImport>>;
    public addOnshapeImport(body?: OnshapeImport, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOnshapeImport>('post',`${this.basePath}/onshapeimports`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new PostProcessor
     * @param body 
     * @param duplicateId ID of PostProcessor to duplicate
     * @param duplicateVersion Version of PostProcessor to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPostProcessor(body?: PostProcessor, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessor>;
    public addPostProcessor(body?: PostProcessor, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessor>>;
    public addPostProcessor(body?: PostProcessor, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessor>>;
    public addPostProcessor(body?: PostProcessor, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPostProcessor>('post',`${this.basePath}/postprocessors`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new Project
     * @param body 
     * @param duplicateId ID of Project to duplicate
     * @param duplicateVersion Version of Project to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProject(body?: Project, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProject>;
    public addProject(body?: Project, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProject>>;
    public addProject(body?: Project, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProject>>;
    public addProject(body?: Project, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProject>('post',`${this.basePath}/projects`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProjectHeadParts(projectId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPart>;
    public addProjectHeadParts(projectId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPart>>;
    public addProjectHeadParts(projectId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPart>>;
    public addProjectHeadParts(projectId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addProjectHeadParts.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPart>('post',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProjectParts(projectId: string, versionId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPart>;
    public addProjectParts(projectId: string, versionId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPart>>;
    public addProjectParts(projectId: string, versionId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPart>>;
    public addProjectParts(projectId: string, versionId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addProjectParts.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling addProjectParts.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPart>('post',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add a new Workshop
     * @param body 
     * @param duplicateId ID of Workshop to duplicate
     * @param duplicateVersion Version of Workshop to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addWorkshop(body?: Workshop, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkshop>;
    public addWorkshop(body?: Workshop, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkshop>>;
    public addWorkshop(body?: Workshop, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkshop>>;
    public addWorkshop(body?: Workshop, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkshop>('post',`${this.basePath}/workshops`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addWorkshopHeadMachineInstances(workshopId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstance>;
    public addWorkshopHeadMachineInstances(workshopId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstance>>;
    public addWorkshopHeadMachineInstances(workshopId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstance>>;
    public addWorkshopHeadMachineInstances(workshopId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling addWorkshopHeadMachineInstances.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachineInstance>('post',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/machineInstances`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param versionId Version of Workshop
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addWorkshopMachineInstances(workshopId: string, versionId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstance>;
    public addWorkshopMachineInstances(workshopId: string, versionId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstance>>;
    public addWorkshopMachineInstances(workshopId: string, versionId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstance>>;
    public addWorkshopMachineInstances(workshopId: string, versionId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling addWorkshopMachineInstances.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling addWorkshopMachineInstances.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachineInstance>('post',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}/machineInstances`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Bit
     * @param bitId ID of Bit to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadBit(bitId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadBit(bitId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadBit(bitId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadBit(bitId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling canReadBit.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Machine
     * @param machineId ID of Machine to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadMachine(machineId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadMachine(machineId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadMachine(machineId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadMachine(machineId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling canReadMachine.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadOnshapeImport(onshapeimportId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadOnshapeImport(onshapeimportId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadOnshapeImport(onshapeimportId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadOnshapeImport(onshapeimportId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling canReadOnshapeImport.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified PostProcessor
     * @param postprocessorId ID of PostProcessor to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadPostProcessor(postprocessorId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadPostProcessor(postprocessorId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadPostProcessor(postprocessorId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadPostProcessor(postprocessorId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling canReadPostProcessor.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Project
     * @param projectId ID of Project to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadProject(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadProject(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadProject(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadProject(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling canReadProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Workshop
     * @param workshopId ID of Workshop to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadWorkshop(workshopId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadWorkshop(workshopId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadWorkshop(workshopId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadWorkshop(workshopId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling canReadWorkshop.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Bit
     * @param bitId ID of Bit to return
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBit(bitId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedBitPatch>;
    public deleteBit(bitId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedBitPatch>>;
    public deleteBit(bitId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedBitPatch>>;
    public deleteBit(bitId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling deleteBit.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedBitPatch>('delete',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Bit
     * @param bitId ID of Bit to delete version
     * @param versionId Version of Bit to delete
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBitVersion(bitId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteBitVersion(bitId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteBitVersion(bitId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteBitVersion(bitId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling deleteBitVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteBitVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Machine
     * @param machineId ID of Machine to return
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMachine(machineId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachinePatch>;
    public deleteMachine(machineId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachinePatch>>;
    public deleteMachine(machineId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachinePatch>>;
    public deleteMachine(machineId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling deleteMachine.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMachinePatch>('delete',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param machineId ID of Machine
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMachineHeadThumbnail(machineId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteMachineHeadThumbnail(machineId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteMachineHeadThumbnail(machineId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteMachineHeadThumbnail(machineId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling deleteMachineHeadThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/thumbnail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param machineId ID of Machine
     * @param versionId Version of Machine
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMachineThumbnail(machineId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteMachineThumbnail(machineId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteMachineThumbnail(machineId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteMachineThumbnail(machineId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling deleteMachineThumbnail.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteMachineThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/v/${encodeURIComponent(String(versionId))}/thumbnail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Machine
     * @param machineId ID of Machine to delete version
     * @param versionId Version of Machine to delete
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMachineVersion(machineId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteMachineVersion(machineId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteMachineVersion(machineId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteMachineVersion(machineId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling deleteMachineVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteMachineVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to return
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOnshapeImport(onshapeimportId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOnshapeImportPatch>;
    public deleteOnshapeImport(onshapeimportId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOnshapeImportPatch>>;
    public deleteOnshapeImport(onshapeimportId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOnshapeImportPatch>>;
    public deleteOnshapeImport(onshapeimportId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling deleteOnshapeImport.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOnshapeImportPatch>('delete',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to delete version
     * @param versionId Version of OnshapeImport to delete
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOnshapeImportVersion(onshapeimportId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteOnshapeImportVersion(onshapeimportId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteOnshapeImportVersion(onshapeimportId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteOnshapeImportVersion(onshapeimportId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling deleteOnshapeImportVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteOnshapeImportVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete PostProcessor
     * @param postprocessorId ID of PostProcessor to return
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePostProcessor(postprocessorId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessorPatch>;
    public deletePostProcessor(postprocessorId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessorPatch>>;
    public deletePostProcessor(postprocessorId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessorPatch>>;
    public deletePostProcessor(postprocessorId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling deletePostProcessor.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPostProcessorPatch>('delete',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a PostProcessor
     * @param postprocessorId ID of PostProcessor to delete version
     * @param versionId Version of PostProcessor to delete
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePostProcessorVersion(postprocessorId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deletePostProcessorVersion(postprocessorId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deletePostProcessorVersion(postprocessorId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deletePostProcessorVersion(postprocessorId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling deletePostProcessorVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deletePostProcessorVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Project
     * @param projectId ID of Project to return
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProject(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProjectPatch>;
    public deleteProject(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProjectPatch>>;
    public deleteProject(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProjectPatch>>;
    public deleteProject(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProject.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProjectPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectCadFile(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteProjectCadFile(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteProjectCadFile(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteProjectCadFile(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectCadFile.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProjectCadFile.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cad/file`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectCuttingList(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCuttingListPatch>;
    public deleteProjectCuttingList(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCuttingListPatch>>;
    public deleteProjectCuttingList(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCuttingListPatch>>;
    public deleteProjectCuttingList(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectCuttingList.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProjectCuttingList.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCuttingListPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param groupsId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedSheetSetPatch>;
    public deleteProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedSheetSetPatch>>;
    public deleteProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedSheetSetPatch>>;
    public deleteProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectCuttingListGroupsSheets.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProjectCuttingListGroupsSheets.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling deleteProjectCuttingListGroupsSheets.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedSheetSetPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param groupsId 
     * @param sheetsId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedToolSelectionConstraintsPatch>;
    public deleteProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedToolSelectionConstraintsPatch>>;
    public deleteProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedToolSelectionConstraintsPatch>>;
    public deleteProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling deleteProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling deleteProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedToolSelectionConstraintsPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolSelection`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectHeadCadFile(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteProjectHeadCadFile(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteProjectHeadCadFile(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteProjectHeadCadFile(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectHeadCadFile.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cad/file`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectHeadCuttingList(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCuttingListPatch>;
    public deleteProjectHeadCuttingList(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCuttingListPatch>>;
    public deleteProjectHeadCuttingList(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCuttingListPatch>>;
    public deleteProjectHeadCuttingList(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectHeadCuttingList.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCuttingListPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param groupsId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedSheetSetPatch>;
    public deleteProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedSheetSetPatch>>;
    public deleteProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedSheetSetPatch>>;
    public deleteProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectHeadCuttingListGroupsSheets.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling deleteProjectHeadCuttingListGroupsSheets.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedSheetSetPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param groupsId 
     * @param sheetsId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedToolSelectionConstraintsPatch>;
    public deleteProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedToolSelectionConstraintsPatch>>;
    public deleteProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedToolSelectionConstraintsPatch>>;
    public deleteProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling deleteProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling deleteProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedToolSelectionConstraintsPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolSelection`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param partsId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectHeadPartsPart(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartPatch>;
    public deleteProjectHeadPartsPart(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartPatch>>;
    public deleteProjectHeadPartsPart(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartPatch>>;
    public deleteProjectHeadPartsPart(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectHeadPartsPart.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteProjectHeadPartsPart.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param partsId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectHeadPartsStl(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteProjectHeadPartsStl(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteProjectHeadPartsStl(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteProjectHeadPartsStl(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectHeadPartsStl.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteProjectHeadPartsStl.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}/stl`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param partsId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectHeadPartsThumbnail(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteProjectHeadPartsThumbnail(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteProjectHeadPartsThumbnail(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteProjectHeadPartsThumbnail(projectId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectHeadPartsThumbnail.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteProjectHeadPartsThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}/thumbnail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectHeadThumbnail(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteProjectHeadThumbnail(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteProjectHeadThumbnail(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteProjectHeadThumbnail(projectId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectHeadThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/thumbnail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param partsId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectPartsPart(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartPatch>;
    public deleteProjectPartsPart(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartPatch>>;
    public deleteProjectPartsPart(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartPatch>>;
    public deleteProjectPartsPart(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectPartsPart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProjectPartsPart.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteProjectPartsPart.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts/${encodeURIComponent(String(partsId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param partsId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectPartsStl(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteProjectPartsStl(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteProjectPartsStl(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteProjectPartsStl(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectPartsStl.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProjectPartsStl.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteProjectPartsStl.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts/${encodeURIComponent(String(partsId))}/stl`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param partsId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectPartsThumbnail.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProjectPartsThumbnail.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling deleteProjectPartsThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts/${encodeURIComponent(String(partsId))}/thumbnail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectThumbnail(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteProjectThumbnail(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteProjectThumbnail(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteProjectThumbnail(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectThumbnail.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProjectThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/thumbnail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Project
     * @param projectId ID of Project to delete version
     * @param versionId Version of Project to delete
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectVersion(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteProjectVersion(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteProjectVersion(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteProjectVersion(projectId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteProjectVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteProjectVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific user
     * @param email Email of user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(email: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public deleteUser(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public deleteUser(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public deleteUser(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling deleteUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('delete',`${this.basePath}/users/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Workshop
     * @param workshopId ID of Workshop to return
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWorkshop(workshopId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkshopPatch>;
    public deleteWorkshop(workshopId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkshopPatch>>;
    public deleteWorkshop(workshopId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkshopPatch>>;
    public deleteWorkshop(workshopId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling deleteWorkshop.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkshopPatch>('delete',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param machineInstancesId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstancePatch>;
    public deleteWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstancePatch>>;
    public deleteWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstancePatch>>;
    public deleteWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling deleteWorkshopHeadMachineInstancesMachineInstance.');
        }

        if (machineInstancesId === null || machineInstancesId === undefined) {
            throw new Error('Required parameter machineInstancesId was null or undefined when calling deleteWorkshopHeadMachineInstancesMachineInstance.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMachineInstancePatch>('delete',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/machineInstances/${encodeURIComponent(String(machineInstancesId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param versionId Version of Workshop
     * @param machineInstancesId 
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstancePatch>;
    public deleteWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstancePatch>>;
    public deleteWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstancePatch>>;
    public deleteWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling deleteWorkshopMachineInstancesMachineInstance.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteWorkshopMachineInstancesMachineInstance.');
        }

        if (machineInstancesId === null || machineInstancesId === undefined) {
            throw new Error('Required parameter machineInstancesId was null or undefined when calling deleteWorkshopMachineInstancesMachineInstance.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMachineInstancePatch>('delete',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}/machineInstances/${encodeURIComponent(String(machineInstancesId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Workshop
     * @param workshopId ID of Workshop to delete version
     * @param versionId Version of Workshop to delete
     * @param archive 
     * @param failIfUsed 
     * @param updateUsed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWorkshopVersion(workshopId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteWorkshopVersion(workshopId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteWorkshopVersion(workshopId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteWorkshopVersion(workshopId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling deleteWorkshopVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteWorkshopVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Bits
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param name 
     * @param type 
     * @param manufacturer 
     * @param productId 
     * @param diameter 
     * @param shaftDiameter 
     * @param cuttingEdgeLength 
     * @param overallLength 
     * @param lengthOffset 
     * @param tipDiameter 
     * @param fluteLength 
     * @param numberOfFlutes 
     * @param clockwise 
     * @param materialSettings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetBits(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, type?: string, manufacturer?: string, productId?: string, diameter?: string, shaftDiameter?: string, cuttingEdgeLength?: string, overallLength?: string, lengthOffset?: string, tipDiameter?: string, fluteLength?: string, numberOfFlutes?: string, clockwise?: string, materialSettings?: Array<BitMaterial>, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedBit>>;
    public findAndGetBits(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, type?: string, manufacturer?: string, productId?: string, diameter?: string, shaftDiameter?: string, cuttingEdgeLength?: string, overallLength?: string, lengthOffset?: string, tipDiameter?: string, fluteLength?: string, numberOfFlutes?: string, clockwise?: string, materialSettings?: Array<BitMaterial>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedBit>>>;
    public findAndGetBits(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, type?: string, manufacturer?: string, productId?: string, diameter?: string, shaftDiameter?: string, cuttingEdgeLength?: string, overallLength?: string, lengthOffset?: string, tipDiameter?: string, fluteLength?: string, numberOfFlutes?: string, clockwise?: string, materialSettings?: Array<BitMaterial>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedBit>>>;
    public findAndGetBits(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, type?: string, manufacturer?: string, productId?: string, diameter?: string, shaftDiameter?: string, cuttingEdgeLength?: string, overallLength?: string, lengthOffset?: string, tipDiameter?: string, fluteLength?: string, numberOfFlutes?: string, clockwise?: string, materialSettings?: Array<BitMaterial>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (manufacturer !== undefined && manufacturer !== null) {
            queryParameters = queryParameters.set('manufacturer', <any>manufacturer);
        }
        if (productId !== undefined && productId !== null) {
            queryParameters = queryParameters.set('productId', <any>productId);
        }
        if (diameter !== undefined && diameter !== null) {
            queryParameters = queryParameters.set('diameter', <any>diameter);
        }
        if (shaftDiameter !== undefined && shaftDiameter !== null) {
            queryParameters = queryParameters.set('shaftDiameter', <any>shaftDiameter);
        }
        if (cuttingEdgeLength !== undefined && cuttingEdgeLength !== null) {
            queryParameters = queryParameters.set('cuttingEdgeLength', <any>cuttingEdgeLength);
        }
        if (overallLength !== undefined && overallLength !== null) {
            queryParameters = queryParameters.set('overallLength', <any>overallLength);
        }
        if (lengthOffset !== undefined && lengthOffset !== null) {
            queryParameters = queryParameters.set('lengthOffset', <any>lengthOffset);
        }
        if (tipDiameter !== undefined && tipDiameter !== null) {
            queryParameters = queryParameters.set('tipDiameter', <any>tipDiameter);
        }
        if (fluteLength !== undefined && fluteLength !== null) {
            queryParameters = queryParameters.set('fluteLength', <any>fluteLength);
        }
        if (numberOfFlutes !== undefined && numberOfFlutes !== null) {
            queryParameters = queryParameters.set('numberOfFlutes', <any>numberOfFlutes);
        }
        if (clockwise !== undefined && clockwise !== null) {
            queryParameters = queryParameters.set('clockwise', <any>clockwise);
        }
        if (materialSettings) {
            materialSettings.forEach((element) => {
                queryParameters = queryParameters.append('materialSettings', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedBit>>('get',`${this.basePath}/bits/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Machines
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param name 
     * @param brand 
     * @param model 
     * @param ATC 
     * @param xLength 
     * @param yLength 
     * @param zHeight 
     * @param minSpindleSpeed 
     * @param maxSpindleSpeed 
     * @param minColletSize 
     * @param maxColletSize 
     * @param minCuttingSpeed 
     * @param maxCuttingSpeed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetMachines(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, brand?: string, model?: string, ATC?: string, xLength?: string, yLength?: string, zHeight?: string, minSpindleSpeed?: string, maxSpindleSpeed?: string, minColletSize?: string, maxColletSize?: string, minCuttingSpeed?: string, maxCuttingSpeed?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedMachine>>;
    public findAndGetMachines(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, brand?: string, model?: string, ATC?: string, xLength?: string, yLength?: string, zHeight?: string, minSpindleSpeed?: string, maxSpindleSpeed?: string, minColletSize?: string, maxColletSize?: string, minCuttingSpeed?: string, maxCuttingSpeed?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedMachine>>>;
    public findAndGetMachines(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, brand?: string, model?: string, ATC?: string, xLength?: string, yLength?: string, zHeight?: string, minSpindleSpeed?: string, maxSpindleSpeed?: string, minColletSize?: string, maxColletSize?: string, minCuttingSpeed?: string, maxCuttingSpeed?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedMachine>>>;
    public findAndGetMachines(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, brand?: string, model?: string, ATC?: string, xLength?: string, yLength?: string, zHeight?: string, minSpindleSpeed?: string, maxSpindleSpeed?: string, minColletSize?: string, maxColletSize?: string, minCuttingSpeed?: string, maxCuttingSpeed?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (brand !== undefined && brand !== null) {
            queryParameters = queryParameters.set('brand', <any>brand);
        }
        if (model !== undefined && model !== null) {
            queryParameters = queryParameters.set('model', <any>model);
        }
        if (ATC !== undefined && ATC !== null) {
            queryParameters = queryParameters.set('ATC', <any>ATC);
        }
        if (xLength !== undefined && xLength !== null) {
            queryParameters = queryParameters.set('xLength', <any>xLength);
        }
        if (yLength !== undefined && yLength !== null) {
            queryParameters = queryParameters.set('yLength', <any>yLength);
        }
        if (zHeight !== undefined && zHeight !== null) {
            queryParameters = queryParameters.set('zHeight', <any>zHeight);
        }
        if (minSpindleSpeed !== undefined && minSpindleSpeed !== null) {
            queryParameters = queryParameters.set('minSpindleSpeed', <any>minSpindleSpeed);
        }
        if (maxSpindleSpeed !== undefined && maxSpindleSpeed !== null) {
            queryParameters = queryParameters.set('maxSpindleSpeed', <any>maxSpindleSpeed);
        }
        if (minColletSize !== undefined && minColletSize !== null) {
            queryParameters = queryParameters.set('minColletSize', <any>minColletSize);
        }
        if (maxColletSize !== undefined && maxColletSize !== null) {
            queryParameters = queryParameters.set('maxColletSize', <any>maxColletSize);
        }
        if (minCuttingSpeed !== undefined && minCuttingSpeed !== null) {
            queryParameters = queryParameters.set('minCuttingSpeed', <any>minCuttingSpeed);
        }
        if (maxCuttingSpeed !== undefined && maxCuttingSpeed !== null) {
            queryParameters = queryParameters.set('maxCuttingSpeed', <any>maxCuttingSpeed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedMachine>>('get',`${this.basePath}/machines/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all OnshapeImports
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param documentId 
     * @param workspaceId 
     * @param elementId 
     * @param versionId 
     * @param microversionId 
     * @param configuration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetOnshapeImports(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, documentId?: string, workspaceId?: string, elementId?: string, versionId?: string, microversionId?: string, configuration?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedOnshapeImport>>;
    public findAndGetOnshapeImports(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, documentId?: string, workspaceId?: string, elementId?: string, versionId?: string, microversionId?: string, configuration?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedOnshapeImport>>>;
    public findAndGetOnshapeImports(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, documentId?: string, workspaceId?: string, elementId?: string, versionId?: string, microversionId?: string, configuration?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedOnshapeImport>>>;
    public findAndGetOnshapeImports(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, documentId?: string, workspaceId?: string, elementId?: string, versionId?: string, microversionId?: string, configuration?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {














        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }
        if (workspaceId !== undefined && workspaceId !== null) {
            queryParameters = queryParameters.set('workspaceId', <any>workspaceId);
        }
        if (elementId !== undefined && elementId !== null) {
            queryParameters = queryParameters.set('elementId', <any>elementId);
        }
        if (versionId !== undefined && versionId !== null) {
            queryParameters = queryParameters.set('versionId', <any>versionId);
        }
        if (microversionId !== undefined && microversionId !== null) {
            queryParameters = queryParameters.set('microversionId', <any>microversionId);
        }
        if (configuration !== undefined && configuration !== null) {
            queryParameters = queryParameters.set('configuration', <any>configuration);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedOnshapeImport>>('get',`${this.basePath}/onshapeimports/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all PostProcessors
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param name 
     * @param description 
     * @param fileExtension 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetPostProcessors(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, description?: string, fileExtension?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedPostProcessor>>;
    public findAndGetPostProcessors(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, description?: string, fileExtension?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedPostProcessor>>>;
    public findAndGetPostProcessors(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, description?: string, fileExtension?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedPostProcessor>>>;
    public findAndGetPostProcessors(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, description?: string, fileExtension?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (fileExtension !== undefined && fileExtension !== null) {
            queryParameters = queryParameters.set('fileExtension', <any>fileExtension);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedPostProcessor>>('get',`${this.basePath}/postprocessors/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Projects
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param name 
     * @param cadProvider 
     * @param cadType 
     * @param cadId 
     * @param cadName 
     * @param cadHash 
     * @param cadUpdated 
     * @param cadConfiguration 
     * @param cadFileName 
     * @param cadFileContentType 
     * @param cadFileUri 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetProjects(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, cadProvider?: string, cadType?: string, cadId?: string, cadName?: string, cadHash?: string, cadUpdated?: Date, cadConfiguration?: string, cadFileName?: string, cadFileContentType?: string, cadFileUri?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedProject>>;
    public findAndGetProjects(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, cadProvider?: string, cadType?: string, cadId?: string, cadName?: string, cadHash?: string, cadUpdated?: Date, cadConfiguration?: string, cadFileName?: string, cadFileContentType?: string, cadFileUri?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedProject>>>;
    public findAndGetProjects(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, cadProvider?: string, cadType?: string, cadId?: string, cadName?: string, cadHash?: string, cadUpdated?: Date, cadConfiguration?: string, cadFileName?: string, cadFileContentType?: string, cadFileUri?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedProject>>>;
    public findAndGetProjects(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, cadProvider?: string, cadType?: string, cadId?: string, cadName?: string, cadHash?: string, cadUpdated?: Date, cadConfiguration?: string, cadFileName?: string, cadFileContentType?: string, cadFileUri?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (cadProvider !== undefined && cadProvider !== null) {
            queryParameters = queryParameters.set('cad.provider', <any>cadProvider);
        }
        if (cadType !== undefined && cadType !== null) {
            queryParameters = queryParameters.set('cad.type', <any>cadType);
        }
        if (cadId !== undefined && cadId !== null) {
            queryParameters = queryParameters.set('cad.id', <any>cadId);
        }
        if (cadName !== undefined && cadName !== null) {
            queryParameters = queryParameters.set('cad.name', <any>cadName);
        }
        if (cadHash !== undefined && cadHash !== null) {
            queryParameters = queryParameters.set('cad.hash', <any>cadHash);
        }
        if (cadUpdated !== undefined && cadUpdated !== null) {
            queryParameters = queryParameters.set('cad.updated', <any>cadUpdated.toISOString());
        }
        if (cadConfiguration !== undefined && cadConfiguration !== null) {
            queryParameters = queryParameters.set('cad.configuration', <any>cadConfiguration);
        }
        if (cadFileName !== undefined && cadFileName !== null) {
            queryParameters = queryParameters.set('cad.file.name', <any>cadFileName);
        }
        if (cadFileContentType !== undefined && cadFileContentType !== null) {
            queryParameters = queryParameters.set('cad.file.contentType', <any>cadFileContentType);
        }
        if (cadFileUri !== undefined && cadFileUri !== null) {
            queryParameters = queryParameters.set('cad.file.uri', <any>cadFileUri);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedProject>>('get',`${this.basePath}/projects/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Workshops
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetWorkshops(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedWorkshop>>;
    public findAndGetWorkshops(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedWorkshop>>>;
    public findAndGetWorkshops(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedWorkshop>>>;
    public findAndGetWorkshops(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedWorkshop>>('get',`${this.basePath}/workshops/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Bits
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param name 
     * @param type 
     * @param manufacturer 
     * @param productId 
     * @param diameter 
     * @param shaftDiameter 
     * @param cuttingEdgeLength 
     * @param overallLength 
     * @param lengthOffset 
     * @param tipDiameter 
     * @param fluteLength 
     * @param numberOfFlutes 
     * @param clockwise 
     * @param materialSettings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findBits(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, type?: string, manufacturer?: string, productId?: string, diameter?: string, shaftDiameter?: string, cuttingEdgeLength?: string, overallLength?: string, lengthOffset?: string, tipDiameter?: string, fluteLength?: string, numberOfFlutes?: string, clockwise?: string, materialSettings?: Array<BitMaterial>, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findBits(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, type?: string, manufacturer?: string, productId?: string, diameter?: string, shaftDiameter?: string, cuttingEdgeLength?: string, overallLength?: string, lengthOffset?: string, tipDiameter?: string, fluteLength?: string, numberOfFlutes?: string, clockwise?: string, materialSettings?: Array<BitMaterial>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findBits(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, type?: string, manufacturer?: string, productId?: string, diameter?: string, shaftDiameter?: string, cuttingEdgeLength?: string, overallLength?: string, lengthOffset?: string, tipDiameter?: string, fluteLength?: string, numberOfFlutes?: string, clockwise?: string, materialSettings?: Array<BitMaterial>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findBits(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, type?: string, manufacturer?: string, productId?: string, diameter?: string, shaftDiameter?: string, cuttingEdgeLength?: string, overallLength?: string, lengthOffset?: string, tipDiameter?: string, fluteLength?: string, numberOfFlutes?: string, clockwise?: string, materialSettings?: Array<BitMaterial>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (manufacturer !== undefined && manufacturer !== null) {
            queryParameters = queryParameters.set('manufacturer', <any>manufacturer);
        }
        if (productId !== undefined && productId !== null) {
            queryParameters = queryParameters.set('productId', <any>productId);
        }
        if (diameter !== undefined && diameter !== null) {
            queryParameters = queryParameters.set('diameter', <any>diameter);
        }
        if (shaftDiameter !== undefined && shaftDiameter !== null) {
            queryParameters = queryParameters.set('shaftDiameter', <any>shaftDiameter);
        }
        if (cuttingEdgeLength !== undefined && cuttingEdgeLength !== null) {
            queryParameters = queryParameters.set('cuttingEdgeLength', <any>cuttingEdgeLength);
        }
        if (overallLength !== undefined && overallLength !== null) {
            queryParameters = queryParameters.set('overallLength', <any>overallLength);
        }
        if (lengthOffset !== undefined && lengthOffset !== null) {
            queryParameters = queryParameters.set('lengthOffset', <any>lengthOffset);
        }
        if (tipDiameter !== undefined && tipDiameter !== null) {
            queryParameters = queryParameters.set('tipDiameter', <any>tipDiameter);
        }
        if (fluteLength !== undefined && fluteLength !== null) {
            queryParameters = queryParameters.set('fluteLength', <any>fluteLength);
        }
        if (numberOfFlutes !== undefined && numberOfFlutes !== null) {
            queryParameters = queryParameters.set('numberOfFlutes', <any>numberOfFlutes);
        }
        if (clockwise !== undefined && clockwise !== null) {
            queryParameters = queryParameters.set('clockwise', <any>clockwise);
        }
        if (materialSettings) {
            materialSettings.forEach((element) => {
                queryParameters = queryParameters.append('materialSettings', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/bits`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Machines
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param name 
     * @param brand 
     * @param model 
     * @param ATC 
     * @param xLength 
     * @param yLength 
     * @param zHeight 
     * @param minSpindleSpeed 
     * @param maxSpindleSpeed 
     * @param minColletSize 
     * @param maxColletSize 
     * @param minCuttingSpeed 
     * @param maxCuttingSpeed 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findMachines(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, brand?: string, model?: string, ATC?: string, xLength?: string, yLength?: string, zHeight?: string, minSpindleSpeed?: string, maxSpindleSpeed?: string, minColletSize?: string, maxColletSize?: string, minCuttingSpeed?: string, maxCuttingSpeed?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findMachines(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, brand?: string, model?: string, ATC?: string, xLength?: string, yLength?: string, zHeight?: string, minSpindleSpeed?: string, maxSpindleSpeed?: string, minColletSize?: string, maxColletSize?: string, minCuttingSpeed?: string, maxCuttingSpeed?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findMachines(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, brand?: string, model?: string, ATC?: string, xLength?: string, yLength?: string, zHeight?: string, minSpindleSpeed?: string, maxSpindleSpeed?: string, minColletSize?: string, maxColletSize?: string, minCuttingSpeed?: string, maxCuttingSpeed?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findMachines(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, brand?: string, model?: string, ATC?: string, xLength?: string, yLength?: string, zHeight?: string, minSpindleSpeed?: string, maxSpindleSpeed?: string, minColletSize?: string, maxColletSize?: string, minCuttingSpeed?: string, maxCuttingSpeed?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (brand !== undefined && brand !== null) {
            queryParameters = queryParameters.set('brand', <any>brand);
        }
        if (model !== undefined && model !== null) {
            queryParameters = queryParameters.set('model', <any>model);
        }
        if (ATC !== undefined && ATC !== null) {
            queryParameters = queryParameters.set('ATC', <any>ATC);
        }
        if (xLength !== undefined && xLength !== null) {
            queryParameters = queryParameters.set('xLength', <any>xLength);
        }
        if (yLength !== undefined && yLength !== null) {
            queryParameters = queryParameters.set('yLength', <any>yLength);
        }
        if (zHeight !== undefined && zHeight !== null) {
            queryParameters = queryParameters.set('zHeight', <any>zHeight);
        }
        if (minSpindleSpeed !== undefined && minSpindleSpeed !== null) {
            queryParameters = queryParameters.set('minSpindleSpeed', <any>minSpindleSpeed);
        }
        if (maxSpindleSpeed !== undefined && maxSpindleSpeed !== null) {
            queryParameters = queryParameters.set('maxSpindleSpeed', <any>maxSpindleSpeed);
        }
        if (minColletSize !== undefined && minColletSize !== null) {
            queryParameters = queryParameters.set('minColletSize', <any>minColletSize);
        }
        if (maxColletSize !== undefined && maxColletSize !== null) {
            queryParameters = queryParameters.set('maxColletSize', <any>maxColletSize);
        }
        if (minCuttingSpeed !== undefined && minCuttingSpeed !== null) {
            queryParameters = queryParameters.set('minCuttingSpeed', <any>minCuttingSpeed);
        }
        if (maxCuttingSpeed !== undefined && maxCuttingSpeed !== null) {
            queryParameters = queryParameters.set('maxCuttingSpeed', <any>maxCuttingSpeed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/machines`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all OnshapeImports
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param documentId 
     * @param workspaceId 
     * @param elementId 
     * @param versionId 
     * @param microversionId 
     * @param configuration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findOnshapeImports(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, documentId?: string, workspaceId?: string, elementId?: string, versionId?: string, microversionId?: string, configuration?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findOnshapeImports(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, documentId?: string, workspaceId?: string, elementId?: string, versionId?: string, microversionId?: string, configuration?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findOnshapeImports(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, documentId?: string, workspaceId?: string, elementId?: string, versionId?: string, microversionId?: string, configuration?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findOnshapeImports(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, documentId?: string, workspaceId?: string, elementId?: string, versionId?: string, microversionId?: string, configuration?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {














        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }
        if (workspaceId !== undefined && workspaceId !== null) {
            queryParameters = queryParameters.set('workspaceId', <any>workspaceId);
        }
        if (elementId !== undefined && elementId !== null) {
            queryParameters = queryParameters.set('elementId', <any>elementId);
        }
        if (versionId !== undefined && versionId !== null) {
            queryParameters = queryParameters.set('versionId', <any>versionId);
        }
        if (microversionId !== undefined && microversionId !== null) {
            queryParameters = queryParameters.set('microversionId', <any>microversionId);
        }
        if (configuration !== undefined && configuration !== null) {
            queryParameters = queryParameters.set('configuration', <any>configuration);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/onshapeimports`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all PostProcessors
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param name 
     * @param description 
     * @param fileExtension 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findPostProcessors(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, description?: string, fileExtension?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findPostProcessors(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, description?: string, fileExtension?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findPostProcessors(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, description?: string, fileExtension?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findPostProcessors(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, description?: string, fileExtension?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (fileExtension !== undefined && fileExtension !== null) {
            queryParameters = queryParameters.set('fileExtension', <any>fileExtension);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/postprocessors`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Projects
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param name 
     * @param cadProvider 
     * @param cadType 
     * @param cadId 
     * @param cadName 
     * @param cadHash 
     * @param cadUpdated 
     * @param cadConfiguration 
     * @param cadFileName 
     * @param cadFileContentType 
     * @param cadFileUri 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findProjects(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, cadProvider?: string, cadType?: string, cadId?: string, cadName?: string, cadHash?: string, cadUpdated?: Date, cadConfiguration?: string, cadFileName?: string, cadFileContentType?: string, cadFileUri?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findProjects(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, cadProvider?: string, cadType?: string, cadId?: string, cadName?: string, cadHash?: string, cadUpdated?: Date, cadConfiguration?: string, cadFileName?: string, cadFileContentType?: string, cadFileUri?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findProjects(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, cadProvider?: string, cadType?: string, cadId?: string, cadName?: string, cadHash?: string, cadUpdated?: Date, cadConfiguration?: string, cadFileName?: string, cadFileContentType?: string, cadFileUri?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findProjects(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, cadProvider?: string, cadType?: string, cadId?: string, cadName?: string, cadHash?: string, cadUpdated?: Date, cadConfiguration?: string, cadFileName?: string, cadFileContentType?: string, cadFileUri?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (cadProvider !== undefined && cadProvider !== null) {
            queryParameters = queryParameters.set('cad.provider', <any>cadProvider);
        }
        if (cadType !== undefined && cadType !== null) {
            queryParameters = queryParameters.set('cad.type', <any>cadType);
        }
        if (cadId !== undefined && cadId !== null) {
            queryParameters = queryParameters.set('cad.id', <any>cadId);
        }
        if (cadName !== undefined && cadName !== null) {
            queryParameters = queryParameters.set('cad.name', <any>cadName);
        }
        if (cadHash !== undefined && cadHash !== null) {
            queryParameters = queryParameters.set('cad.hash', <any>cadHash);
        }
        if (cadUpdated !== undefined && cadUpdated !== null) {
            queryParameters = queryParameters.set('cad.updated', <any>cadUpdated.toISOString());
        }
        if (cadConfiguration !== undefined && cadConfiguration !== null) {
            queryParameters = queryParameters.set('cad.configuration', <any>cadConfiguration);
        }
        if (cadFileName !== undefined && cadFileName !== null) {
            queryParameters = queryParameters.set('cad.file.name', <any>cadFileName);
        }
        if (cadFileContentType !== undefined && cadFileContentType !== null) {
            queryParameters = queryParameters.set('cad.file.contentType', <any>cadFileContentType);
        }
        if (cadFileUri !== undefined && cadFileUri !== null) {
            queryParameters = queryParameters.set('cad.file.uri', <any>cadFileUri);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/projects`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Workshops
     * @param mine 
     * @param shared 
     * @param includeAttributes 
     * @param includeDeleted 
     * @param canWrite 
     * @param superAdmin 
     * @param _public 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findWorkshops(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findWorkshops(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findWorkshops(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findWorkshops(mine?: boolean, shared?: boolean, includeAttributes?: boolean, includeDeleted?: boolean, canWrite?: boolean, superAdmin?: boolean, _public?: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('includeAttributes', <any>includeAttributes);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (superAdmin !== undefined && superAdmin !== null) {
            queryParameters = queryParameters.set('superAdmin', <any>superAdmin);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/workshops`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Bit
     * @param bitId ID of Bit to return
     * @param versionId Version of Bit to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBit(bitId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedBit>;
    public getBit(bitId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedBit>>;
    public getBit(bitId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedBit>>;
    public getBit(bitId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling getBit.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getBit.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedBit>('get',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Bits
     * @param bitId1 ID of first Bit to compare
     * @param versionId1 Version of first Bit to compare
     * @param bitId2 ID of second Bit to compare
     * @param versionId2 Version of second Bit to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBitDiff(bitId1: string, versionId1: string, bitId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedBitDiff>;
    public getBitDiff(bitId1: string, versionId1: string, bitId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedBitDiff>>;
    public getBitDiff(bitId1: string, versionId1: string, bitId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedBitDiff>>;
    public getBitDiff(bitId1: string, versionId1: string, bitId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId1 === null || bitId1 === undefined) {
            throw new Error('Required parameter bitId1 was null or undefined when calling getBitDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getBitDiff.');
        }

        if (bitId2 === null || bitId2 === undefined) {
            throw new Error('Required parameter bitId2 was null or undefined when calling getBitDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getBitDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedBitDiff>('get',`${this.basePath}/bits/${encodeURIComponent(String(bitId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(bitId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Bit
     * @param bitId ID of Bit to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBitHead(bitId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedBit>;
    public getBitHead(bitId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedBit>>;
    public getBitHead(bitId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedBit>>;
    public getBitHead(bitId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling getBitHead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedBit>('get',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Bit
     * @param bitId ID of Bit to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBitHistory(bitId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getBitHistory(bitId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getBitHistory(bitId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getBitHistory(bitId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling getBitHistory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/history`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Bit
     * @param bitId ID of Bit to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBitLog(bitId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getBitLog(bitId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getBitLog(bitId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getBitLog(bitId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling getBitLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Bit
     * @param bitId ID of Bit to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBitSharing(bitId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getBitSharing(bitId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getBitSharing(bitId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getBitSharing(bitId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling getBitSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Bit
     * @param bitId ID of Bit to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBitUsages(bitId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getBitUsages(bitId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getBitUsages(bitId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getBitUsages(bitId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling getBitUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Bit
     * @param bitId ID of Bit to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBitVersionSummary(bitId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getBitVersionSummary(bitId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getBitVersionSummary(bitId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getBitVersionSummary(bitId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling getBitVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getBitVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Bit version
     * @param bitId ID of Bit to return
     * @param versionId Version of Bit to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBitVersionUsages(bitId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getBitVersionUsages(bitId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getBitVersionUsages(bitId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getBitVersionUsages(bitId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling getBitVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getBitVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Machine
     * @param machineId ID of Machine to return
     * @param versionId Version of Machine to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachine(machineId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachine>;
    public getMachine(machineId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachine>>;
    public getMachine(machineId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachine>>;
    public getMachine(machineId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling getMachine.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getMachine.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMachine>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Machines
     * @param machineId1 ID of first Machine to compare
     * @param versionId1 Version of first Machine to compare
     * @param machineId2 ID of second Machine to compare
     * @param versionId2 Version of second Machine to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachineDiff(machineId1: string, versionId1: string, machineId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineDiff>;
    public getMachineDiff(machineId1: string, versionId1: string, machineId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineDiff>>;
    public getMachineDiff(machineId1: string, versionId1: string, machineId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineDiff>>;
    public getMachineDiff(machineId1: string, versionId1: string, machineId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId1 === null || machineId1 === undefined) {
            throw new Error('Required parameter machineId1 was null or undefined when calling getMachineDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getMachineDiff.');
        }

        if (machineId2 === null || machineId2 === undefined) {
            throw new Error('Required parameter machineId2 was null or undefined when calling getMachineDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getMachineDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMachineDiff>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(machineId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Machine
     * @param machineId ID of Machine to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachineHead(machineId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachine>;
    public getMachineHead(machineId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachine>>;
    public getMachineHead(machineId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachine>>;
    public getMachineHead(machineId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling getMachineHead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMachine>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param machineId ID of Machine
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachineHeadThumbnail(machineId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getMachineHeadThumbnail(machineId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getMachineHeadThumbnail(machineId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getMachineHeadThumbnail(machineId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling getMachineHeadThumbnail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/thumbnail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param machineId ID of Machine to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachineHeadThumbnailLog(machineId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getMachineHeadThumbnailLog(machineId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getMachineHeadThumbnailLog(machineId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getMachineHeadThumbnailLog(machineId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling getMachineHeadThumbnailLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/thumbnail/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Machine
     * @param machineId ID of Machine to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachineHistory(machineId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getMachineHistory(machineId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getMachineHistory(machineId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getMachineHistory(machineId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling getMachineHistory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/history`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Machine
     * @param machineId ID of Machine to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachineLog(machineId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getMachineLog(machineId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getMachineLog(machineId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getMachineLog(machineId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling getMachineLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Machine
     * @param machineId ID of Machine to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachineSharing(machineId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getMachineSharing(machineId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getMachineSharing(machineId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getMachineSharing(machineId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling getMachineSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param machineId ID of Machine
     * @param versionId Version of Machine
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachineThumbnail(machineId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getMachineThumbnail(machineId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getMachineThumbnail(machineId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getMachineThumbnail(machineId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling getMachineThumbnail.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getMachineThumbnail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/v/${encodeURIComponent(String(versionId))}/thumbnail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Machine
     * @param machineId ID of Machine to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachineUsages(machineId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getMachineUsages(machineId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getMachineUsages(machineId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getMachineUsages(machineId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling getMachineUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Machine
     * @param machineId ID of Machine to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachineVersionSummary(machineId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getMachineVersionSummary(machineId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getMachineVersionSummary(machineId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getMachineVersionSummary(machineId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling getMachineVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getMachineVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Machine version
     * @param machineId ID of Machine to return
     * @param versionId Version of Machine to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMachineVersionUsages(machineId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getMachineVersionUsages(machineId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getMachineVersionUsages(machineId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getMachineVersionUsages(machineId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling getMachineVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getMachineVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get details of current user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMe(observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getMe(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getMe(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getMe(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('get',`${this.basePath}/users/me`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get activity of current user
     * @param from From date
     * @param to To date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyActivity(from?: string, to?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Activity>>;
    public getMyActivity(from?: string, to?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Activity>>>;
    public getMyActivity(from?: string, to?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Activity>>>;
    public getMyActivity(from?: string, to?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Activity>>('get',`${this.basePath}/users/me/activity`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to return
     * @param versionId Version of OnshapeImport to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImport(onshapeimportId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOnshapeImport>;
    public getOnshapeImport(onshapeimportId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOnshapeImport>>;
    public getOnshapeImport(onshapeimportId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOnshapeImport>>;
    public getOnshapeImport(onshapeimportId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling getOnshapeImport.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOnshapeImport.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOnshapeImport>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of OnshapeImports
     * @param onshapeimportId1 ID of first OnshapeImport to compare
     * @param versionId1 Version of first OnshapeImport to compare
     * @param onshapeimportId2 ID of second OnshapeImport to compare
     * @param versionId2 Version of second OnshapeImport to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImportDiff(onshapeimportId1: string, versionId1: string, onshapeimportId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOnshapeImportDiff>;
    public getOnshapeImportDiff(onshapeimportId1: string, versionId1: string, onshapeimportId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOnshapeImportDiff>>;
    public getOnshapeImportDiff(onshapeimportId1: string, versionId1: string, onshapeimportId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOnshapeImportDiff>>;
    public getOnshapeImportDiff(onshapeimportId1: string, versionId1: string, onshapeimportId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId1 === null || onshapeimportId1 === undefined) {
            throw new Error('Required parameter onshapeimportId1 was null or undefined when calling getOnshapeImportDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getOnshapeImportDiff.');
        }

        if (onshapeimportId2 === null || onshapeimportId2 === undefined) {
            throw new Error('Required parameter onshapeimportId2 was null or undefined when calling getOnshapeImportDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getOnshapeImportDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOnshapeImportDiff>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(onshapeimportId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImportHead(onshapeimportId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedOnshapeImport>;
    public getOnshapeImportHead(onshapeimportId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOnshapeImport>>;
    public getOnshapeImportHead(onshapeimportId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOnshapeImport>>;
    public getOnshapeImportHead(onshapeimportId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling getOnshapeImportHead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedOnshapeImport>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param onshapeimportId ID of OnshapeImport
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImportHeadProject(onshapeimportId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedReference>;
    public getOnshapeImportHeadProject(onshapeimportId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedReference>>;
    public getOnshapeImportHeadProject(onshapeimportId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedReference>>;
    public getOnshapeImportHeadProject(onshapeimportId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling getOnshapeImportHeadProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedReference>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/project`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param onshapeimportId ID of OnshapeImport to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImportHeadProjectLog(onshapeimportId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getOnshapeImportHeadProjectLog(onshapeimportId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getOnshapeImportHeadProjectLog(onshapeimportId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getOnshapeImportHeadProjectLog(onshapeimportId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling getOnshapeImportHeadProjectLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/project/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImportHistory(onshapeimportId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getOnshapeImportHistory(onshapeimportId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getOnshapeImportHistory(onshapeimportId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getOnshapeImportHistory(onshapeimportId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling getOnshapeImportHistory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/history`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImportLog(onshapeimportId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getOnshapeImportLog(onshapeimportId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getOnshapeImportLog(onshapeimportId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getOnshapeImportLog(onshapeimportId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling getOnshapeImportLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param onshapeimportId ID of OnshapeImport
     * @param versionId Version of OnshapeImport
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImportProject(onshapeimportId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedReference>;
    public getOnshapeImportProject(onshapeimportId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedReference>>;
    public getOnshapeImportProject(onshapeimportId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedReference>>;
    public getOnshapeImportProject(onshapeimportId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling getOnshapeImportProject.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOnshapeImportProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedReference>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/v/${encodeURIComponent(String(versionId))}/project`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImportSharing(onshapeimportId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getOnshapeImportSharing(onshapeimportId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getOnshapeImportSharing(onshapeimportId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getOnshapeImportSharing(onshapeimportId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling getOnshapeImportSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImportUsages(onshapeimportId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getOnshapeImportUsages(onshapeimportId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getOnshapeImportUsages(onshapeimportId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getOnshapeImportUsages(onshapeimportId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling getOnshapeImportUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImportVersionSummary(onshapeimportId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getOnshapeImportVersionSummary(onshapeimportId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getOnshapeImportVersionSummary(onshapeimportId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getOnshapeImportVersionSummary(onshapeimportId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling getOnshapeImportVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOnshapeImportVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified OnshapeImport version
     * @param onshapeimportId ID of OnshapeImport to return
     * @param versionId Version of OnshapeImport to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnshapeImportVersionUsages(onshapeimportId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getOnshapeImportVersionUsages(onshapeimportId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getOnshapeImportVersionUsages(onshapeimportId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getOnshapeImportVersionUsages(onshapeimportId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling getOnshapeImportVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getOnshapeImportVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified PostProcessor
     * @param postprocessorId ID of PostProcessor to return
     * @param versionId Version of PostProcessor to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessor(postprocessorId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessor>;
    public getPostProcessor(postprocessorId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessor>>;
    public getPostProcessor(postprocessorId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessor>>;
    public getPostProcessor(postprocessorId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling getPostProcessor.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getPostProcessor.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPostProcessor>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of PostProcessors
     * @param postprocessorId1 ID of first PostProcessor to compare
     * @param versionId1 Version of first PostProcessor to compare
     * @param postprocessorId2 ID of second PostProcessor to compare
     * @param versionId2 Version of second PostProcessor to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessorDiff(postprocessorId1: string, versionId1: string, postprocessorId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessorDiff>;
    public getPostProcessorDiff(postprocessorId1: string, versionId1: string, postprocessorId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessorDiff>>;
    public getPostProcessorDiff(postprocessorId1: string, versionId1: string, postprocessorId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessorDiff>>;
    public getPostProcessorDiff(postprocessorId1: string, versionId1: string, postprocessorId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId1 === null || postprocessorId1 === undefined) {
            throw new Error('Required parameter postprocessorId1 was null or undefined when calling getPostProcessorDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getPostProcessorDiff.');
        }

        if (postprocessorId2 === null || postprocessorId2 === undefined) {
            throw new Error('Required parameter postprocessorId2 was null or undefined when calling getPostProcessorDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getPostProcessorDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPostProcessorDiff>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(postprocessorId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param postprocessorId ID of PostProcessor
     * @param versionId Version of PostProcessor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessorExampleOutput(postprocessorId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessOutput>;
    public getPostProcessorExampleOutput(postprocessorId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessOutput>>;
    public getPostProcessorExampleOutput(postprocessorId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessOutput>>;
    public getPostProcessorExampleOutput(postprocessorId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling getPostProcessorExampleOutput.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getPostProcessorExampleOutput.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPostProcessOutput>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/v/${encodeURIComponent(String(versionId))}/exampleOutput`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified PostProcessor
     * @param postprocessorId ID of PostProcessor to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessorHead(postprocessorId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessor>;
    public getPostProcessorHead(postprocessorId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessor>>;
    public getPostProcessorHead(postprocessorId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessor>>;
    public getPostProcessorHead(postprocessorId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling getPostProcessorHead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPostProcessor>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param postprocessorId ID of PostProcessor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessorHeadExampleOutput(postprocessorId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessOutput>;
    public getPostProcessorHeadExampleOutput(postprocessorId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessOutput>>;
    public getPostProcessorHeadExampleOutput(postprocessorId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessOutput>>;
    public getPostProcessorHeadExampleOutput(postprocessorId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling getPostProcessorHeadExampleOutput.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPostProcessOutput>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/exampleOutput`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param postprocessorId ID of PostProcessor to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessorHeadExampleOutputLog(postprocessorId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getPostProcessorHeadExampleOutputLog(postprocessorId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getPostProcessorHeadExampleOutputLog(postprocessorId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getPostProcessorHeadExampleOutputLog(postprocessorId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling getPostProcessorHeadExampleOutputLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/exampleOutput/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified PostProcessor
     * @param postprocessorId ID of PostProcessor to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessorHistory(postprocessorId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getPostProcessorHistory(postprocessorId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getPostProcessorHistory(postprocessorId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getPostProcessorHistory(postprocessorId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling getPostProcessorHistory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/history`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified PostProcessor
     * @param postprocessorId ID of PostProcessor to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessorLog(postprocessorId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getPostProcessorLog(postprocessorId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getPostProcessorLog(postprocessorId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getPostProcessorLog(postprocessorId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling getPostProcessorLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified PostProcessor
     * @param postprocessorId ID of PostProcessor to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessorSharing(postprocessorId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getPostProcessorSharing(postprocessorId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getPostProcessorSharing(postprocessorId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getPostProcessorSharing(postprocessorId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling getPostProcessorSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified PostProcessor
     * @param postprocessorId ID of PostProcessor to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessorUsages(postprocessorId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getPostProcessorUsages(postprocessorId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getPostProcessorUsages(postprocessorId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getPostProcessorUsages(postprocessorId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling getPostProcessorUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified PostProcessor
     * @param postprocessorId ID of PostProcessor to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessorVersionSummary(postprocessorId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getPostProcessorVersionSummary(postprocessorId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getPostProcessorVersionSummary(postprocessorId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getPostProcessorVersionSummary(postprocessorId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling getPostProcessorVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getPostProcessorVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified PostProcessor version
     * @param postprocessorId ID of PostProcessor to return
     * @param versionId Version of PostProcessor to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostProcessorVersionUsages(postprocessorId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getPostProcessorVersionUsages(postprocessorId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getPostProcessorVersionUsages(postprocessorId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getPostProcessorVersionUsages(postprocessorId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling getPostProcessorVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getPostProcessorVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Project
     * @param projectId ID of Project to return
     * @param versionId Version of Project to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProject(projectId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProject>;
    public getProject(projectId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProject>>;
    public getProject(projectId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProject>>;
    public getProject(projectId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProject.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProject>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectCadFile(projectId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getProjectCadFile(projectId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getProjectCadFile(projectId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getProjectCadFile(projectId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectCadFile.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectCadFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cad/file`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectCuttingList(projectId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCuttingList>;
    public getProjectCuttingList(projectId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCuttingList>>;
    public getProjectCuttingList(projectId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCuttingList>>;
    public getProjectCuttingList(projectId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectCuttingList.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectCuttingList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCuttingList>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param groupsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedSheetSet>;
    public getProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedSheetSet>>;
    public getProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedSheetSet>>;
    public getProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectCuttingListGroupsSheets.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectCuttingListGroupsSheets.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectCuttingListGroupsSheets.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedSheetSet>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param groupsId 
     * @param sheetsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectCuttingListGroupsSheetsSheetsOutput(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessOutput>;
    public getProjectCuttingListGroupsSheetsSheetsOutput(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessOutput>>;
    public getProjectCuttingListGroupsSheetsSheetsOutput(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessOutput>>;
    public getProjectCuttingListGroupsSheetsSheetsOutput(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsOutput.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsOutput.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsOutput.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsOutput.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPostProcessOutput>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/output`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param groupsId 
     * @param sheetsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedToolSelectionConstraints>;
    public getProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedToolSelectionConstraints>>;
    public getProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedToolSelectionConstraints>>;
    public getProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedToolSelectionConstraints>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolSelection`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param groupsId 
     * @param sheetsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectCuttingListGroupsSheetsSheetsToolpath(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedToolpath>;
    public getProjectCuttingListGroupsSheetsSheetsToolpath(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedToolpath>>;
    public getProjectCuttingListGroupsSheetsSheetsToolpath(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedToolpath>>;
    public getProjectCuttingListGroupsSheetsSheetsToolpath(projectId: string, versionId: string, groupsId: string, sheetsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsToolpath.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsToolpath.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsToolpath.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling getProjectCuttingListGroupsSheetsSheetsToolpath.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedToolpath>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolpath`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Projects
     * @param projectId1 ID of first Project to compare
     * @param versionId1 Version of first Project to compare
     * @param projectId2 ID of second Project to compare
     * @param versionId2 Version of second Project to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiff(projectId1: string, versionId1: string, projectId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProjectDiff>;
    public getProjectDiff(projectId1: string, versionId1: string, projectId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProjectDiff>>;
    public getProjectDiff(projectId1: string, versionId1: string, projectId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProjectDiff>>;
    public getProjectDiff(projectId1: string, versionId1: string, projectId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId1 === null || projectId1 === undefined) {
            throw new Error('Required parameter projectId1 was null or undefined when calling getProjectDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getProjectDiff.');
        }

        if (projectId2 === null || projectId2 === undefined) {
            throw new Error('Required parameter projectId2 was null or undefined when calling getProjectDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getProjectDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProjectDiff>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(projectId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all available tools for a project group
     * @param projectId ID of project
     * @param projectVersion Version of project
     * @param groupId Cutting list group
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectGroupToolChoices(projectId: string, projectVersion: string, groupId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ToolChoice>>;
    public getProjectGroupToolChoices(projectId: string, projectVersion: string, groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ToolChoice>>>;
    public getProjectGroupToolChoices(projectId: string, projectVersion: string, groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ToolChoice>>>;
    public getProjectGroupToolChoices(projectId: string, projectVersion: string, groupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectGroupToolChoices.');
        }

        if (projectVersion === null || projectVersion === undefined) {
            throw new Error('Required parameter projectVersion was null or undefined when calling getProjectGroupToolChoices.');
        }

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling getProjectGroupToolChoices.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ToolChoice>>('get',`${this.basePath}/projecttools/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(projectVersion))}/groups/${encodeURIComponent(String(groupId))}/tools`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Project
     * @param projectId ID of Project to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHead(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedProject>;
    public getProjectHead(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProject>>;
    public getProjectHead(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProject>>;
    public getProjectHead(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedProject>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCadFile(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getProjectHeadCadFile(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getProjectHeadCadFile(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getProjectHeadCadFile(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCadFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cad/file`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCadFileLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadCadFileLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadCadFileLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadCadFileLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCadFileLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cad/file/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCuttingList(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCuttingList>;
    public getProjectHeadCuttingList(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCuttingList>>;
    public getProjectHeadCuttingList(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCuttingList>>;
    public getProjectHeadCuttingList(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCuttingList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCuttingList>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param groupsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedSheetSet>;
    public getProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedSheetSet>>;
    public getProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedSheetSet>>;
    public getProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCuttingListGroupsSheets.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectHeadCuttingListGroupsSheets.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedSheetSet>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param groupsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCuttingListGroupsSheetsLog(projectId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadCuttingListGroupsSheetsLog(projectId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadCuttingListGroupsSheetsLog(projectId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadCuttingListGroupsSheetsLog(projectId: string, groupsId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsLog.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param groupsId 
     * @param sheetsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCuttingListGroupsSheetsSheetsOutput(projectId: string, groupsId: string, sheetsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessOutput>;
    public getProjectHeadCuttingListGroupsSheetsSheetsOutput(projectId: string, groupsId: string, sheetsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessOutput>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsOutput(projectId: string, groupsId: string, sheetsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessOutput>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsOutput(projectId: string, groupsId: string, sheetsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsOutput.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsOutput.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsOutput.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPostProcessOutput>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/output`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param groupsId 
     * @param sheetsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCuttingListGroupsSheetsSheetsOutputLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadCuttingListGroupsSheetsSheetsOutputLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsOutputLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsOutputLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsOutputLog.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsOutputLog.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsOutputLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/output/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param groupsId 
     * @param sheetsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedToolSelectionConstraints>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedToolSelectionConstraints>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedToolSelectionConstraints>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedToolSelectionConstraints>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolSelection`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param groupsId 
     * @param sheetsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCuttingListGroupsSheetsSheetsToolSelectionLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolSelectionLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolSelectionLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolSelectionLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolSelectionLog.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolSelectionLog.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolSelectionLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolSelection/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param groupsId 
     * @param sheetsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCuttingListGroupsSheetsSheetsToolpath(projectId: string, groupsId: string, sheetsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedToolpath>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolpath(projectId: string, groupsId: string, sheetsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedToolpath>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolpath(projectId: string, groupsId: string, sheetsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedToolpath>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolpath(projectId: string, groupsId: string, sheetsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolpath.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolpath.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolpath.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedToolpath>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolpath`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param groupsId 
     * @param sheetsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCuttingListGroupsSheetsSheetsToolpathLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolpathLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolpathLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadCuttingListGroupsSheetsSheetsToolpathLog(projectId: string, groupsId: string, sheetsId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolpathLog.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolpathLog.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling getProjectHeadCuttingListGroupsSheetsSheetsToolpathLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolpath/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadCuttingListLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadCuttingListLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadCuttingListLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadCuttingListLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadCuttingListLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadParts(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartMap>;
    public getProjectHeadParts(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartMap>>;
    public getProjectHeadParts(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartMap>>;
    public getProjectHeadParts(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadParts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartMap>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadPartsLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadPartsLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadPartsLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadPartsLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadPartsLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param partsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadPartsPart(projectId: string, partsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPart>;
    public getProjectHeadPartsPart(projectId: string, partsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPart>>;
    public getProjectHeadPartsPart(projectId: string, partsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPart>>;
    public getProjectHeadPartsPart(projectId: string, partsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadPartsPart.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectHeadPartsPart.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPart>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadPartsPartLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadPartsPartLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadPartsPartLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadPartsPartLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadPartsPartLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectHeadPartsPartLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param partsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadPartsSlice(projectId: string, partsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedSlice>;
    public getProjectHeadPartsSlice(projectId: string, partsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedSlice>>;
    public getProjectHeadPartsSlice(projectId: string, partsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedSlice>>;
    public getProjectHeadPartsSlice(projectId: string, partsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadPartsSlice.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectHeadPartsSlice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedSlice>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}/slice`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadPartsSliceLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadPartsSliceLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadPartsSliceLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadPartsSliceLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadPartsSliceLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectHeadPartsSliceLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}/slice/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param partsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadPartsStl(projectId: string, partsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getProjectHeadPartsStl(projectId: string, partsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getProjectHeadPartsStl(projectId: string, partsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getProjectHeadPartsStl(projectId: string, partsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadPartsStl.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectHeadPartsStl.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}/stl`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadPartsStlLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadPartsStlLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadPartsStlLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadPartsStlLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadPartsStlLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectHeadPartsStlLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}/stl/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param partsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadPartsThumbnail(projectId: string, partsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getProjectHeadPartsThumbnail(projectId: string, partsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getProjectHeadPartsThumbnail(projectId: string, partsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getProjectHeadPartsThumbnail(projectId: string, partsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadPartsThumbnail.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectHeadPartsThumbnail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}/thumbnail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param partsId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadPartsThumbnailLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadPartsThumbnailLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadPartsThumbnailLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadPartsThumbnailLog(projectId: string, partsId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadPartsThumbnailLog.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectHeadPartsThumbnailLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}/thumbnail/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadThumbnail(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getProjectHeadThumbnail(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getProjectHeadThumbnail(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getProjectHeadThumbnail(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadThumbnail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/thumbnail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHeadThumbnailLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectHeadThumbnailLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectHeadThumbnailLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectHeadThumbnailLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHeadThumbnailLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/thumbnail/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Project
     * @param projectId ID of Project to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectHistory(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getProjectHistory(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getProjectHistory(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getProjectHistory(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectHistory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/history`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Project
     * @param projectId ID of Project to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getProjectLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getProjectLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getProjectLog(projectId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectParts(projectId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartMap>;
    public getProjectParts(projectId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartMap>>;
    public getProjectParts(projectId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartMap>>;
    public getProjectParts(projectId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectParts.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectParts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPartMap>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param partsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectPartsPart(projectId: string, versionId: string, partsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedPart>;
    public getProjectPartsPart(projectId: string, versionId: string, partsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPart>>;
    public getProjectPartsPart(projectId: string, versionId: string, partsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPart>>;
    public getProjectPartsPart(projectId: string, versionId: string, partsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectPartsPart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectPartsPart.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectPartsPart.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedPart>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts/${encodeURIComponent(String(partsId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param partsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectPartsSlice(projectId: string, versionId: string, partsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedSlice>;
    public getProjectPartsSlice(projectId: string, versionId: string, partsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedSlice>>;
    public getProjectPartsSlice(projectId: string, versionId: string, partsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedSlice>>;
    public getProjectPartsSlice(projectId: string, versionId: string, partsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectPartsSlice.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectPartsSlice.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectPartsSlice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedSlice>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts/${encodeURIComponent(String(partsId))}/slice`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param partsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectPartsStl(projectId: string, versionId: string, partsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getProjectPartsStl(projectId: string, versionId: string, partsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getProjectPartsStl(projectId: string, versionId: string, partsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getProjectPartsStl(projectId: string, versionId: string, partsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectPartsStl.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectPartsStl.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectPartsStl.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts/${encodeURIComponent(String(partsId))}/stl`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param partsId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectPartsThumbnail.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectPartsThumbnail.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling getProjectPartsThumbnail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts/${encodeURIComponent(String(partsId))}/thumbnail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Project
     * @param projectId ID of Project to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectSharing(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getProjectSharing(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getProjectSharing(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getProjectSharing(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all available sheets for a project
     * @param projectId ID of project
     * @param projectVersion Version of project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectSheetChoices(projectId: string, projectVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SheetChoice>>;
    public getProjectSheetChoices(projectId: string, projectVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SheetChoice>>>;
    public getProjectSheetChoices(projectId: string, projectVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SheetChoice>>>;
    public getProjectSheetChoices(projectId: string, projectVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectSheetChoices.');
        }

        if (projectVersion === null || projectVersion === undefined) {
            throw new Error('Required parameter projectVersion was null or undefined when calling getProjectSheetChoices.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SheetChoice>>('get',`${this.basePath}/projectsheets/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(projectVersion))}/sheets`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectThumbnail(projectId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getProjectThumbnail(projectId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getProjectThumbnail(projectId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getProjectThumbnail(projectId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectThumbnail.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectThumbnail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/thumbnail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Project
     * @param projectId ID of Project to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectUsages(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getProjectUsages(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getProjectUsages(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getProjectUsages(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Project
     * @param projectId ID of Project to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectVersionSummary(projectId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getProjectVersionSummary(projectId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getProjectVersionSummary(projectId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getProjectVersionSummary(projectId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Project version
     * @param projectId ID of Project to return
     * @param versionId Version of Project to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectVersionUsages(projectId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getProjectVersionUsages(projectId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getProjectVersionUsages(projectId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getProjectVersionUsages(projectId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getProjectVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get details of specific user
     * @param email Email of user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(email: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUser(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUser(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUser(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('get',`${this.basePath}/users/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get details of visible users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsers(observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public getUsers(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public getUsers(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public getUsers(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<User>>('get',`${this.basePath}/users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Workshop
     * @param workshopId ID of Workshop to return
     * @param versionId Version of Workshop to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshop(workshopId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkshop>;
    public getWorkshop(workshopId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkshop>>;
    public getWorkshop(workshopId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkshop>>;
    public getWorkshop(workshopId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshop.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkshop.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkshop>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Workshops
     * @param workshopId1 ID of first Workshop to compare
     * @param versionId1 Version of first Workshop to compare
     * @param workshopId2 ID of second Workshop to compare
     * @param versionId2 Version of second Workshop to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopDiff(workshopId1: string, versionId1: string, workshopId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkshopDiff>;
    public getWorkshopDiff(workshopId1: string, versionId1: string, workshopId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkshopDiff>>;
    public getWorkshopDiff(workshopId1: string, versionId1: string, workshopId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkshopDiff>>;
    public getWorkshopDiff(workshopId1: string, versionId1: string, workshopId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId1 === null || workshopId1 === undefined) {
            throw new Error('Required parameter workshopId1 was null or undefined when calling getWorkshopDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getWorkshopDiff.');
        }

        if (workshopId2 === null || workshopId2 === undefined) {
            throw new Error('Required parameter workshopId2 was null or undefined when calling getWorkshopDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getWorkshopDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkshopDiff>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(workshopId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Workshop
     * @param workshopId ID of Workshop to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopHead(workshopId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkshop>;
    public getWorkshopHead(workshopId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkshop>>;
    public getWorkshopHead(workshopId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkshop>>;
    public getWorkshopHead(workshopId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopHead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedWorkshop>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopHeadMachineInstances(workshopId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstanceMap>;
    public getWorkshopHeadMachineInstances(workshopId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstanceMap>>;
    public getWorkshopHeadMachineInstances(workshopId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstanceMap>>;
    public getWorkshopHeadMachineInstances(workshopId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopHeadMachineInstances.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMachineInstanceMap>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/machineInstances`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopHeadMachineInstancesLog(workshopId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getWorkshopHeadMachineInstancesLog(workshopId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getWorkshopHeadMachineInstancesLog(workshopId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getWorkshopHeadMachineInstancesLog(workshopId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopHeadMachineInstancesLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/machineInstances/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param machineInstancesId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstance>;
    public getWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstance>>;
    public getWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstance>>;
    public getWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopHeadMachineInstancesMachineInstance.');
        }

        if (machineInstancesId === null || machineInstancesId === undefined) {
            throw new Error('Required parameter machineInstancesId was null or undefined when calling getWorkshopHeadMachineInstancesMachineInstance.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMachineInstance>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/machineInstances/${encodeURIComponent(String(machineInstancesId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop to return
     * @param machineInstancesId 
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopHeadMachineInstancesMachineInstanceLog(workshopId: string, machineInstancesId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getWorkshopHeadMachineInstancesMachineInstanceLog(workshopId: string, machineInstancesId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getWorkshopHeadMachineInstancesMachineInstanceLog(workshopId: string, machineInstancesId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getWorkshopHeadMachineInstancesMachineInstanceLog(workshopId: string, machineInstancesId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopHeadMachineInstancesMachineInstanceLog.');
        }

        if (machineInstancesId === null || machineInstancesId === undefined) {
            throw new Error('Required parameter machineInstancesId was null or undefined when calling getWorkshopHeadMachineInstancesMachineInstanceLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/machineInstances/${encodeURIComponent(String(machineInstancesId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Workshop
     * @param workshopId ID of Workshop to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopHistory(workshopId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getWorkshopHistory(workshopId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getWorkshopHistory(workshopId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getWorkshopHistory(workshopId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopHistory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/history`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Workshop
     * @param workshopId ID of Workshop to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopLog(workshopId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getWorkshopLog(workshopId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getWorkshopLog(workshopId: string, from?: string, to?: string, limit?: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getWorkshopLog(workshopId: string, from?: string, to?: string, limit?: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopLog.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get references to machine instances from workshop
     * @param workshopId ID of workshop
     * @param workshopVersion Version of workshop
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopMachineInstanceChoices(workshopId: string, workshopVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MachineChoice>>;
    public getWorkshopMachineInstanceChoices(workshopId: string, workshopVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MachineChoice>>>;
    public getWorkshopMachineInstanceChoices(workshopId: string, workshopVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MachineChoice>>>;
    public getWorkshopMachineInstanceChoices(workshopId: string, workshopVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopMachineInstanceChoices.');
        }

        if (workshopVersion === null || workshopVersion === undefined) {
            throw new Error('Required parameter workshopVersion was null or undefined when calling getWorkshopMachineInstanceChoices.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MachineChoice>>('get',`${this.basePath}/workshopmaterials/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(workshopVersion))}/machines`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get compatible materials with the given machine instance
     * @param workshopId ID of workshop
     * @param workshopVersion Version of workshop
     * @param machineInstance ID of machine instance in workshop
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopMachineInstanceMaterialChoices(workshopId: string, workshopVersion: string, machineInstance: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getWorkshopMachineInstanceMaterialChoices(workshopId: string, workshopVersion: string, machineInstance: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getWorkshopMachineInstanceMaterialChoices(workshopId: string, workshopVersion: string, machineInstance: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getWorkshopMachineInstanceMaterialChoices(workshopId: string, workshopVersion: string, machineInstance: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopMachineInstanceMaterialChoices.');
        }

        if (workshopVersion === null || workshopVersion === undefined) {
            throw new Error('Required parameter workshopVersion was null or undefined when calling getWorkshopMachineInstanceMaterialChoices.');
        }

        if (machineInstance === null || machineInstance === undefined) {
            throw new Error('Required parameter machineInstance was null or undefined when calling getWorkshopMachineInstanceMaterialChoices.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/workshopmaterials/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(workshopVersion))}/machines/${encodeURIComponent(String(machineInstance))}/materials`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param versionId Version of Workshop
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopMachineInstances(workshopId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstanceMap>;
    public getWorkshopMachineInstances(workshopId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstanceMap>>;
    public getWorkshopMachineInstances(workshopId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstanceMap>>;
    public getWorkshopMachineInstances(workshopId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopMachineInstances.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkshopMachineInstances.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMachineInstanceMap>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}/machineInstances`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param versionId Version of Workshop
     * @param machineInstancesId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstance>;
    public getWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstance>>;
    public getWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstance>>;
    public getWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopMachineInstancesMachineInstance.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkshopMachineInstancesMachineInstance.');
        }

        if (machineInstancesId === null || machineInstancesId === undefined) {
            throw new Error('Required parameter machineInstancesId was null or undefined when calling getWorkshopMachineInstancesMachineInstance.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedMachineInstance>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}/machineInstances/${encodeURIComponent(String(machineInstancesId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get compatible materials for the workshop
     * @param workshopId ID of workshop
     * @param workshopVersion Version of workshop
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopMaterialChoices(workshopId: string, workshopVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getWorkshopMaterialChoices(workshopId: string, workshopVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getWorkshopMaterialChoices(workshopId: string, workshopVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getWorkshopMaterialChoices(workshopId: string, workshopVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopMaterialChoices.');
        }

        if (workshopVersion === null || workshopVersion === undefined) {
            throw new Error('Required parameter workshopVersion was null or undefined when calling getWorkshopMaterialChoices.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/workshopmaterials/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(workshopVersion))}/materials`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get compatible machine instances with the given material
     * @param workshopId ID of workshop
     * @param workshopVersion Version of workshop
     * @param material Name of material
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopMaterialMachineInstanceChoices(workshopId: string, workshopVersion: string, material: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MachineChoice>>;
    public getWorkshopMaterialMachineInstanceChoices(workshopId: string, workshopVersion: string, material: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MachineChoice>>>;
    public getWorkshopMaterialMachineInstanceChoices(workshopId: string, workshopVersion: string, material: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MachineChoice>>>;
    public getWorkshopMaterialMachineInstanceChoices(workshopId: string, workshopVersion: string, material: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopMaterialMachineInstanceChoices.');
        }

        if (workshopVersion === null || workshopVersion === undefined) {
            throw new Error('Required parameter workshopVersion was null or undefined when calling getWorkshopMaterialMachineInstanceChoices.');
        }

        if (material === null || material === undefined) {
            throw new Error('Required parameter material was null or undefined when calling getWorkshopMaterialMachineInstanceChoices.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MachineChoice>>('get',`${this.basePath}/workshopmaterials/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(workshopVersion))}/materials/${encodeURIComponent(String(material))}/machines`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Workshop
     * @param workshopId ID of Workshop to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopSharing(workshopId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getWorkshopSharing(workshopId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getWorkshopSharing(workshopId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getWorkshopSharing(workshopId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopSharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Workshop
     * @param workshopId ID of Workshop to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopUsages(workshopId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getWorkshopUsages(workshopId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getWorkshopUsages(workshopId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getWorkshopUsages(workshopId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Workshop
     * @param workshopId ID of Workshop to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopVersionSummary(workshopId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getWorkshopVersionSummary(workshopId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getWorkshopVersionSummary(workshopId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getWorkshopVersionSummary(workshopId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkshopVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Workshop version
     * @param workshopId ID of Workshop to return
     * @param versionId Version of Workshop to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkshopVersionUsages(workshopId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getWorkshopVersionUsages(workshopId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getWorkshopVersionUsages(workshopId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getWorkshopVersionUsages(workshopId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling getWorkshopVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getWorkshopVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Invite new user(s) using email address(es)
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviteUsers(body?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public inviteUsers(body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public inviteUsers(body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public inviteUsers(body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<User>>('post',`${this.basePath}/users/invitations`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Bit
     * @param bitId ID of Bit to update
     * @param versionId Version of Bit to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchBit(bitId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedBitPatch>;
    public patchBit(bitId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedBitPatch>>;
    public patchBit(bitId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedBitPatch>>;
    public patchBit(bitId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling patchBit.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchBit.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedBitPatch>('patch',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Machine
     * @param machineId ID of Machine to update
     * @param versionId Version of Machine to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchMachine(machineId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachinePatch>;
    public patchMachine(machineId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachinePatch>>;
    public patchMachine(machineId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachinePatch>>;
    public patchMachine(machineId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling patchMachine.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchMachine.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachinePatch>('patch',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to update
     * @param versionId Version of OnshapeImport to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchOnshapeImport(onshapeimportId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOnshapeImportPatch>;
    public patchOnshapeImport(onshapeimportId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOnshapeImportPatch>>;
    public patchOnshapeImport(onshapeimportId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOnshapeImportPatch>>;
    public patchOnshapeImport(onshapeimportId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling patchOnshapeImport.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchOnshapeImport.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOnshapeImportPatch>('patch',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a PostProcessor
     * @param postprocessorId ID of PostProcessor to update
     * @param versionId Version of PostProcessor to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchPostProcessor(postprocessorId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessorPatch>;
    public patchPostProcessor(postprocessorId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessorPatch>>;
    public patchPostProcessor(postprocessorId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessorPatch>>;
    public patchPostProcessor(postprocessorId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling patchPostProcessor.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchPostProcessor.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPostProcessorPatch>('patch',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Project
     * @param projectId ID of Project to update
     * @param versionId Version of Project to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProject(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProjectPatch>;
    public patchProject(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProjectPatch>>;
    public patchProject(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProjectPatch>>;
    public patchProject(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling patchProject.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchProject.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProjectPatch>('patch',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProjectCuttingList(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCuttingListPatch>;
    public patchProjectCuttingList(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCuttingListPatch>>;
    public patchProjectCuttingList(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCuttingListPatch>>;
    public patchProjectCuttingList(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling patchProjectCuttingList.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchProjectCuttingList.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCuttingListPatch>('patch',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param groupsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedSheetSetPatch>;
    public patchProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedSheetSetPatch>>;
    public patchProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedSheetSetPatch>>;
    public patchProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling patchProjectCuttingListGroupsSheets.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchProjectCuttingListGroupsSheets.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling patchProjectCuttingListGroupsSheets.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedSheetSetPatch>('patch',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param groupsId 
     * @param sheetsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedToolSelectionConstraintsPatch>;
    public patchProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedToolSelectionConstraintsPatch>>;
    public patchProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedToolSelectionConstraintsPatch>>;
    public patchProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling patchProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling patchProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling patchProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedToolSelectionConstraintsPatch>('patch',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolSelection`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProjectHeadCuttingList(projectId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCuttingListPatch>;
    public patchProjectHeadCuttingList(projectId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCuttingListPatch>>;
    public patchProjectHeadCuttingList(projectId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCuttingListPatch>>;
    public patchProjectHeadCuttingList(projectId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling patchProjectHeadCuttingList.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCuttingListPatch>('patch',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param groupsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedSheetSetPatch>;
    public patchProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedSheetSetPatch>>;
    public patchProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedSheetSetPatch>>;
    public patchProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling patchProjectHeadCuttingListGroupsSheets.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling patchProjectHeadCuttingListGroupsSheets.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedSheetSetPatch>('patch',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param groupsId 
     * @param sheetsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedToolSelectionConstraintsPatch>;
    public patchProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedToolSelectionConstraintsPatch>>;
    public patchProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedToolSelectionConstraintsPatch>>;
    public patchProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling patchProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling patchProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling patchProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedToolSelectionConstraintsPatch>('patch',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolSelection`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProjectHeadParts(projectId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartMapPatch>;
    public patchProjectHeadParts(projectId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartMapPatch>>;
    public patchProjectHeadParts(projectId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartMapPatch>>;
    public patchProjectHeadParts(projectId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling patchProjectHeadParts.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartMapPatch>('patch',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param partsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProjectHeadPartsPart(projectId: string, partsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartPatch>;
    public patchProjectHeadPartsPart(projectId: string, partsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartPatch>>;
    public patchProjectHeadPartsPart(projectId: string, partsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartPatch>>;
    public patchProjectHeadPartsPart(projectId: string, partsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling patchProjectHeadPartsPart.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling patchProjectHeadPartsPart.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartPatch>('patch',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProjectParts(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartMapPatch>;
    public patchProjectParts(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartMapPatch>>;
    public patchProjectParts(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartMapPatch>>;
    public patchProjectParts(projectId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling patchProjectParts.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchProjectParts.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartMapPatch>('patch',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param partsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchProjectPartsPart(projectId: string, versionId: string, partsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartPatch>;
    public patchProjectPartsPart(projectId: string, versionId: string, partsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartPatch>>;
    public patchProjectPartsPart(projectId: string, versionId: string, partsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartPatch>>;
    public patchProjectPartsPart(projectId: string, versionId: string, partsId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling patchProjectPartsPart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchProjectPartsPart.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling patchProjectPartsPart.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartPatch>('patch',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts/${encodeURIComponent(String(partsId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Workshop
     * @param workshopId ID of Workshop to update
     * @param versionId Version of Workshop to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchWorkshop(workshopId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkshopPatch>;
    public patchWorkshop(workshopId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkshopPatch>>;
    public patchWorkshop(workshopId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkshopPatch>>;
    public patchWorkshop(workshopId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling patchWorkshop.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchWorkshop.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkshopPatch>('patch',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchWorkshopHeadMachineInstances(workshopId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstanceMapPatch>;
    public patchWorkshopHeadMachineInstances(workshopId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstanceMapPatch>>;
    public patchWorkshopHeadMachineInstances(workshopId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstanceMapPatch>>;
    public patchWorkshopHeadMachineInstances(workshopId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling patchWorkshopHeadMachineInstances.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachineInstanceMapPatch>('patch',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/machineInstances`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param machineInstancesId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstancePatch>;
    public patchWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstancePatch>>;
    public patchWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstancePatch>>;
    public patchWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling patchWorkshopHeadMachineInstancesMachineInstance.');
        }

        if (machineInstancesId === null || machineInstancesId === undefined) {
            throw new Error('Required parameter machineInstancesId was null or undefined when calling patchWorkshopHeadMachineInstancesMachineInstance.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachineInstancePatch>('patch',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/machineInstances/${encodeURIComponent(String(machineInstancesId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param versionId Version of Workshop
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchWorkshopMachineInstances(workshopId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstanceMapPatch>;
    public patchWorkshopMachineInstances(workshopId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstanceMapPatch>>;
    public patchWorkshopMachineInstances(workshopId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstanceMapPatch>>;
    public patchWorkshopMachineInstances(workshopId: string, versionId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling patchWorkshopMachineInstances.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchWorkshopMachineInstances.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachineInstanceMapPatch>('patch',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}/machineInstances`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param versionId Version of Workshop
     * @param machineInstancesId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstancePatch>;
    public patchWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstancePatch>>;
    public patchWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstancePatch>>;
    public patchWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, body?: Patch, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling patchWorkshopMachineInstancesMachineInstance.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchWorkshopMachineInstancesMachineInstance.');
        }

        if (machineInstancesId === null || machineInstancesId === undefined) {
            throw new Error('Required parameter machineInstancesId was null or undefined when calling patchWorkshopMachineInstancesMachineInstance.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachineInstancePatch>('patch',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}/machineInstances/${encodeURIComponent(String(machineInstancesId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Revoke an invitation
     * @param code Invitation code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revokeInvitation(code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public revokeInvitation(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public revokeInvitation(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public revokeInvitation(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling revokeInvitation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/users/invitations/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Bit summary
     * @param bitId ID of Bit to check
     * @param includeAttributes Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseBit(bitId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseBit(bitId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseBit(bitId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseBit(bitId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling summariseBit.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Machine summary
     * @param machineId ID of Machine to check
     * @param includeAttributes Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseMachine(machineId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseMachine(machineId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseMachine(machineId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseMachine(machineId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling summariseMachine.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get OnshapeImport summary
     * @param onshapeimportId ID of OnshapeImport to check
     * @param includeAttributes Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseOnshapeImport(onshapeimportId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseOnshapeImport(onshapeimportId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseOnshapeImport(onshapeimportId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseOnshapeImport(onshapeimportId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling summariseOnshapeImport.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get PostProcessor summary
     * @param postprocessorId ID of PostProcessor to check
     * @param includeAttributes Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summarisePostProcessor(postprocessorId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summarisePostProcessor(postprocessorId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summarisePostProcessor(postprocessorId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summarisePostProcessor(postprocessorId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling summarisePostProcessor.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Project summary
     * @param projectId ID of Project to check
     * @param includeAttributes Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseProject(projectId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseProject(projectId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseProject(projectId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseProject(projectId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling summariseProject.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Workshop summary
     * @param workshopId ID of Workshop to check
     * @param includeAttributes Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseWorkshop(workshopId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseWorkshop(workshopId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseWorkshop(workshopId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseWorkshop(workshopId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling summariseWorkshop.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Bit
     * @param bitId ID of Bit to update
     * @param versionId Version of Bit to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBit(bitId: string, versionId: string, body?: Bit, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedBitPatch>;
    public updateBit(bitId: string, versionId: string, body?: Bit, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedBitPatch>>;
    public updateBit(bitId: string, versionId: string, body?: Bit, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedBitPatch>>;
    public updateBit(bitId: string, versionId: string, body?: Bit, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling updateBit.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateBit.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedBitPatch>('put',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Bit
     * @param bitId ID of Bit to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBitHead(bitId: string, body?: Bit, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedBitPatch>;
    public updateBitHead(bitId: string, body?: Bit, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedBitPatch>>;
    public updateBitHead(bitId: string, body?: Bit, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedBitPatch>>;
    public updateBitHead(bitId: string, body?: Bit, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling updateBitHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedBitPatch>('put',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Bit
     * @param bitId ID of Bit to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBitSharing(bitId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateBitSharing(bitId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateBitSharing(bitId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateBitSharing(bitId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling updateBitSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Bit
     * @param bitId ID of Bit to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBitVersionSummary(bitId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateBitVersionSummary(bitId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateBitVersionSummary(bitId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateBitVersionSummary(bitId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bitId === null || bitId === undefined) {
            throw new Error('Required parameter bitId was null or undefined when calling updateBitVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateBitVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/bits/${encodeURIComponent(String(bitId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Machine
     * @param machineId ID of Machine to update
     * @param versionId Version of Machine to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMachine(machineId: string, versionId: string, body?: Machine, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachinePatch>;
    public updateMachine(machineId: string, versionId: string, body?: Machine, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachinePatch>>;
    public updateMachine(machineId: string, versionId: string, body?: Machine, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachinePatch>>;
    public updateMachine(machineId: string, versionId: string, body?: Machine, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling updateMachine.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateMachine.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachinePatch>('put',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Machine
     * @param machineId ID of Machine to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMachineHead(machineId: string, body?: Machine, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachinePatch>;
    public updateMachineHead(machineId: string, body?: Machine, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachinePatch>>;
    public updateMachineHead(machineId: string, body?: Machine, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachinePatch>>;
    public updateMachineHead(machineId: string, body?: Machine, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling updateMachineHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachinePatch>('put',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param machineId ID of Machine
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMachineHeadThumbnail(machineId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateMachineHeadThumbnail(machineId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateMachineHeadThumbnail(machineId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateMachineHeadThumbnail(machineId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling updateMachineHeadThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/thumbnail`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Machine
     * @param machineId ID of Machine to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMachineSharing(machineId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateMachineSharing(machineId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateMachineSharing(machineId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateMachineSharing(machineId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling updateMachineSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param machineId ID of Machine
     * @param versionId Version of Machine
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMachineThumbnail(machineId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateMachineThumbnail(machineId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateMachineThumbnail(machineId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateMachineThumbnail(machineId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling updateMachineThumbnail.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateMachineThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/v/${encodeURIComponent(String(versionId))}/thumbnail`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Machine
     * @param machineId ID of Machine to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMachineVersionSummary(machineId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateMachineVersionSummary(machineId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateMachineVersionSummary(machineId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateMachineVersionSummary(machineId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (machineId === null || machineId === undefined) {
            throw new Error('Required parameter machineId was null or undefined when calling updateMachineVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateMachineVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/machines/${encodeURIComponent(String(machineId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update details of current user
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMe(body?: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public updateMe(body?: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public updateMe(body?: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public updateMe(body?: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('put',`${this.basePath}/users/me`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to update
     * @param versionId Version of OnshapeImport to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOnshapeImport(onshapeimportId: string, versionId: string, body?: OnshapeImport, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOnshapeImportPatch>;
    public updateOnshapeImport(onshapeimportId: string, versionId: string, body?: OnshapeImport, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOnshapeImportPatch>>;
    public updateOnshapeImport(onshapeimportId: string, versionId: string, body?: OnshapeImport, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOnshapeImportPatch>>;
    public updateOnshapeImport(onshapeimportId: string, versionId: string, body?: OnshapeImport, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling updateOnshapeImport.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateOnshapeImport.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOnshapeImportPatch>('put',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOnshapeImportHead(onshapeimportId: string, body?: OnshapeImport, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedOnshapeImportPatch>;
    public updateOnshapeImportHead(onshapeimportId: string, body?: OnshapeImport, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedOnshapeImportPatch>>;
    public updateOnshapeImportHead(onshapeimportId: string, body?: OnshapeImport, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedOnshapeImportPatch>>;
    public updateOnshapeImportHead(onshapeimportId: string, body?: OnshapeImport, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling updateOnshapeImportHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedOnshapeImportPatch>('put',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOnshapeImportSharing(onshapeimportId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateOnshapeImportSharing(onshapeimportId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateOnshapeImportSharing(onshapeimportId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateOnshapeImportSharing(onshapeimportId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling updateOnshapeImportSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified OnshapeImport
     * @param onshapeimportId ID of OnshapeImport to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOnshapeImportVersionSummary(onshapeimportId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateOnshapeImportVersionSummary(onshapeimportId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateOnshapeImportVersionSummary(onshapeimportId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateOnshapeImportVersionSummary(onshapeimportId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (onshapeimportId === null || onshapeimportId === undefined) {
            throw new Error('Required parameter onshapeimportId was null or undefined when calling updateOnshapeImportVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateOnshapeImportVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/onshapeimports/${encodeURIComponent(String(onshapeimportId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a PostProcessor
     * @param postprocessorId ID of PostProcessor to update
     * @param versionId Version of PostProcessor to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePostProcessor(postprocessorId: string, versionId: string, body?: PostProcessor, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessorPatch>;
    public updatePostProcessor(postprocessorId: string, versionId: string, body?: PostProcessor, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessorPatch>>;
    public updatePostProcessor(postprocessorId: string, versionId: string, body?: PostProcessor, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessorPatch>>;
    public updatePostProcessor(postprocessorId: string, versionId: string, body?: PostProcessor, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling updatePostProcessor.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updatePostProcessor.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPostProcessorPatch>('put',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of PostProcessor
     * @param postprocessorId ID of PostProcessor to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePostProcessorHead(postprocessorId: string, body?: PostProcessor, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPostProcessorPatch>;
    public updatePostProcessorHead(postprocessorId: string, body?: PostProcessor, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPostProcessorPatch>>;
    public updatePostProcessorHead(postprocessorId: string, body?: PostProcessor, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPostProcessorPatch>>;
    public updatePostProcessorHead(postprocessorId: string, body?: PostProcessor, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling updatePostProcessorHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPostProcessorPatch>('put',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified PostProcessor
     * @param postprocessorId ID of PostProcessor to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePostProcessorSharing(postprocessorId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updatePostProcessorSharing(postprocessorId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updatePostProcessorSharing(postprocessorId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updatePostProcessorSharing(postprocessorId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling updatePostProcessorSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified PostProcessor
     * @param postprocessorId ID of PostProcessor to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePostProcessorVersionSummary(postprocessorId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updatePostProcessorVersionSummary(postprocessorId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updatePostProcessorVersionSummary(postprocessorId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updatePostProcessorVersionSummary(postprocessorId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postprocessorId === null || postprocessorId === undefined) {
            throw new Error('Required parameter postprocessorId was null or undefined when calling updatePostProcessorVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updatePostProcessorVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/postprocessors/${encodeURIComponent(String(postprocessorId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Project
     * @param projectId ID of Project to update
     * @param versionId Version of Project to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProject(projectId: string, versionId: string, body?: Project, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProjectPatch>;
    public updateProject(projectId: string, versionId: string, body?: Project, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProjectPatch>>;
    public updateProject(projectId: string, versionId: string, body?: Project, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProjectPatch>>;
    public updateProject(projectId: string, versionId: string, body?: Project, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProject.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProject.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProjectPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectCadFile(projectId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateProjectCadFile(projectId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateProjectCadFile(projectId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateProjectCadFile(projectId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectCadFile.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProjectCadFile.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cad/file`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectCuttingList(projectId: string, versionId: string, body?: CuttingList, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCuttingListPatch>;
    public updateProjectCuttingList(projectId: string, versionId: string, body?: CuttingList, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCuttingListPatch>>;
    public updateProjectCuttingList(projectId: string, versionId: string, body?: CuttingList, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCuttingListPatch>>;
    public updateProjectCuttingList(projectId: string, versionId: string, body?: CuttingList, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectCuttingList.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProjectCuttingList.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCuttingListPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param groupsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, body?: SheetSet, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedSheetSetPatch>;
    public updateProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, body?: SheetSet, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedSheetSetPatch>>;
    public updateProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, body?: SheetSet, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedSheetSetPatch>>;
    public updateProjectCuttingListGroupsSheets(projectId: string, versionId: string, groupsId: string, body?: SheetSet, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectCuttingListGroupsSheets.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProjectCuttingListGroupsSheets.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling updateProjectCuttingListGroupsSheets.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedSheetSetPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param groupsId 
     * @param sheetsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, body?: ToolSelectionConstraints, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedToolSelectionConstraintsPatch>;
    public updateProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, body?: ToolSelectionConstraints, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedToolSelectionConstraintsPatch>>;
    public updateProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, body?: ToolSelectionConstraints, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedToolSelectionConstraintsPatch>>;
    public updateProjectCuttingListGroupsSheetsSheetsToolSelection(projectId: string, versionId: string, groupsId: string, sheetsId: string, body?: ToolSelectionConstraints, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling updateProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling updateProjectCuttingListGroupsSheetsSheetsToolSelection.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedToolSelectionConstraintsPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolSelection`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Project
     * @param projectId ID of Project to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectHead(projectId: string, body?: Project, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedProjectPatch>;
    public updateProjectHead(projectId: string, body?: Project, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedProjectPatch>>;
    public updateProjectHead(projectId: string, body?: Project, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedProjectPatch>>;
    public updateProjectHead(projectId: string, body?: Project, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedProjectPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectHeadCadFile(projectId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateProjectHeadCadFile(projectId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateProjectHeadCadFile(projectId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateProjectHeadCadFile(projectId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectHeadCadFile.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cad/file`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectHeadCuttingList(projectId: string, body?: CuttingList, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCuttingListPatch>;
    public updateProjectHeadCuttingList(projectId: string, body?: CuttingList, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCuttingListPatch>>;
    public updateProjectHeadCuttingList(projectId: string, body?: CuttingList, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCuttingListPatch>>;
    public updateProjectHeadCuttingList(projectId: string, body?: CuttingList, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectHeadCuttingList.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCuttingListPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param groupsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, body?: SheetSet, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedSheetSetPatch>;
    public updateProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, body?: SheetSet, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedSheetSetPatch>>;
    public updateProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, body?: SheetSet, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedSheetSetPatch>>;
    public updateProjectHeadCuttingListGroupsSheets(projectId: string, groupsId: string, body?: SheetSet, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectHeadCuttingListGroupsSheets.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling updateProjectHeadCuttingListGroupsSheets.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedSheetSetPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param groupsId 
     * @param sheetsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, body?: ToolSelectionConstraints, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedToolSelectionConstraintsPatch>;
    public updateProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, body?: ToolSelectionConstraints, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedToolSelectionConstraintsPatch>>;
    public updateProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, body?: ToolSelectionConstraints, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedToolSelectionConstraintsPatch>>;
    public updateProjectHeadCuttingListGroupsSheetsSheetsToolSelection(projectId: string, groupsId: string, sheetsId: string, body?: ToolSelectionConstraints, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (groupsId === null || groupsId === undefined) {
            throw new Error('Required parameter groupsId was null or undefined when calling updateProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }

        if (sheetsId === null || sheetsId === undefined) {
            throw new Error('Required parameter sheetsId was null or undefined when calling updateProjectHeadCuttingListGroupsSheetsSheetsToolSelection.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedToolSelectionConstraintsPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/cuttingList/groups/${encodeURIComponent(String(groupsId))}/sheets/sheets/${encodeURIComponent(String(sheetsId))}/toolSelection`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectHeadParts(projectId: string, body?: { [key: string]: Part; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartMapPatch>;
    public updateProjectHeadParts(projectId: string, body?: { [key: string]: Part; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartMapPatch>>;
    public updateProjectHeadParts(projectId: string, body?: { [key: string]: Part; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartMapPatch>>;
    public updateProjectHeadParts(projectId: string, body?: { [key: string]: Part; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectHeadParts.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartMapPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param partsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectHeadPartsPart(projectId: string, partsId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartPatch>;
    public updateProjectHeadPartsPart(projectId: string, partsId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartPatch>>;
    public updateProjectHeadPartsPart(projectId: string, partsId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartPatch>>;
    public updateProjectHeadPartsPart(projectId: string, partsId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectHeadPartsPart.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateProjectHeadPartsPart.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param partsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectHeadPartsStl(projectId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateProjectHeadPartsStl(projectId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateProjectHeadPartsStl(projectId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateProjectHeadPartsStl(projectId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectHeadPartsStl.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateProjectHeadPartsStl.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}/stl`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param partsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectHeadPartsThumbnail(projectId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateProjectHeadPartsThumbnail(projectId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateProjectHeadPartsThumbnail(projectId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateProjectHeadPartsThumbnail(projectId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectHeadPartsThumbnail.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateProjectHeadPartsThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/parts/${encodeURIComponent(String(partsId))}/thumbnail`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectHeadThumbnail(projectId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateProjectHeadThumbnail(projectId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateProjectHeadThumbnail(projectId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateProjectHeadThumbnail(projectId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectHeadThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/thumbnail`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectParts(projectId: string, versionId: string, body?: { [key: string]: Part; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartMapPatch>;
    public updateProjectParts(projectId: string, versionId: string, body?: { [key: string]: Part; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartMapPatch>>;
    public updateProjectParts(projectId: string, versionId: string, body?: { [key: string]: Part; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartMapPatch>>;
    public updateProjectParts(projectId: string, versionId: string, body?: { [key: string]: Part; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectParts.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProjectParts.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartMapPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param partsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectPartsPart(projectId: string, versionId: string, partsId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedPartPatch>;
    public updateProjectPartsPart(projectId: string, versionId: string, partsId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedPartPatch>>;
    public updateProjectPartsPart(projectId: string, versionId: string, partsId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedPartPatch>>;
    public updateProjectPartsPart(projectId: string, versionId: string, partsId: string, body?: Part, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectPartsPart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProjectPartsPart.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateProjectPartsPart.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedPartPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts/${encodeURIComponent(String(partsId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param partsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectPartsStl(projectId: string, versionId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateProjectPartsStl(projectId: string, versionId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateProjectPartsStl(projectId: string, versionId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateProjectPartsStl(projectId: string, versionId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectPartsStl.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProjectPartsStl.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateProjectPartsStl.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts/${encodeURIComponent(String(partsId))}/stl`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param partsId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateProjectPartsThumbnail(projectId: string, versionId: string, partsId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectPartsThumbnail.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProjectPartsThumbnail.');
        }

        if (partsId === null || partsId === undefined) {
            throw new Error('Required parameter partsId was null or undefined when calling updateProjectPartsThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/parts/${encodeURIComponent(String(partsId))}/thumbnail`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Project
     * @param projectId ID of Project to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectSharing(projectId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateProjectSharing(projectId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateProjectSharing(projectId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateProjectSharing(projectId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId ID of Project
     * @param versionId Version of Project
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectThumbnail(projectId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateProjectThumbnail(projectId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateProjectThumbnail(projectId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateProjectThumbnail(projectId: string, versionId: string, body?: string, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectThumbnail.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProjectThumbnail.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/v/${encodeURIComponent(String(versionId))}/thumbnail`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Project
     * @param projectId ID of Project to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectVersionSummary(projectId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateProjectVersionSummary(projectId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateProjectVersionSummary(projectId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateProjectVersionSummary(projectId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateProjectVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateProjectVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/projects/${encodeURIComponent(String(projectId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update details of specific user
     * @param email Email of user
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUser(email: string, body?: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public updateUser(email: string, body?: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public updateUser(email: string, body?: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public updateUser(email: string, body?: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling updateUser.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('put',`${this.basePath}/users/${encodeURIComponent(String(email))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Workshop
     * @param workshopId ID of Workshop to update
     * @param versionId Version of Workshop to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkshop(workshopId: string, versionId: string, body?: Workshop, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkshopPatch>;
    public updateWorkshop(workshopId: string, versionId: string, body?: Workshop, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkshopPatch>>;
    public updateWorkshop(workshopId: string, versionId: string, body?: Workshop, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkshopPatch>>;
    public updateWorkshop(workshopId: string, versionId: string, body?: Workshop, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling updateWorkshop.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateWorkshop.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkshopPatch>('put',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Workshop
     * @param workshopId ID of Workshop to update
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkshopHead(workshopId: string, body?: Workshop, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedWorkshopPatch>;
    public updateWorkshopHead(workshopId: string, body?: Workshop, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedWorkshopPatch>>;
    public updateWorkshopHead(workshopId: string, body?: Workshop, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedWorkshopPatch>>;
    public updateWorkshopHead(workshopId: string, body?: Workshop, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling updateWorkshopHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedWorkshopPatch>('put',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkshopHeadMachineInstances(workshopId: string, body?: { [key: string]: MachineInstance; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstanceMapPatch>;
    public updateWorkshopHeadMachineInstances(workshopId: string, body?: { [key: string]: MachineInstance; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstanceMapPatch>>;
    public updateWorkshopHeadMachineInstances(workshopId: string, body?: { [key: string]: MachineInstance; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstanceMapPatch>>;
    public updateWorkshopHeadMachineInstances(workshopId: string, body?: { [key: string]: MachineInstance; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling updateWorkshopHeadMachineInstances.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachineInstanceMapPatch>('put',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/machineInstances`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param machineInstancesId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstancePatch>;
    public updateWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstancePatch>>;
    public updateWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstancePatch>>;
    public updateWorkshopHeadMachineInstancesMachineInstance(workshopId: string, machineInstancesId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling updateWorkshopHeadMachineInstancesMachineInstance.');
        }

        if (machineInstancesId === null || machineInstancesId === undefined) {
            throw new Error('Required parameter machineInstancesId was null or undefined when calling updateWorkshopHeadMachineInstancesMachineInstance.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachineInstancePatch>('put',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/machineInstances/${encodeURIComponent(String(machineInstancesId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param versionId Version of Workshop
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkshopMachineInstances(workshopId: string, versionId: string, body?: { [key: string]: MachineInstance; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstanceMapPatch>;
    public updateWorkshopMachineInstances(workshopId: string, versionId: string, body?: { [key: string]: MachineInstance; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstanceMapPatch>>;
    public updateWorkshopMachineInstances(workshopId: string, versionId: string, body?: { [key: string]: MachineInstance; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstanceMapPatch>>;
    public updateWorkshopMachineInstances(workshopId: string, versionId: string, body?: { [key: string]: MachineInstance; }, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling updateWorkshopMachineInstances.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateWorkshopMachineInstances.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachineInstanceMapPatch>('put',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}/machineInstances`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workshopId ID of Workshop
     * @param versionId Version of Workshop
     * @param machineInstancesId 
     * @param body 
     * @param updateAllReferences 
     * @param updateMyReferences 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedMachineInstancePatch>;
    public updateWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedMachineInstancePatch>>;
    public updateWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedMachineInstancePatch>>;
    public updateWorkshopMachineInstancesMachineInstance(workshopId: string, versionId: string, machineInstancesId: string, body?: MachineInstance, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling updateWorkshopMachineInstancesMachineInstance.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateWorkshopMachineInstancesMachineInstance.');
        }

        if (machineInstancesId === null || machineInstancesId === undefined) {
            throw new Error('Required parameter machineInstancesId was null or undefined when calling updateWorkshopMachineInstancesMachineInstance.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedMachineInstancePatch>('put',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/v/${encodeURIComponent(String(versionId))}/machineInstances/${encodeURIComponent(String(machineInstancesId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Workshop
     * @param workshopId ID of Workshop to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkshopSharing(workshopId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateWorkshopSharing(workshopId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateWorkshopSharing(workshopId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateWorkshopSharing(workshopId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling updateWorkshopSharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Workshop
     * @param workshopId ID of Workshop to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkshopVersionSummary(workshopId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateWorkshopVersionSummary(workshopId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateWorkshopVersionSummary(workshopId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateWorkshopVersionSummary(workshopId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workshopId === null || workshopId === undefined) {
            throw new Error('Required parameter workshopId was null or undefined when calling updateWorkshopVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateWorkshopVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/workshops/${encodeURIComponent(String(workshopId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
